// Users for Leaderboard
const user = {
  email: "user email",
  name: "user name",
  groups: ['Phone Banking', 'Door Knocking', 'IT'],  
  dataLoaded: false
};

export const initState = {  
  user
} 

export default (state = initState, action) => {
  switch (action.type) {    
    case 'SET_USER':
      console.log('SET_USER')     
      return {
        ...state,
        user: {
          ...action.userObj,          
          dataLoaded: true
        }
      };    
    default:
      return state
  }
}
