/*eslint-disable*/
import { v4 as uuid } from 'uuid';
import React from 'react'

// Orders Data
// const filteredDataHasLoaded = false
const finishedSettingRange = false
const dataDownloadTableDataCSV = []
const currentDashboardCSVData = []
const recentDashboardCSVData = []
const yearlyDashboardCSVData = []
const yearsQty = 0
const rangeOfData = []
const metricsIdsArray = []
const nextYearsDataLoaded = false
const dataLoaded = false
const currentlyDeleting = false
const deleteMetricModal = false
const tablesEditable = false
const submitted = false
const monthToStartFilter = ''
const recentColumnHeaders = {metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: "Quarter Target", baseline: "Baseline", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", actions: "Actions"}
const yearlyColumnHeaders = {metricType: "Metric Type",metricDescription: "Metric Description", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul",  aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", actions: "Actions"}
const dataDownloadColumnHeaders = {metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: "Quarter Target", baseline: "Baseline", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", status: "Status", actions: "Actions"}




export const initState = {
  tablesEditable,
  recentColumnHeaders,
  yearlyColumnHeaders,
  dataDownloadColumnHeaders,
  dataLoaded,
  nextYearsDataLoaded,
  metricsIdsArray,
  rangeOfData,
  yearsQty,
  finishedSettingRange,
  currentDashboardCSVData,
  recentDashboardCSVData,
  yearlyDashboardCSVData,
  dataDownloadTableDataCSV
}

export default (state = initState, action) => {
  console.log('initstate', action)
  switch (action.type) {
    case   "SET_DASHBOARD_EDITABLE":
      var toReturn = {...state}
      toReturn.tablesEditable = !toReturn.tablesEditable
      toReturn.rowIndex = action.index
      console.log('toReturn', 'settingeditable', toReturn.tablesEditable)
      return toReturn
      case   "SET_METRICSDATA":
        var toReturn = {...state}
        toReturn.data = action.dataRows
        toReturn.monthToStartFilter = action.monthToStartFilter
        toReturn.metricsIdsArray = action.idsArray
        toReturn.dataLoaded = true
        return toReturn


        case "SET_NEXTYEARSMETRICSDATA":
          var toReturn = {...state}
          toReturn.nextYearsMetrics = action.nextYearsDocumentArray
          return toReturn

          case "FINISHED_SETTING_RANGE":
            var toReturn = {...state}
            toReturn.finishedSettingRange = action.boolean
            return toReturn


          case "SET_CURRENT_DASHBOARD_EXPORT_DATA":
            var toReturn = {...state}
            toReturn.currentDashboardCSVData = action.csvData
            return toReturn


            case "SET_RECENT_DASHBOARD_EXPORT_DATA":
              var toReturn = {...state}
              toReturn.recentDashboardCSVData = action.csvData
              return toReturn

              case "SET_YEARLY_DASHBOARD_EXPORT_DATA":
                var toReturn = {...state}
                toReturn.yearlyDashboardCSVData = action.csvData
                return toReturn

                case "SET_DATA_DOWNLOAD_DASHBOARD_EXPORT_DATA":
                var toReturn = {...state}
                toReturn.dataDownloadTableDataCSV = action.csvData
                return toReturn

          case  "SET_RANGE_OF_YEARS_METRIC_DATA":
            var toReturn = {...state}
            toReturn.rangeOfData = action.range
            toReturn.yearsQty = action.yearsQty
            return toReturn

        case  "SET_NEXTYEARSMETRICSDATALOADED":
          var toReturn = {...state}
          toReturn.nextYearsDataLoaded = true
          return toReturn

        case "SET_ADDEDMETRICSDATA":
          console.log('testing', 'reducer')
        var toReturn = {...state}
        toReturn.submitted = true
        return toReturn
        case "SET_UPDATED_METRIC":
        var toReturn = {...state}
        toReturn.data = action.data
        return toReturn
        case "SET_UPDATED_NEXT_YEAR_METRIC":
          var toReturn = {...state}
          toReturn.nextYearsMetrics = action.allNextYearData
          return toReturn
        case "SET_DELETED_METRICS":
          var toReturn = {...state}
          toReturn.data = action.filteredData
          toReturn.deleteMetricModal = false
          toReturn.currentlyDeleting = false
          toReturn.metricsIdsArray = action.idsToReturn
          return toReturn
          case "SET_DELETED_METRICS_NEW_CURRENT_MONTH":
            var toReturn = {...state}
            toReturn.deleteMetricModal = false
            toReturn.currentlyDeleting = false
            return toReturn
          case "SET_ENABLED_DISABLED_METRICS":
            var toReturn = {...state}
            toReturn.data = action.data
            return toReturn
            case "SET_DELETEMETRICMODAL":
              var toReturn = {...state}
              toReturn.deleteMetricModal = action.boolean
              return toReturn
              case "SET_CURRENTLYDELETING":
                var toReturn = {...state}
                toReturn.currentlyDeleting = action.boolean
                return toReturn
                case "SET_FILTERED_DATA_HAS_LOADED":
                  var toReturn = {...state}
                  return toReturn
            case "SET_CHANGED_HEADERS":
              var toReturn = {...state}
              toReturn.recentColumnHeaders = action.recent
              toReturn.yearlyColumnHeaders = action.yearly

              return toReturn

              case  "SET_UPDATED_RANGE":
                var toReturn = {...state}
                toReturn.rangeOfData = action.data

                return toReturn

    default:
      return state
  }
}
