import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";
import { Grid } from "@material-ui/core";

// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions'
import { tableToFirestoreObjects, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetUsersList } from "store/actions/adminActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: ''
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  disableUser = (uid) => {
    db.collection("TEST-users").doc(uid).set({
      disable: true
    }, {merge: true}).then(() => {
      window.location.reload()  
    }).catch((err) => {
      console.log(`${err}`)
    })
  }


  loadData = () => {
    console.log(this.props.user)
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop }
      const uid = colObj.UID
      const username = colObj.username
      // Load Data for Summary Cards and add buttons     
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Link to={`/chat/${uid}`}>
          <Button
            color="primary" 
            fullWidth
            // onClick={
            //   () => this.handleClickOpen("assignActionModal",
            //     volUID
            //   )}
          >CHAT</Button>
          </Link>),
        disable: (
          this.props.user.admin ?
          <Button
            color="danger" 
            fullWidth
            onClick={
                () => this.disableUser(uid)}
          >DISABLE</Button> : null
        )
      }
    })

    this.setState({
      usersData,
      dataLoaded: true
    })
  }

  handleChange = (docID) => {
    console.log(docID)
  }

  render() {
    const { AuthBool, user, classes } = this.props;
    if (AuthBool) {
      if (this.props.users.dataLoaded && this.props.user.dataLoaded
        && !this.state.dataLoaded) {
        this.loadData()
      }
    }
        function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
        row[id] !== undefined ?
          String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
          true
      );
    }
    if (user.termGroups) {
      console.log(user.termGroups.length)
    
      if (user.termGroups.length < 2) {
        return <Redirect to='/profile' />
      }
    }

    //console.log(this.state.usersData)
    return (
      <div>
        {
          this.state.dataLoaded && user.dataLoaded ? (
            <GridContainer>
              <GridItem xs={12}>
                <h1 className={classes.title}>MEMBER DIRECTORY</h1>
                {console.log(user)}
                {user.admin ? (<Button
                  color="danger"                  
                  onClick={
                    () => this.handleClickOpen("assignActionModal",
                      "All"
                    )}
                >ASSIGN ACTION TO ALL</Button>) : null
                }
                
                <div>
                  <ReactTable
                    data={this.state.usersData}
                    filterable
                    columns={this.props.users.columns}
                    defaultPageSize={10}
                                defaultFilterMethod={filterCaseInsensitive}

                    defaultSorted={[
                      {
                        id: "status",
                        desc: false
                      }
                    ]}
                    //showPaginationTop
                    showPaginationBottom
                    style={{
                      color: "#000"
                    }}
                    className="-striped -highlight"
                  />
                  {/* ASSIGN ACTION MODAL */}
                  <Dialog
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modal
                    }}
                    open={this.state.assignActionModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("assignActionModal")}
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("assignActionModal")}
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h2 className={classes.modalTitle}>ASSIGN ACTION</h2>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <AssignActionForm
                        usersData={this.props.users.dataRows}
                        queryString={this.state.volUID}
                        modalOpen={this.state.assignActionModal}
                      />
                      <Button
                        className={classes.ltrButtonWhite}
                        style={{
                          marginLeft: "10px",
                          marginTop: "10px"
                        }}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("assignActionModal")}
                      >
                        CLOSE
              </Button>
                    </DialogContent>
                  </Dialog>
                </div>
              </GridItem>

            </GridContainer>
          ) : (
              <p className={classes.cardSubtitle}>Loading...</p>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.profile.user)
  return {
    AuthBool: !!state.auth.user,
    user: state.profile.user,
    users: state.admin.publicUsers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSetUsersList: dispatch(startSetUsersList())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Users);
