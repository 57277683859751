import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import loader from "assets/img/loader.gif";
import miniLoading from "assets/img/simple_loading.gif";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Accordion from "components/Accordion/Accordion.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";
import { Grid } from "@material-ui/core";

import EventsDetailsMap from "./EventsDetailsMap"
// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions'
import { tableToFirestoreObjects, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetUsersList } from "store/actions/adminActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: ''
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop }
      const volUID = colObj.volUID
      const username = colObj.username
      // Load Data for Summary Cards and add buttons     
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => this.handleClickOpen("assignActionModal",
                volUID
              )}
          >ASSIGN ACTION</Button>)
      }
    })

    this.setState({
      usersData,
      dataLoaded: true
    })
  }

  handleChange = (docID) => {
    console.log(docID)
  }

  render() {
    const { AuthBool, classes, user, userProfile, cases, id, users } = this.props;
    console.log(cases)
    const searchButton =
    classes.top +
    " " +
    classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded
        && !this.state.dataLoaded) {
        this.loadData()
      }
    }

    return (
      <div>
        {
          this.state.dataLoaded ? (
            <GridContainer justify="center">
              <GridItem xs={11}>              
                <EventsDetailsMap uid={user.uid} id={id} userType={userProfile.userType} style={{marginTop: "-30px"}}/>
              </GridItem>
            </GridContainer>
            
          ) : (
                <img
                  style={{margin: "65% 0% 0% 41%", position: "relative", width: "20%"}}
                  src={loader}
                  alt="..."
                />
              )
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
    userProfile: state.profile.user,
    user: state.auth.user,
    id: ownProps.match.params.id
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startSetUsersList: dispatch(startSetUsersList()),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Users);
