import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetMessagesList = () => {
  return (dispatch) => {
    db.collection("contact-messages")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            id: doc.id,
            email: docData.email ? docData.email : "N/A",
            firstName: docData.firstName ? docData.firstName : "N/A",
            lastName: docData.lastName ? docData.lastName : "N/A",
            message: docData.message ? docData.message : "N/A",
            updatedAt: docData.updatedAt ? moment(docData.updatedAt.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setMessagesList(data))
      })
  }
}

export const setMessagesList = (dataRows) => ({
  type: 'SET_MESSAGES_LIST',
  dataRows
})

