import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// react component for creating dynamic tables
import ReactTable from "react-table";
import createTableObject from "components/Table/createReactTableObject.jsx"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/brokering/brokersStyle";

class Brokers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: createTableObject(this.props.brokerData)
    };
  }
  render() {
    const { classes, brokerData } = this.props;
    console.log(brokerData)
    return (
      <GridContainer spacing={8}>
        <GridItem xs={6} sm={2}>
          <Card>
            <h2 className={classes.cardTitle}><ArrowUpward className={classes.successIcon} />$3,046</h2>
            <p className={classes.cardSubtitle}>Revenue / Truck</p>
          </Card>
        </GridItem>
        <GridItem xs={6} sm={2}>
          <Card>
            <h2 className={classes.cardTitle}><ArrowDownward className={classes.dangerIcon} />0.019</h2>
            <p className={classes.cardSubtitle}>Drivers / Overhead</p>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={8}>
          <Card>
            <div className={classes.alignRight}><h1 className={classes.cardTitle}>$40,634/12,000,000</h1></div>
          </Card>
        </GridItem>   
        <GridItem xs={12}>
          <h1 className={classes.title}>BROKER PARTNERS</h1>
        </GridItem>     
        <GridItem xs={12}>
          <ReactTable
            data={this.state.data}
            filterable
            columns={brokerData.columns}
            defaultPageSize={5}
            //showPaginationTop
            showPaginationBottom
            style={{
              color: "#f2f2f2"
            }}
            className="-striped -highlight"
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    brokerData: state.brokering.brokers    
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Brokers);
