import {
    bodyText,
    title
  } from "assets/jss/material-dashboard-pro-react.jsx";

const actionPlanStyle = (theme) => ({
    modalCloseButton: {
        color: "black",
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "-10px",
        marginBottom: "-15px",
        "&:hover": {
            color: "black",
            cursor: "pointer"
        }
    },
    popperDiv: {
        display: "flex",
        // height: "85px",
        // flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 12px",
        height: "50px",
        // margin: "0px auto",
        "&:hover": {
            cursor: "pointer",
            background: "#F6F6F7"
        }
    },
    popperSelections: {
        width: "100%",
        margin: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    button:{
        backgroundColor: "#FC662B",
        color: "white",
        fontSize: "12px",
        borderRadius: "5px",
        boxShadow: "4px 4px 0px 0px #e3470a",
        "&:hover": {
          backgroundColor: "#FC662B",
          color: "white",
          boxShadow: "2px 2px 0px 0px #e3470a"
        },
    },
    subTitle: {
        ...title,
      },
    body: {
        ...bodyText,
    }
});

export default actionPlanStyle