/*eslint-disable*/
import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import { db } from "firebase/fbConfig.js"
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import SendIcon from '@material-ui/icons/Send';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/CreateMessageFormStyle.jsx";

class Form extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false,
    email: ''
  }
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid, id) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
      id: id
    })
    console.log("testing state",this.state)
  }
  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state)
    //getting email of person receiving the message
    db.collection('TEST-users').doc(this.state.id)
    .get()
    .then((queryResult) => {
      this.setState({
        email: queryResult.data().email
      })
    })


    // update firestore document with PENDING and comment
    db.collection("TEST-users").doc(this.state.uid).collection("threads").doc(String(this.state.id)).set({
      uid: this.state.uid,
      message: this.state.message,
      name: this.props.name,
      createdDate: new Date()
    }, { merge: true }).then(() => {
      db.collection("TEST-users").doc(this.state.id).collection("threads").doc(String(this.state.uid)).set({
        uid: this.state.uid,
        message: this.state.message,
        name: this.props.name,
        createdDate: new Date()
      }, { merge: true }).then(() => {

        db.collection("TEST-users").doc(this.state.uid).collection("threads").doc(String(this.state.id)).collection("messages").doc().set({
          uid: this.state.uid,
          message: this.state.message,
          createdTime: new Date()
        }, { merge: true }).then(() => {

          db.collection("TEST-users").doc(this.state.id).collection("threads").doc(String(this.state.uid)).collection("messages").doc().set({
            uid: this.state.uid,
            message: this.state.message,
            createdTime: new Date()
          }, { merge: true }).then(() => {

            db.collection("TEST-users").doc(this.state.id).collection("notifications").doc().set({
              uid: this.state.uid,
              userName: this.props.user.firstName + " " + this.props.user.lastName,
              name: `${this.props.user.firstName + " " + this.props.user.lastName} sent you a message`,
              createdTime: new Date(),
              date: moment().format('MMMM Do YYYY, h:mm:ss a'),
              email: this.state.email

            }, { merge: true }).then(() => {
              console.log(``)
              this.setState({
                messageSent: true,
                message: ''
              })
              console.log(e)
            }).catch((err) => {
              console.log(`${err}`)
            })
          }).catch((err) => {
            console.log(`${err}`)
          })
        }).catch((err) => {
          console.log(`${err}`)
        })
      }).catch((err) => {
        console.log(`${err}`)
      })
    }).catch((err) => {
      console.log(`${err}`)
    })
  }


  resetRequest = () => {
    window.location.reload();
  }

  render() {
    const { classes, modalClose, authUser, blogID, uid, id } = this.props;
    //console.log(uid + " " + id)
    // reset modal when closed
    //if (this.state.messageSent) { this.resetRequest() }
    //console.log(this.state)
    // console.log(this.props.user.firstName)
    return (
      <div>

        <div className={classes.formContainer} justify="center"
          style={{
            padding: "0px 50px 10px 40px",
            marginLeft: "-40px",
            position: "fixed",
            bottom: "0", width: "105%",
            height: "11vh"
          }}>

          <form onSubmit={this.handleSubmit}
          >
            <GridContainer>
              <GridItem xs={9}>
                {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                <br />
                <CustomInput
                  labelText="Type something"
                  id="message"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    value: this.state.message,
                    multiline: false,
                    rows: 1,
                    onChange: (event) => this.handleChange(event, uid, id)
                  }}
                />

              </GridItem>
              <GridItem xs={1}>
                <Button
                  type="submit"
                  color="primary"
                  style={{
                    marginTop: "20px",
                    marginLeft: "10px",
                    marginBottom: "10px"
                  }}>
                  <SendIcon></SendIcon>
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </div>


      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);