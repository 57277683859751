import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js"
import { Chart } from 'react-google-charts'
import moment from 'moment'
import { Redirect } from 'react-router-dom'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import NotificationsIcon from '@material-ui/icons/Notifications';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/principlesStyle.jsx";
import { Grid } from "@material-ui/core";
import addNotification from 'react-push-notification';

// Forms

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import { setFirstAssessment } from "store/actions/assessmentActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Principles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new: false,
      notifications: [],
      userLoaded: false,
      notificationsLoaded: false
  }
}




  loadContentData = (uid, id) => {
    let data = db.collection("memberPrinciplesPage")
    .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          var docObj = {
            id: doc.id,
            ...docData
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        this.setState({
          contentData: data,
          contentDataLoaded: true,
          refresh: false
        })
      })

    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
  }

  componentWillUnmount() {
    this.unsubUser();
    this.unsubNotifications();
}

componentDidUpdate(prevProps, prevState) {
    if (!prevProps.user.dataLoaded && this.props.user.dataLoaded) {
        const userRef = db.collection('TEST-users').doc(this.props.authUser.uid);
        const userObserver = userRef.onSnapshot(async doc => {
            // console.log('userChanged');
            // debugger;
            const docData = {
                ...doc.data(),
                id: doc.id
            }

            if (!this.state.userLoaded) return this.setState({ userLoaded: true, new: docData.notification ? true : false });

            this.setState({
                new: docData.notification ? true : false
            });

        });


        const notificationsObserver = db.collection('TEST-users').doc(this.props.authUser.uid).collection('notifications')
            .onSnapshot(async querySnapshot => {
                // debugger;

                // const snapshot = querySnapshot.docs;
                // const notifications = [];
                // for (let i = 0; i < snapshot.length; i++) {
                //     const docData = {
                //         ...snapshot[i].data(),
                //         id: snapshot[i].id
                //     }
                //     notifications.push(docData);
                // }

                // this.setState({
                //     notifications: notifications
                // });

                if (!this.state.notificationsLoaded) {
                    this.setState({ notificationsLoaded: true })
                } else {
                    userRef.set({
                      notification: true
                    }, { merge: true })
                }

            })

        this.unsubUser = userObserver;
        this.unsubNotifications = notificationsObserver;
    }

}

buttonClick = () => {
  addNotification({
      title: `Notification`,
      subtitle: 'Click here to see.',
      message: 'You got a message',
      theme: 'darkblue',
      onClick: () => window.location.replace(`/notifications`),
      native: true, // when using native, your OS will handle theming.
      icon: "crx", // optional, Native only. Sets an icon for the notification.
      vibrate: 1
    });
};
  // Load data for Table
  // if there are no actions make a default one



  render() {
    const { AuthBool, classes, user, assessments } = this.props;
    if (this.state.new) {

      this.buttonClick()
    }

    console.log(user)
    //console.log(assessments.dataLoaded)

    if (user.dataLoaded && !this.state.contentDataLoaded) {
      this.loadContentData()
    }

    console.log(this.state)

    return (
     <NotificationsIcon  style={{marginLeft: "1rem", color: `${this.state.new ? "#ec5634" : "white"}`}}/>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Principles);