// Orders Data
const actions = {
    columns: [    
      {
        Header: "ACTION ID",
        accessor: "actionID",
        show: false
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "BODY",
        accessor: "description",
        show: false
      },
      {
        Header: "DUE",
        accessor: "dueDate"
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },    
      {
        Header: "STATUS",
        accessor: "status"
      },    
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  }
  ;
  // Orders Data
  const pendingActions = {
    columns: [  
      {
        Header: "VOL EMAIL",
        accessor: "email"
      },  
      {
        Header: "VOL UID",
        accessor: "volUID"      
      },
      {
        Header: "ACTION ID",
        accessor: "actionId"      
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "COMMENT",
        accessor: "comment"     
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },  
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  };
  
  // Orders Data
  const podcasts = {
    columns: [        
      {
        Header: "TITLE",
        accessor: "title"      
      },
      {
        Header: "LENGTH",
        accessor: "length"      
      },
      {
        Header: "PRESENTERS",
        accessor: "presenters"      
      },
      {
        Header: "DESCRIPTION",
        accessor: "description"      
      },
      {
        Header: "CATEGORY",
        accessor: "category"      
      },
      {
        Header: "TOPIC",
        accessor: "topic"      
      },
      {
        Header: "VIDEO",
        accessor: "video"
      },
      {
        Header: "AUDIO",
        accessor: "audio"
      }
    ],  
    dataLoaded: false
  };

    // Orders Data
  const upcomingPodcasts = {
    columns: [        
      {
        Header: "TYPE",
        accessor: "type"      
      },
      {
        Header: "LENGTH",
        accessor: "length"      
      },
      {
        Header: "DATE",
        accessor: "date"      
      },
      {
        Header: "TIME",
        accessor: "time"      
      },
      {
        Header: "TITLE",
        accessor: "title"      
      },
      {
        Header: "PRESENTERS",
        accessor: "presenters"      
      },
      {
        Header: "Description",
        accessor: "description"      
      },
      {
        Header: "REGISTER",
        accessor: "register"
      }
    ],  
    dataLoaded: false
  };

  
  export const initState = {
    actions,
    pendingActions,
    upcomingPodcasts,
    podcasts
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_PODCASTS_LIST':
        console.log('SET_PODCASTS_LIST')     
        return {
          ...state,
          podcasts: {
            ...state.podcasts,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
        case 'SET_UPCOMING_PODCASTS_LIST':
        console.log('SET_UPCOMING_PODCASTS_LIST')     
        console.log(action.dataRows)
        return {
          upcomingPodcasts: {
            ...state.upcomingPodcasts,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        }; 
      default:
        return state
    }
  }
  