/*eslint-disable*/
import { db } from "firebase/fbConfig.js"
import { setUpdatedMemberName } from './SESActions.jsx'

export function startLoad(uid) {
  return (dispatch) => {
    db.collection("TEST-users")
      .doc(uid)
      .collection("lists")
      .get()
      .then(snapshot => snapshot.forEach(list => {
        console.log('listxzing', list.data())
        dispatch(setList(list.id, list.data().values));
      }));
  }
}


export function startLoadList(uid, name) {
  return (dispatch) => {
    return db.collection("TEST-users").doc(uid).collection("lists").doc(name).get().then(list => {
      console.log('listxzingload', list.data())
      list.data() && dispatch(setList(list.id, list.data().values));

    });
  }
}



export function startSaveList(uid, name, list) {
  return (dispatch) => {
    // need to perform this compound query to have strategy sheet collection hold
    var batch = db.batch()
    console.log('listxz', uid)
    if (name === 'members') {
      for (var x = 0; x < list.length; x++) {
        console.log('listxzitme', list[x])
        list[x].status = "active"
        if (list[x].id === undefined) {
          list[x].id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        }
      }
      return db.collection("TEST-users")
        .doc(uid)
        .collection("lists")
        .doc(name)
        .set({ values: list.filter(item => item.text) }, { merge: true })
        .then(() => {
          // "all stakeholders" is a necessary collection to query to create strategy sheet colletion upon submission of stakeholder community form and have a status of "active" right away
          db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').get()
            .then((snapshot) => {
              console.log('snappyexists?ata', snapshot.data())
              if (!snapshot.exists) {
                console.log('snappyexistsNO')
                db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').set({ values: list.filter(item => item.text) }, { merge: true })
                  .then(() => {
                    list.forEach((doc) => {
                      if (doc.text !== "") {
                        var docRef = db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc(doc.id)
                        batch.set(docRef, doc);
                      }
                    })
                    batch.commit()
                    .then(() => {
                      window.location.reload();
                    })
                    // dispatch(setCommunityStakeholderForm(false))
                  })
              } else {
                snapshot = snapshot.data().values
                console.log('snappyexistsYes', snapshot)
                if (snapshot.length > 0) {
                  // mapping through snapshot and creating array of all ids to check against incoming list ids

                  // ids in firebase already
                  let idValuesArray = snapshot.map(a => a.id);
                  // names and values in firebase already
                  // console.log('snappypre', nameValuesArray)

                  // names in firebase already
                  for (var x = 0; x < list.length; x++) {
                    if (idValuesArray.indexOf(list[x].id) === -1 && list[x].text !== '') {
                      snapshot.push(list[x])
                    }
                    for (var y = 0; y < snapshot.length; y++) {
                      if (list[x].id === snapshot[y].id && list[x].text !== snapshot[y].text && list[x].text !== '') {
                        snapshot[y].text = list[x].text
                      }
                    }
                  }

                  db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').set({ values: [...snapshot] }, { merge: true })
                  .then(() => {
                    // this may be causing name edit issues from playbook
                    snapshot.forEach((doc) => {
                      console.log('snapzzx', snapshot)
                      var docRef = db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc(doc.id)
                      batch.set(docRef, doc);
                    })
                    batch.commit()
                    .then(() => {
                      window.location.reload();
                    })
                  })
                  // this else will literally never happen
                } else {
                  dispatch(setCommunityStakeholderForm(false))
                }
              }
            })
        })
    } else {
      return db.collection("TEST-users")
        .doc(uid)
        .collection("lists")
        .doc(name)
        .set({ values: list.filter(item => item.text) }, { merge: true });
    }
  }
}


export function startSaveSESList(uid, name, list, stakeholderId) {
  // name param is the name of the form
  return (dispatch) => {
    return db.collection("TEST-users")
      .doc(uid)
      .collection("strategy-sheet")
      .doc(stakeholderId)
      .set({
        [name]: list.filter(item => item.text)
      }, { merge: true })
      .then(() => {
        db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').get()
          .then((snapshot) => {
            snapshot = snapshot.data().values
            snapshot.forEach((doc) => {
              if (doc.id === stakeholderId) {
                console.log('SAVINGDATA', list)
                doc[name] = list.filter(item => item.text)
              }
            })
            db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').set({ values: snapshot })
              .then(() => {
                console.log('successful ses list save')
                dispatch(setList(name, list))
                window.location.reload();
              })
          })
      })
  }
}

export function editStakeHolderName(uid, stakeholderId, updatedName) {
  console.log('tryingtoedit', uid, stakeholderId, updatedName)
  return (dispatch) => {
    return db.collection("TEST-users").doc(uid).collection("lists").doc("members").get()
      .then(snapshot => {
        snapshot = snapshot.data().values
        for (var x = 0; x < snapshot.length; x++) {
          if (snapshot[x].id === stakeholderId) {
            console.log('herezzzz', snapshot[x])
            if (updatedName === undefined) {
              updatedName =  snapshot[x].text
            } else {
              snapshot[x].text = updatedName
            }
            console.log('herezzzz', snapshot[x])
          }
        }
        db.collection("TEST-users").doc(uid).collection("lists").doc("members").set({ values: snapshot })
          .then((results) => {
            db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc(stakeholderId).get()
              .then((snapshot) => {
                snapshot = snapshot.data()
                snapshot.name = updatedName
                snapshot.text =updatedName
                console.log('herezzzz', snapshot)
                db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc(stakeholderId).set(snapshot)
                  .then(() => {
                    db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').get()
                      .then((snapshot) => {
                        snapshot = snapshot.data().values
                        snapshot.forEach((doc) => {
                          if (doc.id === stakeholderId) {
                            doc.text = updatedName
                          }
                        })
                        db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').set({ values: snapshot })
                          .then(() => {
                            console.log('herezzzz', results)
                            dispatch(setList("members", snapshot))
                            dispatch(setUpdatedMemberName(updatedName, stakeholderId))
                          })

                      })
                  })
              })
          })
      })
  }
}



export function setCommunityStakeholderForm(boolean) {
  return {
    type: 'PLAYBOOK_SET_COMMUNITYSTAKEHOLDERFORM',
    boolean
  }
}




export function setList(name, list) {
  console.log('listzz', list)
  return {
    type: 'PLAYBOOK_SET_LIST',
    name,
    list
  }
}


export function setListItem(name, index, item) {
  return {
    type: 'PLAYBOOK_SET_LIST_ITEM',
    name,
    index,
    item
  }
}

export function addListItem(name, item) {
  return {
    type: 'PLAYBOOK_ADD_LIST_ITEM',
    name,
    item
  }
}

export function submit(name) {
  return {
    type: 'PLAYBOOK_LIST_SUBMIT',
    name
  }
}

export function openDialog(name) {
  return {
    type: 'PLAYBOOK_LIST_OPEN_DIALOG',
    name
  }
}

export function closeDialog(name) {
  return {
    type: 'PLAYBOOK_LIST_CLOSE_DIALOG',
    name
  }
}





// .then(() => {
//   // this may be causing name edit issues from playbook
//   snapshot.forEach((doc) => {
//     console.log('snapzzx', snapshot)
//     // db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc(doc.id).set({ name: doc.text, status: doc.status }, { merge: true })
//     // this isn't resolving promise
//     var docRef = db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc(doc.id)
//     batch.set(docRef, doc);
//   })
//   batch.commit()
//   .then(() => {
//     window.location.reload();
//     // dispatch(setCommunityStakeholderForm(false))
//   })
//   // window.location.reload();