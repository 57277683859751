import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { FooterText } from "./FooterText";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";


import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";
import FeedbackForm from "../Forms/FeedbackForm";

function Footer({ ...props }) {
  const { classes, fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <Card style={{padding: "0px 0px 10px 10px"}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <h2 className={classes.subTitle} style={{whiteSpace: "pre-wrap"}}>
              Need More Details?{"\n"}Contact Us
          </h2>
            <h4 className={classes.body}>
              We are here to assist. Contact us by phone, email or via our social media channels.
          </h4>
            <Link to="/contact">
              <Button
                type="button"
                className={classes.button2}
              >CONTACT</Button>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.feedbackForm}>
            <FeedbackForm/>
          </GridItem>
        </GridContainer>
        </Card>
        <p className={classes.right}>
        <FooterText anchor={anchor} rtlActive={rtlActive} />
      </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
