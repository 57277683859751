/*eslint-disable*/
import React, { useState } from "react";
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { Link } from "react-router-dom";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';






export const addBackToPlaybook = (id, name, uid) => {
  console.log('nsapx', id, name, uid)
  db.collection("TEST-users").doc(uid).collection("lists").doc('members').get()
    .then((snapshot) => {
      snapshot = snapshot.data().values
      snapshot = [...snapshot, { id, text: name, status: "active" }]
      console.log('nsapx', snapshot)
      db.collection("TEST-users").doc(uid).collection("lists").doc('members').set({ values: snapshot })
      db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').get()
        .then((snapshot) => {
          snapshot = snapshot.data().values
          var personToUpdate = {}
          snapshot.forEach((doc) => {
            if (doc.id === id) {
              doc.status = "active"
              personToUpdate = doc
              // delete personToUpdate.text
              personToUpdate.name = name
            }
          })
          console.log('persontoudpate', personToUpdate)
          db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').set({ values: snapshot })
            .then(() => {
              db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc(id).set(personToUpdate)
                .then(() => {
                  console.log('nsapx', 'SUCCESS')
                  window.location.reload()
                })
            })
        })
    })
}




export const permDelete = (id, name, uid) => {
  console.log('nsapx', id, name, uid)
  db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').get()
    .then((snapshot) => {
      snapshot = snapshot.data().values
      snapshot.forEach((doc) => {
        console.log('matchAllDocs', doc)
        if (doc.id === id) {
          doc.status = 'deleted'
        }
      })
      db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders').set({ values: snapshot })
        .then(() => {
          console.log('nsapx', 'SUCCESS')
          window.location.reload()
        })
    })
}



export function getEngagementWorksheetsData(uid) {
  return (dispatch) => {
    db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc('all-stakeholders')
      .onSnapshot((snapshot) => {
        const data = []
        console.log('nsapx', snapshot)
        if (!snapshot.exists) {
          dispatch(setEngagementWorksheetsList(data))
        } else {
          snapshot = snapshot.data().values
          snapshot.forEach((doc) => {
            // var docData = doc.data()
            console.log('nsapx', doc)
            if (doc.status !== 'deleted') {
              var docObj = {
                id: doc.id,
                accountability: doc.accountability ? doc.accountability[0].text : "N/A",
                status: doc.status,
                stakeholderName: doc.text ? doc.text : "N/A",
                actions:
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {doc.status === 'active' ? (
                      <Link style={{ width: "20%" }} to={'/tools/strategy-sheet/' + doc.id}>
                        <Button
                          style={{
                            width: "100%",
                            backgroundColor: "#F4BF4F",
                            boxShadow: "4px 4px 0px 0px #755c26",
                            color: "white",
                            borderRadius: "5px",
                            "&:hover": {
                              backgroundColor: "#F4BF4F",
                              color: "white",
                              boxShadow: "2px 2px 0px 0px #755c26"
                            },
                          }}
                        >
                          <EditIcon /></Button></Link>
                    ) : (<div></div>)}

                    {doc.status === "inactive" ? (
                      <div>
                        <Button onClick={() => addBackToPlaybook(doc.id, doc.text, uid)} style={{ fontSize: "xx-large", marginRight: "25px" }} color='success'><ImportContactsIcon style={{ fontSize: "xx-large" }} /></Button>
                        <Button onClick={() => dispatch(triggerDeleteModal(doc.id, doc.text, uid, true))} color='danger'><DeleteForeverIcon /></Button>
                      </div>
                    ) : (<div></div>)}

                  </div>
              }

              data.push(docObj)
              // })
              // .then(() => {
              dispatch(setEngagementWorksheetsList(data))
              // })
            }
          })
        }

      })
  }
}




export function setEngagementWorksheetsList(dataRows) {
  return {
    type: 'SET_ENGAGEMENTWORKSHEETS_LIST',
    dataRows
  }
}


// are you sure you want to delete modal

export function triggerDeleteModal(stakeholderid, stakeholderName, uid, boolean) {
  console.log('triggered', boolean)
  return {
    type: 'SET_TRIGGERDELETEMODAL',
    boolean,
    data: { stakeholderid, stakeholderName, uid }
  }
}


