import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetMessagesList = (uid, threadID) => {
  return (dispatch) => {
    db.collection("TEST-users").doc(uid).collection("threads").doc(threadID).collection("messages").orderBy('createdTime')
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            id: doc.id,
            message: docData.message ? docData.message : "N/A",
            createdTime: docData.createdTime ? docData.createdTime : "N/A",
            uid: docData.uid ? docData.uid : "N/A"
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setMessagesList(data))
      })
  }
}

export const startSetClientMessagesList = (uid, caseID, threadID) => {
  return (dispatch) => {
    db.collection("TEST-users").doc(uid).collection("cases").doc(caseID).collection("threads").doc(threadID).collection("messages").orderBy('createdTime')
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            id: doc.id,
            message: docData.message ? docData.message : "N/A",
            createdTime: docData.createdTime ? docData.createdTime : "N/A",
            uid: docData.uid ? docData.uid : "N/A"
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setMessagesList(data))
      })
  }
}

export const setMessagesList = (dataRows) => ({
  type: 'SET_MESSAGES_LIST',
  dataRows
})

