import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetProfessionalsList = () => {
  return (dispatch) => {
    db.collection("professionals")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            expertise: docData.expertise ? docData.expertise : "N/A",
            firm: docData.firm ? docData.firm : "N/A",
            name: docData.name ? docData.name : "N/A",
            phone: docData.phone ? docData.phone : "N/A",
            email: docData.email ? docData.email : "N/A",
            category: docData.category ? docData.category : "N/A",
            link: docData.link ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.link)}
          >Website</Button>)
           : "N/A",
           schedule: docData.schedule ? (
            <Button
              color="primary"
              fullWidth
              onClick={
                () => window.open(docData.schedule)}
            >schedule</Button>)
             : "N/A" 
  
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        console.log(data)
        dispatch(setProfessionalsList(data))
      })
  }
}

  

export const setProfessionalsList = (dataRows) => ({
  type: 'SET_PROFESSIONALS_LIST',
  dataRows
})

