import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { Redirect } from 'react-router-dom';

// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";



import Style from "assets/jss/material-dashboard-pro-react/views/takeAssessmentStyle";


import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"
// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class TakeAssessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            updateProfileModal: false,
            updatePhotoModal: false
        };
    }

    handleClickOpen = (modal) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
        });
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    loadData = () => {
        this.setState({
            dataLoaded: true
        })
    }

    

    render() {
        const { classes, user } = this.props;

        if (user.dataLoaded
            && !this.state.dataLoaded) {
            this.loadData()
        }

        if (user.termGroups) {
            console.log(user.termGroups.length)
      
            if (user.termGroups.length < 2) {
              return <Redirect to='/profile' />
            }
          }

       
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        {/* this.state.dataLoaded ? 
                  this.props.assessments.assessments.map(assessment => {
                    return <p>{assessment.question1}</p>
                  }) :
                  <p>Loading...</p>
                */}
                        {/*this.props.user.saPercent ? <p className={classes.body}>{this.props.user.saPercent}</p> : <p className={classes.body}>Click UPDATE to add company name</p>*/}

                        <h1 className={classes.title}>
                            ABOUT THE ASSESSMENT & OUR PROCESS
                    </h1>
                        <h3 className={classes.subtitle}>
                            This assessment is meant to guide your efforts to understanding your business strategies and objects. By revisiting it regularly, you can utilize this tool to re-align your organization with meaningful leadership methods.
                    </h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <br />
                        <br />
                        <br />
                        <div className={classes.dashboardModalBtn}>
                            <Button
                                color="primary"
                                onClick={() => this.handleClickOpen("learnDashboardModal")}
                            >
                                LEARN ABOUT ASSESSMENT
                </Button>
                        </div>
                    </GridItem>
                </GridContainer>
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal + " " + classes.modalLarge
                    }}
                    open={this.state.learnDashboardModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("learnDashboardModal")}
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                >
                    <DialogTitle
                        id="shipment-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.handleClose("learnDashboardModal")}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        <h3>Instructions to taking the Leading from Purpose Organizational Assessment</h3>
                    </DialogTitle>
                    <DialogContent
                        id="shipment-modal-slide-description"
                        className={classes.modalBody}
                    >
                        <p>Welcome to the Lead from Purpose Organizational Assessment. We are excited that you have decided to take this next step on your journey to building a more purposeful organization and business. Purposeful Leadership, or Leading from Purpose, is when we recognize the organization’s reason for being, the underlying human problem the organization exists to solve through its products or services, as the core driving force behind everything it does and how it is represented in the world.</p>

                        <p>This assessment is designed to give you a snapshot of where your organization stands in relation to the basic practices of Purposeful Leadership. Please remember we want a snap shot of your current state, not your desired state. As you are reading the questions and considering your responses, please respond honestly about what your organization is currently doing. There are no right or wrong answers. There are only the best answers for your organization at this moment.</p>

                        <p> Here are a few definitions you might find helpful in answering the questions:</p>

                        <p> Purpose, the intended result for which an organization through its actions was formed to achieve; the primary reason for the organization’s existence in the view of a stakeholder group that finds that reason meaningful and important to them.</p>

                        <p> Stakeholder, an individual or a group of individuals who impacts or is impacted by the organization.</p>

                        <p> Stakeholder Group, a collection of stakeholders that shares similar characteristics and relates to the organization in a similar manner (e.g., shareholders, employees, local community, customers, tec.).</p>

                        <p> Stakeholder Community, stakeholders or stakeholder groups that have significant influence on the actions of the organization or that significantly impact or are impacted by the organization and are critical for the organization’s success; key stakeholders.</p>

                        <p>Stakeholder World, the many individuals and groups of individuals who impact or are impacted by the organization.</p>

                        <p> Once you complete the assessment, you will see your results displayed on your personal Dashboard showing you how far along your organization is in mastering the four practice areas of Purposeful Leadership. These four practice areas are:</p>

                        <p> Purpose Embraced, discovering and taking on a single common purpose, beyond profit, that is inspiring;
                         Stakeholders Engaged, communicating your purpose to all of your key stakeholders in a way that gains
                         their support;Strategy Aligned, designing an organization that will achieve your Purpose; and
                Actions Aligned , ensuring that your organization’s culture and actions support fulfilling that Purpose.</p>

                        <p> For more information on this, go to the Path to Purposeful Leadership.</p>

                        <p> The assessment consists of 30 questions, which should take about 30-40 minutes to complete. You do not have to complete the assessment at one sitting. At any time you can leave the assessment and return later to complete. You do not have to start all over.</p>

                        <p> The questions are organized by the four practice areas, with different questions in each area addressing the various Principles of Purposeful Leadership . The five possible answers to each question reflect the different levels of mastery your organization is at (pre-inquiry, inquiry, commitment, development and mastery) starting with the lowest level of pre-inquiry (first answer) and ending with mastery (fifth answer).</p>

                        <p> The assessment tool is designed for you to modify your answers over time as your organization begins to take on the principles and practices of Purposeful Leadership, which we call the path to mastery. Once you complete the assessment for the first time, you will be able to view your responses on your dashboard along with an explanation of why that question is important and then have an opportunity to change your answer then or in the future as your organization proceeds along the path to mastery. You never have to take the entire assessment over unless you wish to. To receive a score, however, you must answer all 30 questions.</p>

                        <p> Enjoy the process.</p>
                        <Button
                            color="danger"
                            style={{
                                marginLeft: "10px",
                                marginTop: "10px"
                            }}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.handleClose("learnDashboardModal")}
                        >
                            CLOSE
                  </Button>
                    </DialogContent>

                </Dialog>


                <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={10}>
                        <h3>Welcome to the Lead from Purpose Organizational Self-Assessment.</h3>
                        <h4>You’ve taken the first step toward building a more purposeful organization.</h4>
                        <p>This exercise is designed to take you through a series of questions to help you learn the key practices to building a purposeful organization that engages and compels your workers, your community, and the world.
                        Please choose the best answer that describes the current state of your organization as you perceive it and not what you may think is the "right" answer to each question.
                        This will help you to customize your journey with the Foundation for Purposeful Organizations and access the resources with the most leverage to transform and optimize your organization around purpose.
                    This assessment will take around 30 minutes to complete.</p>

                        {this.state.dataLoaded ? (
                            <GridContainer>
                                <GridItem xs={12}>
                                    <TakeAssessmentForm
                                        user={this.props.user}
                                        uid={this.props.authUser.uid}
                                        modalOpen={this.state.updateProfileModal}
                                    />
                                </GridItem>
                            </GridContainer>
                        ) : (
                  <div className={classes.easeInOut}>
              <Hidden smDown implementation="css">
                <div className={classes.preloaderDesktop}
                  style={{
                    background: "white",
                    position: "absolute",
                    width: "110%",
                    margin: "-25% 0% 0% -8%",
                    height: "180%",
                    zIndex: "99999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 33%",
                      position: "relative",
                      width: "25%"
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "2% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
              <Hidden mdUp implementation="css">
                <div className={classes.preloaderMobile}
                  style={{
                    background: "white",
                    position: "absolute",
                    width: "110%",
                    margin: "-30% 0% 0% -10%",
                    height: "110%",
                    zIndex: "9999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 20%",
                      position: "relative",
                      width: "50%"
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "15% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
            </div>
                            )}
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authUser: state.auth.user,
        user: state.profile.user
    }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
    connect(mapStateToProps),
    withStyles(Style)
)(TakeAssessment);
