import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link, useParams, NavLink } from "react-router-dom";
import { db, firebase } from "firebase/fbConfig.js"
import moment from "moment";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Popper from "@material-ui/core/Popper";
import Fade from '@material-ui/core/Fade';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomTabs from "components/CustomTabs/CustomTabs";
// @material-ui/icons
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import Close from "@material-ui/icons/Close";


import Style from "assets/jss/material-dashboard-pro-react/views/actionPlanStyle.jsx";

import { Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Cancel, CloseOutlined, Delete, Edit, LaptopWindows, Replay } from "@material-ui/icons";

export function Initiative(props) {

    const { classes, history, user, authUser } = props;

    const [open, setOpen] = useState(false)
    const [info, setInfo] = useState({
        name: '',
        measureResult: '',
        date: '',
        personAccountable: ''
    })
    const [infoArr, setInfoArr] = useState([])
    const [popperOpen, setPopperOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentCard, setCurrentCard] = useState({})
    const [editModal, setEditModal] = useState(false)
    const [actionID, setActionID] = useState('')
    const onChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }

    const onClose = () => {
        setInfo({
            name: '',
            measureResult: '',
            date: '',
            personAccountable: ''
        })
        setOpen(false)
    }

    const changeStatus = (e, id, bool) => {
        firebase.auth().onAuthStateChanged(async function (user) {
            await db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(id).update({
                active: !bool
            })
                .then(() => {
                    setPopperOpen(!popperOpen)
                    setAnchorEl(anchorEl ? null : e.currentTarget);
                })
        })
    }

    const clickDelete = (e, id) => {
        firebase.auth().onAuthStateChanged(async function (user) {
            await db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(id).update({
                hide: true
            })
                .then(() => {
                    setPopperOpen(!popperOpen)
                    setAnchorEl(anchorEl ? null : e.currentTarget);
                })
        })
    }
    const clickEdit = (e) => {
        e.preventDefault()
        firebase.auth().onAuthStateChanged(async function (user) {
            if (user) {
                let update = {}
                if (info.name) {
                    update.initiativeName = info.name
                }
                if (info.measureResult) {
                    update.measureResult = info.measureResult
                }
                if (info.date) {
                    update.targetDate = moment(info.date).format("MM/DD/YYYY")
                }
                if (info.personAccountable) {
                    update.personAccountable = info.personAccountable
                }
                await db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(actionID).update(update)
                    .then(() => {
                        // window.location.reload()
                        setInfo({
                            name: '',
                            measureResult: '',
                            date: '',
                            personAccountable: ''
                        })
                    })
                    .then(() => {
                        setEditModal(false)
                        setPopperOpen(!popperOpen)
                        setAnchorEl(anchorEl ? null : e.currentTarget)
                    })
            }
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        let data = {
            initiativeName: info.name,
            measureResult: info.measureResult,
            targetDate: moment(info.date).format("MM/DD/YYYY"),
            personAccountable: info.personAccountable,
            docID: "Placeholder",
            timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            active: true,
            hide: false
        }
        firebase.auth().onAuthStateChanged(async function (user) {
            if (user) {
                console.log("checking uid", user.uid)
                await db.collection('TEST-users').doc(user.uid).collection('action-plan').add(data)
                    .then(async function (docRef) {
                        db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docRef.id).update({
                            docID: docRef.id
                        })
                    })
                    .then(() => {
                        window.location.reload()
                    })
            }
        })
    }


    async function getInfo() {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                db.collection('TEST-users').doc(user.uid).collection('action-plan')
                    .orderBy('timeStamp', 'desc')
                    .onSnapshot((querySnapshot) => {
                        const items = [];
                        querySnapshot.forEach((doc) => {
                            items.push(doc.data())
                        });
                        setInfoArr(items)
                    });
            }
        })
    }

    useEffect(() => {
        getInfo()
    }, [])


    return (
        <div className={classes.container}>
            <CustomTabs
                headerColor="primary"
                tabs={[
                    {
                        tabName: "Active",
                        tabContent: (
                            <div>
                                <h3 className={classes.subTitle} style={{ paddingLeft: "5px" }}>Active Initiatives</h3>
                                <GridContainer>
                                    {infoArr.map((data, index) => (
                                        <>
                                            {data.active && !data.hide ?
                                                <GridItem xs={12} sm={6} md={4} lg={3}>

                                                    <Card style={{ borderRadius: 5, paddingBottom: "15px" }} key={index}>
                                                        <MoreVertOutlinedIcon
                                                            style={{ display: "block", marginTop: "10px", marginLeft: "auto", marginRight: "15px" }}
                                                            onClick={(e) => {
                                                                {
                                                                    setCurrentCard(infoArr[index])
                                                                    setPopperOpen(!popperOpen)
                                                                    setAnchorEl(anchorEl ? null : e.currentTarget)
                                                                    setActionID(infoArr[index].docID)
                                                                }
                                                            }}
                                                        />
                                                        <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                                                            {({ TransitionProps }) => (
                                                                <Fade {...TransitionProps}>
                                                                    <Card>
                                                                        <Button
                                                                            onClick={(e) => {
                                                                                setAnchorEl(anchorEl ? null : e.currentTarget);
                                                                                setPopperOpen(!popperOpen)
                                                                            }}
                                                                            simple
                                                                            className={classes.modalCloseButton}
                                                                            key="close"
                                                                            aria-label="Close"
                                                                        >
                                                                            {""}
                                                                            <Close className={classes.modalClose} />
                                                                        </Button>
                                                                        <div className={classes.popperDiv} style={{ width: "150px" }}>
                                                                            <div className={classes.popperSelections} style={{ width: "150px" }} onClick={() => setEditModal(true)}>
                                                                                <Edit /> Edit
                                                                            </div>
                                                                        </div>
                                                                        <div className={classes.popperDiv} style={{ width: "150px" }} onClick={(e) => changeStatus(e, currentCard.docID, currentCard.active)}>
                                                                            <div className={classes.popperSelections} style={{ width: "150px" }}>
                                                                                <Cancel /> Deactivate
                                                                            </div>
                                                                        </div>
                                                                        <div className={classes.popperDiv} style={{ width: "150px" }} onClick={(e) => clickDelete(e, currentCard.docID)}>
                                                                            <div className={classes.popperSelections} style={{ width: "150px" }}>
                                                                                <Delete /> Delete
                                                                            </div>
                                                                        </div>
                                                                    </Card>
                                                                </Fade>
                                                            )}
                                                        </Popper>

                                                        <Dialog
                                                            open={editModal}
                                                            // onClose={() => setOpen(false)}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                            fullWidth={true}
                                                            maxWidth="md"
                                                        >
                                                            <CloseOutlined style={{ display: "block", marginTop: "10px", marginLeft: "auto", marginRight: "15px" }} onClick={() => setEditModal(false)} />


                                                            <form onSubmit={clickEdit}>
                                                                <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
                                                                    Edit Action Plan Form
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <Card style={{ padding: "10px" }}>
                                                                        <GridContainer>

                                                                            <GridItem xs={6} style={{ marginTop: "10px" }}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    id="name"
                                                                                    label="Initiative Name"
                                                                                    defaultValue={currentCard.initiativeName}
                                                                                    InputProps={{
                                                                                        classes: {
                                                                                            root: classes.textFieldRoot,
                                                                                        },
                                                                                        inputProps: {
                                                                                            onChange: onChange
                                                                                        }
                                                                                    }}
                                                                                    className={classes.textFieldRoot}
                                                                                    variant="outlined"
                                                                                />
                                                                            </GridItem>

                                                                            <GridItem xs={6} style={{ marginTop: "10px" }}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    id="measureResult"
                                                                                    label="Specific Measureable Result"
                                                                                    defaultValue={currentCard.measureResult}
                                                                                    InputProps={{
                                                                                        classes: {
                                                                                            root: classes.textFieldRoot,
                                                                                        },
                                                                                        inputProps: {
                                                                                            onChange: onChange
                                                                                        }
                                                                                    }}
                                                                                    className={classes.textFieldRoot}
                                                                                    variant="outlined"
                                                                                />
                                                                            </GridItem>

                                                                            <GridItem xs={6} style={{ marginTop: "10px" }}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    id="date"
                                                                                    helperText="Target Date"
                                                                                    type="date"
                                                                                    defaultValue={currentCard.targetDate}
                                                                                    InputProps={{
                                                                                        classes: {
                                                                                            root: classes.textFieldRoot,
                                                                                        },
                                                                                        inputProps: {
                                                                                            onChange: onChange
                                                                                        }
                                                                                    }}
                                                                                    className={classes.textFieldRoot}
                                                                                    variant="outlined"
                                                                                />
                                                                            </GridItem>

                                                                            <GridItem xs={6} style={{ marginTop: "10px" }}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    id="personAccountable"
                                                                                    label="Person Accountable"
                                                                                    defaultValue={currentCard.personAccountable}
                                                                                    InputProps={{
                                                                                        classes: {
                                                                                            root: classes.textFieldRoot,
                                                                                        },
                                                                                        inputProps: {
                                                                                            onChange: onChange
                                                                                        }
                                                                                    }}
                                                                                    className={classes.textFieldRoot}
                                                                                    variant="outlined"
                                                                                />
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                    </Card>
                                                                </DialogContent>
                                                                <div style={{ justifyContent: "center", display: "flex", paddingBottom: "10px" }}>
                                                                    <Button type="submit" className={classes.button}>
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                            </form>
                                                        </Dialog>


                                                        <CardBody>
                                                            <center><strong><h3 className={classes.body}>{data.initiativeName}</h3></strong></center>
                                                            <h4 className={classes.body}><strong>Measure Result: </strong>{data.measureResult}</h4>
                                                            <h4 className={classes.body}><strong>Target Date: </strong>{data.targetDate}</h4>
                                                            <h4 className={classes.body}><strong>Person Accountable: </strong>{data.personAccountable}</h4>
                                                        </CardBody>
                                                        <NavLink to={`/tools/details/${data.docID}`} className={classes.navLink}>
                                                            <div style={{ justifyContent: "center", display: "flex" }}>
                                                                <Button
                                                                    className={classes.button}
                                                                // style={{ backgroundColor: "#4ec7bc", width: "75%" }}
                                                                >
                                                                    View Details
                                                                </Button>
                                                            </div>
                                                        </NavLink>
                                                    </Card>
                                                </GridItem>
                                                : null}
                                        </>
                                    ))}

                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={3}>
                                        <Button onClick={() => setOpen(true)}
                                            style={{ height: "50px", width: "50px", fontSize: "25px", right: "20px", bottom: "55px", position: "fixed", zIndex: "10", background: "#F4BF4F", color: "white" }}
                                            justIcon round>
                                            +
                                        </Button>
                                    </GridItem>
                                    <Dialog
                                        open={open}
                                        // onClose={() => setOpen(false)}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                        fullWidth={true}
                                        maxWidth="md"
                                    >
                                        <CloseOutlined style={{ display: "block", marginTop: "10px", marginLeft: "auto", marginRight: "15px" }} onClick={() => onClose()} />


                                        <form onSubmit={onSubmit}>
                                            <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
                                                Action Plan Form
                                            </DialogTitle>
                                            <DialogContent>
                                                <Card style={{ padding: "10px" }}>
                                                    <GridContainer>

                                                        <GridItem xs={6} style={{ marginTop: "10px" }}>
                                                            <TextField
                                                                fullWidth
                                                                id="name"
                                                                label="Initiative Name"
                                                                value={info.name}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.textFieldRoot,
                                                                    },
                                                                    inputProps: {
                                                                        onChange: onChange
                                                                    }
                                                                }}
                                                                className={classes.textFieldRoot}
                                                                variant="outlined"
                                                            />
                                                        </GridItem>

                                                        <GridItem xs={6} style={{ marginTop: "10px" }}>
                                                            <TextField
                                                                fullWidth
                                                                id="measureResult"
                                                                label="Specific Measureable Result"
                                                                value={info.measureResult}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.textFieldRoot,
                                                                    },
                                                                    inputProps: {
                                                                        onChange: onChange
                                                                    }
                                                                }}
                                                                className={classes.textFieldRoot}
                                                                variant="outlined"
                                                            />
                                                        </GridItem>

                                                        <GridItem xs={6} style={{ marginTop: "10px" }}>
                                                            <TextField
                                                                fullWidth
                                                                id="date"
                                                                helperText="Target Date"
                                                                type="date"
                                                                value={info.date}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.textFieldRoot,
                                                                    },
                                                                    inputProps: {
                                                                        onChange: onChange
                                                                    }
                                                                }}
                                                                className={classes.textFieldRoot}
                                                                variant="outlined"
                                                            />
                                                        </GridItem>

                                                        <GridItem xs={6} style={{ marginTop: "10px" }}>
                                                            <TextField
                                                                fullWidth
                                                                id="personAccountable"
                                                                label="Person Accountable"
                                                                value={info.personAccountable}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.textFieldRoot,
                                                                    },
                                                                    inputProps: {
                                                                        onChange: onChange
                                                                    }
                                                                }}
                                                                className={classes.textFieldRoot}
                                                                variant="outlined"
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </Card>
                                            </DialogContent>
                                            <div style={{ justifyContent: "center", display: "flex", paddingBottom: "10px" }}>
                                                <Button type="submit" className={classes.button}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </form>
                                    </Dialog>
                                </GridContainer>
                            </div>
                        )
                    },
                    {
                        tabName: "Inactive",
                        tabContent: (
                            <div>
                                <h3 className={classes.subTitle} style={{ paddingLeft: "5px" }}>Inactive Initiatives</h3>
                                <GridContainer>
                                    {infoArr.map((data, index) => (
                                        <>
                                            {!data.active && !data.hide ?
                                                <GridItem xs={12} sm={6} md={4} lg={3}>

                                                    <Card style={{ borderRadius: 5, paddingBottom: "15px" }} key={index}>
                                                        <MoreVertOutlinedIcon
                                                            style={{ display: "block", marginTop: "10px", marginLeft: "auto", marginRight: "15px" }}
                                                            onClick={(e) => {
                                                                {
                                                                    setCurrentCard(infoArr[index])
                                                                    setPopperOpen(!popperOpen)
                                                                    setAnchorEl(anchorEl ? null : e.currentTarget)
                                                                }
                                                            }}
                                                        />
                                                        <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                                                            {({ TransitionProps }) => (
                                                                <Fade {...TransitionProps}>
                                                                    <Card>
                                                                        <Button
                                                                            onClick={(e) => {
                                                                                setAnchorEl(anchorEl ? null : e.currentTarget);
                                                                                setPopperOpen(!popperOpen)
                                                                            }}
                                                                            simple
                                                                            className={classes.modalCloseButton}
                                                                            key="close"
                                                                            aria-label="Close"
                                                                        >
                                                                            {""}
                                                                            <Close className={classes.modalClose} />
                                                                        </Button>
                                                                        <div className={classes.popperDiv} style={{ width: "150px" }} onClick={(e) => changeStatus(e, currentCard.docID, currentCard.active)}>
                                                                            <div className={classes.popperSelections} style={{ width: "150px" }}>
                                                                                <Replay /> Reactivate
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className={classes.popperDiv} style={{ width: "150px" }}>
                                                                            <div className={classes.popperSelections} style={{ width: "150px" }}>
                                                                                Delete
                                                                            </div>
                                                                        </div> */}
                                                                    </Card>
                                                                </Fade>
                                                            )}
                                                        </Popper>


                                                        <CardBody>
                                                            <center><strong><h3 className={classes.body}>{data.initiatveName}</h3></strong></center>
                                                            <h4 className={classes.body}><strong>Measure Result: </strong>{data.measureResult}</h4>
                                                            <h4 className={classes.body}><strong>Target Date: </strong>{data.targetDate}</h4>
                                                            <h4 className={classes.body}><strong>Person Accountable: </strong>{data.personAccountable}</h4>
                                                        </CardBody>
                                                        <NavLink to={`/tools/details/${data.docID}`} className={classes.navLink}>
                                                            <div style={{ justifyContent: "center", display: "flex" }}>
                                                                <Button className={classes.button}>
                                                                    View Details
                                                                </Button>
                                                            </div>
                                                        </NavLink>
                                                    </Card>
                                                </GridItem>
                                                : null}
                                        </>
                                    ))}

                                </GridContainer>
                            </div>
                        )
                    }
                ]}>

            </CustomTabs>
        </div>
    )
}

Initiative.propTypes = {
    classes: PropTypes.object.isRequired,
}

// const mapDispatchToProps = (dispatch) => ({
//     // nothing yet
// });

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        user: state.profile.user,
    }
};

export default compose(
    connect(mapStateToProps,),
    withStyles(Style)
)(Initiative);