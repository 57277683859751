import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import PropTypes from "prop-types";

import { db } from "firebase/fbConfig.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class Form extends React.Component {
  state = {
    businessDescription: "",
    messageSent: false,
    messageError: false,
  };
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid, name) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
      name: name,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    // update firestore document with PENDING and declaration
    db.collection("TEST-users")
      .doc(this.state.uid)
      .set(
        {
          businessDescription: this.state.businessDescription,
          updatedAt: new Date(),
        },
        { merge: true }
      )
      .then(() => {
        console.log(``);
        this.setState({
          businessDescription: "",
          messageSent: true,
        });
      })
      .catch((err) => {
        console.log(`${err}`);
      });
  };

  resetRequest = () => {
    window.location.reload();
  };

  render() {
    const { classes, authUser, user } = this.props;

    // reset modal when closed
    if (this.state.messageSent) {
      this.resetRequest();
    }

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Business Description Set!</h4>
            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer}>
            <form onSubmit={this.handleSubmit}>
              <GridContainer justify="center">
                <GridItem xs={11}>
                  {this.state.messageError ? (
                    <h5 className={classes.bodyText}>
                      There Has Been An Error
                    </h5>
                  ) : null}
                  <CustomInput
                    labelText="Business Description"
                    id="businessDescription"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                        value: this.state.businessDescription ? this.state.businessDescription : (this.props.user.businessDescription ? this.props.user.businessDescription : ''),
                      required: true,
                      type: "text",
                      multiline: true,
                      rows: 5,
                      onChange: (event) =>
                        this.handleChange(event, authUser.uid),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                type="submit"
                className={classes.button}
                style={{marginLeft:"40px"}}
              >
                SUBMIT
              </Button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  };
};

export default compose(connect(mapStateToProps), withStyles(styles))(Form);