import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link, useParams } from "react-router-dom";
import { db, firebase } from "firebase/fbConfig.js"
import moment from "moment";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Popper from "@material-ui/core/Popper";
import Fade from '@material-ui/core/Fade';
import { Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

// @material-ui/icons
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import Close from "@material-ui/icons/Close";
import { CloseOutlined, Delete, Edit, ExpandLess, ExpandMore } from "@material-ui/icons";


import Style from "assets/jss/material-dashboard-pro-react/views/actionPlanStyle.jsx";


const tableHeaders = ["Action Items", "Start Date", "People Required", "Completion Date", "Comments", "Capital Required", " "]

export function InitiativeDetails(props) {
    //Completion Date, Start Date, Capital Required smaller widths
    // Action Items & Comments Widths Bigger
    const { classes, history } = props;
    let { docID } = useParams();
    const [popperOpen, setPopperOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [infoArr, setInfoArr] = useState([])
    const [currentItem, setCurrentItem] = useState({})
    const [itemArr, setItemArr] = useState([])
    const [open, setOpen] = useState(false)
    const [position, setPosition] = useState(null)
    const [edit, setEdit] = useState(false)
    const [items, setItems] = useState({
        action: '',
        startDate: '',
        peopleRequired: '',
        endDate: '',
        comments: '',
        capitalRequired: '',
    })

    const onChange = (e) => {
        setItems({
            ...items,
            [e.target.id]: e.target.value
        })
    }

    const onClose = () => {
        setItems({
            action: '',
            startDate: '',
            peopleRequired: '',
            endDate: '',
            comments: '',
            capitalRequired: '',
        })
        setOpen(false)
    }
    const deleteClick = (e, id) => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).collection('action-items').doc(id).delete()
                    .then(() => {
                        setPopperOpen(!popperOpen)
                        setAnchorEl(anchorEl ? null : e.currentTarget);
                    })
            }
        })
    }

    const changeIndex = (e, direction, data, index) => {
        var data = [...itemArr]
        if (direction === 'up' && index > 0) {
            //position above item selected
            var aboveID = data[index - 1].docID
            var currentID = data[index].docID

            var aboveIndex = data[index - 1].position
            var currentIndex = data[index].position


            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    const aboveDoc = db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).collection('action-items').doc(aboveID)
                    const currentDoc = db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).collection('action-items').doc(currentID)

                    //change aboveDoc position to currentIndex and vice versa
                    aboveDoc.update({
                        position: currentIndex
                    })
                        .then(() => {
                            currentDoc.update({
                                position: aboveIndex
                            }).then(() => {
                                setPopperOpen(!popperOpen)
                                setAnchorEl(anchorEl ? null : e.currentTarget);
                            })
                        })

                }
            })

        }

        if (direction === 'down' && index < itemArr.length - 1) {
            var currentID = data[index].docID
            var belowID = data[index + 1].docID

            var currentIndex = data[index].position
            var belowIndex = data[index + 1].position

            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    const belowDoc = db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).collection('action-items').doc(belowID)
                    const currentDoc = db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).collection('action-items').doc(currentID)

                    //change aboveDoc position to currentIndex and vice versa
                    belowDoc.update({
                        position: currentIndex
                    })
                        .then(() => {
                            currentDoc.update({
                                position: belowIndex
                            }).then(() => {
                                setPopperOpen(!popperOpen)
                                setAnchorEl(anchorEl ? null : e.currentTarget);
                            })
                        })

                }
            })
        }
    }
    const onCancel = () => {
        setEdit(false)
        setItems({
            action: '',
            startDate: '',
            peopleRequired: '',
            endDate: '',
            comments: '',
            capitalRequired: '',
        })

    }

    const onUpdate = (id) => {
        console.log("checking inside functions")
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                let update = {}
                if (items.action) {
                    update.actionItem = items.action
                }
                if (items.startDate) {
                    update.startDate = moment(items.startDate).format("MM/DD/YYYY")
                }
                if (items.peopleRequired) {
                    update.peopleRequired = items.peopleRequired
                }
                if (items.endDate) {
                    update.endDate = moment(items.endDate).format("MM/DD/YYYY")
                }
                if (items.comments) {
                    update.comments = items.comments
                }
                if (items.capitalRequired) {
                    update.capitalRequired = items.capitalRequired
                }
                console.log("checking what update is")
                db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).collection('action-items').doc(id).update(update)
                    .then(() => {
                        setEdit(false)
                    })
                setItems({
                    action: '',
                    startDate: '',
                    peopleRequired: '',
                    endDate: '',
                    comments: '',
                    capitalRequired: '',
                })
                // console.log("checking items: ", items)
                // console.log("checkilng updates:", update)
            }
        })
        setEdit(false)
    }
    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{
                width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)",
                fontFamily: "'Merriweather Sans', sans-serif", paddingBottom: "10px", lineHeight: "1.2em"
            }} key={index}>{header}
            </th>
        })
    }
    async function getInfo() {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                const items = []
                db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).get()
                    .then((queryResult) => {
                        items.push(queryResult.data());
                        setInfoArr(items)

                    })
            }
        })
    }

    useEffect(() => {
        getInfo()
    }, [])

    async function getItems() {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).collection('action-items')
                    .orderBy('position', 'asc')
                    .onSnapshot((querySnapshot) => {
                        const items = [];
                        querySnapshot.forEach((doc) => {
                            items.push(doc.data());
                        })
                        setItemArr(items)
                    })
            }
        })
    }
    useEffect(() => {
        getItems()
    }, [])
    const renderTableData = () => {
        return itemArr.map((data, index) => {
            if (edit) {
                return (
                    <>
                        {index === position ?
                            <tr style={{ textAlign: "center", alignItems: "center", width: "100%" }}>
                                <td style={{ width: "35%", textAlign: "left" }}>
                                    <TextField
                                        fullWidth
                                        id="action"
                                        defaultValue={data.actionItem}
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldRoot,
                                            },
                                            inputProps: {
                                                onChange: onChange,
                                            }
                                        }}
                                        className={classes.textFieldRoot}
                                        variant="outlined"
                                    />
                                </td>
                                <td style={{ width: "7%" }}>
                                    <TextField
                                        fullWidth
                                        id="startDate"
                                        type="date"
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldRoot,
                                            },
                                            inputProps: {
                                                onChange: onChange,
                                                defaultValue: data.startDate
                                            }
                                        }}
                                        className={classes.textFieldRoot}
                                        variant="outlined"
                                    />
                                </td>
                                <td style={{ width: "7%" }}>
                                    <TextField
                                        fullWidth
                                        id="peopleRequired"
                                        defaultValue={data.peopleRequired}
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldRoot,
                                            },
                                            inputProps: {
                                                onChange: onChange
                                            }
                                        }}
                                        className={classes.textFieldRoot}
                                        variant="outlined"
                                    />
                                </td>
                                <td style={{ width: "7%" }}>
                                    <TextField
                                        fullWidth
                                        id="endDate"
                                        type="date"
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldRoot,
                                            },
                                            inputProps: {
                                                onChange: onChange,
                                                defaultValue: data.endDate
                                            }
                                        }}
                                        className={classes.textFieldRoot}
                                        variant="outlined"
                                    />
                                </td>
                                <td style={{ width: "30%", textAlign: "left" }}>
                                    <TextField
                                        fullWidth
                                        id="comments"
                                        defaultValue={data.comments}
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldRoot,
                                            },
                                            inputProps: {
                                                onChange: onChange
                                            }
                                        }}
                                        className={classes.textFieldRoot}
                                        variant="outlined"
                                    />
                                </td>
                                <td style={{ width: "7%" }}>
                                    <TextField
                                        fullWidth
                                        id="capitalRequired"
                                        defaultValue={data.capitalRequired}
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldRoot,
                                            },
                                            inputProps: {
                                                onChange: onChange
                                            }
                                        }}
                                        className={classes.textFieldRoot}
                                        variant="outlined"
                                    />
                                </td>
                                <td style={{ width: "7%" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <button style={{ width: "60px" }} onClick={() => onUpdate(data.docID)}>
                                            Save
                                        </button>
                                        <button style={{ width: "60px" }} onClick={() => onCancel()}>
                                            Cancel
                                        </button>
                                    </div>

                                </td>
                            </tr>
                            :
                            <tr style={{ textAlign: "center", alignItems: "center", width: "100%" }}>
                                <td style={{ width: "35%", textAlign: "left" }}>
                                    {data.actionItem}
                                </td>
                                <td style={{ width: "7%" }}>
                                    {data.startDate}
                                </td>
                                <td style={{ width: "7%" }}>
                                    {data.peopleRequired}
                                </td>
                                <td style={{ width: "7%" }}>
                                    {data.endDate}
                                </td>
                                <td style={{ width: "30%", textAlign: "left" }}>
                                    {data.comments}
                                </td>
                                <td style={{ width: "7%" }}>
                                    {data.capitalRequired}
                                </td>
                            </tr>}
                    </>
                )
            }
            else {
                return (
                    <tr style={{ textAlign: "center", alignItems: "center", width: "100%" }}>
                        <td style={{ width: "35%", textAlign: "left" }}>
                            {data.actionItem}
                        </td>
                        <td style={{ width: "7%" }}>
                            {data.startDate}
                        </td>
                        <td style={{ width: "7%" }}>
                            {data.peopleRequired}
                        </td>
                        <td style={{ width: "7%" }}>
                            {data.endDate}
                        </td>
                        <td style={{ width: "30%", textAlign: "left" }}>
                            {data.comments}
                        </td>
                        <td style={{ width: "7%" }}>
                            {data.capitalRequired}
                        </td>
                        <td style={{ width: "7%" }}>
                            <MoreVertOutlinedIcon onClick={(event) => {
                                {
                                    setCurrentItem(itemArr[index])
                                    setPopperOpen(!popperOpen)
                                    setAnchorEl(anchorEl ? null : event.currentTarget);
                                    setPosition(index)
                                }
                            }} />
                            <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps}>
                                        <Card>
                                            <Button
                                                onClick={(event) => {
                                                    setAnchorEl(anchorEl ? null : event.currentTarget);
                                                    setPopperOpen(!popperOpen)
                                                }}
                                                simple
                                                className={classes.modalCloseButton}
                                                key="close"
                                                aria-label="Close"
                                            >
                                                {""}
                                                <Close className={classes.modalClose} />
                                            </Button>
                                            <div className={classes.popperDiv} style={{ width: "150px" }} onClick={() => setEdit(true)}>
                                                <div className={classes.popperSelections} style={{ width: "150px" }}>
                                                    <Edit /> Edit
                                                </div>
                                            </div>
                                            <div className={classes.popperDiv} style={{ width: "150px" }} onClick={(e) => deleteClick(e, currentItem.docID)}>
                                                <div className={classes.popperSelections} style={{ width: "150px" }}>
                                                    <Delete /> Delete
                                                </div>
                                            </div>
                                            <div className={classes.popperDiv} style={{ width: "150px" }}>
                                                <div className={classes.popperSelections} style={{ width: "150px" }} onClick={(e) => changeIndex(e, 'up', itemArr, position)}>
                                                    <ExpandLess /> Move Up
                                                </div>
                                            </div>
                                            <div className={classes.popperDiv} style={{ width: "150px" }} onClick={(e) => changeIndex(e, 'down', itemArr, position)}>
                                                <div className={classes.popperSelections} style={{ width: "150px" }}>
                                                    <ExpandMore /> Move Down
                                                </div>
                                            </div>
                                        </Card>
                                    </Fade>
                                )}
                            </Popper>
                        </td>
                    </tr>
                )
            }

        })
    }

    const printPDF = () => {
        const doc = new jsPDF();
        doc.setDrawColor(77, 171, 41);
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(`Initiative Name:`, 5, 10);
        doc.setFont("helvetica", "normal");
        var initName = doc.splitTextToSize(`${infoArr[0].initiativeName}`, 60)
        doc.text(initName, 37, 10)
        doc.setFont("helvetica", "bold");
        doc.text("Target Date:", 130, 10);
        doc.setFont("helvetica", "normal");
        doc.text(`${infoArr[0].targetDate}`, 155, 10);
        doc.setFont("helvetica", "bold");
        doc.text("Specific Measurable Result:", 5, 25);
        doc.setFont("helvetica", "normal");
        var measureResult = doc.splitTextToSize(`${infoArr[0].measureResult}`, 60)
        doc.text(measureResult, 62, 25);
        doc.setFont("helvetica", "bold");
        doc.text("Person Accountable:", 130, 25);
        doc.setFont("helvetica", "normal");
        var personAcc = doc.splitTextToSize(`${infoArr[0].personAccountable}`, 60)
        doc.text(personAcc, 172, 25);




        // Testing
        var col = ["Action Items", "Start Date", "People Req.", "Completion Date", "Comments", "Capital Req."];
        var rows = [];
        itemArr.map((item) => {
            var temp = [doc.splitTextToSize(item.actionItem), doc.splitTextToSize(item.startDate),
            doc.splitTextToSize(item.peopleRequired), doc.splitTextToSize(item.endDate), doc.splitTextToSize(item.comments),
            doc.splitTextToSize(item.capitalRequired)]
            rows.push(temp)
        });

        // doc.autoTable(col, rows, {
        //     startY: 50, tableWidth: 'auto', margin: 15, styles: { halign: "left", cellWidth: 60, overflow: "linebreak", 
        //     fontSize: 12, fillColor: (220, 220, 220), textColor: "black",
        //     columnStyles: {text: {columnWidth: 'auto'}, longTitle: {columnWidth: 'wrap'}} },
        // })


        doc.autoTable(col, rows, {
            startY: 50,
            theme: 'grid',
            tableWidth: 'auto',
            fontSize: 12,
            margin: { horizontal: 7 },
            styles: { overflow: 'linebreak', halign: "left", fillColor: (220, 220, 220), textColor: "black", },
            columnStyles: { text: { columnWidth: 'wrap' }, longTitle: { columnWidth: 'wrap' } },
        })

        doc.save(`${infoArr[0].initiativeName}_action_plan.pdf`);
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        let data = {
            actionItem: items.action,
            startDate: moment(items.startDate).format("MM/DD/YYYY"),
            peopleRequired: items.peopleRequired,
            endDate: moment(items.endDate).format("MM/DD/YYYY"),
            comments: items.comments,
            capitalRequired: items.capitalRequired,
            position: itemArr.length + 1,
            docID: "Placeholder"
        }
        firebase.auth().onAuthStateChanged(async function (user) {
            if (user) {
                await db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).collection('action-items').add(data)
                    .then(async function (docRef) {
                        await db.collection('TEST-users').doc(user.uid).collection('action-plan').doc(docID).collection('action-items').doc(docRef.id).update({
                            docID: docRef.id
                        })
                    })
                    .then(() => {
                        window.location.reload()
                    })
            }
        })
    }

    return (
        <div className={classes.container}>
            <GridContainer>
                <GridItem xs={6}>
                    <h3 className={classes.subTitle} style={{ paddingLeft: "40px" }}>Initiative Action Plan</h3>
                </GridItem>

                <GridItem xs={6}
                    style={{ justifyContent: "flex-end", display: "flex", marginTop: "7px" }}
                >
                    <Button
                        className={classes.button}
                        style={{ height: "35px", marginRight: "35px", marginTop: "20px" }}
                        onClick={() => { printPDF() }}
                    >
                        Print PDF
                    </Button>
                </GridItem>
            </GridContainer>
            <GridContainer style={{ alignItems: "center", justifyContent: "center", margin: "0px auto", width: "95%" }}>
                {infoArr.length > 0 ?
                    infoArr.map((data, index) => (
                        <Card style={{ padding: "10px" }}>
                            <GridContainer>
                                <GridItem xs={8}>
                                    <h3 className={classes.body} style={{ fontSize: "20px" }}><strong>Initiative Name: </strong> {data.initiativeName}</h3>
                                    <h3 className={classes.body} style={{ fontSize: "20px" }}><strong>Specific Measurable Result: </strong> {data.measureResult}</h3>

                                </GridItem>
                                <GridItem xs={4}>
                                    <h3 className={classes.body} style={{ fontSize: "20px" }}><strong>Target Date: </strong> {data.targetDate}</h3>
                                    <h3 className={classes.body} style={{ fontSize: "20px" }}><strong>Person Accountable:</strong> {data.personAccountable}</h3>
                                </GridItem>

                            </GridContainer>


                        </Card>
                    ))
                    :
                    <Card style={{ padding: "10px" }}>
                        <h3 className={classes.body} style={{ borderBottom: "1px solid black", fontSize: "20px" }}>Initiative Name:</h3>
                        <h3 className={classes.body} style={{ borderBottom: "1px solid black", fontSize: "20px" }}>Specific Measurable Result: </h3>
                        <h3 className={classes.body} style={{ borderBottom: "1px solid black", fontSize: "20px" }}>Target Date: </h3>
                        <h3 className={classes.body} style={{ borderBottom: "1px solid black", fontSize: "20px" }}>Person Accountable: </h3>
                    </Card>}
                <Card style={{ padding: "10px" }}>
                    <table style={{ width: "100%", marginTop: "10px" }}>
                        <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                        {renderTableData()}
                    </table>
                </Card>
                <GridItem xs={3}>
                    <Button onClick={() => setOpen(true)}
                        style={{ height: "50px", width: "50px", fontSize: "25px", right: "20px", bottom: "55px", position: "fixed", zIndex: "10", background: "#F4BF4F", color: "white" }}
                        justIcon round>
                        +
                    </Button>
                </GridItem>
                <Dialog
                    open={open}
                    // onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth="md"
                >
                    <CloseOutlined style={{ display: "block", marginTop: "10px", marginLeft: "auto", marginRight: "15px" }} onClick={() => onClose()} />

                    <form onSubmit={onSubmit}>
                        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
                            Action Item Form
                        </DialogTitle>
                        <DialogContent>
                            <Card style={{ padding: "10px" }}>
                                <GridContainer>

                                    <GridItem xs={6} >
                                        <TextField
                                            fullWidth
                                            id="action"
                                            label="Action Item"
                                            value={items.action}
                                            InputProps={{
                                                classes: {
                                                    root: classes.textFieldRoot,
                                                },
                                                inputProps: {
                                                    onChange: onChange
                                                }
                                            }}
                                            className={classes.textFieldRoot}
                                            variant="outlined"
                                        />
                                    </GridItem>

                                    <GridItem xs={6} >
                                        <TextField
                                            focused
                                            fullWidth
                                            id="startDate"
                                            // label="Start Date"
                                            type="date"
                                            helperText="Start Date"
                                            InputProps={{
                                                classes: {
                                                    root: classes.textFieldRoot,
                                                },
                                                inputProps: {
                                                    onChange: onChange,
                                                }
                                            }}
                                            className={classes.textFieldRoot}
                                            variant="outlined"
                                        />
                                    </GridItem>

                                    <GridItem xs={6} style={{ marginTop: "15px" }}>
                                        <TextField
                                            fullWidth
                                            id="peopleRequired"
                                            label="People Required"
                                            value={items.peopleRequired}
                                            InputProps={{
                                                classes: {
                                                    root: classes.textFieldRoot,
                                                },
                                                inputProps: {
                                                    onChange: onChange
                                                }
                                            }}
                                            className={classes.textFieldRoot}
                                            variant="outlined"
                                        />
                                    </GridItem>

                                    <GridItem xs={6} style={{ marginTop: "15px" }}>
                                        <TextField
                                            fullWidth
                                            id="endDate"
                                            // label="End Date"
                                            helperText="Completion Date"
                                            type="date"
                                            InputProps={{
                                                classes: {
                                                    root: classes.textFieldRoot,
                                                },
                                                inputProps: {
                                                    onChange: onChange
                                                }
                                            }}
                                            className={classes.textFieldRoot}
                                            variant="outlined"
                                        />
                                    </GridItem>

                                    <GridItem xs={6} style={{ marginTop: "15px" }}>
                                        <TextField
                                            fullWidth
                                            id="comments"
                                            label="Comments"
                                            value={items.comments}
                                            InputProps={{
                                                classes: {
                                                    root: classes.textFieldRoot,
                                                },
                                                inputProps: {
                                                    onChange: onChange
                                                }
                                            }}
                                            className={classes.textFieldRoot}
                                            variant="outlined"
                                        />
                                    </GridItem>

                                    <GridItem xs={6} style={{ marginTop: "15px" }}>
                                        <TextField
                                            fullWidth
                                            id="capitalRequired"
                                            label="Capital Required"
                                            value={items.capitalRequired}
                                            InputProps={{
                                                classes: {
                                                    root: classes.textFieldRoot,
                                                },
                                                inputProps: {
                                                    onChange: onChange
                                                }
                                            }}
                                            className={classes.textFieldRoot}
                                            variant="outlined"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </DialogContent>
                        <div style={{ justifyContent: "center", display: "flex", paddingBottom: "10px" }}>
                            <Button type="submit" style={{ background: "#4ec7bc", width: "500px" }}>
                                Submit
                            </Button>
                        </div>
                    </form>
                </Dialog>

            </GridContainer>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = (state) => {
    // nothing yet
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(InitiativeDetails);