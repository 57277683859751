import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import { db } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class Form extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      valueTitle1: this.props.user.valueTitle1 ? this.props.user.valueTitle1 : '',      
      valueProposition1: this.props.user.valueProposition1 ? this.props.user.valueProposition1 : '',      
      valueProposition2: this.props.user.valueProposition2 ? this.props.user.valueProposition2 : '',      
      valueProposition3: this.props.user.valueProposition3 ? this.props.user.valueProposition3 : '',      
      valueProposition4: this.props.user.valueProposition4 ? this.props.user.valueProposition4 : '',      
      valueTitle2: this.props.user.valueTitle2 ? this.props.user.valueTitle2 : '',      
      valueProposition5: this.props.user.valueProposition5 ? this.props.user.valueProposition5 : '',      
      valueProposition6: this.props.user.valueProposition6 ? this.props.user.valueProposition6 : '',   
      valueProposition7: this.props.user.valueProposition7 ? this.props.user.valueProposition7 : '',      
      valueProposition8: this.props.user.valueProposition8 ? this.props.user.valueProposition8 : '',      
      messageSent: false,
      messageError: false,
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid, name) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
      name: name
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();

    // update firestore document with PENDING and declaration
    db.collection("TEST-users").doc(this.state.uid).set({
      valueTitle1: this.state.valueTitle1,
      valueProposition1: this.state.valueProposition1,
      valueProposition2: this.state.valueProposition2,
      valueProposition3: this.state.valueProposition3,
      valueProposition4: this.state.valueProposition4,
      valueTitle2: this.state.valueTitle2,
      valueProposition5: this.state.valueProposition5,
      valueProposition6: this.state.valueProposition6,  
      valueProposition7: this.state.valueProposition7,
      valueProposition8: this.state.valueProposition8,           
      updatedAt: new Date()
    }, { merge: true }).then(() => {
      console.log(``)
      this.setState({        
        messageSent: true
      })
    }).catch((err) => {
      console.log(`${err}`)
    })
  }

  resetRequest = () => {
    window.location.reload();
  }

  render() {
    const { classes, authUser, user } = this.props;

    // reset modal when closed
    if (this.state.messageSent) { this.resetRequest() }

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Core Values & Goals Set!</h4>

            </GridItem>
          </GridContainer>
        ) : (
            <div className={classes.formContainer}>
              <form onSubmit={this.handleSubmit}
              >
                <GridContainer justify="center">
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="Customer Value Proposition Title"
                      id="valueTitle1"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueTitle1 ? this.props.user.valueTitle1 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>                  
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="First Customer Value Proposition"
                      id="valueProposition1"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueProposition1 ? this.props.user.valueProposition1 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>                  
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="Second Customer Value Proposition"
                      id="valueProposition2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueProposition2 ? this.props.user.valueProposition2 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>                  
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="Third Customer Value Proposition"
                      id="valueProposition3"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueProposition3 ? this.props.user.valueProposition3 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>  
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="Fourth Customer Value Proposition"
                      id="valueProposition4"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueProposition4 ? this.props.user.valueProposition4 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>  
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="Staff Value Proposition Title"
                      id="valueTitle2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueTitle2 ? this.props.user.valueTitle2 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>         
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="First Staff Value Proposition"
                      id="valueProposition5"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueProposition5 ? this.props.user.valueProposition5 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>   
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="Second Staff Value Proposition"
                      id="valueProposition6"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueProposition6 ? this.props.user.valueProposition6 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>        
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="Third Staff Value Proposition"
                      id="valueProposition7"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueProposition7 ? this.props.user.valueProposition7 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>  
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                    <CustomInput
                      labelText="Fourth Staff Value Proposition"
                      id="valueProposition8"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.props.user.valueProposition8 ? this.props.user.valueProposition8 : '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />
                  </GridItem>  
                </GridContainer>
                <Button
                  type="submit"
                  color="primary"
                  style={{
                    marginLeft: "10px",
                    marginBottom: "10px"
                  }}
                >
                  SUBMIT
              </Button>
              </form>
            </div>

          )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);