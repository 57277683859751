import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { Chart } from 'react-google-charts';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/practicesStyle.jsx";
import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"
import ShareResourceForm from "../../components/Forms/ShareResourceForm.jsx";

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import { setFirstAssessment } from "store/actions/assessmentActions"
import { startSetUsersList } from "store/actions/adminActions"
import { startSetStudiesList } from "store/actions/studiesActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Principles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updatePhotoModal: false,
      learnDashboardModal: false
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
    let studiesData = this.props.studies.dataRows.map((prop, key) => {
      let colObj = { ...prop }
      const volUID = colObj.volUID
      const username = colObj.username
      // Load Data for Summary Cards and add buttons     
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        order: colObj.order,
        actions: (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => this.handleClickOpen("assignActionModal",
                volUID
              )}
          >ASSIGN ACTION</Button>)
      }
    })

    this.setState({
      studiesData,
      dataLoaded: true
    })
  }

  // Load data for Table
  // if there are no actions make a default one



  render() {
    const { AuthBool, classes, user, studies, assessments } = this.props;
    //console.log(this.state.practicesData)
    let profilePhoto = DefaultProfile

    if (AuthBool) { // fixes freeze on logout
      if (user.dataLoaded) {
        user.profileURL ? (
          profilePhoto = user.profileURL
        ) : (
          // add link to profile photo to firestore
          profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
          // add profile photo to firebase storage        
          // update redux with link
        )
      }
    }

    let lastAssessmentPurposePercent
    //console.log(user)
    //console.log(assessments.dataLoaded)
    if (studies.dataLoaded
      && !this.state.dataLoaded) {
      //console.log("load data")
      this.loadData()
      // console.log(user)
      // console.log(user.levelUpdatedAt.toDate())
      // console.log(moment(user.levelUpdatedAt.toDate()).format('MM-DD-YYYY'))  
    }

    if (user.termGroups) {
      console.log(user.termGroups.length)

      if (user.termGroups.length < 2) {
        return <Redirect to='/profile' />
      }
    }

    //console.log(assessments.assessments.length)
    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
        row[id] !== undefined ?
          String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
          :
          true
      );
    }

    return (
      <div>
        <GridContainer
          style={{ backgroundColor: "#E5E5E5", height: "60px", paddingBottom: "5px" }}
        >
          <GridItem xs={6}>
            <h3
              style={{
                fontFamily: "'Merriweather Sans', sans-serif",
                textTransform: "uppercase",
                color: "#3a3934",
                fontWeight: "600",
                verticalAlign: "center"
              }}
            >
              Welcome to The Research Studies Library
            </h3>
          </GridItem>
          <GridItem xs={6}
            style={{ justifyContent: "flex-end", display: "flex", marginTop: "7px" }}
          >
            <Button
              className={classes.button}
              style={{ height: "35px", marginRight: "15px" }}
              onClick={() => this.handleClickOpen("updatePhotoModal")}
            >
              SHARE A RESOURCE
            </Button>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            {this.state.dataLoaded ? (
              <div>
                <Card style={{ padding: "10px" }}>
                  <ReactTable
                    data={this.state.studiesData}
                    filterable
                    defaultFilterMethod={filterCaseInsensitive}

                    columns={this.props.studies.columns}
                    defaultPageSize={10}
                    defaultSorted={[
                      {
                      }
                    ]}
                    //showPaginationTop
                    showPaginationBottom
                    style={{
                      color: "#000",
                      marginTop: "50px"
                    }}
                    className="-striped -highlight"
                  />
                </Card>

                {/* UPDATE PROFILE PHOTO MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSmall
                  }}
                  open={this.state.updatePhotoModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => this.handleClose("updatePhotoModal")}
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("updatePhotoModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <h2 className={classes.modalTitle} style={{ marginLeft: "15px" }}>SHARE A RESOURCE</h2>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    {/* FORM */}
                    {AuthBool &&
                      <ShareResourceForm
                        currentPic={profilePhoto}
                        username={this.props.user.username}
                        uid={this.props.authUser.uid}
                      />
                    }

                    <div
                      style={{
                        textAlign: "center"
                      }}
                    >
                      {/* <Button
                        color="danger"
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("updatePhotoModal")}
                      >
                        CANCEL
                      </Button> */}
                    </div>
                  </DialogContent>
                </Dialog>

              </div>
            ) : (
              <div className={classes.easeInOut}>
                <Hidden smDown implementation="css">
                  <div className={classes.preloaderDesktop}
                    style={{
                      background: "white",
                      position: "absolute",
                      width: "110%",
                      margin: "-25% 0% 0% -8%",
                      height: "180%",
                      zIndex: "99999",
                    }}
                  >
                    <img
                      style={{
                        margin: "30% 0% 0% 33%",
                        position: "relative",
                        width: "25%"
                      }}
                      src={Logo}
                      alt="..."
                    />
                    <img
                      style={{
                        margin: "2% 0% 0% 38%",
                        position: "relative",
                        width: "20%"
                      }}
                      src={Loader}
                      alt="..."
                    />
                  </div>
                </Hidden>
                <Hidden mdUp implementation="css">
                  <div className={classes.preloaderMobile}
                    style={{
                      background: "white",
                      position: "absolute",
                      width: "110%",
                      margin: "-30% 0% 0% -10%",
                      height: "110%",
                      zIndex: "9999",
                    }}
                  >
                    <img
                      style={{
                        margin: "30% 0% 0% 20%",
                        position: "relative",
                        width: "50%"
                      }}
                      src={Logo}
                      alt="..."
                    />
                    <img
                      style={{
                        margin: "15% 0% 0% 38%",
                        position: "relative",
                        width: "20%"
                      }}
                      src={Loader}
                      alt="..."
                    />
                  </div>
                </Hidden>
              </div>
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    studies: state.studies.studies
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSetStudiesList: dispatch(startSetStudiesList())
  }
}


// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Principles);