import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { db, firebase } from "firebase/fbConfig.js"
import axios from 'axios'
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from "@material-ui/core";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from '@material-ui/icons/Check';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/profileFormStyle.jsx";

import { updateUser } from "store/actions/profileActions.jsx"

// Assets
import precincts from "variables/precincts.jsx"
import { ThreeSixtySharp } from "@material-ui/icons";

// let consultantArr = ["Not Assigned", "Paul Ratoff"]
class Form extends React.Component {
  state = {
    GROUPS: [],
  }

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      ...this.state,
      groups: this.props.user.groups,
      hideData: this.props.user.hideData,
      termGroups: this.props.user.termGroups.filter(group => group != "Pioneer Commitment" && group != "Professional Partner Terms" && group != "Consulting Partner Terms" &&
      group != "Terms and Conditions for Certified PL Consultant"),
      agreeToTermsError: false,
      county: !!this.props.user.county ? (this.props.user.county) : 'Other',
      precinct: !!this.props.user.precinct ? (this.props.user.precinct) : '',
      contactPrivacy: this.props.user.contactPrivacy ? this.props.user.contactPrivacy : '',
      namePrivacy: this.props.user.namePrivacy ? this.props.user.namePrivacy : '',
      emailError: '',
      emailChange: false, // when user wants to change email
      typeArr: [{ name: "User", id: 0 }, { name: "Certified PL Consultant", id: 1 }],
      typeIndex: null,
      typeValue: this.props.user.typeValue ? this.props.user.typeValue : '',
      consultantValue: '',
      consultantCommit: false,
      // consultantArr: ["Not Assigned"]
    };
  }

  componentDidMount = () => {
    var inputElement = document.getElementById('phone');
    inputElement.addEventListener('keydown', this.enforceFormat);
    inputElement.addEventListener('keyup', this.formatToPhone);
    // firebase.auth().onAuthStateChanged(function (user) {
    //   if (user) {
    //     db.collection('consultants')
    //       .onSnapshot((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //           consultantArr.push(doc.data().name)
    //         });
    //       });
    //   }
    // })
  }

  componentWillUnmount = () => {
    var inputElement = document.getElementById('phone');
    inputElement.removeEventListener('keydown', this.enforceFormat);
    inputElement.removeEventListener('keyup', this.formatToPhone);
    // consultantArr = ["Not Assigned"]
  }

  //Phone number code modified from
  // https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript 
  isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };

  enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
      event.preventDefault();
    }
  };

  formatToPhone = (event) => {
    if (this.isModifierKey(event)) {
      return;
    }

    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only    
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    let targetValue
    if (input.length > 6) { targetValue = `(${zip}) ${middle} - ${last}`; }
    else if (input.length > 3) { targetValue = `(${zip}) ${middle}`; }
    else if (input.length > 0) { targetValue = `(${zip}`; }
    target.value = targetValue
    this.setState({
      phone: targetValue
    })
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  handleChange = (e) => {
    let value = e.target.value
    if (e.target.id === "firstName" || e.target.id === "lastName") {
      value = value.charAt(0).toUpperCase() + value.slice(1)
    }
    this.setState({
      [e.target.id]: value
    })
  }

  handleToggle(e, value) {
    const { groups } = this.state;
    const currentIndex = groups.indexOf(value);
    const newGroups = [...groups];

    if (currentIndex === -1) {
      newGroups.push(value);
    } else {
      newGroups.splice(currentIndex, 1);
    }
    this.setState({
      groups: newGroups
    });
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleType = (e, i) => {
    if (e.target.value === "User") {
      this.setState({ typeIndex: i, typeValue: "User" })
    }
    else {
      this.setState({ typeIndex: i, typeValue: "Certified PL Consultant" })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // let termLength;
    // if (this.state.groups.indexOf('Consulting Partner') !== -1) {
    //   if (this.state.groups.indexOf('Professional Partner') !== -1) {
    //     termLength = 4
    //   } else {
    //     termLength = 3
    //   }
    // } else if (this.state.groups.indexOf('Professional Partner') !== -1) {
    //   termLength = 3
    // } else { termLength = 2 }

    if (this.state.termGroups.includes('Privacy Policy', 'Terms and Conditions') && this.state.typeValue != "Certified PL Consultant") {
      updateUser(this.state, this.props.uid)
    }
    else if (this.state.termGroups.includes('Privacy Policy', 'Terms and Conditions', 'Certified PL Consultant Terms') && this.state.typeValue === "Certified PL Consultant") {
      updateUser(this.state, this.props.uid)
    }
    else {
      this.setState({
        agreeToTermsError: true
      })
    }
  }


  handleToggleContactPrivacy(value) {
    this.setState({
      contactPrivacy: !this.state.contactPrivacy
    });
  }

  handleToggleNamePrivacy(value) {
    this.setState({
      typeValue: !this.state.namePrivacy
    });
  }

  toggleData() {
    this.setState({
      hideData: !this.state.hideData
    });
  }

  triggerHideData() {
    this.setState({
      hideData: false
    });
  }


  handleToggleTerms(value) {
    const { termGroups } = this.state;
    const currentIndex = termGroups.indexOf(value);
    const filteredGroup = termGroups.filter(group => group != "Pioneer Commitment" && group != "Professional Partner Terms" && group != "Consulting Partner Terms"
      && group != "Terms and Conditions for Certified PL Consultant")
    const newTermGroups = [...filteredGroup];


    if (currentIndex === -1) {
      newTermGroups.push(value);
    } else {
      newTermGroups.splice(currentIndex, 1);
    }

    this.setState({
      termGroups: newTermGroups
    });
  }

  render() {
    const { classes, consultantArr, consultantValue } = this.props;
    if (this.state.hideData === undefined) {
      this.triggerHideData()
    }

    if(this.state.consultantValue === '' && consultantValue != undefined){
      console.log("checking: ", this.props.consultantValue)
      this.setState({
        consultantValue: this.props.consultantValue
      })
    }
    
    return (
      <div className={classes.formContainer}>
        <form onSubmit={this.handleSubmit}
        >
          <GridContainer justify="center">
            <GridItem xs={11}>
              {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
              <h5 className={classes.bodyText}>EMAIL: {this.props.user.email}</h5>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Purpose Declaration"
                    id="purposeDeclaration"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      onChange: (event) => this.handleChange(event),
                      defaultValue: this.props.user.purposeDeclaration ? this.props.user.purposeDeclaration : ''
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Representative First Name"
                    id="firstName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      onChange: (event) => this.handleChange(event),
                      defaultValue: this.props.user.firstName ? this.props.user.firstName : ''
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Representative Last Name"
                    id="lastName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      onChange: (event) => this.handleChange(event),
                      defaultValue: this.props.user.lastName ? this.props.user.lastName : ''
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Company Name"
                    id="companyName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      type: "text",
                      onChange: (event) => this.handleChange(event),
                      defaultValue: this.props.user.companyName
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Phone Number"
                    id="phone"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      maxLength: "16",
                      type: "text",
                      onChange: (event) => this.handleChange(event),
                      defaultValue: this.props.user.phone
                    }}
                  />
                </GridItem>
                {this.state.groups.length !== 0 ?
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      labelText="Business or Service Type"
                      id="businessType"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: false,
                        type: "text",
                        onChange: (event) => this.handleChange(event),
                        defaultValue: this.props.user.businessType
                      }}
                    />
                  </GridItem> : null
                }

                {/* {this.state.groups.length !== 0 ?
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      labelText="Bio"
                      id="biography"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        maxLength: "16",
                        type: "text",
                        onChange: (event) => this.handleChange(event),
                        defaultValue: this.props.user.biography
                      }}
                    />
                  </GridItem> : null

                } */}
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Number of Employees"
                    id="employeeCount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      // maxLength: "16",
                      type: "number",
                      onChange: (event) => this.handleChange(event),
                      defaultValue: this.props.user.employeeCount
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6}>
                  <h5 className={classes.bodyText}>TYPE</h5>
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => {
                            this.setState({
                              typeValue: "User"
                            })
                          }}
                          checked={this.state.typeValue === "User" ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label='User'
                    />
                  </div>
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => {
                            this.setState({
                              typeValue: "Certified PL Consultant"
                            })
                          }}
                          checked={this.state.typeValue === "Certified PL Consultant" ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label='Certified PL Consultant'
                    />
                  </div>

                  {this.state.typeValue === "User" ?
                    <div style={{ marginTop: "10px" }}>
                      <p style={{ color: "black" }}>Who is your Certified PL Consultant ?</p>
                      <Select
                        style={{ width: "350px"}}
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        // classes={{
                        //   select: classes.select,
                        // }}
                        value={this.state.consultantValue}
                        onChange={(event) => {
                          this.setState({ consultantValue: event.target.value })
                        }}
                        variant="filled"
                        inputProps={{
                          name: "pageSelect",
                          id: "page-select",
                        }}
                      >
                        {consultantArr.map((prop, key) => {
                          return (
                            <MenuItem
                              sx={{
                                '& .Form-select': {
                                  fontSize: "20",
                                  color: "black"
                                }
                              }}
                              key={key}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={prop}
                            >
                              {prop}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                    : null}
                </GridItem>

                <GridItem xs={12} sm={6}>
                  <h5 className={classes.bodyText}>DOCUMENTS</h5>
                  <Button
                    className={classes.button}
                    onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Legal%20Docs%2FLegal%20Docs%20for%20Lead%20From%20Purpose.com%2FFFPO%20--%20Subscriber%20T%26C.%209.30.2022.pdf?alt=media&token=4adeb09c-744b-4a37-831d-767a07c2d8fe', "_blank")}
                  >TERMS AND CONDITIONS</Button><br /><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggleTerms('Terms and Conditions')}
                          checked={this.state.termGroups.indexOf('Terms and Conditions') !== -1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label='I agree to the terms.'
                    />
                  </div>
                  <br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <Button
                      className={classes.button}
                      onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Legal%20Docs%2FLegal%20Docs%20for%20Lead%20From%20Purpose.com%2FFFPO%20--%20Privacy%20Policy.%209.30.22.pdf?alt=media&token=f74d78ce-1980-46d5-a651-3400f0feba5d', "_blank")}
                    >PRIVACY POLICY</Button><br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggleTerms('Privacy Policy')}
                          checked={this.state.termGroups.indexOf('Privacy Policy') !== -1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label='I agree to the terms.'
                    />
                  </div>
                  {this.state.typeValue === "Certified PL Consultant" ?
                    <div>
                      <Button
                        className={classes.button}
                        onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Legal%20Docs%2FLegal%20Docs%20for%20Lead%20From%20Purpose.com%2FFFPO%20Consulting%20Partnership%20Agreement_Revised%20September%2030%202022.pdf?alt=media&token=a6d4d73d-bbec-4d11-9fd9-a5887a8a405e', "_blank")}
                      > CERTIFIED PL CONSULTANT AGREEMENT</Button><br /><br />
                      <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={() => this.handleToggleTerms('Certified PL Consultant Terms')}
                              checked={this.state.termGroups.indexOf('Certified PL Consultant Terms') !== -1 ? true : false}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{ label: classes.bodyText }}
                          label='I have read, understand, and expressly agree to the terms and conditions of the PL Consulting Partner Agreement linked here by checking this box.'
                        />
                      </div>
                      <br />
                    </div> : null
                  }
                  {/* {this.state.groups.indexOf('Professional Partner') !== -1 ?
                    <div>
                      <Button
                        color="primary"
                        onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Legal%20Docs%2FFFPO%20Professional%20Partnership%20Agreement_v4_July%2014%202021.pdf?alt=media&token=3d98881c-1386-4a22-8d0e-eef2125493a6', "_blank")}
                      >PROFESSIONAL PARTNER AGREEMENT</Button><br /><br />
                      <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={() => this.handleToggleTerms('Professional Partner Terms')}
                              checked={this.state.termGroups.indexOf('Professional Partner Terms') !== -1 ? true : false}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{ label: classes.bodyText }}
                          label='I have read, understand, and expressly agree to the terms and conditions of the Professional Partner Agreement linked here by checking this box.'
                        />
                      </div>
                      <br />

                    </div> : null */}
                  }
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          {
            this.state.agreeToTermsError ? (
              <p className={classes.errorText}>Please agree to the terms</p>
            ) : null
          }
          <Button
            type="submit"
            className={classes.button}
            style={{
              marginLeft: "10px",
              marginBottom: "10px"
            }}
          >
            Save Changes
          </Button>
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  console.log("checking state: ", state.profile.user)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
  }
}


export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);
