import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from "firebase/fbConfig.js";
import { Chart } from "react-google-charts";
import moment from "moment";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
// import DocViewer from "react-doc-viewer";
// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Assess from "assets/img/assess.png";
import Connect from "assets/img/connect.png";
import Grow from "assets/img/grow.png";
import Step1 from "assets/img/step1.png";
import Step2 from "assets/img/step2.png";
import Step3 from "assets/img/step3.png";
import Step4 from "assets/img/step4.png";
import Step5 from "assets/img/step5.png";
import Strategy from "assets/img/strategy.png";
import Playbook from "assets/img/playbook.png";
import DashboardIMG from "assets/img/dashboard.png";
import CheckMark from "assets/img/check.png";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/pathStyle.jsx";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx";
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx";

// Assets
import DefaultProfile from "assets/img/default-avatar.png";

// Firebase Redux Integration
import {
  tableToFirestoreObjects,
  updateFirestoreWithOneObject,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from "store/actions/firestoreActions";
import {
  startSetActionsList,
  startToggleCompleted,
  setUserScore,
} from "store/actions/actionsActions";
import { setFirstAssessment } from "store/actions/assessmentActions";
import { startSetUsersList } from "store/actions/adminActions";
import { startSetPracticesList } from "store/actions/practicesActions";

import pathImg from "assets/img/path/path-image1.png";
import maturityLevel from "assets/img/path/MaturityLevel.png";

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class Principles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updatePhotoModal: false,
      learnDashboardModal: false,
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  loadData = () => {
    let practicesData = this.props.practices.dataRows.map((prop, key) => {
      let colObj = { ...prop };
      const volUID = colObj.volUID;
      const username = colObj.username;
      // Load Data for Summary Cards and add buttons
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        order: colObj.order,
        actions: (
          <Button
            color="primary"
            fullWidth
            onClick={() => this.handleClickOpen("assignActionModal", volUID)}
          >
            ASSIGN ACTION
          </Button>
        ),
      };
    });

    this.setState({
      practicesData,
      dataLoaded: true,
    });
  };

  // Load data for Table
  // if there are no actions make a default one

  render() {
    const { AuthBool, classes, user, practices, assessments } = this.props;
    //console.log(this.state.practicesData)
    let profilePhoto = DefaultProfile;
    //     const playbook ="https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Tools%2FGomez%20Foods%20Dash%20Board%20_Sep%2012%202019.pdf?alt=media&token=daa9d20b-df5c-46f2-9892-793a7170487b";
    // const type = "pdf";

    if (AuthBool) {
      // fixes freeze on logout
      if (user.dataLoaded) {
        user.profileURL
          ? (profilePhoto = user.profileURL)
          : // add link to profile photo to firestore
          (profilePhoto = !!this.props.authUser.photoURL
            ? this.props.authUser.photoURL
            : DefaultProfile);
        // add profile photo to firebase storage
        // update redux with link
      }
    }

    let lastAssessmentPurposePercent;
    //console.log(user)
    //console.log(assessments.dataLoaded)
    if (practices.dataLoaded && !this.state.dataLoaded) {
      //console.log("load data")
      this.loadData();
      // console.log(user)
      // console.log(user.levelUpdatedAt.toDate())
      // console.log(moment(user.levelUpdatedAt.toDate()).format('MM-DD-YYYY'))
    }

    if (user.termGroups) {
      console.log(user.termGroups.length);

      if (user.termGroups.length < 2) {
        return <Redirect to="/profile" />;
      }
    }

    //console.log(assessments.assessments.length)

    let chartData = [
      ["Date", "Actions", "Strategy", "Stakeholders", "Purpose"],
    ];

    //console.log(assessments.assessments.length)
    for (var i = assessments.assessments.length - 1; i > -1; i--) {
      //console.log(assessments.assessments[i])
      let assessmentData = [
        moment(Date(assessments.assessments[i].updatedAt)).format("MM-DD-YYYY"),
        assessments.assessments[i].aaPercent * 100,
        assessments.assessments[i].saPercent * 100,
        assessments.assessments[i].sePercent * 100,
        assessments.assessments[i].pePercent * 100,
      ];

      chartData.push(assessmentData);
    }

    return (
      <div>
        <div className={classes.tealBar}>
          {/* this.state.dataLoaded ?
                  this.props.assessments.assessments.map(assessment => {
                    return <p>{assessment.question1}</p>
                  }) :
                  <p>Loading...</p>
                */}
          {/*this.props.user.saPercent ? <p className={classes.body}>{this.props.user.saPercent}</p> : <p className={classes.body}>Click UPDATE to add company name</p>*/}
        </div>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} >
            {this.state.dataLoaded ? (
              <div>
                <GridContainer className={classes.tealBar} style={{ marginTop: "20px" }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <h2 className={classes.title}>
                      THE PATH TO PURPOSEFUL LEADERSHIP</h2>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridContainer>
                    <GridItem xs={12}>
                      <h3 className={classes.subTitle}>
                        Welcome to the Path to Purposeful Leadership (PL)
                      </h3>
                      <Card style={{ padding: "10px" }}>
                        <div style={{ fontSize: "18px" }}>
                          <p>
                            We are very excited that you have
                            become a member and have joined our community of
                            leaders who are committed to leading their
                            organizations from Purpose. As a member, you will have
                            full access to all member service offerings available
                            on this platform.
                          </p>
                          <p>
                            With that completed, you are ready to start your journey to master Purposeful Leadership. You are now
                            at <strong>Home</strong>, your homepage, which will display your assessment results (once you have taken the
                            assessment) and other pertinent information about your organization’s progress on this journey. To find
                            out more about how your Homepage works, you can click on {" "}<Link
                              to="/home"
                              style={{ color: "#4ec7bc", fontWeight: "900" }}
                            >
                              LEARN ABOUT HOMEPAGE
                            </Link>{" "} in the upper right-
                            hand corner of this page.
                          </p>
                        </div>
                      </Card>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} >
                      <h3 className={classes.subTitle}>
                        Our Platform Design
                      </h3>
                      <Card style={{ padding: "10px" }}>
                        <div style={{ fontSize: "18px" }}>
                          <p>
                            Our platform has been designed to support you and your PL Consultant with the tools and resources you
                            will need to master Purposeful Leadership.
                          </p>

                          <p style={{ marginTop: "60px" }}>
                            Early on, you will want to take the <strong>Purposeful Leadership Assessment</strong>,
                            by clicking on the first green bar
                            labelled: START ASSESSMENT located on your homepage under your assessment graphic.
                          </p>

                          <h5 style={{ marginTop: "20px" }}>
                            All the resources you will need to complete the Introductory Program can be found
                            under <strong>Learn,</strong> which includes:
                          </h5>

                          <p style={{ marginTop: "10px", whiteSpace: "pre-wrap", lineHeight: "1.5rem" }}>
                            <strong>• Core Beliefs and Principles </strong> -two downloadable documents that summarize
                            the beliefs and principles of Purposeful Leadership {"\n"}
                            <strong>• Practices</strong> – a library of downloadable practices {"\n"}
                            <strong>• Webinars</strong> - a library of recorded webinars, some of
                            which are required viewing in our introductory program{"\n"}
                            <strong>• Glossary</strong> – definitions of commonly used words or phrases used in Purposeful Leadership{"\n"}
                            <strong>• Coaching Programs</strong> – descriptions of our current program offerings
                          </p>

                          <h5 style={{ marginTop: "20px" }}>
                            Under <strong>Grow</strong>, you will find additional resources to help you keep abreast with the current thinking in
                            certain areas touching on Purposeful Behavior, which includes:
                          </h5>

                          <p style={{ marginTop: "10px", whiteSpace: "pre-wrap", lineHeight: "1.5rem" }}>
                            <strong>• Articles Library</strong> – published articles addressing various topics concerning Purpose in business{"\n"}
                            <strong>• Research Studies Library</strong> – studies that validate the benefits of purposeful behavior{"\n"}
                            <strong>• Books Library</strong> – Titles of books that address Purpose{"\n"}
                          </p>

                          <h5 style={{ marginTop: "20px" }}>
                            Under <strong>Tools</strong> you will find all of our online tools that you can use on your journey in Mastering
                            Purposeful Leadership. They include:
                          </h5>

                          <p style={{ marginTop: "10px", whiteSpace: "pre-wrap", lineHeight: "1.5rem" }}>
                            <strong>• Playbook</strong> – a graphical representation of your business model{"\n"}
                            <strong>• SES worksheets-</strong> – your key stakeholder engagement strategy worksheets{"\n"}
                            <strong>• Dashboard</strong> – your metric (Key Performance Indicators – KPIs) tracking system{"\n"}
                            <strong>• Value Behaviors</strong> – worksheets for documenting behaviors reflecting each of your organization’s
                            values at the departmental level{"\n"}
                            <strong>• Initiative Worksheet</strong> – worksheets for documenting and managing actions required to complete
                            an initiative
                          </p>
                        </div>
                      </Card>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12}>
                      <h3 className={classes.subTitle}>
                        Our Methodology
                      </h3>
                      <Card style={{ padding: "10px" }}>
                        <div style={{ fontSize: "18px" }}>
                          <h5>
                            Purposeful Leadership embodies four interrelated areas or stages:
                          </h5>

                          <p style={{ margin: "10px 0px 0px 50px", whiteSpace: "pre-wrap", lineHeight: "2.0rem" }}>
                            1. Purpose Embraced{"\n"}
                            2. Stakeholders Engaged{"\n"}
                            3. Strategy Aligned{"\n"}
                            4. Actions Aligned
                          </p>

                          <p style={{ marginTop: "20px" }}>
                            In each of these areas, you and your organization will take on the principles and practices needed to
                            achieve mastery as shown in our Maturity Model graphically represented below.
                          </p>
                        </div>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </GridContainer>
                <GridContainer>
                  <GridContainer>
                    <GridItem xs={12}>
                      <h3 className={classes.subTitle}>
                        PURPOSEFUL LEADERSHIP MATURITY MODEL
                      </h3>
                      <Card>
                        <img
                          src={maturityLevel}
                          className={classes.img}
                          alt="User Photo"
                        />
                      </Card>
                    </GridItem>

                    <GridItem xs={12}>
                      <h3 className={classes.subTitle}>
                        Your PL Consultant
                      </h3>
                      <Card style={{ padding: "10px" }}>
                        <div style={{ fontSize: "18px" }}>
                          <p>
                            Accompanying your journey will be your Purposeful Leadership (PL) Consultant who will coach you at
                            every stage of your training and development. If you do not have a PL Consultant, please <strong>Contact Us </strong>
                            immediately so we can help introduce you to one.
                          </p>
                        </div>
                      </Card>
                    </GridItem>

                    <GridItem xs={12}>
                      <h3
                        className={classes.subTitle}>Start with the Assessment
                      </h3>
                      <Card style={{ padding: "10px" }}>
                        <div style={{ fontSize: "18px" }}>
                          <p>
                            Your first action will be to take our Assessment. This assessment will give you a baseline of where your
                            organization currently stands in mastering the principles and practices of Purposeful Leadership. If you
                            want to first familiarize yourself with Purposeful Leadership, we suggest you watch our <strong>Introduction to
                              Purposeful Leadership </strong> Webinar. In addition, you can download our <strong>Principles </strong> documents, which
                            summarize the principles of Purposeful Leadership.
                          </p>

                          <p style={{ marginTop: "20px" }}>
                            To take the Assessment, go to <strong>Home </strong> and click on TAKE ASSESSMENT. We recommend that you read
                            about the tool before starting by clicking on LEARN ABOUT ASSESSMENT at the top of the landing page.
                          </p>

                          <p style={{ marginTop: "20px" }}>
                            The assessment contains 30 questions and will take approximately 40 minutes to complete. If you don’t
                            complete the assessment at one sitting, you can come back to it as many times as needed.
                          </p>

                          <h6 style={{ fontSize: "18px" }} className={classes.subTitle}>Check Out Your Assessment Results</h6>
                          <p style={{ marginTop: "20px" }}>
                            Once you complete the assessment, you will be able to view your results on your Home Page, which will
                            display and track your assessment results. Every time you log in, you will be taken back to your
                            Homepage, which will continue to monitor your progress in mastering Purposeful Leadership. To learn
                            more about the Home page, click on LEARN ABOUT HOMEPAGE.
                            After you have completed the assessment, you will want to review or edit your answers as you progress
                            through the program by clicking on the second green bar SEE ASSESSMENT DETAILS.
                          </p>
                        </div>
                      </Card>
                    </GridItem>

                    <GridItem xs={12}>
                      <h3 className={classes.subTitle}>The Introductory Program</h3>
                      <Card style={{ padding: "10px" }}>
                        <div style={{ fontSize: "18px" }}>
                          <p>
                            The Introductory Program is designed to take you through each of the four areas of Purposeful
                            Leadership so that you can begin leading from Purpose powerfully in your organization. Members can
                            go through the program at their own pace. Generally, it will take from 3-9 months to complete based on
                            the complexity of your organization and the intensity of training undertaken. Your PL Consultant will
                            tailor your program based on the specific needs of your organization.
                          </p>
                        </div>
                      </Card>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12}>
                      <h3 className={classes.subTitle}>Introductory Program Deliverables</h3>
                      <Card style={{ padding: "10px" }}>
                        <div style={{ fontSize: "18px" }}>
                          <p style={{ marginTop: "10px", whiteSpace: "pre-wrap", lineHeight: "1.5rem" }}>
                            At the completion of the Introductory Program you will have:{"\n"}
                          </p>
                          <p style={{ marginTop: "20px", whiteSpace: "pre-wrap", lineHeight: "1.5rem" }}>
                            1) {" "}A completed one-page <strong>Playbook </strong> containing your:{"\n"}
                            • Purpose Declaration and Tag Line{"\n"}
                            • Vision of your Purpose fulfilled{"\n"}
                            • Purpose Measures{"\n"}
                            • Key Stakeholders{"\n"}
                            • An Accountability Structure{"\n"}
                            • Core and Goal Values{"\n"}
                            • Health Measures{"\n"}
                            • Nine important elements of your Business Model
                          </p>
                        </div>
                        <a
                          target="_blank"
                          href="https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Introduction%20to%20Purposeful%20Leadership%2FPlaybook.pdf?alt=media&token=400b74df-22fb-4c39-8ce9-b687f5113c28"
                        >
                          <Button className={classes.button}>Playbook</Button>
                        </a>

                        <div style={{ fontSize: "18px" }}>
                          <p style={{ marginTop: "20px", whiteSpace: "pre-wrap", lineHeight: "1.5rem" }}>
                            2) {" "}Completed <strong>Engagement Strategy Worksheet </strong> for each of your Key Stakeholders containing:{"\n"}
                            • Who will be accountable for managing engagement{"\n"}
                            • Measures to confirm stakeholder is engaged{"\n"}
                            • What engagement looks like{"\n"}
                            • Your promises to empower stakeholder’s engagement{"\n"}
                            • What actions to take to activate engagement strategy{"\n"}
                            • Communication that will inspire stakeholder{"\n"}
                          </p>
                        </div>
                        <a
                          target="_blank"
                          href="https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Introduction%20to%20Purposeful%20Leadership%2FSES%20Worksheet.pdf?alt=media&token=9f043818-22dd-46b0-b359-bc75f27950e7"
                        >
                          <Button className={classes.button}>Engagement Strategy Worksheet</Button>
                        </a>

                        <div style={{ fontSize: "18px" }}>
                          <p style={{ marginTop: "20px", whiteSpace: "pre-wrap", lineHeight: "1.5rem" }}>
                            3) {" "}A completed one-page <strong>Dashboard</strong> that tracks your progress in executing your Playbook that contains:{"\n"}
                            • Purpose metrics{"\n"}
                            • Stakeholder Engagement metrics{"\n"}
                            • Organizational Health metrics{"\n"}
                            • Value Proposition metrics (optional){"\n"}
                            • Annual Initiative metrics{"\n"}
                          </p>
                        </div>
                        <a
                          target="_blank"
                          href="https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Introduction%20to%20Purposeful%20Leadership%2FDashboard.pdf?alt=media&token=5b46194d-52cf-4a6e-863f-0bc4149e9a0a"
                        >
                          <Button className={classes.button}>Dashboard</Button>
                        </a>

                        <div style={{ fontSize: "18px" }}>
                          <p style={{ marginTop: "20px", whiteSpace: "pre-wrap", lineHeight: "1.5rem" }}>
                            4) {" "}Your assessment score in each of the four areas of Purposeful leadership showing the level of
                            mastery you have attained in the program so far.
                          </p>
                        </div>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </GridContainer>

              </div>
            ) : (
              <div className={classes.easeInOut}>
                <Hidden smDown implementation="css">
                  <div
                    className={classes.preloaderDesktop}
                    style={{
                      background: "white",
                      position: "absolute",
                      width: "110%",
                      margin: "-25% 0% 0% -8%",
                      height: "180%",
                      zIndex: "99999",
                    }}
                  >
                    <img
                      style={{
                        margin: "30% 0% 0% 33%",
                        position: "relative",
                        width: "25%",
                      }}
                      src={Logo}
                      alt="..."
                    />
                    <img
                      style={{
                        margin: "2% 0% 0% 38%",
                        position: "relative",
                        width: "20%",
                      }}
                      src={Loader}
                      alt="..."
                    />
                  </div>
                </Hidden>
                <Hidden mdUp implementation="css">
                  <div
                    className={classes.preloaderMobile}
                    style={{
                      background: "white",
                      position: "absolute",
                      width: "110%",
                      margin: "-30% 0% 0% -10%",
                      height: "110%",
                      zIndex: "9999",
                    }}
                  >
                    <img
                      style={{
                        margin: "30% 0% 0% 20%",
                        position: "relative",
                        width: "50%",
                      }}
                      src={Logo}
                      alt="..."
                    />
                    <img
                      style={{
                        margin: "15% 0% 0% 38%",
                        position: "relative",
                        width: "20%",
                      }}
                      src={Loader}
                      alt="..."
                    />
                  </div>
                </Hidden>
              </div>
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    practices: state.practices.practices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSetPracticesList: dispatch(startSetPracticesList()),
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Principles);
