import React from "react";
import PropTypes from "prop-types";
import { db, firebase } from "firebase/fbConfig.js"

import axios from 'axios'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from '@material-ui/icons/Check';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/profileFormStyle.jsx";

import { updateAssessment } from "store/actions/assessmentActions.jsx"

// Assets
import precincts from "variables/precincts.jsx"
import Question from "./Questions/Question";

class Form extends React.Component {
  state = {
    showIntro: true,
    showq1: false,
    showq2: false,
    showq3: false,
    showq4: false,
    showq5: false,
    showq6: false,
    showq7: false,
    showq8: false,
    showq9: false,
    showq10: false,
    showq11: false,
    showq12: false,
    showq13: false,
    showq14: false,
    showq15: false,
    showq16: false,
    showq17: false,
    showq18: false,
    showq19: false,
    showq20: false,
    showq21: false,
    showq22: false,
    showq23: false,
    showq24: false,
    showq25: false,
    showq26: false,
    showq27: false,
    showq28: false,
    showq29: false,
    showq30: false,
    question1: null,
    question2: null,
    question3: null,
    question4: null,
    question5: null,
    question6: null,
    question7: null,
    question8: null,
    question9: null,
    question10: null,
    question11: null,
    question12: null,
    question13: null,
    question14: null,
    question15: null,
    question16: null,
    question17: null,
    question18: null,
    question19: null,
    question20: null,
    question21: null,
    question22: null,
    question23: null,
    question24: null,
    question25: null,
    question26: null,
    question27: null,
    question28: null,
    question29: null,
    question30: null,
    showSubmit: false,
  }

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      ...this.state,
    };
  }

  //Phone number code modified from
  // https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript 
  isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };

  enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
      event.preventDefault();
    }
  };

  formatToPhone = (event) => {
    if (this.isModifierKey(event)) {
      return;
    }

    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only    
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    let targetValue
    if (input.length > 6) { targetValue = `(${zip}) ${middle} - ${last}`; }
    else if (input.length > 3) { targetValue = `(${zip}) ${middle}`; }
    else if (input.length > 0) { targetValue = `(${zip}`; }
    target.value = targetValue
    this.setState({
      phone: targetValue
    })
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  handleChange = (e) => {
    let value = e.target.value
    if (e.target.id === "firstName" || e.target.id === "lastName") {
      value = value.charAt(0).toUpperCase() + value.slice(1)
    }
    console.log(value)
    this.setState({
      [e.target.id]: value
    })
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.question1 && this.state.question2 && this.state.question3 && this.state.question4 && this.state.question5 && this.state.question6 && this.state.question7 && this.state.question8 && this.state.question9 && this.state.question10 && this.state.question11 && this.state.question12 && this.state.question13 && this.state.question14 && this.state.question15 && this.state.question16 && this.state.question17 && this.state.question18 && this.state.question18 && this.state.question19 && this.state.question20 && this.state.question21 && this.state.question22 && this.state.question23 && this.state.question24 && this.state.question25 && this.state.question26 && this.state.question27 && this.state.question28 && this.state.question29 && this.state.question30) {
      updateAssessment(this.state, this.props.uid)
    } else {
      this.setState({
        unfinishedError: true
      })
      console.log(this.state)
      console.log(this.state.question1 && this.state.question2 && this.state.question3 && this.state.question4 && this.state.question5 && this.state.question6 && this.state.question7 && this.state.question8 && this.state.question9 && this.state.question10 && this.state.question11 && this.state.question12 && this.state.question13 && this.state.question14 && this.state.question15 && this.state.question16 && this.state.question17 && this.state.question18 && this.state.question18 && this.state.question19 && this.state.question20 && this.state.question21 && this.state.question22 && this.state.question23 && this.state.question24 && this.state.question25 && this.state.question26 && this.state.question27 && this.state.question28 && this.state.question29 && this.state.question30)
      console.log("unfinisehed")
      if (this.state.question1 == null) {
        this.setState({
          showq1: true,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question2 == null) {
        this.setState({
          showq1: false,
          showq2: true,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question3 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: true,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question4 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: true,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question5 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: true,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question6 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: true,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question7 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: true,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question8 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: true,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question9 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: true,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question10 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: true,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question11 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: true,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question12 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: true,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question13 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: true,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question14 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: true,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question15 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: true,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question16 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: true,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question17 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: true,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question18 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: true,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question19 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: true,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question20 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: true,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question21 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: true,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question22 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: true,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question23 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: true,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question24 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: true,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question25 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: true,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question26 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: true,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question27 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: true,
          showq28: false,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question28 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: true,
          showq29: false,
          showq30: false,
        })
      } else if (this.state.question29 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: true,
          showq30: false,
        })
      } else if (this.state.question30 == null) {
        this.setState({
          showq1: false,
          showq2: false,
          showq3: false,
          showq4: false,
          showq5: false,
          showq6: false,
          showq7: false,
          showq8: false,
          showq9: false,
          showq10: false,
          showq11: false,
          showq12: false,
          showq13: false,
          showq14: false,
          showq15: false,
          showq16: false,
          showq17: false,
          showq18: false,
          showq19: false,
          showq20: false,
          showq21: false,
          showq22: false,
          showq23: false,
          showq24: false,
          showq25: false,
          showq26: false,
          showq27: false,
          showq28: false,
          showq29: false,
          showq30: true,
        })
      } else {
        updateAssessment(this.state, this.props.uid)
      }
    }
  }

  handleToggle(value, question) {
    const { question1 } = this.state;
    console.log(this.state)
    if (this.state[question] === value) {
      this.setState({
        [question]: null,
      });
    } else {
      this.setState({
        [question]: value,
      });
    }
  }

  loadQuestionsData = (id) => {
    let data = db.collection("questions").orderBy("order")
      .get().then((snapshot) => {
        //console.log(snapshot)
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          var docObj = {
            id: doc.id,
            ...docData
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        // data
        this.setState({
          questionsData: data,
          questionsDataLoaded: true,
        })
      })
  }



  render() {
    const { classes } = this.props;
    const selectedValue = this.state.selectedValue;
    const setSelectedValue = this.state.setSelectedValue;

    if (!this.state.questionsData) {
      this.loadQuestionsData()
    }

    console.log(this.state)
    return (
      <div className={classes.formContainer}>
        <form onSubmit={this.handleSubmit}
        >
          <GridContainer justify="center">
            <GridItem xs={11}>
              {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
              {/*<Question order="1" content={this.state.question1content} questionState={this.state.question1} field="question1" answer1={this.state.question1answer1} answer2={this.state.question1answer2} answer3={this.state.question1answer3} answer4={this.state.question1answer4} answer5={this.state.question1answer5}/>*/}
              {this.state.showIntro &&
                <center>
                  <h3 className={classes.subTitle}>
                    Welcome to the Lead From Purpose Organizational Self-Assessment
                  </h3>
                  <p style={{ fontSize: "20px", color: "#3a3934" }} className={classes.bodyText}>
                    You've taken the first step toward building a more purposeful organization.
                  </p>
                  <p className={classes.bodyText} style={{ fontSize: "16px", color: "#3a3934", marginTop:"5px" }}>
                    This exercise is designed to take you through a series of questions to help you learn the key practices
                    to building a purposeful organization that engages and compels your workers, your community, and the world.
                    Please choose the best answer that describes the current state of your organization as you perceive it
                    and not what you may think is the "right" answer to each question. This will help your PL Consultant customize your Purposeful Leadership journey and help you
                    access the resources with the most leverage to transform and optimize your organization around Purpose. This assessment will take 30-60 minutes to complete.
                  </p>

                  <Button
                    className={classes.button}
                    onClick={() => this.setState({
                      showIntro: false,
                      showq1: true,
                      showSubmit: true,
                    })}
                  >
                    Start
                  </Button>
                </center>
              }
              {this.state.showq1 &&
                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 1</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[0].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 1)}
                          checked={this.state.question1 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[0].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 1)}
                          checked={this.state.question1 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[0].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 1)}
                          checked={this.state.question1 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[0].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 1)}
                          checked={this.state.question1 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[0].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 1)}
                          checked={this.state.question1 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[0].answer5}
                    />
                  </div><br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showIntro: true,
                        showq1: false,
                        showSubmit: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq1: false,
                        showq2: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq2 &&
                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 2</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[1].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 2)}
                          checked={this.state.question2 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[1].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 2)}
                          checked={this.state.question2 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[1].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 2)}
                          checked={this.state.question2 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[1].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 2)}
                          checked={this.state.question2 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[1].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 2)}
                          checked={this.state.question2 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[1].answer5}
                    />
                  </div><br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq1: true,
                        showq2: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq2: false,
                        showq3: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq3 &&
                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 3</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[2].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 3)}
                          checked={this.state.question3 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[2].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 3)}
                          checked={this.state.question3 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[2].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 3)}
                          checked={this.state.question3 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[2].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 3)}
                          checked={this.state.question3 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[2].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 3)}
                          checked={this.state.question3 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[2].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq2: true,
                        showq3: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq3: false,
                        showq4: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq4 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 4</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[3].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 4)}
                          checked={this.state.question4 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[3].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 4)}
                          checked={this.state.question4 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[3].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 4)}
                          checked={this.state.question4 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[3].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 4)}
                          checked={this.state.question4 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[3].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 4)}
                          checked={this.state.question4 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[3].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq3: true,
                        showq4: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq4: false,
                        showq5: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq5 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 5</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[4].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 5)}
                          checked={this.state.question5 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[4].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 5)}
                          checked={this.state.question5 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[4].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 5)}
                          checked={this.state.question5 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[4].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 5)}
                          checked={this.state.question5 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[4].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 5)}
                          checked={this.state.question5 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[4].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq4: true,
                        showq5: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq5: false,
                        showq6: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq6 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 6</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[5].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 6)}
                          checked={this.state.question6 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[5].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 6)}
                          checked={this.state.question6 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[5].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 6)}
                          checked={this.state.question6 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[5].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 6)}
                          checked={this.state.question6 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[5].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 6)}
                          checked={this.state.question6 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[5].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq5: true,
                        showq6: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq6: false,
                        showq7: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq7 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 7</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[6].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 7)}
                          checked={this.state.question7 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[6].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 7)}
                          checked={this.state.question7 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[6].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 7)}
                          checked={this.state.question7 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[6].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 7)}
                          checked={this.state.question7 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[6].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 7)}
                          checked={this.state.question7 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[6].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq6: true,
                        showq7: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq7: false,
                        showq8: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq8 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 8</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[7].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 8)}
                          checked={this.state.question8 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[7].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 8)}
                          checked={this.state.question8 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[7].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 8)}
                          checked={this.state.question8 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[7].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 8)}
                          checked={this.state.question8 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[7].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 8)}
                          checked={this.state.question8 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[7].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq7: true,
                        showq8: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq8: false,
                        showq9: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq9 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 9</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[8].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 9)}
                          checked={this.state.question9 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[8].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 9)}
                          checked={this.state.question9 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[8].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 9)}
                          checked={this.state.question9 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[8].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 9)}
                          checked={this.state.question9 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[8].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 9)}
                          checked={this.state.question9 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[8].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq8: true,
                        showq9: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq9: false,
                        showq10: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq10 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 10</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[9].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 10)}
                          checked={this.state.question10 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[9].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 10)}
                          checked={this.state.question10 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[9].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 10)}
                          checked={this.state.question10 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[9].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 10)}
                          checked={this.state.question10 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[9].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 10)}
                          checked={this.state.question10 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[9].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq9: true,
                        showq10: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq10: false,
                        showq11: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq11 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 11</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[10].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 11)}
                          checked={this.state.question11 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[10].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 11)}
                          checked={this.state.question11 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[10].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 11)}
                          checked={this.state.question11 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[10].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 11)}
                          checked={this.state.question11 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[10].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 11)}
                          checked={this.state.question11 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[10].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq10: true,
                        showq11: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq11: false,
                        showq12: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq12 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 12</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[11].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 12)}
                          checked={this.state.question12 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[11].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 12)}
                          checked={this.state.question12 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[11].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 12)}
                          checked={this.state.question12 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[11].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 12)}
                          checked={this.state.question12 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[11].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 12)}
                          checked={this.state.question12 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[11].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq11: true,
                        showq12: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq12: false,
                        showq13: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq13 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 13</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[12].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 13)}
                          checked={this.state.question13 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[12].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 13)}
                          checked={this.state.question13 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[12].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 13)}
                          checked={this.state.question13 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[12].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 13)}
                          checked={this.state.question13 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[12].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 13)}
                          checked={this.state.question13 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[12].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq12: true,
                        showq13: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq13: false,
                        showq14: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq14 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 14</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[13].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 14)}
                          checked={this.state.question14 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[13].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 14)}
                          checked={this.state.question14 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[13].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 14)}
                          checked={this.state.question14 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[13].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 14)}
                          checked={this.state.question14 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[13].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 14)}
                          checked={this.state.question14 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[13].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq13: true,
                        showq14: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq14: false,
                        showq15: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq15 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 15</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[14].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 15)}
                          checked={this.state.question15 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[14].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 15)}
                          checked={this.state.question15 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[14].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 15)}
                          checked={this.state.question15 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[14].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 15)}
                          checked={this.state.question15 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[14].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 15)}
                          checked={this.state.question15 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[14].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq14: true,
                        showq15: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq15: false,
                        showq16: true
                      })}
                    >
                      Next
                    </Button>

                  </div>
                </div>
              }
              {this.state.showq16 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 16</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[15].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 16)}
                          checked={this.state.question16 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[15].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 16)}
                          checked={this.state.question16 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[15].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 16)}
                          checked={this.state.question16 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[15].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 16)}
                          checked={this.state.question16 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[15].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 16)}
                          checked={this.state.question16 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[15].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq15: true,
                        showq16: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq16: false,
                        showq17: true
                      })}
                    >
                      Next
                    </Button>

                  </div>
                </div>
              }
              {this.state.showq17 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 17</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[16].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 17)}
                          checked={this.state.question17 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[16].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 17)}
                          checked={this.state.question17 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[16].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 17)}
                          checked={this.state.question17 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[16].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 17)}
                          checked={this.state.question17 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[16].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 17)}
                          checked={this.state.question17 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[16].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq16: true,
                        showq17: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq17: false,
                        showq18: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq18 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 18</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[17].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 18)}
                          checked={this.state.question18 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[17].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 18)}
                          checked={this.state.question18 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[17].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 18)}
                          checked={this.state.question18 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[17].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 18)}
                          checked={this.state.question18 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[17].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 18)}
                          checked={this.state.question18 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[17].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq17: true,
                        showq18: false
                      })}
                    >
                      Previous
                    </Button>


                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq18: false,
                        showq19: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq19 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 19</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[18].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 19)}
                          checked={this.state.question19 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[18].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 19)}
                          checked={this.state.question19 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[18].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 19)}
                          checked={this.state.question19 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[18].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 19)}
                          checked={this.state.question19 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[18].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 19)}
                          checked={this.state.question19 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[18].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq18: true,
                        showq19: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq19: false,
                        showq20: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq20 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 20</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[19].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 20)}
                          checked={this.state.question20 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[19].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 20)}
                          checked={this.state.question20 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[19].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 20)}
                          checked={this.state.question20 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[19].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 20)}
                          checked={this.state.question20 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[19].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 20)}
                          checked={this.state.question20 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[19].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq19: true,
                        showq20: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq20: false,
                        showq21: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq21 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 21</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[20].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 21)}
                          checked={this.state.question21 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[20].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 21)}
                          checked={this.state.question21 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[20].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 21)}
                          checked={this.state.question21 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[20].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 21)}
                          checked={this.state.question21 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[20].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 21)}
                          checked={this.state.question21 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[20].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq20: true,
                        showq21: false
                      })}
                    >
                      Previous
                    </Button>


                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq21: false,
                        showq22: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq22 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 22</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[21].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 22)}
                          checked={this.state.question22 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[21].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 22)}
                          checked={this.state.question22 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[21].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 22)}
                          checked={this.state.question22 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[21].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 22)}
                          checked={this.state.question22 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[21].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 22)}
                          checked={this.state.question22 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[21].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq21: true,
                        showq22: false
                      })}
                    >
                      Previous
                    </Button>


                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq22: false,
                        showq23: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq23 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 23</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[22].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 23)}
                          checked={this.state.question23 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[22].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 23)}
                          checked={this.state.question23 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[22].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 23)}
                          checked={this.state.question23 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[22].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 23)}
                          checked={this.state.question23 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[22].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 23)}
                          checked={this.state.question23 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[22].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq22: true,
                        showq23: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq23: false,
                        showq24: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq24 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 24</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[23].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 24)}
                          checked={this.state.question24 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[23].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 24)}
                          checked={this.state.question24 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[23].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 24)}
                          checked={this.state.question24 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[23].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 24)}
                          checked={this.state.question24 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[23].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 24)}
                          checked={this.state.question24 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[23].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq23: true,
                        showq24: false
                      })}
                    >
                      Previous
                    </Button>


                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq24: false,
                        showq25: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq25 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 25</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[24].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 25)}
                          checked={this.state.question25 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[24].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 25)}
                          checked={this.state.question25 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[24].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 25)}
                          checked={this.state.question25 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[24].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 25)}
                          checked={this.state.question25 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[24].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 25)}
                          checked={this.state.question25 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[24].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq24: true,
                        showq25: false
                      })}
                    >
                      Previous
                    </Button>


                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq25: false,
                        showq26: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq26 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 26</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[25].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 26)}
                          checked={this.state.question26 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[25].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 26)}
                          checked={this.state.question26 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[25].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 26)}
                          checked={this.state.question26 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[25].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 26)}
                          checked={this.state.question26 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[25].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 26)}
                          checked={this.state.question26 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[25].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq25: true,
                        showq26: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq26: false,
                        showq27: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq27 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 27</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[26].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 27)}
                          checked={this.state.question27 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[26].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 27)}
                          checked={this.state.question27 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[26].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 27)}
                          checked={this.state.question27 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[26].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 27)}
                          checked={this.state.question27 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[26].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 27)}
                          checked={this.state.question27 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[26].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq26: true,
                        showq27: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq27: false,
                        showq28: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq28 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 28</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[27].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 28)}
                          checked={this.state.question28 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[27].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 28)}
                          checked={this.state.question28 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[27].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 28)}
                          checked={this.state.question28 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[27].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 28)}
                          checked={this.state.question28 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[27].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 28)}
                          checked={this.state.question28 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[27].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq27: true,
                        showq28: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq28: false,
                        showq29: true
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq29 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 29</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[28].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 29)}
                          checked={this.state.question29 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[28].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 29)}
                          checked={this.state.question29 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[28].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 29)}
                          checked={this.state.question29 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[28].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 29)}
                          checked={this.state.question29 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[28].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 29)}
                          checked={this.state.question29 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[28].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq28: true,
                        showq29: false
                      })}
                    >
                      Previous
                    </Button>


                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq29: false,
                        showq30: true,
                      })}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {this.state.showq30 &&

                <div className={classes.question}>
                  <h5 className={classes.bodyText}>QUESTION 30</h5>
                  <h5 className={classes.bodyText}>{this.state.questionsData[29].content}</h5><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(0, 'question' + 30)}
                          checked={this.state.question30 === 0 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          color="primary"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[29].answer1}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1, 'question' + 30)}
                          checked={this.state.question30 === 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[29].answer2}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(2, 'question' + 30)}
                          checked={this.state.question30 === 2 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[29].answer3}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(3, 'question' + 30)}
                          checked={this.state.question30 === 3 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[29].answer4}
                    />
                  </div><br />
                  <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(4, 'question' + 30)}
                          checked={this.state.question30 === 4 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.bodyText }}
                      label={this.state.questionsData[29].answer5}
                    />
                  </div> <br />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.setState({
                        showq29: true,
                        showq30: false
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      type="submit"
                      className={classes.button}
                    // style={{
                    //   marginLeft: "10px",
                    //   marginBottom: "10px"
                    // }}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              }
            </GridItem>
          </GridContainer>
          {/* {this.state.showSubmit &&
          <Button
            type="submit"
            color="primary"
            style={{
              marginLeft: "10px",
              marginBottom: "10px"
            }}
          >
            Submit
          </Button> } */}
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Form);
