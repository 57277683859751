import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetNewslettersList = () => {
    console.log("fetching newsletters")
  return (dispatch) => {
    db.collection("newsletter").where("show", "==", true)
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            category: docData.category ? docData.category : "N/A",
            topic: docData.topic ? docData.topic : "N/A",
            title: docData.title ? docData.title : "N/A",
            show: docData.show ? docData.show : "N/A",
            date: docData.date ? moment(docData.date.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
            pdf: docData.pdf ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.pdf)}
          >DOWNLOAD</Button>)
           : "N/A",
           }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setNewslettersList(data))
      })
  }
}

export const setNewslettersList = (dataRows) => ({
  type: 'SET_NEWSLETTERS_LIST',
  dataRows
})
