import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetPodcastsList = () => {
  return (dispatch) => {
    db.collection("podcasts").where("show", "==", true).orderBy('order')
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            ...docData,
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            type: docData.type ? docData.type : "N/A",
            length: docData.length ? docData.length : "N/A",
            title: docData.title ? docData.title : "N/A",
           video: docData.video ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.video)}
          >VIEW</Button>)
           : "N/A",
           audio: docData.audio ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.audio)}
          >LISTEN</Button>)
           : "N/A",
           }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setPodcastsList(data))
      })
  }
}

export const setPodcastsList = (dataRows) => ({
  type: 'SET_PODCASTS_LIST',
  dataRows
})

export const setUpcomingCoursesList = (dataRows) => ({
  type: 'SET_UPCOMING_COURSES_LIST',
  dataRows
})
