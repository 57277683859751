/*eslint-disable*/
import {
  container,
  title,
  cardTitle,
  mlAuto,
  primaryColor,
  bodyText
} from "assets/jss/material-dashboard-pro-react.jsx";

import {
  header1,
  header3,
  jetEngineBlack,
  freightlinerGrey,
  greenLight,
  
} from "assets/jss/ltr-styles.jsx"

const styles = {
  container,
  dueDate: {
    color: 'red'
  },
  // coloredShadow,
  title,
  header1,
  bodyText: {
    ...bodyText,
    color: "#333"
  },
  header3,
  // mlAuto,
  cardTitle,
  textPrimary: {
    ...bodyText,
    color: primaryColor
  },
  formHeader: {
    background: jetEngineBlack,
    padding: ".25vh",
    margin: 0,
    // marginBottom: "1vh"
  },
  formContainer: {
    background: "white",
    borderRadius: "5px",
    // position: "absolute"
    // media query for height, if calculated height is > 900px, then add margin from top
  },
  bigMap: {
    height: "55vh",
    maxHeight: "550px",
    width: "100%",
    display: "block",
    marginTop: "2rem",
    paddingBottom: "2rem"
  },
  notification: {
    height: "160px",
    padding: "12px 5px"
  },
  notificationType: {
    marginBottom: "35px",
    color: "#4ec7bc",
    fontWeight: 300,
    fontFamily: 'Oswald',
    fontSize: '23px'
  },
  notificationBody: {
    paddingTop: "0px"
  },
  closingX: {
    alignSelf: 'flex-end',
    paddingTop: '20px',
    '&:hover': {
      color: '#FC6047',
      cursor: 'pointer'
    }
  },
  title: {
    marginTop: "50px",
    marginLeft: "2rem",
    marginRight: "2rem",
    '@media (max-width: 525px)': {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& h2": {
      '@media (min-width: 1000px)': {
        fontSize: "4rem",
        lineHeight: "4rem",
      },
      '@media (min-width: 2000px)': {
        fontSize: "5rem",
        lineHeight: "5rem",
      },

    },
    "& h5": {
      fontFamily: "Noto Serif",
      '@media (min-width: 1000px)': {
        fontSize: "2rem",
        lineHeight: "2.5rem",
      },
      '@media (min-width: 2000px)': {
        fontSize: "2.5rem",
        lineHeight: "3rem",
      },

    },
    "& h4": {
      fontSize: "1rem",
      color: "#39A585",
    },
  },
  contactHeader: {
    "& p": {
      fontSize: "2rem !important",
      fontWeight: "300",
      color: "#3A7AB7",
    },
  },
  cardColor: {
    backgroundColor: "#fafafa"
  },
  cardPrivacy: {
    marginTop: "-3rem",
    paddingRight: "0px"
  },
  container: {
    marginBottom: "-50px",
    marginLeft: "4rem",
    marginRight: "4rem",
    '@media (max-width: 525px)': {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& h2": {
      color: "#3A7AB7"
    },
    "& h4": {
      fontFamily: "Noto Serif",
    }
  },
  navButtonContainer: {
    marginTop: "20px;",
    width: "100%"
  },
  navButton: {
    backgroundColor: "rgba(57, 165, 133, 1)",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%"
  },
  sectionGray: {
    marginTop: "50px"
  },
  sectionWhite: {
    background: "#FFFFFF"
  },
  aboutPrivacyDescription: {
    fontFamily: "Noto Serif",
    lineHeight: "1.5rem",
    '@media (min-width: 1000px)': {
      fontSize: "2rem",
      lineHeight: "2.5rem",
    },
    '@media (min-width: 2000px)': {
      fontSize: "2.5rem",
      lineHeight: "3rem",
    },

  },
  cardTitleAbsolute: {
    ...cardTitle,
    position: "absolute !important",
    bottom: "15px !important",
    left: "15px !important",
    color: "#fff !important",
    fontSize: "1.125rem !important",
    textShadow: "0 2px 5px rgba(33, 33, 33, 0.5) !important"
  },
  cardTitleWhite: {
    "&, & a": {
      ...title,
      marginTop: ".625rem",
      marginBottom: "0",
      minHeight: "auto",
      color: "#fff !important"
    }
  },
  cardCategory: {
    marginTop: "10px",
    "& svg": {
      position: "relative",
      top: "8px"
    }
  },
  cardCategorySocial: {
    marginTop: "10px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "22px",
      position: "relative",
      marginTop: "-4px",
      top: "2px",
      marginRight: "5px"
    },
    "& svg": {
      position: "relative",
      top: "5px"
    }
  },
  cardCategorySocialWhite: {
    marginTop: "10px",
    color: "rgba(255, 255, 255, 0.8)",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "22px",
      position: "relative",
      marginTop: "-4px",
      top: "2px",
      marginRight: "5px"
    },
    "& svg": {
      position: "relative",
      top: "5px"
    }
  },
  cardCategoryWhite: {
    marginTop: "10px",
    color: "rgba(255, 255, 255, 0.7)"
  },
  cardCategoryFullWhite: {
    marginTop: "10px",
    color: "#FFFFFF"
  },
  cardDescriptionWhite: {
    color: "rgba(255, 255, 255, 0.8)"
  },
  aboutCardDescription: {
    color: "#3A7AB7 !important",
    textAlign: "left",
    lineHeight: "42px !important",
    fontFamily: "Lato",
    '@media (min-width: 1000px)': {
      fontSize: "3rem",
      lineHeight: "3rem",
    },
    '@media (min-width: 2000px)': {
      fontSize: "3.5rem",
      lineHeight: "3.5rem",
    },

  },
  author: {
    display: "inline-flex",
    "& a": {
      color: "#3C4858"
    }
  },
  authorWhite: {
    display: "inline-flex",
    "& a": {
      color: "rgba(255, 255, 255, 0.8)"
    }
  },
  avatar: {
    width: "30px",
    height: "30px",
    overflow: "hidden",
    borderRadius: "50%",
    marginRight: "5px"
  },
  statsWhite: {
    color: "rgba(255, 255, 255, 0.8)",
    display: "inline-flex",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      fontSize: "18px",
      lineHeight: "18px"
    },
    "& svg": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "18px",
      height: "18px"
    }
  },
  stats: {
    color: "#999999",
    display: "flex",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      fontSize: "18px",
      lineHeight: "18px"
    },
    "& svg": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "18px",
      height: "18px"
    }
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
  iconWrapper: {
    color: "rgba(255, 255, 255, 0.76)",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "55px",
      lineHeight: "55px"
    },
    "& svg": {
      width: "55px",
      height: "55px"
    }
  },
  iconWrapperColor: {
    borderColor: "rgba(255, 255, 255, 0.25)"
  },
  iconWhite: {
    color: "#FFFFFF"
  },
  marginTop30: {
    marginTop: "30px"
  },
  textCenter: {
    textAlign: "center"
  },
  marginBottom20: {
    marginBottom: "20px"
  },
  subTitle: {
    ...title,
  },
  body: {
    ...bodyText,
  },
  button: {
    backgroundColor: "#FC662B",
    color: "white",
    fontSize: "12px",
    borderRadius: "5px",
    boxShadow: "4px 4px 0px 0px #e3470a",
    "&:hover": {
      backgroundColor: "#FC662B",
      color: "white",
      boxShadow: "2px 2px 0px 0px #e3470a"
    },
  }
};

export default styles;
