import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import { db, sendEmail } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class Form extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false
  }
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    // update firestore document with PENDING and comment
    db.collection("TEST-users").doc(this.props.authUser.uid).collection("invited").doc().set({
      name: this.state.name,
      email: this.state.email,
      updatedAt: new Date()
    }, { merge: true }).then(async () => {
      console.log(this.state.email)
      //const email = this.props.user.email

      let subject = `You have been invited to Lead From Purpose`
      let html = `<p>Hello & Welcome To Lead From Purpose,</p>`
      html += `<p>One of our consultants has invited you to join our business strategy platform. Here you can learn how to build a company driven by purposeful leadership and values as well as network with other business professionals.<p>`
      html += `<p>We offer two forms of memberships with the Lead From Purpose.<p>`
      html += `<p>1. Monthly Memberships (1 Year Term) @ $25/mth<p>`
      html += `<a href="https://www.leadfrompurpose.org/product/guest-monthly-membership/">https://www.leadfrompurpose.org/product/guest-monthly-membership/</a>`
      html += `<p>2. Yearly Memberships (One-Time Payment) @ $295/year<p>`
      html += `<a href="https://www.leadfrompurpose.org/product/guest-annual-membership-one-time-payment/">https://www.leadfrompurpose.org/product/guest-annual-membership-one-time-payment/</a>`
      html += `<p>Once you have chosen your preferred Lead From Purpose membership option, please get in touch with [referrer-name / email], to get a walkthrough of the features associated with our strategic development curriculum. We can't wait to have you with us!</p>`
      html += `<p>Kind Regards,</p>`
      html += `<img src="https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/assets%2Fffpologo.a659f63c.png?alt=media&token=3d6a1320-a28a-4af1-9e9a-ace57def16cf" width="200">`
      // html += `<p>You have been invited to Legalese click here: localhost:3000/pages/signup/${this.props.authUser.uid}/case/${this.props.caseID}</p>`
      const emails = [this.state.email]
      const attatchment = true;
      const emailReturn = await sendEmail({
        emails,
        subject,
        html,
        attatchment
      })

    }).then(() => {
      window.location.reload()
    })
  }

  resetRequest = () => {
    window.location.reload();
  }

  render() {
    const { classes, modalClose, authUser } = this.props;
    console.log(this.state)
    // reset modal when closed
    if (this.state.messageSent) { this.resetRequest() }

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Feedback Submitted!</h4>

            </GridItem>
          </GridContainer>
        ) : (
          <div>
            <form onSubmit={this.handleSubmit}
            >
              <h5 className={classes.subTitle}>Invite User</h5>
              {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
              <CustomInput
                labelText="Name"
                id="name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  type: "text",
                  onChange: (event) => this.handleChange(event)
                }}
              />
              <CustomInput
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  type: "text",
                  onChange: (event) => this.handleChange(event)
                }}
              />
              <Button
                type="submit"
                style={{
                  marginLeft: "10px",
                  marginBottom: "10px",
                  color: "white",
                  backgroundColor: "#FC662B",
                  boxShadow: "4px 4px 0px 0px #755c26",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #755c26"
                  },
                }}
              >
                SUBMIT
              </Button>
            </form>
          </div>

        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);