import {
  bodyText,
  title
} from "assets/jss/material-dashboard-pro-react.jsx";
const valueBehaviorsStyle = {
  titleHeader: {
    ...title,
    textAlign: "center"
  },
  spaceAround: {
    ...bodyText,
    display: "flex",
    justifyContent: "space-around"
  },
  dashboardModalBtn: {
    position: "absolute",
    left: "21vw",
    backgroundColor: "#F4BF4F",
    "&:hover": {
      backgroundColor: "#F4BF4F",
      color: "white",
      boxShadow: "2px 2px 0px 0px #755c26"
    },
  },
  tableHeader: {
    color: "#4ec3b9",
    fontSize: "15px",
  },
  tableBody: {
    // marginRight:"20px"
  },
  valueHeader: {
    color: "#4ec3b9",
    fontSize: "15px",
  },
  addBehaviorButton: {
    float: "right",
    backgroundColor: "#FC662B",
    color: "white",
    fontSize: "12px",
    borderRadius: "5px",
    boxShadow: "4px 4px 0px 0px #e3470a",
    "&:hover": {
      backgroundColor: "#FC662B",
      color: "white",
      boxShadow: "2px 2px 0px 0px #e3470a"
    },

  },
  editorCell: {
    display: "flex",
    justifyContent: "space-between"
  },
  editorIcon: {
    padding: "2px",
    margin: "15px 0 0 0"
  },
  editButton: {
    margin: "0 15px 0 0",
    height: "40px",
    backgroundColor: "#F4BF4F",
    "&:hover": {
      backgroundColor: "#F4BF4F",
      color: "white",
      boxShadow: "2px 2px 0px 0px #755c26"
    },

  },
  modalCloseButton: {
    color: "black",
    display: "flex",
    marginTop: "-10px",
    marginBottom: "-15px",
    "&:hover": {
      color: "black",
      cursor: "pointer"
    }
  },
  button: {
    backgroundColor: "#FC662B",
    color: "white",
    fontSize: "12px",
    borderRadius: "5px",
    boxShadow: "4px 4px 0px 0px #e3470a",
    "&:hover": {
      backgroundColor: "#FC662B",
      color: "white",
      boxShadow: "2px 2px 0px 0px #e3470a"
    },
  },
  title: {
    ...title,
  }
}

export default valueBehaviorsStyle;