import { db, firebase } from "firebase/fbConfig.js"

// Turns data formatted for Table component to objects for firestore upload
export const tableToFirestoreObjects = (tData) => {
  // create array of objects from tableData using
  // accessor as key and dataRows row for value.
  const columns = tData.tableHead

  const data = tData.tableData.map(row => {
    let object = {};
    for (let i = 0; i < row.length; i++) {
      object = {
        ...object,
        [columns[i]]: row[i]
      };
    };
    return object
  });

  return (data)
};

// Turns data formatted for ReactTable component to objects for firestore upload
export const reactTableToFirestoreObjects = (tableData) => {
  // create array of objects from tableData using
  // accessor as key and dataRows row for value.
  const data = tableData.dataRows.map(row => {
    let object = {};
    for (let i = 0; i < row.length; i++) {
      object = {
        ...object,
        [tableData.columns[i].accessor]: row[i]
      };
    };
    return object
  });
  return (data)
};

export const addFieldToDocument = (docID, fieldOBJ) => {
  db.collection("TEST-actions-list").doc(docID).set({
    ...fieldOBJ,
    updatedAt: new Date()
  }, { merge: true }).then(() => {
    console.log(`Update TEST-actions-list with id: ${docID}`)
  }).catch((err) => {
    console.log(`Update TEST-actions-list with id: ${docID} with error: ${err}`)
  })
}

export const updateDocumentField = (docID, fieldOBJ = { fieldOBJ: firebase.firestore.FieldValue.delete() }) => {
  db.collection("TEST-actions-list").doc(docID).update({
    ...fieldOBJ,
    updatedAt: new Date()
  }).then(() => {
    console.log(`Update TEST-actions-list with id: ${docID}`)
  }).catch((err) => {
    console.log(`Update TEST-actions-list with id: ${docID} with error: ${err}`)
  })
}



export const updateFirestoreWithTableData = (collection, objects) => {
  // upload each object in objects to firestore collection
  objects.map(object => {
    //Update Collection 
    db.collection(collection).doc(object.id).set({
      ...object,
      updatedAt: new Date()
    }).then(() => {
      console.log(`Update ${collection} with id: ${object.id}`)
    }).catch((err) => {
      console.log(`Update ${collection} with id: ${object.id} with error: ${err}`)
    })
  })
}

export const updateFirestoreWithOneObject = (collection, object, id) => {
  //Update Collection 
  db.collection(collection).doc(id).set({
    ...object,
    updatedAt: new Date()
  }).then(() => {
    return (`Update ${collection} with id: ${object.id}`)
  }).catch((err) => {
    return (`Update ${collection} with id: ${object.id} with error: ${err}`)
  })
}

export const getFirestoreData = (collection, uid) => {
  return db.collection(collection).where("volUID", "==", uid)
    .get().then((snapshot) => {
      const data = []
      // Parse data into array like in firebase.js
      snapshot.forEach((doc) => {
        var docObj = {
          ...doc.data(),
          id: doc.id
        }
        data.push(docObj);
      })
      return (data)
    }).catch((e) => {
      console.log('error fetching data: ', e)
    })
}

    // // UPDATE KEY OF EVERY ENTRY IF EVERY DOC OF A COLLECTION
    // db.collection("TEST-users")
    //   .get().then((snapshot) => {
    //     snapshot.forEach((doc) => {
    //       const data = doc.data()
    //       const id = doc.id

    //       const updates = {
    //         EMAIL: data.email,
    //         GROUPS: data.groups,
    //         NAME: data.username,
    //         SCORE: data.score,
    //         COUNTY: data.county ? data.county : '',
    //         PRECINCT: data.precinct ? data.precinct : ''
    //       }

    //       updateFirestoreWithOneObject('TEST-users', updates, id)
    //     })
    //   }).catch((e) => { console.log('error: ' + e) })


// // update collection example
// let ids = []

// let updates = {
//   title: "Become a Sustained Donor",
//   description: "Click the donate button and become a sustained donor",
//   updatedAt: new Date()
// }

// db.collection("TEST-actions-list").where("title", "==", "Become a Sustained Doner")
//   .get().then((snapshot) => {
//     snapshot.forEach((doc) => {
//       ids.push(doc.id)
//     })
//   }).then(() => {
//     console.log(ids)
//     // ids.forEach((id) => {{
//     //   addFieldToDocument(id, updates)
//     // }}) 
//   }).catch((e) => { console.log('error: ' + e) })

// // Format and upload data to firestore.
//     // Data split between datarows and objects in expanded data
//     const expandedData = this.props.manifests.expandedData
//     const dataRows = this.props.manifests.dataRows

//     // Loop through dataRows
//     for (let i = 0; i < dataRows.length; i++) {
//       // get shipment from expanded data
//       let row = dataRows[i]
//       let id = row[0]
//       let object = expandedData[id]

//       // assign missing data in row to object
//       object = {
//         ...object,
//         id,
//         status: row[1],
//         deliveryLocation: row[2],
//         estDelivery: row[3],
//         type: row[4]
//       }

//       console.log(object)      

//       // upload to firestore
//       updateFirestoreWithOneObject("TEST-dispatch-manifests", object, object.id)
//     }


// // Format and upload data to firestore
    // object of objects with nested array for subcomponents
    // const shipmentsData = Object.entries(this.props.shipments.expandedData)
    // shipmentsData.map(shipment => {
    //   updateFirestoreWithOneObject("TEST-dispatch-shipments", shipment[1], shipment[0])
    // })

    // // Format and upload data to firestore.
    // // Data split between datarows and objects in expanded data
    // const expandedData = this.props.availableDriversTrucks.expandedData
    // const dataRows = this.props.availableDriversTrucks.dataRows

    // // Loop through dataRows
    // for (let i = 0; i < dataRows.length; i++) {
    //   // get shipment from expanded data
    //   let row = dataRows[i]
    //   let id = row[0]
    //   let object = expandedData[id]

    //   // Firestore doesn't like nested arrays 
    //   // format stops into objects
    //   let stops = {}
    //   for (let i = 0; i < object.stops.length; i++) {
    //     stops = {
    //       ...stops,
    //       [(i+1).toString()]: {
    //         location: object.stops[i][0],
    //         type: object.stops[i][1]
    //       }
    //     }        
    //   }

    //   // assign missing data in row to object
    //   object = {
    //     ...object,
    //     id,
    //     driver: row[1],
    //     company: row[2],
    //     space: row[3],
    //     location: row[4],
    //     stops        
    //   }

    //   console.log(object)      

    //   // upload to firestore
    //   updateFirestoreWithOneObject("TEST-dispatch-available-drivers-trucks", object, object.id)
    // }




