
import LoginPage from "views/Pages/LoginPage.jsx";
import SignupPage from "views/Pages/SignupPage.jsx";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";

const pagesRoutes = [
  {
    path: "/pages/signup-page",
    name: "Signup",
    short: "Signup",
    mini: "HM",
    icon: PersonAdd,
    component: SignupPage
  },
  {
    path: "/pages/login-page",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
];

export default pagesRoutes;
