import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, sendEmail, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";
import { Link } from 'react-router-dom'

// Get Users for Users Panel
export const startSetCoursesList = () => {
  return (dispatch) => {
    db.collection("courses").where("show", "==", true).where("completed", "==", true).orderBy("order")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()

          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            type: docData.type ? docData.type : "N/A",
            category: docData.category ? docData.category : "N/A",
            presenters: docData.presenters ? docData.presenters : "N/A",
            description: docData.description ? docData.description : "N/A",
            length: docData.length ? docData.length : "N/A",
            //start1: docData.start ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
            start: docData.start ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
            end: docData.end ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
            title: docData.title ? docData.title : "N/A",
            pdf1: docData.pdf1 ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.pdf1)}
              >{docData.pdf1Name}</Button>)
              : "N/A",
            pdf2: docData.pdf2 ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.pdf2)}
              >{docData.pdf2Name}</Button>)
              : "N/A",
            pdf3: docData.pdf3 ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.pdf3)}
              >{docData.pdf3Name}</Button>)
              : "N/A",
            pdf4: docData.pdf4 ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.pdf4)}
              >{docData.pdf4Name}</Button>)
              : "N/A",
            video: docData.video ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.video)}
              >VIEW</Button>)
              : "N/A",
            audio: docData.audio ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.audio)}
              >LISTEN</Button>)
              : "N/A",
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        dispatch(setCoursesList(data))
      })
  }
}

export const startSetCoursesCalendarList = () => {
  return (dispatch) => {
    db.collection("courses").where("show", "==", true)
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()

          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            type: docData.type ? docData.type : "N/A",
            category: docData.category ? docData.category : "N/A",
            presenters: docData.presenters ? docData.presenters : "N/A",
            description: docData.description ? docData.description : "N/A",
            length: docData.length ? docData.length : "N/A",
            //start1: docData.start ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
            start: docData.start ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
            end: docData.end ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
            title: docData.title ? docData.title : "N/A",
            pdf1: docData.pdf1 ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.pdf1)}
              >{docData.pdf1Name}</Button>)
              : "N/A",
            pdf2: docData.pdf2 ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.pdf2)}
              >{docData.pdf2Name}</Button>)
              : "N/A",
            pdf3: docData.pdf3 ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.pdf3)}
              >{docData.pdf3Name}</Button>)
              : "N/A",
            pdf4: docData.pdf4 ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.pdf4)}
              >{docData.pdf4Name}</Button>)
              : "N/A",
            video: docData.video ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.video)}
              >VIEW</Button>)
              : "N/A",
            audio: docData.audio ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                fullWidth
                onClick={
                  () => window.open(docData.audio)}
              >LISTEN</Button>)
              : "N/A",
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        dispatch(setCoursesList(data))
      })
  }
}


export const startSetPurposeCoursesList = (category) => {
  return (dispatch) => {
    db.collection("courses").where(category, '==', true)
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()

          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            type: docData.type ? docData.type : "N/A",
            length: docData.length ? docData.length : "N/A",
            title: docData.title ? docData.title : "N/A",
            pdf1: docData.pdf1 ? (
              <Button
                color="primary"
                fullWidth
                onClick={
                  () => window.open(docData.pdf1)}
              >{docData.pdf1Name}</Button>)
              : "N/A",
            pdf2: docData.pdf2 ? (
              <Button
                color="primary"
                fullWidth
                onClick={
                  () => window.open(docData.pdf2)}
              >{docData.pdf2Name}</Button>)
              : "N/A",
            pdf3: docData.pdf3 ? (
              <Button
                color="primary"
                fullWidth
                onClick={
                  () => window.open(docData.pdf3)}
              >{docData.pdf3Name}</Button>)
              : "N/A",
            pdf4: docData.pdf4 ? (
              <Button
                color="primary"
                fullWidth
                onClick={
                  () => window.open(docData.pdf4)}
              >{docData.pdf4Name}</Button>)
              : "N/A",
            video: docData.video ? (
              <Button
                color="primary"
                fullWidth
                onClick={
                  () => window.open(docData.video)}
              >VIEW</Button>)
              : "N/A",
            audio: docData.audio ? (
              <Button
                color="primary"
                fullWidth
                onClick={
                  () => window.open(docData.audio)}
              >LISTEN</Button>)
              : "N/A",
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        dispatch(setCoursesList(data))
      })
  }
}


export const startSetUpcomingCoursesList = (user, authUser) => {
  console.log(user, authUser.uid)
  return (dispatch) => {
    db.collection("courses")
      .where("completed", '==', false)
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()

          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            type: docData.type ? docData.type : "N/A",
            length: docData.length ? docData.length : "N/A",
            date: docData.date ? docData.date : "N/A",
            time: docData.time ? docData.time : "N/A",
            title: docData.title ? docData.title : "N/A",
            presenters: docData.presenters ? docData.presenters : "N/A",
            description: docData.description ? docData.description : "N/A",
            register: docData.register ? (
              <Button
                color="primary"
                onClick={() => {
                  db.collection("TEST-users").doc(authUser.uid).collection("registeredCourses").doc(doc.id).set({
                    id: doc.id,
                    user: user
                  }, { merge: true }).then(() => {
                    window.location.replace(`/courses/${doc.id}`)
                  })
                }}
                fullWidth
              >ADD TO CALENDAR</Button>)
              : "N/A",
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        console.log("hello?")
        console.log(data)
        dispatch(setUpcomingCoursesList(data))
      })
  }
}

export const registerCourse = (courseID, uid) => {
  //console.log(this.state)
  db.collection("TEST-users").doc(uid).collection("registeredCourses").doc(courseID).set({
    attended: true,
    updatedAt: new Date()
  }, { merge: true }).then(() => {
    console.log(``)
  }).catch((err) => {
    console.log(`${err}`)
  })
}

export const setCoursesList = (dataRows) => ({
  type: 'SET_COURSES_LIST',
  dataRows
})

export const setUpcomingCoursesList = (dataRows) => ({
  type: 'SET_UPCOMING_COURSES_LIST',
  dataRows
})
