/*eslint-disable*/
import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from "firebase/fbConfig.js";
import { Chart } from "react-google-charts";
import moment from "moment";
import { Link } from "react-router-dom";
import { Redirect, withRouter, useNavigate } from "react-router-dom";
import { createBrowserHistory } from 'history';

// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Assess from "assets/img/assess.png";
import Connect from "assets/img/connect.png";
import Grow from "assets/img/grow.png";
import Step1 from "assets/img/step1.png";
import Step2 from "assets/img/step2.png";
import Step3 from "assets/img/step3.png";
import Step4 from "assets/img/step4.png";
import Step5 from "assets/img/step5.png";
import Strategy from "assets/img/strategy.png";
import PlaybookIMG from "assets/img/playbook.png";
import DashboardIMG from "assets/img/dashboard.png";
import CheckMark from "assets/img/check.png";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Info from "@material-ui/icons/Info";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import ReactDragListView from "react-drag-listview";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/playbookStyle.jsx";
import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

// Forms
import AccountabilityStructureForm from "components/Forms/AccountabilityStructureForm.jsx";
import PurposeDeclarationForm from "components/Forms/PurposeDeclarationForm.jsx";
import TaglineForm from "components/Forms/TaglineForm.jsx";
import BusinessDescriptionForm from "components/Forms/BusinessDescriptionForm.jsx";
import PurposeOriginForm from "components/Forms/PurposeOriginForm.jsx";
import PurposeEnvisionedForm from "components/Forms/PurposeEnvisionedForm.jsx";
import StakeholderCommunityForm from "components/Forms/StakeholderCommunityForm.jsx";
import CoreValuesForm from "components/Forms/CoreValuesForm.jsx";
import CoreGoalsForm from "components/Forms/CoreGoalsForm.jsx";
import PurposeMetricsForm from "components/Forms/PurposeMetricsForm.jsx";
import HealthMetricsForm from "components/Forms/HealthMetricsForm.jsx";
import TargetMarketForm from "components/Forms/TargetMarketForm.jsx";
import CustomerValuePropositionForm from "components/Forms/CustomerValuePropositionForm.jsx";
import StaffValuePropositionForm from "components/Forms/StaffValuePropositionForm.jsx";
import KeyResourcesForm from "components/Forms/KeyResourcesForm.jsx";
import CustomerRelationshipsForm from "components/Forms/CustomerRelationshipsForm.jsx";
import KeyCompetenciesForm from "components/Forms/KeyCompetenciesForm.jsx";
import KeyPartnershipsForm from "components/Forms/KeyPartnershipsForm.jsx";
import RevenueStreamsForm from "components/Forms/RevenueStreamsForm.jsx";
import CostStructureForm from "components/Forms/CostStructureForm.jsx";
import KeyActivitiesForm from "components/Forms/KeyActivitiesForm.jsx";
import KeyChannelsForm from "components/Forms/KeyChannelsForm.jsx";
import CombinedValuesGoalsForm from "components/Forms/CombinedValuesGoalsForm.jsx";
import CombinedCustomerStaffValuePropositionForm from "components/Forms/CombinedCustomerStaffValuePropositionForm.jsx";
import ProfileForm from "components/Forms/profileForm.jsx";
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx";
import AccountabilityForm from "components/Forms/AccountabilityForm.jsx";
import EngagementMetricsForm from "components/Forms/EngagementMetricsForm.jsx";
import StakeholderRelationshipForm from "components/Forms/StakeholderRelationshipForm.jsx";
import ValuePropositionSESForm from "components/Forms/ValuePropositionSESForm.jsx";
import CommunicationStrategyForm from "components/Forms/CommunicationStrategyForm.jsx";
import ActionsForm from "components/Forms/ActionsForm.jsx";
import CreateIcon from '@material-ui/icons/Create';
import { EditOutlined } from "@material-ui/icons";

import {
  startSESLoadList,
  openDialog,
  closeDialog,
  setState,
  setStakeholderHasBeenEdited
} from "store/actions/SESActions.jsx";

import {
  editStakeHolderName
} from "store/actions/playbookListActions.jsx";



// Assets
import DefaultProfile from "assets/img/default-avatar.png";

// Firebase Redux Integration
import {
  tableToFirestoreObjects,
  updateFirestoreWithOneObject,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from "store/actions/firestoreActions";
import {
  startSetActionsList,
  startToggleCompleted,
  setUserScore,
} from "store/actions/actionsActions";
import { setFirstAssessment } from "store/actions/assessmentActions";
import { startSetUsersList } from "store/actions/adminActions";
import { startSetPracticesList } from "store/actions/practicesActions";
import { resetStakeholder } from "store/actions/SESActions";

import pathImg from "assets/img/path/path-image1.png";
import maturityLevel from "assets/img/path/MaturityLevel.png";

// import { html2canvas } from "html2canvas";
import { jsPDF } from "jspdf";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditorComponent from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class Playbook extends React.Component {
  constructor(props) {
    super(props);

    const data = [];
    for (let i = 1, len = 7; i < len; i++) {
      data.push({
        title: `rows${i}`,
      });
    }
    this.state = {
      data,
      dataLoaded: false,
      strategySheetDataLoaded: false,
      accountabilityStructureModal: false,
      coreValuesModal: false,
      combinedValuesGoalsModal: false,
      combinedCustomerStaffValueModal: false,
      purposeDeclarationModal: false,
      taglineModal: false,
      businessDescription: false,
      purposeOriginModal: false,
      stakeholderCommunityModal: false,
      purposeMetricsModal: false,
      healthMetricsModal: false,
      targetMarketModal: false,
      valuePropositionModal: false,
      keyResourcesModal: false,
      customerRelationshipsModal: false,
      keyCompetenciesModal: false,
      keyPartnershipsModal: false,
      revenueStreamsModal: false,
      costStructureModal: false,
      keyActivitiesModal: false,
      keyChannelsModal: false,
      learnPlaybookModal: false,
      accountabilityModal: false,
      engagementMetricModal: false,
      deleteStakeHolder: false,
      stakeholderRelationshipModal: false,
      valuePropModal: false,
      communicationStrategyModel: false,
      actionsModal: false,
      stakeholderDeleted: false,
      editStakeholder: false,
      stakeholderEdited: false,
      updatedName: this.props.stakeholderName
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  componentDidMount() {
    if (this.props.authUser && !this.state.dataLoaded) {
      db.collection("TEST-users").doc(this.props.authUser.uid).collection("strategy-sheet").doc(this.props.id)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            snapshot = snapshot.data()
            this.setState({
              name: snapshot.text || snapshot.name,
              accountability: snapshot.accountability || '',
              actions: snapshot.actions || '',
              communicationStrategy: snapshot.communicationStrategy || '',
              purposeOriginCondition: snapshot.purposeOriginCondition || '',
              purposeOriginProblem: snapshot.purposeOriginProblem || '',
              purposeOriginSolution: snapshot.purposeOriginSolution || '',
              engagementMetric: snapshot.engagementMetric || '',
              stakeholderRelationship: snapshot.stakeholderRelationship || '',
              valueProp: snapshot.valueProp || '',
              dataLoaded: true
            }, () => {
              const sesData = { accountability: this.state.accountability, engagementMetric: this.state.engagementMetric, stakeholderRelationship: this.state.stakeholderRelationship, valueProp: this.state.valueProp, actions: this.state.actions, communicationStrategy: this.state.communicationStrategy }
              this.props.setState(sesData)
            });
          }
        })
    }
  }

  componentDidUpdate() {
    if (this.props.authUser && !this.state.dataLoaded) {
      db.collection("TEST-users").doc(this.props.authUser.uid).collection("strategy-sheet").doc(this.props.id)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            snapshot = snapshot.data()
            this.setState({
              name: snapshot.text || snapshot.name,
              accountability: snapshot.accountability || '',
              actions: snapshot.actions || '',
              communicationStrategy: snapshot.communicationStrategy || '',
              purposeOriginCondition: snapshot.purposeOriginCondition || '',
              purposeOriginProblem: snapshot.purposeOriginProblem || '',
              purposeOriginSolution: snapshot.purposeOriginSolution || '',
              engagementMetric: snapshot.engagementMetric || '',
              stakeholderRelationship: snapshot.stakeholderRelationship || '',
              valueProp: snapshot.valueProp || '',
              dataLoaded: true
            }, () => {
              const sesData = { accountability: this.state.accountability, engagementMetric: this.state.engagementMetric, stakeholderRelationship: this.state.stakeholderRelationship, valueProp: this.state.valueProp, actions: this.state.actions, communicationStrategy: this.state.communicationStrategy }
              this.props.setState(sesData)
            });
          }
        })
    }
  }



  printSES = () => {
    // maximize space so as to have maximum amount of data as possible
    const doc = new jsPDF("");
    // doc.rect(pixel from left, pixel from the top,width,height)
    // doc.text(pixel left to right ,pixel height)
    doc.setDrawColor(77, 171, 41);
    // Purpose Delcaartion Recantagnle
    // doc.rect(4,5,55,31)
    doc.setFontSize(22);
    doc.setTextColor('#FF0000');
    doc.text(`${this.state.name} Engagement Strategy`, 30, 20);

    // doc.rect(10, 50, 90, 70);
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "italic");
    doc.setFontSize(16);
    doc.text("Accountability:", 13, 58);
    doc.setFontSize(10);
    const split5 = doc.splitTextToSize(this.props.accountability.values
      .filter((item) => item.text !== "")
      .map((item) => {
        return " " + item.text;
      }), 82)
    doc.setLineHeightFactor(1.5)
    doc.text(split5, 12, 64);
    // //   // <ArrowDownward style={{ color:'lightblue'}}/>
    doc.setFontSize(12);
    doc.setFont("times", "normal");
    // doc.text(this.props.user.purposeOriginSolution, 12, 73);

    // Engagment Meterics
    // need direct path for vales to pass them in for Accountability Structure
    // doc.rect(112, 50, 90, 70);
    doc.setFontSize(16);
    doc.setFont("helvetica", "italic");
    doc.text("Engagement Metrics:" + "", 114, 58);
    // <FiberManualRecordIcon style={{margin:"125,60"}}/>
    doc.setFontSize(10);
    const split4 = doc.splitTextToSize(this.props.engagementMetric.values
      .filter((item) => item.text !== "")
      .map((item) => {
        return "• " + item.text;
      }), 82)
    doc.text(split4, 114, 64);

    // StakeHolder Relationship
    // Still need Path added!!
    // doc.rect(10, 125, 90, 70);
    doc.setFontSize(16);
    doc.text("Stakeholder Relationship:", 13, 110);
    doc.setFontSize(10);
    const split3 = doc.splitTextToSize(this.props.stakeholderRelationship.values
      .filter((item) => item.text !== "")
      .map((item) => {
        return "• " + item.text;
      }), 82)
    doc.text(split3, 13, 115);

    // // // Value Proposition
    // doc.rect(112, 125, 90, 70);
    doc.setFontSize(16);
    doc.text("Value Proposition:", 114, 110);
    // doc.setFontSize(12);
    // doc.text("Engagement Promise:", 13, 134);
    doc.setFontSize(10);
    const split2 = doc.splitTextToSize(this.props.valueProp.values
      .filter((item) => item.text !== "")
      .map((item) => {
        return "• " + item.text;
      }), 82)
    doc.text(split2, 114, 115);

    // //  Communication Strategy
    // doc.rect(10, 200, 90, 90);
    doc.setFontSize(16);
    doc.text("Communication Strategy:", 13, 185);
    doc.setFontSize(10);
    doc.setLineHeightFactor(1.5)
    const arr = [];
    arr.push(this.state.purposeOriginCondition, this.state.purposeOriginProblem, this.state.purposeOriginSolution)
    const arrSplit = doc.splitTextToSize(arr
      .filter((item) => item !== "")
      .map((item) => {
        return item;
      }), 82)
    doc.text(arrSplit, 13, 190);

    // // Actions
    // // Still need Pathway
    // doc.rect(112, 200, 90, 90);
    doc.setFontSize(16);
    doc.text("Actions:", 114, 185);
    doc.setFontSize(10);
    doc.setLineHeightFactor(1.5)
    const split1 = doc.splitTextToSize(this.props.actions.values
      .filter((item) => item.text !== "")
      .map((item) => {
        return "• " + item.text;
      }), 82)
    doc.text(split1, 114, 190);

    //Key StakeHolders
    // doc.rect(55, 25, 100, 20);
    doc.setFontSize(16);
    doc.setFontSize(12);
    const splitPurposeDeclartion = doc.splitTextToSize(this.props.user.purposeDeclaration, 80)
    doc.text(splitPurposeDeclartion, 65, 30);

    // doc.setFontSize(12);

    doc.save("strategy.pdf");
  };


  deleteStakeholder = (e) => {
    // delete stakeholder id in strategy sheet colleciton and all of its data
    // e.preventDefault()
    db.collection("TEST-users").doc(this.props.authUser.uid).collection("strategy-sheet").doc(this.props.id).delete()
      .then(() => {
        db.collection("TEST-users").doc(this.props.authUser.uid).collection("strategy-sheet").doc("all-stakeholders").get()
          .then((snapshot) => {
            snapshot = snapshot.data().values
            snapshot.forEach((doc) => {
              if (doc.id === this.props.id) {
                doc.status = "inactive"
              }
            })
            db.collection("TEST-users").doc(this.props.authUser.uid).collection("strategy-sheet").doc('all-stakeholders').set({ values: snapshot })
              .then(() => {
                // delete stakeholder name found in members collection
                this.setState({
                  stakeholderDeleted: true
                })
                db.collection("TEST-users").doc(this.props.authUser.uid).collection("lists").doc("members").get()
                  .then((snapshot) => {
                    snapshot = snapshot.data().values
                    for (var x = 0; x < snapshot.length; x++) {
                      if (snapshot[x].id === this.props.id) {
                        snapshot.splice(x, 1)
                        let newObj = {}
                        newObj.values = snapshot
                        db.collection("TEST-users").doc(this.props.authUser.uid).collection("lists").doc("members").set(newObj)
                          .then(() => {
                            this.props.history.push('/tools/playbook')

                          })
                          .catch((err) => {
                            console.log('err', err)
                          })
                      }
                    }
                  })
                  .catch((err) => {
                    console.log('err', err)
                  })
              })
          })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  render() {
    const {
      authUser,
      classes,
      user,
      purposeEnvisioned,
      openPlaybookListDialog,
      closePlaybookListDialog,
      Members,
      purposeMetrics,
      healthMetrics,
      targetMarkets,
      keyResources,
      customerRelationships,
      keyCompetencies,
      revenueStreams,
      costStructures,
      keyActivities,
      keyChannels,
      keyPartnerships,
      coreValues,
      coreGoals,
      customerValues,
      staffValues,
      tagline,
      accountability,
    } = this.props;


    if (!!authUser && user.dataLoaded && !this.state.playBookDataLoaded) {

      const sesData = { accountability: this.state.accountability, engagementMetric: this.state.engagementMetric, stakeholderRelationship: this.state.stakeholderRelationship, valueProp: this.state.valueProp, actions: this.state.actions, communicationStrategy: this.state.communicationStrategy }
      setState(sesData)
      // this.loadPlayBookData(authUser.uid);
    }

    let playbook;
    if (this.state.playBookDataLoaded) {
      playbook = this.state.playBookData;
    }

    if (user.termGroups) {
      if (user.termGroups.length < 2) {
        return <Redirect to="/profile" />;
      }
    }

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.data];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
      },
      nodeSelector: "li",
      handleSelector: "a",
    };

    return (
      <div id="divToPrint">
        {this.state.dataLoaded
          && this.props.finishedLoading
          ? (
            <GridContainer justify="center">
              {console.log('strat state', this.state)}
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <GridContainer style={{ backgroundColor: "#E5E5E5", height: "60px", paddingBottom: "5px" }}>
                    <GridItem xs={6}>
                      <h3
                        style={{
                          fontFamily: "'Merriweather Sans', sans-serif",
                          textTransform: "uppercase",
                          color: "#3a3934",
                          fontWeight: "600",
                          verticalAlign: "center"
                        }}
                      >
                        Welcome to Your SES Worksheet</h3>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}
                      style={{ justifyContent: "flex-end", display: "flex", marginTop: "7px", verticalAlign: "center" }}
                    >
                      <Button
                        className={classes.button}
                        style={{ height: "35px", marginRight: "15px" }}
                        onClick={() => this.printSES()}
                      >
                        PRINT SES Worksheet
                      </Button>
                      <Button
                        className={classes.button}
                        style={{ height: "35px", marginRight: "5px" }}
                        onClick={() => this.handleClickOpen("learnPlaybookModal")}
                      >
                        Learn About The SES Worksheet
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card style={{ padding: "30px" }}>
                        <div className={classes.trashAndPeniclIcons}>
                          <RemoveCircleOutlineIcon
                            className={classes.trashIcon}
                            style={{ color: "#F4BF4F" }}
                            onClick={() =>
                              this.handleClickOpen("deleteStakeHolder")
                            }
                          />
                          <CreateIcon
                            style={{ color: "#F4BF4F" }}
                            className={classes.pencilIcon}
                            onClick={() => {
                              this.handleClickOpen("editStakeholder")
                              this.props.setStakeholderHasBeenEdited(true)
                            }} />
                        </div>
                        <center>
                          {this.props.user.companyName ? (
                            <h2 className={classes.subTitle}>
                              <b style={{ textTransform: "uppercase" }}>
                                {this.state.name}
                                {" "}
                                <span style={{ color: "#4ABEB5" }}>
                                  {'' + 'STAKEHOLDER ENGAGEMENT STRATEGY'}
                                </span>
                              </b>
                            </h2>
                          ) : (
                            <p className={classes.body}>Enter Company Name on Profile Page</p>
                          )}

                          <Tooltip
                            id="tooltip-top"
                            title="Purpose Declaration: A statement of an organization’s commitment or promise to its Stakeholder World of the difference it wants to make in that world. Generally begins: “We are committed to...” This declaration should be meaningful and important to the organization’s stakeholder world. It should provide the overriding context for everything the organization does: its Purpose."
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            {this.props.user.purposeDeclaration ? (
                              <p className={classes.body} style={{fontSize:"18px"}}>{this.props.user.purposeDeclaration}</p>
                            ) : (
                              <p className={classes.body} style={{fontSize:"18px"}}>Declare your purpose</p>
                            )}
                          </Tooltip>
                        </center>
                      </Card>
                      <GridContainer>
                        <GridItem xs={12} sm={6}>
                          <Card style={{ padding: "30px" }}>
                            <GridContainer
                              style={{ margin: "0px 0px -60px 0px" }}
                            >
                              <GridItem xs={9}></GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div className={classes.dashboardModalBtn}>
                                  <Button
                                    style={{ backgroundColor: "#F4BF4F" }}
                                    onClick={() =>
                                      this.handleClickOpen(
                                        "accountabilityModal"
                                      )
                                    }
                                  >
                                    <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                  </Button>
                                </div>
                              </GridItem>
                            </GridContainer>
                            <Tooltip
                              id="tooltip-top"
                              title="Accountability: The person who is primarily responsible for managing this relationship and keeping this
                            Stakeholder engaged. To whom is this person accountable to with regard to this relationship?"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <h3 className={classes.boldBody}>Accountability</h3>
                            </Tooltip>
                            <ul>
                              {this.state.accountability ?
                                this.state.accountability
                                  .filter((item) => item.text !== "")
                                  .map((item) => {
                                    return <li key={item.id}>{item.text}</li>;
                                  }) : (<li> </li>)}
                            </ul>
                          </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6}>

                          <Card style={{ padding: "30px" }}>
                            <GridContainer
                              style={{ margin: "0px 0px -60px 0px" }}
                            >
                              <GridItem xs={9}></GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div className={classes.dashboardModalBtn}>
                                  <Button
                                    style={{ backgroundColor: "#F4BF4F" }}
                                    onClick={() =>
                                      this.handleClickOpen("engagementMetricModal")
                                    }
                                  >
                                    <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                  </Button>
                                </div>
                              </GridItem>
                            </GridContainer>
                            <Tooltip
                              id="tooltip-top"
                              title="Engagement Metrics: Ways of measuring this Stakeholder’s level of engagement, listed in order of
                              priority. The top measure will be tracked on your Dashboard."
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <h3 className={classes.boldBody}>Engagement Metrics</h3>
                            </Tooltip>
                            <ul>
                              {this.state.engagementMetric ? this.state.engagementMetric
                                .filter((item) => item.text !== "")
                                .map((item) => {
                                  return <li key={item.id}>{item.text}</li>;
                                }) : (<li> </li>)}
                            </ul>
                          </Card>

                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={6}>
                          <Card style={{ padding: "30px" }}>
                            <GridContainer
                              style={{ margin: "0px 0px -60px 0px" }}
                            >
                              <GridItem xs={9}></GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div className={classes.dashboardModalBtn}>
                                  <Button
                                    style={{ backgroundColor: "#F4BF4F" }}
                                    onClick={() =>
                                      this.handleClickOpen("stakeholderRelationshipModal")
                                    }
                                  >
                                    <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                  </Button>
                                </div>
                              </GridItem>
                            </GridContainer>
                            <Tooltip
                              id="tooltip-top"
                              title="Stakeholder Relationship: The reason why this Stakeholder is so important to the success of your
                            organization. What do you expect from this Stakeholder? Tie that expectation to the fulfillment of your
                            Purpose."
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <h3 className={classes.boldBody}>Stakeholder Relationship</h3>

                            </Tooltip>
                            <ul>
                              {this.state.stakeholderRelationship ? this.state.stakeholderRelationship
                                .filter((item) => item.text !== "")
                                .map((item) => {
                                  return <li key={item.id}>{item.text}</li>;
                                }) : (<li></li>)}
                            </ul>
                          </Card>
                        </GridItem>
                        {/* <GridItem xs={12} sm={4}></GridItem> */}
                        <GridItem xs={12} sm={6}>
                          <Card style={{ padding: "30px" }}>
                            <GridContainer
                              style={{ margin: "0px 0px -60px 0px" }}
                            >
                              <GridItem xs={9}></GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div className={classes.dashboardModalBtn}>
                                  <Button
                                    style={{ backgroundColor: "#F4BF4F" }}
                                    onClick={() =>
                                      this.handleClickOpen("valuePropModal")
                                    }
                                  >
                                    <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                  </Button>
                                </div>
                              </GridItem>
                            </GridContainer>
                            <Tooltip
                              id="tooltip-top"
                              title="Value Proposition: The benefits you provide to this stakeholder. What is in it for them? Your promise to
                            them to keep them engaged."
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <h3 className={classes.boldBody}>Value Proposition</h3>
                            </Tooltip>
                            <ul>
                              {this.state.valueProp ?
                                this.state.valueProp
                                  .filter((item) => item.text !== "")
                                  .map((item) => {
                                    return <li key={item.id}>{item.text}</li>;
                                  }) : (<li> </li>)}
                            </ul>
                          </Card>
                        </GridItem>


                        <GridItem xs={12} sm={6}>
                          <Card style={{ padding: "30px" }}>
                            <GridContainer
                              style={{ margin: "0px 0px -60px 0px" }}
                            >
                              <GridItem xs={9}></GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div className={classes.dashboardModalBtn}>
                                  <Button
                                    style={{ backgroundColor: "#F4BF4F" }}
                                    onClick={() =>
                                      this.handleClickOpen("communicationStrategyModel")
                                    }
                                  >
                                    <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                  </Button>
                                </div>
                              </GridItem>
                            </GridContainer>
                            <Tooltip
                              id="tooltip-top"
                              title="Communication Strategy/Your Inspiration Story: Your inspiration story, like your Purpose origin, has
                            three parts: 1. What desired condition do you want?, 2. What specific problem prevents that condition?, 3. How will you solve that problem?"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <h3 className={classes.boldBody}>Communication Strategy</h3>
                            </Tooltip>
                            <ul>
                              {this.state.purposeOriginCondition ?
                                <p>{this.state.purposeOriginCondition}</p>
                                :
                                null
                              }
                              {this.state.purposeOriginProblem ?
                                <p>{this.state.purposeOriginProblem}</p>
                                :
                                null
                              }
                              {this.state.purposeOriginSolution ?
                                <p>{this.state.purposeOriginSolution}</p>
                                :
                                null
                              }
                            </ul>
                          </Card>
                        </GridItem>
                        {/* <GridItem xs={12} sm={4}></GridItem> */}
                        <GridItem xs={12} sm={6}>
                          <Card style={{ padding: "30px" }}>
                            <GridContainer
                              style={{ margin: "0px 0px -60px 0px" }}
                            >
                              <GridItem xs={9}></GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div className={classes.dashboardModalBtn}>
                                  <Button
                                    style={{ backgroundColor: "#F4BF4F" }}
                                    onClick={() =>
                                      this.handleClickOpen("actionsModal")
                                    }
                                  >
                                    <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                  </Button>
                                </div>
                              </GridItem>
                            </GridContainer>
                            <Tooltip
                              id="tooltip-top"
                              title="Actions: Actions you can take now to increase the level of engagement of this Stakeholder with your
                            organization. These action items will also be considered in your annual initiative brainstorming process."
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <h3 className={classes.boldBody}>Actions</h3>
                            </Tooltip>
                            <ul>
                              {this.state.actions ? this.state.actions
                                .filter((item) => item.text !== "")
                                .map((item) => {
                                  return <li key={item.id}>{item.text}</li>;
                                }) : (<li> </li>)}
                            </ul>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      {/* TODO: NEEDS TO BE ADDED TO SOME OF THE FORM FIELDS (GOES TO CHILD COMPONENT) */}
                      {/* <GridContainer justify="center">
                      <GridItem sm={12} xs={12}>
                        <Card style={{ padding: "30px" }}>
                        <h3>Draggable List Component Demo</h3>
                          <ReactDragListView {...dragProps}>

                            <ol>
                              {this.state.data.map((item, index) => (
                                <li key={index}>
                                  {item.title}
                                  <span style={{float: "right", margin: "0px 30px 0px 0px"}}><a href="#">Drag</a></span>
                                </li>
                              ))}
                            </ol>

                          </ReactDragListView>
                        </Card>
                      </GridItem>
                    </GridContainer> */}
                    </GridItem>
                  </GridContainer>
                  {/* LEARN PLAYBOOK MODAL */}
                  <Dialog
                    style={{ height: "95%", marginTop: "30px", marginBottom: "40px" }}
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.state.learnPlaybookModal}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("learnPlaybookModal")}
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h3 className={classes.subTitle} style={{ marginLeft: "5px" }}>Learn About The SES Worksheet</h3>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <p>For each of your key stakeholders you will complete a Stakeholder Engagement Strategy (SESabbreviated) worksheet.  You can edit this worksheet by clicking on the stakeholder’s name in your Stakeholder Community element in your Playbook or by going to the SES Worksheet table and clicking on the edit symbol next to its name.</p>
                      <p>
                        When developing an engagement strategy, there are six issues, which correspond to the six elements or boxes in the SES worksheet, to consider:
                        <br />
                        <br />
                        1. Accountability.  Who in your organization will be responsible for maintaining a high level ofstakeholder engagement? If the stakeholder is your customer, it should be someone on yoursales team If it’s one of your suppliers, maybe it’s your purchasing agent or a key sourcingperson. One person must be responsible for that relationship.
                        <br />
                        <br />
                        2.  Stakeholder Relationship. Why is this stakeholder important to the organization and what do you specifically need from them? What you need from them is exactly why they are important to your organization’s success and fulfillment of its Purpose.  What you need from them will impact what you measure and what kind of value proposition you will promise them.
                        <br />
                        <br />
                        3.  Value Proposition. Why should this stakeholder care? What’s in it for them? In other words, what value proposition are you promising them so that it’s a win-win?  The value proposition should empower them to give you what you need from them.  Why is it in their interest to provide what you need?
                        <br />
                        <br />
                        4.  Communication Strategy. How will you inspire them? We know from past studies that material benefits will not be enough. They need to find your Purpose meaningful and important tothem. They will need to be inspired. How will you create an inspiration story that resonates with them on a personal level. Your Purpose Origan, with its three parts, will give you access to that story.
                        <br />
                        <br />
                        5.  Engagement Metrics. How will you measure successful engagement? Again, if you don’t measure, it will be difficult to improve. These kinds of measurements are not easy. Get clearwhat you are expecting from the relationship, the more that can be quantified thebetter. Consider those expectations as a starting point.
                        <br />
                        <br />
                        6.   Actions. What actions will you take to execute this strategy? These actions willbecome part of your brainstorming exercise in Phase 4 when you choose what youwill do to activate your business model and fulfill your Purpose.
                        <br />
                        <br />
                        The functionality of the SES Worksheet is similar to that of the Playbook.
                        <br />
                        <br />
                        <b> To Delete a Stakeholder:</b> You can permanently delete a particular stakeholder and their SES Worksheet by clicking on the symbol in the upper left hand corner of the SES Worksheet header box.   Then go to the SES Worksheet table.  There you will then have an opportunity to restore that stakeholder by clicking on the green restore button next to the stakeholder’s name or you can click on the Red delete button to the right of the restore button to complete the deletion process.  You will then have one final opportunity to cancel the deletion.  Once you click on Delete again, the stakeholder and that SES stakeholder worksheet will be permanently deleted.
                        <br />
                        <br />
                        <b>To Make a Stakeholder Inactive: </b> You can leave a stakeholder in an inactive status, by deleting the stakeholder on the SES Worksheet as described above and not taking any further action.  If you go to the SES worksheet table, you will notice that all deleted worksheets remain in an inactive status unless you restore it to active status (It will then show up in your Stakeholder Community on your Playbook.) or permanently delete it.
                      </p>
                      <br />
                    </DialogContent>
                  </Dialog>




                  {/* ACCOUNTABILITY MODAL */}
                  <Dialog
                    className={classes.modalContainer}
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.state.accountabilityModal}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() =>
                          this.handleClose("accountabilityModal")
                        }
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <Tooltip
                        id="tooltip-top"
                        title="Accountability Structure: Consider major functions or roles in the organization and those people who are accountable for them. Please limit primary roles to five and sub-roles under each primary role to three for a maximum of 15 lines."
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <h3 className={classes.subTitle}>Decide On An Accountability Structure</h3>
                      </Tooltip>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <AccountabilityForm
                        accountability={this.state.accountability}
                        stakeholderId={this.props.id}
                      />
                    </DialogContent>
                  </Dialog>


                  {/* Engagement Metric MODAL */}
                  <Dialog
                    className={classes.modalContainer}
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.state.engagementMetricModal}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() =>
                          this.handleClose("engagementMetricModal")
                        }
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <Tooltip
                        id="tooltip-top"
                        title="Engagement Metrics: Ways of measuring this Stakeholder’s level of engagement, listed in order of priority. The top measure will be tracked on your Dashboard."
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <h3 className={classes.subTitle}>Choose a few engagement metrics</h3>
                      </Tooltip>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <EngagementMetricsForm
                        engagementMetric={this.state.engagementMetric}
                        stakeholderId={this.props.id}
                      />
                    </DialogContent>
                  </Dialog>



                  {/* Stakeholder relationship MODAL */}
                  <Dialog
                    className={classes.modalContainer}
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.state.stakeholderRelationshipModal}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() =>
                          this.handleClose("stakeholderRelationshipModal")
                        }
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <Tooltip
                        id="tooltip-top"
                        title="Stakeholder Relationship: The reason why this Stakeholder is so important to the success of your
                            organization. What do you expect from this Stakeholder? Tie that expectation to the fulfillment of your
                            Purpose."
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <h3 className={classes.subTitle}>Describe the relationship with the stakeholder</h3>
                      </Tooltip>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <StakeholderRelationshipForm
                        stakeholderRelationship={this.state.stakeholderRelationship}
                        stakeholderId={this.props.id}
                      />
                    </DialogContent>
                  </Dialog>





                  {/* Value prop MODAL */}
                  <Dialog
                    className={classes.modalContainer}
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.state.valuePropModal}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() =>
                          this.handleClose("valuePropModal")
                        }
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <Tooltip
                        id="tooltip-top"
                        title="Value Proposition: The benefits you provide to this stakeholder. What is in it for them? Your promise to
                            them to keep them engaged."
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <h3 className={classes.subTitle}>Describe the value you bring to the stakeholder</h3>
                      </Tooltip>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <ValuePropositionSESForm
                        valueProp={this.state.valueProp}
                        stakeholderId={this.props.id}
                      />
                    </DialogContent>
                  </Dialog>







                  {/* Communication Strategy MODAL */}
                  <Dialog
                    className={classes.modalContainer}
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.state.communicationStrategyModel}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() =>
                          this.handleClose("communicationStrategyModel")
                        }
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <Tooltip
                        id="tooltip-top"
                        title="Communication Strategy/Your Inspiration Story: Your inspiration story, like your Purpose origin, has
                      three parts: 1. What desired condition do you want?, 2. What specific problem prevents that condition?, 3. How will you solve that problem?"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <h3 className={classes.subTitle} style={{marginLeft:"45px"}}>Establish your communication strategy</h3>
                      </Tooltip>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <CommunicationStrategyForm
                        communicationStrategy={this.state.communicationStrategy}
                        stakeholderId={this.props.id}
                      />
                    </DialogContent>
                  </Dialog>



                  {/* Actions MODAL */}
                  <Dialog
                    className={classes.modalContainer}
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.state.actionsModal}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() =>
                          this.handleClose("actionsModal")
                        }
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <Tooltip
                        id="tooltip-top"
                        title="Actions: Actions you can take now to increase the level of engagement of this Stakeholder with your
                      organization. These action items will also be considered in your annual initiative brainstorming process."
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <h3 className={classes.subTitle} style={{marginLeft:"35px"}}>Commit to some actions you will take</h3>
                      </Tooltip>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <ActionsForm stakeholderId={this.props.id} />
                    </DialogContent>
                  </Dialog>


                  {this.state.stakeholderDeleted ? (

                    <Dialog
                      classes={{
                        root: classes.modalRoot,
                        paper: classes.modal + " " + classes.modalLarge,
                      }}
                      open={this.state.deleteStakeHolder}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => this.handleClose("learnPlaybookModal")}
                      aria-labelledby="shipment-modal-slide-title"
                      aria-describedby="shipment-modal-slide-description"
                    >
                      <DialogTitle
                        id="shipment-modal-slide-title"
                        disableTypography
                        className={classes.textPrimary}
                      >
                        <Button
                          simple
                          className={classes.modalCloseButton}
                          key="close"
                          aria-label="Close"
                          onClick={() => this.handleClose("deleteStakeHolder")}
                        >
                          {" "}
                          <Close className={classes.modalClose} />
                        </Button>
                        <h3>Stakeholder Deleted!</h3>
                      </DialogTitle>
                      <DialogContent
                        id="shipment-modal-slide-description"
                        className={classes.modalBody}
                      >
                      </DialogContent>
                    </Dialog>

                  ) : (


                    <Dialog
                      classes={{
                        root: classes.modalRoot,
                        paper: classes.modal + " " + classes.modalLarge,
                      }}
                      open={this.state.deleteStakeHolder}
                      TransitionComponent={Transition}
                      keepMounted
                      aria-labelledby="shipment-modal-slide-title"
                      aria-describedby="shipment-modal-slide-description"
                    >
                      <DialogTitle
                        id="shipment-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                      >
                        <Button
                          simple
                          className={classes.modalCloseButton}
                          key="close"
                          aria-label="Close"
                          onClick={() => this.handleClose("deleteStakeHolder")}
                        >
                          {" "}
                          <Close className={classes.modalClose} />
                        </Button>
                        <h3 className={classes.subTitle}>Are you sure you want to remove this stakeholder from your playbook?</h3>
                      </DialogTitle>
                      <DialogContent
                        id="shipment-modal-slide-description"
                        className={classes.modalBody}
                      >
                        <div style={{justifyContent:"space-between"}}>
                        <Button
                          onClick={this.deleteStakeholder}
                          style={{
                            backgroundColor: "red",
                            marginLeft: "10px",
                            marginBottom: "10px",
                            color:"white",
                            borderRadius:"5px",
                            boxShadow: "4px 4px 0px 0px red",
                            "&:hover": {
                              backgroundColor: "red",
                              color: "white",
                              boxShadow: "2px 2px 0px 0px red"
                            },
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          className={classes.button}
                          onClick={() => this.handleClose("deleteStakeHolder")}
                          style={{
                            float:"right",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Cancel
                        </Button>
                        </div>
                      </DialogContent>
                    </Dialog>

                  )}




                  <Dialog
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.props.stakeholderHasBeenEdited}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                      this.handleClose("editStakeholder")
                      this.props.setStakeholderHasBeenEdited(false)

                    }}
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => {
                          this.handleClose("editStakeholder")
                          this.props.setStakeholderHasBeenEdited(false)
                        }}
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h3 className={classes.subTitle} style={{ margin: "20px 0 10px 20px" }}>Edit Stakeholder Name</h3>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <Card className={classes.cardForm}>

                        <GridContainer>
                          {/* <form> */}
                          <input
                            defaultValue={this.state.name}
                            onChange={(e) => this.setState({
                              updatedName: e.target.value
                            })}
                            className={classes.editStakeholderInput}></input>
                          {/* </form> */}

                        </GridContainer>
                      </Card>
                      <Button
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                          this.props.editStakeHolder(this.props.authUser.uid, this.props.id, this.state.updatedName)
                          this.props.setStakeholderHasBeenEdited(false)
                          this.setState({
                            stakeholderEdited: true
                          })
                        }}
                        style={{
                          marginLeft: "20px",
                          marginBottom: "10px"
                        }}
                      >
                        SUBMIT
                      </Button>
                    </DialogContent>
                  </Dialog>


                </div>
              </GridItem>
            </GridContainer>
          ) : (
            <div className={classes.easeInOut}>
              <Hidden smDown implementation="css">
                <div
                  className={classes.preloaderDesktop}
                  style={{
                    background: "white",
                    position: "absolute",
                    width: "110%",
                    margin: "-25% 0% 0% -8%",
                    height: "180%",
                    zIndex: "99999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 33%",
                      position: "relative",
                      width: "25%",
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "2% 0% 0% 38%",
                      position: "relative",
                      width: "20%",
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
              <Hidden mdUp implementation="css">
                <div
                  className={classes.preloaderMobile}
                  style={{
                    background: "white",
                    position: "absolute",
                    width: "110%",
                    margin: "-30% 0% 0% -10%",
                    height: "110%",
                    zIndex: "9999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 20%",
                      position: "relative",
                      width: "50%",
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "15% 0% 0% 38%",
                      position: "relative",
                      width: "20%",
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    tagline: state.playbookList.tagline,
    accountability: state.SES.accountability,
    stakeholderRelationship: state.SES.stakeholderRelationship,
    valueProp: state.SES.valueProp,
    id: ownProps.match.params.id,
    location: state.router,
    stakeholderName: state.SES.stakeholderName,
    finishedLoading: state.SES.finishedLoading,
    finishedUpdating: state.SES.finishedUpdating,
    stakeholderHasBeenEdited: state.SES.stakeholderHasBeenEdited,
    engagementMetric: state.SES.engagementMetric,
    communicationStrategy: state.SES.communicationStrategy,
    actions: state.SES.actions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSetPracticesList: dispatch(startSetPracticesList()), // JE says: So, so bad... must... not... start... refactoring...
    openPlaybookListDialog: (name) => dispatch(openDialog(name)),
    closePlaybookListDialog: (uid, name) =>
      dispatch(startLoadList(uid, name)).then(() =>
        dispatch(closeDialog(name))
      ),
    resetStakeholder: (stakeholder) => dispatch(resetStakeholder(stakeholder)),
    loadPlaybookListData: (uid, stakeholderId) => dispatch(startSESLoadList(uid, stakeholderId)),
    editStakeHolder: (uid, stakeholderId, updatedName) => dispatch(editStakeHolderName(uid, stakeholderId, updatedName)),
    setState: (data) => dispatch(setState(data)),
    setStakeholderHasBeenEdited: (boolean) => dispatch(setStakeholderHasBeenEdited(boolean))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Playbook);
