// Orders Data
const actions = {
  columns: [    
    {
      Header: "ACTION ID",
      accessor: "actionID",
      show: false
    },
    {
      Header: "TITLE",
      accessor: "title"
    },
    {
      Header: "BODY",
      accessor: "description",
      show: false
    },
    {
      Header: "DUE",
      accessor: "dueDate"
    },
    {
      Header: "DAYS DUE",
      accessor: "days",
      show: false
    },
    {
      Header: "POINTS",
      accessor: "points"
    },    
    {
      Header: "STATUS",
      accessor: "status"
    },    
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],  
  dataLoaded: false
}
;
// Orders Data
const pendingActions = {
  columns: [  
    {
      Header: "VOL EMAIL",
      accessor: "email"
    },  
    {
      Header: "VOL UID",
      accessor: "volUID"      
    },
    {
      Header: "ACTION ID",
      accessor: "actionId"      
    },
    {
      Header: "TITLE",
      accessor: "title"
    },
    {
      Header: "COMMENT",
      accessor: "comment"     
    },
    {
      Header: "DAYS DUE",
      accessor: "days",
      show: false
    },
    {
      Header: "POINTS",
      accessor: "points"
    },  
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],  
  dataLoaded: false
};

// Orders Data
const users = {
  columns: [        
    {
      Header: "COMPANY",
      accessor: "companyName"      
    },
    {
      Header: "FIRST NAME",
      accessor: "firstName"      
    },
    {
      Header: "LAST NAME",
      accessor: "lastName"      
    },
    {
      Header: "PHONE",
      accessor: "phone"      
    },
    {
      Header: "EMAIL",
      accessor: "email"
    },
    {
      Header: "PE SCORE",
      accessor: "peLevelName"     
    },
    {
      Header: "SE SCORE",
      accessor: "seLevelName"     
    },
    {
      Header: "SA SCORE",
      accessor: "saLevelName"     
    },
    {
      Header: "AA SCORE",
      accessor: "aaLevelName"     
    },
    {
      Header: "GROUPS",
      accessor: "groups",      
    },
    {
      Header: "TERMS",
      accessor: "termGroups",      
    },
    {
      Header: "UID",
      accessor: "UID"      
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    },
    {
      Header: "",
      accessor: "disable",
      sortable: false,
      filterable: false
    }
  ],  
  dataLoaded: false
};
// Orders Data
const publicUsers = {
  columns: [        
    {
      Header: "COMPANY",
      accessor: "companyName"      
    },
    {
      Header: "BUSINESS TYPE",
      accessor: "businessType"      
    },
    {
      Header: "FIRST NAME",
      accessor: "firstName"      
    },
    {
      Header: "LAST NAME",
      accessor: "lastName"      
    },
    {
      Header: "PHONE",
      accessor: "phone"      
    },
    {
      Header: "EMAIL",
      accessor: "email"
    },
    {
      Header: "PURPOSE",
      accessor: "purposeDeclaration",      
    },
    {
      Header: "SPECIAL MEMBER",
      accessor: "groups",      
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    },
    {
      Header: "",
      accessor: "disable",
      sortable: false,
      filterable: false
    }  ],  
  dataLoaded: false
};


export const initState = {
  actions,
  pendingActions,
  users,
  publicUsers
}

export default (state = initState, action) => {
  switch (action.type) { 
    case 'SET_ADMIN_USERS_LIST':
      console.log('SET_ADMIN_USERS_LIST')     
      return {
        ...state,
        users: {
          ...state.users,
          dataRows: action.dataRows,
          dataLoaded: true
        },
        publicUsers: {
          ...state.publicUsers,
          dataRows: action.dataRows,
          dataLoaded: true
        }
      };    
    default:
      return state
  }
}
