import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";
import { Grid } from "@material-ui/core";

// Forms
import ActionConfirmForm from "components/Forms/ActionConfirmForm.jsx"

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions'
import { startSetUsersList } from "store/actions/adminActions"
import { tableToFirestoreObjects, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore, setFirstAction } from "store/actions/actionsActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      confirmActionModal: false,
      confirmActionID: "",
      confirmActionTitle: "",
      confirmActionComment: ""
    };
  }

  handleClickOpen = (modal, actionID, actionTitle, actionComment) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      confirmActionID: actionID,
      confirmActionTitle: actionTitle,
      confirmActionComment: actionComment
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
    // Load data for Table
    // if there are no actions make a default one   
    if (this.props.actionsData.dataRows.length === 0) {
      setFirstAction(this.props.authUser)
    }

    

    let actionsData = this.props.actionsData.dataRows.map((prop, key) => {
      let colObj = { ...prop }

      const volUID = colObj.volUID
      const actionID = colObj.actionId
      const title = colObj.title
      const comment = colObj.comment
      
      const user = this.props.users.dataRows.find(user => user.volUID === volUID)
      const email = user ? user.email : 'user not found'
      console.log(email)
      // Load Data for Summary Cards and add buttons     
      return {
        ...colObj, 
        email,       
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => this.handleClickOpen("confirmActionModal",
                actionID,
                title,
                comment
              )}
          >CONFIRM</Button>)
      }
    })

    this.setState({
      actionsData,
      dataLoaded: true
    })
  }

  handleChange = (docID) => {
    console.log(docID)
  }

  render() {
    const { classes } = this.props;

    if (this.props.actionsData.dataLoaded
      && this.props.users.dataLoaded
      && !this.state.dataLoaded) {
      this.loadData()
    }
    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
        row[id] !== undefined ?
        String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
          true
      );
    }
    return (
      <div>
        {
          this.state.dataLoaded ? (
            <GridContainer>
              <GridItem xs={12}>
                <h1 className={classes.title}>PENDING ACTIONS</h1>
                <div>
                  <ReactTable
                    data={this.state.actionsData}
                    filterable
                    defaultFilterMethod={filterCaseInsensitive}

                    columns={this.props.actionsData.columns}
                    defaultPageSize={10}
                    defaultSorted={[
                      {
                        id: "status",
                        desc: false
                      }
                    ]}
                    //showPaginationTop
                    showPaginationBottom
                    style={{
                      color: "#000"
                    }}
                    className="-striped -highlight"
                  />
                  {/* COMFIRM ACTION MODAL */}
                  <Dialog
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge
                    }}
                    open={this.state.confirmActionModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("confirmActionModal")}
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("confirmActionModal")}
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h2 className={classes.modalTitle}>CONFIRM ACTION: {this.state.confirmActionTitle}</h2>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <ActionConfirmForm
                        UID={this.state.UID}
                        actionID={this.state.confirmActionID}
                        title={this.state.confirmActionTitle}
                        comment={this.state.confirmActionComment}
                        modalClose={!this.state.confirmActionModal}
                      />
                      <Button
                        className={classes.ltrButtonWhite}
                        style={{
                          marginLeft: "10px",
                          marginTop: "10px"
                        }}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("confirmActionModal")}
                      >
                        CLOSE
              </Button>
                    </DialogContent>
                  </Dialog>
                </div>
              </GridItem>

            </GridContainer>
          ) : (
              <p className={classes.cardSubtitle}>Loading...</p>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    actionsData: state.actions.pendingActions,    
    users: state.admin.users,
    authUser: state.auth.user,
    user: state.profile.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSetPendingActionsList: dispatch(startSetPendingActionsList()),
    startSetUsersList: dispatch(startSetUsersList())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Orders);
