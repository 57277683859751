// /*eslint-disable*/

import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Select from "@material-ui/core/Select";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from "@material-ui/core/MenuItem";
import { setList, setListItem, startSaveList, submit } from "store/actions/playbookListActions.jsx";
import DraggableCardContainer from "components/Draggable/DraggableCardContainer.jsx"
import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";


class Form extends React.Component {

    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden",
            coreDepartmentBehaviors: [],
            filteredCoreDepartmentBehaviors: [],
            behaviors: [],
            firstBehavior: "",
            secondBehavior: "",
            thirdBehavior: ""
        };
    }

    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    updateFirstBehavior = (event) => {
        event.preventDefault()
        this.setState({
            firstBehavior: event.target.value
        })

    }

    updateSecondBehavior = (event) => {
        event.preventDefault()
        this.setState({
            secondBehavior: event.target.value
        })
    }

    updateThirdBehavior = (event) => {
        event.preventDefault()
        this.setState({
            thirdBehavior: event.target.value
        })
    }

    addUniqueNumOfBehaviors = (uid, name, list) => {
        let uniqueArray = []
        if (this.state.firstBehavior != "") {
            uniqueArray.push(this.state.firstBehavior)
            // console.log('master array', uniqueArray)
        } if (this.state.secondBehavior != "") {
            uniqueArray.push(this.state.secondBehavior)
            // console.log('master array', uniqueArray)
        } if (this.state.thirdBehavior != "") {
            uniqueArray.push(this.state.thirdBehavior)
            // console.log('master array', uniqueArray)
        }
        this.iterateOverBehaviors(uid, name, list, uniqueArray)
    }


    iterateOverBehaviors = (uid, name, list, uniqueArray) => {
        let savedDropdown = {}
        let masterArray = [...this.props.coreDepartmentBehaviors]
        for (let i = 0; i < uniqueArray.length; i++) {
            masterArray.push({
                coreGoalValue: list.coreGoalValue,
                department: list.department,
                behavior: uniqueArray[i]
            })
            savedDropdown = {
                coreGoal: list.coreGoalValue,
                department: list.department
            }
            console.log("savedDropdown: ", savedDropdown)
        }

        this.submitCoreDepartmentBehavior(uid, name, masterArray, savedDropdown)
    }

    submitCoreDepartmentBehavior = async (uid, name, masterArray, savedDropdown) => {
        const coreDepartmentBehaviorRef = db.collection('TEST-users').doc(uid).collection('lists').doc(name)
        const res = await coreDepartmentBehaviorRef.set({
            values: masterArray
        }, { merge: true }).then(() => this.setState({
            submitted: true
        }),
            db.collection('TEST-users').doc(uid).collection('lists').doc("saved-Dropdown").set(savedDropdown)
        )
    }


    additionalBehavior = () => {
        if (!this.state.secondBehaviorSelected) {
            return (
                this.setState({
                    secondBehaviorSelected: true
                })
            )
        }
        if (this.state.secondBehaviorSelected) {
            return (
                this.setState({
                    thirdBehaviorSelected: true
                })
            )
        }
        // if (this.state.thirdBehaviorSelected) {
        //     return (
        //         this.setState({
        //             fourthBehaviorSelected: true
        //         })
        //     )
        // }
        // if (this.state.fourthBehaviorSelected) {
        //     return (
        //         this.setState({
        //             fifthBehaviorSelected: true
        //         })
        //     )
        // }
    }

    CardContent = ({ card, setCard }) => {
        const { selectedGoal, selectedDepartment } = this.props;

        let filteredCard = card.coreGoalValue === selectedGoal && card.department === selectedDepartment;

        return (
            <GridItem xs={11}>
                {filteredCard ?
                    <CustomInput
                        labelText="What behavior would you like to add to this Value and Department?"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: card ? card.behavior : '',
                            required: false,
                            type: "text",
                            onChange: (event) => setCard({ behavior: event.target.value }),
                        }}
                    />
                    :
                    null
                }
            </GridItem>
        );
    }

    render() {
        const { classes, cards, setCards, setCard, submit, submitted, uid, authUser, departments, coreGoalsValues, selectedGoal, selectedDepartment, coreDepartmentBehaviors } = this.props;

        // if (!this.state.selectedGoal && !this.state.selectedDepartment) {
        //     this.updateState(selectedGoal, selectedDepartment)
        // }

        const resetRequest = () => {
            window.location.reload();
        }

        const filteredResults = coreDepartmentBehaviors.find((coreDepartmentBehavior) => {
            let filteredResult = coreDepartmentBehavior.coreGoalValue == selectedGoal && coreDepartmentBehavior.department == selectedDepartment
            if (filteredResult) {
                this.state.filteredCoreDepartmentBehaviors.push(coreDepartmentBehavior)
            }
            return filteredResult
        })

        return (
            <div>
                {console.log("checking behavior value:", this.props.coreDepartmentBehaviors)}
                {this.state.submitted ? (
                    <GridContainer justify="center">
                        <GridItem xs={11}>
                            {resetRequest()}
                            <h4 className={classes.textPrimary}>Behavior Set!</h4>
                        </GridItem>
                    </GridContainer>
                ) : (
                    <div className={classes.formContainer}>
                        <GridContainer justify="center">
                            <GridContainer>
                                <GridItem xs={2}>
                                    <h6 style={{ color: "#50c6bc", fontWeight: "600" }}>CORE/GOAL VALUES</h6>
                                </GridItem>
                                <GridItem xs={3}>
                                    <FormControl
                                        disabled
                                        className={classes.formControl}>
                                        <Select
                                            style={{ width: "200px" }}
                                            disableUn
                                            MenuProps={{
                                                className: classes.selectMenu
                                            }}
                                            classes={{
                                                formControl: classes.addUserInput
                                            }}
                                            onChange={(event) => {
                                                // this.setState({
                                                //     selectedGoal: event.target.value
                                                // })
                                            }}
                                            value={selectedGoal}
                                        >
                                            {coreGoalsValues.map((value, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelectedMultiple
                                                        }}
                                                        value={value}
                                                    >
                                                        {value}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={2}>
                                    <h6 style={{ color: "#50c6bc", fontWeight: "600" }}>DEPARTMENT</h6>
                                </GridItem>
                                <GridItem xs={3}>
                                    <FormControl disabled className={classes.formControl}>
                                        <Select
                                            style={{ width: "200px" }}
                                            disableUn
                                            MenuProps={{
                                                className: classes.selectMenu
                                            }}
                                            classes={{
                                                formControl: classes.addUserInput
                                            }}
                                            value={selectedDepartment}
                                            onChange={(event) => {
                                                // this.setState({
                                                //     selectedDepartment: event.target.value
                                                // })
                                            }}
                                        >
                                            {departments.map((department, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelectedMultiple
                                                        }}
                                                        value={department.text}
                                                    >
                                                        {department.text}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            {/* {this.state.filteredCoreDepartmentBehaviors.length != 0 ? */}
                            <div>
                                {console.log("finding filteredcore department behavior", this.state.filteredCoreDepartmentBehaviors)}
                                <GridContainer>
                                    <GridItem xs={12} style={{ width: "600px" }}>
                                        {console.log('filtered result within method', this.state.filteredCoreDepartmentBehaviors)}
                                        <CustomInput
                                            labelText="What behavior would you like to add to this Core/Goal Value and Department?"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                // defaultValue: this.state.filteredCoreDepartmentBehaviors[0].behavior, 
                                                defaultValue: "",
                                                required: true,
                                                type: "text",
                                                onChange: (event) => this.updateFirstBehavior(event)
                                            }}

                                        />
                                    </GridItem>
                                    {this.state.secondBehaviorSelected ?
                                        <GridItem xs={12} style={{ width: "600px" }}>
                                            <CustomInput
                                                labelText="What second behavior would you like to add to this Core/Goal Value and Department?"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    defaultValue: this.state.filteredCoreDepartmentBehaviors[0].secondBehavior,
                                                    required: true,
                                                    type: "text",
                                                    onChange: (event) => this.updateSecondBehavior(event)
                                                }}
                                            />
                                        </GridItem>
                                        :
                                        null
                                    }
                                    {this.state.thirdBehaviorSelected ?
                                        <GridItem xs={12} style={{ width: "600px" }}>
                                            <CustomInput
                                                labelText="What third behavior would you like to add to this Core/Goal Value and Department?"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    defaultValue: this.state.filteredCoreDepartmentBehaviors[0].thirdBehavior,
                                                    required: true,
                                                    type: "text",
                                                    onChange: (event) => this.updateThirdBehavior(event)
                                                }}
                                            />
                                        </GridItem>
                                        :
                                        null
                                    }
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Button
                                            onClick={() =>
                                                this.addUniqueNumOfBehaviors(authUser.uid, 'coreDepartmentBehaviors', {
                                                    coreGoalValue: selectedGoal,
                                                    department: selectedDepartment,
                                                    // behavior: this.state.behavior,
                                                    behaviors: [
                                                        // !this.state.secondBehaviorSelected ? this.state.firstBehavior : this.state.filteredCoreDepartmentBehaviors[0].firstBehavior,
                                                        // this.state.secondBehaviorSelected ? this.state.secondBehavior : this.state.filteredCoreDepartmentBehaviors[0].secondBehavior,
                                                        // this.state.thirdBehaviorSelected ? this.state.thirdBehavior : this.state.filteredCoreDepartmentBehaviors[0].thirdBehavior,
                                                    ],
                                                })
                                            }
                                            color="primary"
                                            style={{ display: "block", margin: "0 auto 0 auto" }}
                                        >
                                            Submit
                                        </Button>
                                    </GridItem>
                                    {!this.state.thirdBehaviorSelected ?
                                        <GridItem xs={6}>
                                            <Button
                                                onClick={() =>
                                                    this.additionalBehavior()
                                                }
                                                color="primary"
                                                style={{ display: "block", margin: "0 auto 0 auto" }}
                                            >
                                                Add another Behavior
                                            </Button>
                                        </GridItem>
                                        :
                                        <GridItem xs={6}>
                                            <Button
                                                disabled
                                                color="primary"
                                                style={{ display: "block", margin: "0 auto 0 auto" }}
                                            >
                                                Max of 3 Behaviors
                                            </Button>
                                        </GridItem>
                                    }
                                </GridContainer>
                            </div>
                            {/* // :
                                // <div>
                                //     <GridContainer>
                                //         <GridItem xs={12} style={{ width: "600px" }}>
                                //             {this.state.filteredCoreDepartmentBehaviors.length > 0 && this.state.filteredCoreDepartmentBehaviors[0].length > 0 ? console.log("testest works"): console.log("testest fails")}
                                //             <CustomInput
                                //                 labelText="What behavior would you like to add to this Core/Goal Value and Department?"
                                //                 formControlProps={{
                                //                     fullWidth: true
                                //                 }}
                                //                 inputProps={{
                                //                     defaultValue: "",
                                //                     required: true,
                                //                     type: "text",
                                //                     onChange: (event) => this.updateFirstBehavior(event)
                                //                 }}
                                //             />
                                //         </GridItem>
                                //         {this.state.secondBehaviorSelected ?
                                //             <GridItem xs={12} style={{ width: "600px" }}>
                                //                 <CustomInput
                                //                     labelText="What second behavior would you like to add to this Core/Goal Value and Department?"
                                //                     formControlProps={{
                                //                         fullWidth: true
                                //                     }}
                                //                     inputProps={{
                                //                         defaultValue: '',
                                //                         required: true,
                                //                         type: "text",
                                //                         onChange: (event) => this.updateSecondBehavior(event)
                                //                     }}
                                //                 />
                                //             </GridItem>
                                //             :
                                //             null
                                //         }
                                //         {this.state.thirdBehaviorSelected ?
                                //             <GridItem xs={12} style={{ width: "600px" }}>
                                //                 <CustomInput
                                //                     labelText="What third behavior would you like to add to this Core/Goal Value and Department?"
                                //                     formControlProps={{
                                //                         fullWidth: true
                                //                     }}
                                //                     inputProps={{
                                //                         defaultValue: '',
                                //                         required: true,
                                //                         type: "text",
                                //                         onChange: (event) => this.updateThirdBehavior(event)
                                //                     }}
                                //                 />
                                //             </GridItem>
                                //             :
                                //             null
                                //         }
                                //     </GridContainer>
                                //     <GridContainer>
                                //         <GridItem xs={6}>
                                //             <Button
                                //                 onClick={() =>
                                //                     this.addUniqueNumOfBehaviors(authUser.uid, 'coreDepartmentBehaviors', {
                                //                         coreGoalValue: selectedGoal,
                                //                         department: selectedDepartment,
                                //                         // behavior: this.state.behavior,
                                //                         behaviors: [
                                //                             // this.state.firstBehavior != "" ? this.state.firstBehavior : this.state.filteredCoreDepartmentBehaviors[0].firstBehavior,
                                //                             // this.state.secondBehavior != "" ? this.state.secondBehavior : this.state.filteredCoreDepartmentBehaviors[0].secondBehavior,
                                //                             // this.state.thirdBehavior != "" ? this.state.thirdBehavior : this.state.filteredCoreDepartmentBehaviors[0].thirdBehavior,
                                //                         ],
                                //                     })
                                //                 }
                                //                 color="primary"
                                //                 style={{ display: "block", margin: "0 auto 0 auto" }}
                                //             >
                                //                 Submit
                                //             </Button>
                                //         </GridItem>
                                //         {!this.state.thirdBehaviorSelected ?
                                //             <GridItem xs={6}>
                                //                 <Button
                                //                     onClick={() =>
                                //                         this.additionalBehavior()
                                //                     }
                                //                     color="primary"
                                //                     style={{ display: "block", margin: "0 auto 0 auto" }}
                                //                 >
                                //                     Add another Behavior
                                //                 </Button>
                                //             </GridItem>
                                //             :
                                //             <GridItem xs={6}>
                                //                 <Button
                                //                     disabled
                                //                     color="primary"
                                //                     style={{ display: "block", margin: "0 auto 0 auto" }}
                                //                 >
                                //                     Max of 3 Behaviors
                                //                 </Button>
                                //             </GridItem>
                                //         }
                                //     </GridContainer>
                                // </div>

                            // } */}

                        </GridContainer>
                    </div>
                )}
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.profile.user,
        cards: state.playbookList.coreDepartmentBehaviors.values,
        submitted: state.playbookList.coreDepartmentBehaviors.submitted,
        uid: state.auth.user.uid
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCards: list => dispatch(setList('coreDepartmentBehaviors', list)),
        setCard: (index, item) => dispatch(setListItem('coreDepartmentBehaviors', index, item)),
        submit: (uid, cards) => dispatch(startSaveList(uid, 'coreDepartmentBehaviors', cards)).then(() => dispatch(submit('departments')))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Form);