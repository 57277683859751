/*eslint-disable*/
import React, { useState, useCallback } from 'react';
import { DraggableCard } from './DraggableCard';
const style = {
    width: 1100
};

export default function DraggableCardContainer({ cards, setCards, setCard, renderContent, accountability }) {

        if (cards.length == 0) {
            cards = [{id: "1", text: " "}]
        }
        const moveCard = useCallback((dragIndex, hoverIndex) => {
            const dragCard = cards[dragIndex];
            const newCards = [ ...cards ];
            newCards.splice(dragIndex,1);
            newCards.splice(hoverIndex, 0, dragCard);
            setCards(newCards);
        }, [cards]);

        const renderCard = (card, index) => {
            return (
                <DraggableCard key={card.id} index={index} id={card.id} moveCard={moveCard}>
                    {renderContent({card, setCard: card=>setCard(index, card)})}
                </DraggableCard>
            );
        };

        return (<>
				<div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
			</>);

};