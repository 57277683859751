import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetThreadsList = (uid) => {
  return (dispatch) => {
    db.collection("TEST-users").doc(uid).collection("threads")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            id: doc.id,
            clientFirstName: docData.clientFirstName ? docData.clientFirstName : "N/A",
            clientLastName: docData.clientLastName ? docData.clientLastName : "N/A",
            createdDate: docData.createdDate ? docData.createdDate : "N/A",
            name: docData.name ? docData.name : "N/A"
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setThreadsList(data))
      })
  }
}

// Get Users for Users Panel
export const startGetCurrentThread = (uid, id) => {
    return (dispatch) => {
      db.collection("TEST-users").doc(uid).collection("threads").doc(id)
      .get().then((snapshot) => {
        var docData = snapshot.data()
        if (docData) {
        var docObj = {
            id: snapshot.id,
            clientFirstName: docData.clientFirstName ? docData.clientFirstName : "N/A",
            clientLastName: docData.clientLastName ? docData.clientLastName : "N/A",
          }
        return (docObj)
        } else {
            return(null)
        }
      }, (error) => {
          console.log('error fetching data: ', error)
        }).then(data => {        
          dispatch(getCurrentThread(data))
        })
    }
  }

export const setThreadsList = (dataRows) => ({
  type: 'SET_THREADS_LIST',
  dataRows
})

export const getCurrentThread = (dataRows) => ({
    type: 'GET_CURRENT_THREAD',
    dataRows
  })
