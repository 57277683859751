import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js"
import { Chart } from 'react-google-charts'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Assess from "assets/img/assess.png";
import Connect from "assets/img/connect.png";
import Grow from "assets/img/grow.png";
import Step1 from "assets/img/step1.png";
import Step2 from "assets/img/step2.png";
import Step3 from "assets/img/step3.png";
import Step4 from "assets/img/step4.png";
import Step5 from "assets/img/step5.png";
import Strategy from "assets/img/strategy.png";
import PlaybookIMG from "assets/img/playbook.png";
import DashboardIMG from "assets/img/dashboard.png";
import CheckMark from "assets/img/check.png";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import ReactDragListView from "react-drag-listview";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/playbookStyle.jsx";
import { Grid } from "@material-ui/core";

// Forms
import AccountabilityStructureForm from "components/Forms/AccountabilityStructureForm.jsx";
import BlogCommentForm from "components/Forms/BlogCommentForm.jsx";
import PurposeDeclarationForm from "components/Forms/PurposeDeclarationForm.jsx";
import PurposeOriginForm from "components/Forms/PurposeOriginForm.jsx";
import PurposeEnvisionedForm from "components/Forms/PurposeEnvisionedForm.jsx";
import StakeholderCommunityForm from "components/Forms/StakeholderCommunityForm.jsx";
import CoreValuesForm from "components/Forms/CoreValuesForm.jsx";
import PurposeMetricsForm from "components/Forms/PurposeMetricsForm.jsx";
import HealthMetricsForm from "components/Forms/HealthMetricsForm.jsx";
import TargetMarketForm from "components/Forms/TargetMarketForm.jsx";
import ValuePropositionForm from "components/Forms/ValuePropositionForm.jsx";
import KeyResourcesForm from "components/Forms/KeyResourcesForm.jsx";
import CustomerRelationshipsForm from "components/Forms/CustomerRelationshipsForm.jsx";
import KeyCompetenciesForm from "components/Forms/KeyCompetenciesForm.jsx";
import KeyPartnershipsForm from "components/Forms/KeyPartnershipsForm.jsx";
import RevenueStreamsForm from "components/Forms/RevenueStreamsForm.jsx";
import CostStructureForm from "components/Forms/CostStructureForm.jsx";
import KeyActivitiesForm from "components/Forms/KeyActivitiesForm.jsx";
import KeyChannelsForm from "components/Forms/KeyChannelsForm.jsx";
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import { setFirstAssessment } from "store/actions/assessmentActions"
import { startSetUsersList } from "store/actions/adminActions"
import { startSetPracticesList } from "store/actions/practicesActions"

import pathImg from "assets/img/path/path-image1.png"
import maturityLevel from "assets/img/path/MaturityLevel.png"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Playbook extends React.Component {
  constructor(props) {
    super(props);
    const data = [];
    for (let i = 1, len = 7; i < len; i++) {
      data.push({
        title: `rows${i}`
      });
    }
    this.state = {
      data,
      dataLoaded: false,
      accountabilityStructureModal: false,
      coreValuesModal: false,
      purposeDeclarationModal: false,
      purposeOriginModal: false,
      purposeEnvisionedModal: false,
      stakeholderCommunityModal: false,
      purposeMetricsModal: false,
      healthMetricsModal: false,
      targetMarketModal: false,
      valuePropositionModal: false,
      keyResourcesModal: false,
      customerRelationshipsModal: false,
      keyCompetenciesModal: false,
      keyPartnershipsModal: false,
      revenueStreamsModal: false,
      costStructureModal: false,
      keyActivitiesModal: false,
      keyChannelsModal: false
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = async (uid) => {
    try {
      this.setState({
        dataLoaded: true
      })
    } catch (err) { console.log(err) }

  }

  render() {
    const { authUser, classes, user } = this.props;

    if (!!authUser && user.dataLoaded
      && !this.state.dataLoaded) {
      console.log(user)
      this.loadData(authUser.uid)
    }

    if (user.termGroups) {
      console.log(user.termGroups.length)

      if (user.termGroups.length < 2) {
        return <Redirect to='/profile' />
      }
    }

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.data];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
      },
      nodeSelector: 'li',
      handleSelector: 'a'
    };

    return (
      <div>
        <iframe src="https://www.leadfrompurpose.com/programs/" width="111%" style={{margin: "-190px 0px 0px -4.75%"}} height="975"></iframe>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    practices: state.practices.practices
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSetPracticesList: dispatch(startSetPracticesList())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Playbook);