import React from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// actions
import { emailSignIn, startGoogleLogin, passwordReset } from "../../store/actions/authActions"

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      passwordNotice: null,
      authInfo: {
        email: '',
        password: ''
      }
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.emailSignIn(this.state.authInfo)
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      authInfo: {
        ...this.state.authInfo,
        [e.target.id]: e.target.value
      }
    })
  }

  handlePasswordReset = (e) => {
    e.preventDefault()
    passwordReset(this.state.authInfo.email)
    this.setState({
      ...this.state,
      passwordNotice: "Password reset link sent. Please check your email."
    })
  }

  render() {
    const { authError, classes, startGoogleLogin } = this.props;
    const { passwordNotice } = this.state
    console.log(this.state)
    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} className={classes.formContainer}>
            <form onSubmit={this.handleSubmit}>
              <Card login className={classes[this.state.cardAnimaton]} style={{backgroundColor: "#fff"}}>
                <CardHeader
                  className={classes.cardHeader}
                  // color="primary"
                >
                  <h4 style={{textAlign: "center"}} className={classes.cardTitle}>Log in</h4>
                  {/* <div className={classes.socialLine}>
                    <Button
                      color="transparent"
                      justIcon
                      onClick={startGoogleLogin}
                      className={classes.customButtonClass}
                    >
                      <i className="fab fa-google" />
                    </Button>
                  </div> */}
                </CardHeader>
                <CardBody >
                  <p style={{color: "#333"}} className={classes.textCenter}>New? <Link to="/pages/signup-page" className={classes.linkText}>Create an account now.</Link></p>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.handleChange,
                      type: "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  {authError ? (
                    <div className={classes.justifyContentCenter}>
                      <p className={classes.textDanger}>Error: {authError.message}<br />Forgot your password?</p>
                      <Button
                        type="button"
                        onClick={this.handlePasswordReset}
                        color="danger"
                        simple size="lg"
                        block>
                        Click Here
                      </Button>
                      <p className={classes.textDanger}>to send a password reset email.</p>
                    </div>
                  ) : null}
                  {passwordNotice ? <p className={classes.textDanger}>{passwordNotice}</p> : null}
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    type="submit"
                    className={classes.button}
                  >
                    Let's Go
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error
  }
}

const mapDispatchToProps = (dispatch) => ({
  startGoogleLogin: () => dispatch(startGoogleLogin()),
  emailSignIn: (credentials) => dispatch(emailSignIn(credentials)),
  passwordReset: (email) => dispatch(passwordReset(email))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(LoginPage);
