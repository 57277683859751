import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { Chart } from 'react-google-charts';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/coreBeliefsStyle.jsx";
import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import { setFirstAssessment } from "store/actions/assessmentActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updatePhotoModal: false,
      learnDashboardModal: false
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }


  loadData = () => {
    this.setState({
      dataLoaded: true
    })

    if (this.props.assessments.assessments.length === 0) {
      setFirstAssessment(this.props.authUser)
      console.log("set action for " + this.props.authUser)
    }

  }
  loadContentData = (uid, id) => {
    let data = db.collection("memberPrinciplesPage")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          var docObj = {
            id: doc.id,
            ...docData
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        this.setState({
          contentData: data,
          contentDataLoaded: true,
          refresh: false
        })
      })
  }


  render() {
    const { AuthBool, classes, user, assessments } = this.props;

    let profilePhoto = DefaultProfile
    if (user.termGroups) {
      //console.log(user.termGroups.length)

      if (user.termGroups.length < 2) {
        return <Redirect to='/profile' />
      }
    }

    if (AuthBool) { // fixes freeze on logout
      if (user.dataLoaded) {
        user.profileURL ? (
          profilePhoto = user.profileURL
        ) : (
          // add link to profile photo to firestore
          profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
          // add profile photo to firebase storage        
          // update redux with link
        )
      }
    }

    let lastAssessmentPurposePercent
    //console.log(user)
    //console.log(assessments.dataLoaded)
    if (user.dataLoaded && assessments.dataLoaded
      && !this.state.dataLoaded) {
      //console.log("load data")
      this.loadData()
      // console.log(user)
      // console.log(user.levelUpdatedAt.toDate())
      // console.log(moment(user.levelUpdatedAt.toDate()).format('MM-DD-YYYY'))  
    }

    if (user.dataLoaded && !this.state.contentDataLoaded) {
      this.loadContentData()
    }

    //console.log(assessments.assessments.length)

    let chartData = [['Date', 'Actions', 'Strategy', 'Stakeholders', 'Purpose']];

    //console.log(assessments.assessments.length)
    for (var i = (assessments.assessments.length - 1); i > -1; i--) {
      //console.log(assessments.assessments[i])
      let assessmentData = [
        moment(Date(assessments.assessments[i].updatedAt)).format('MM-DD-YYYY'),
        (assessments.assessments[i].aaPercent * 100),
        (assessments.assessments[i].saPercent * 100),
        (assessments.assessments[i].sePercent * 100),
        (assessments.assessments[i].pePercent * 100)
      ]

      chartData.push(assessmentData);
    };

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          {this.state.dataLoaded && this.state.contentDataLoaded ? (
            <div>
              <GridContainer className={classes.tealBar} style={{ marginTop: "20px" }}>
                <GridItem xs={12} sm={12} md={12}>
                  <h2 className={classes.title}>
                    THE CORE BELIEFS OF PURPOSEFUL LEADERS</h2>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                    <h3 className={classes.subTitle}>
                      Purposeful leadership is built on three core beliefs:
                    </h3>
                </GridItem>
                <Card style={{paddingTop:"20px"}}>
                  <GridItem xs={12} >
                    <div style={{ fontSize: "18px" }}>
                      <b>1. The beliefs that humans are inherently social in nature and are driven to contribute to others; and that these two attributes are expressed through organizations that exist to solve a specific problem in the world.</b>
                      <br />
                      <br />
                      We call that solution its Common and Single Purpose. By ‘common’ we mean it is shared by others within the organization. By ‘single’ we mean it is the only purpose of the organization. In other words, organizations exist to fulfill on its Common and Single Purpose.
                      <br />
                      <br />
                      <b> 2. The belief that an organization’s success and ultimate survival is determined by its relationship with its stakeholder world, both internal and external to the organization.</b>
                      <br />
                      <br />
                      The more engaged and committed the stakeholders, the more successful the organization. The stakeholder world is the totalality of all of its stakeholders or individuals or groups of individuals inside and outside the organization that impact or are impacted by the organization’s actions. An organization’s ‘Stakeholder Community’ is a subset of the stakeholder world, which contains those stakeholders that have the most significant impact on its success, and are most impacted by the actions of the organization.
                      <br />
                      <br />
                      <b>3. The belief that when an organization expresses its common and single purpose in a way that is inspiring and important to its Stakeholder Community, it will have an opportunity to harness the power and resources of that community.</b>
                      <br />
                      <br />
                      When a purpose is clear and shared throughout an organization’s Stakeholder Community, research shows that the power of purpose to transform is unleashed.
                    </div>
                  </GridItem>

                  <GridItem xs={12} >

                    <a href='https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/mastering.pdf?alt=media&token=d834c752-0853-4af7-b9f3-b383da959ced' target="_blank">
                      <Button
                        className={classes.button}
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        DOWNLOAD MASTERING PURPOSEFUL LEADERSHIP
                      </Button>
                    </a>
                  </GridItem>
                </Card>
              </GridContainer>
              <GridContainer className={classes.tealBar} style={{ marginTop: "30px", marginBottom: "30px" }}>
                <GridItem xs={12} sm={12} md={12}>
                  <h2 className={classes.title}>
                    {this.state.contentData[6].content}
                  </h2>
                </GridItem>
              </GridContainer>
    
              <GridContainer>
                <GridItem xs={12}>
                  <h3 className={classes.subTitle}>
                    These are grouped into four areas:
                  </h3>
                </GridItem>
              </GridContainer>

              <Card>
                <GridContainer style={{marginLeft:"15px"}}>
                  <GridItem xs={12} sm={12} md={6}>
                    <img className={classes.img} src={this.state.contentData[2].image} />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <h2 style={{ fontWeight: "500" }}>{this.state.contentData[2].header}</h2><br />
                    <h4>{this.state.contentData[2].subheader}</h4>
                  </GridItem>
                </GridContainer>
              </Card>

              <Card>
                <GridContainer style={{marginLeft:"15px"}}>
                  <GridItem xs={12} sm={12} md={6}>
                    <h2 style={{ fontWeight: "500" }}>{this.state.contentData[3].header}</h2><br />
                    <h4>{this.state.contentData[3].subheader}</h4>


                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>

                    <img className={classes.img} src={this.state.contentData[3].image} />
                  </GridItem>
                </GridContainer>
              </Card>

              <Card>
                <GridContainer style={{marginLeft:"15px"}}>
                  <GridItem xs={12} sm={12} md={6}>
                    <img className={classes.img} src={this.state.contentData[4].image} />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>

                    <h2 style={{ fontWeight: "500" }}>{this.state.contentData[4].header}</h2><br />
                    <h4>{this.state.contentData[4].subheader}</h4>
                  </GridItem>
                </GridContainer>
              </Card>

              <Card>

                <GridContainer style={{marginLeft:"15px"}}>
                  <GridItem xs={12} sm={12} md={6}>
                    <h2 style={{ fontWeight: "500" }}>{this.state.contentData[5].header}</h2><br />
                    <h4>{this.state.contentData[5].subheader}</h4>


                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>

                    <img className={classes.img} src={this.state.contentData[5].image} />
                  </GridItem>
                </GridContainer>
                <a href={this.state.contentData[0].link} target="_blank">
                  <Button
                    className={classes.button}
                    style={{marginLeft:"15px", marginBottom:"25px"}}
                  >
                    {this.state.contentData[0].header}
                  </Button>
                </a>
              </Card>

            </div>
          ) : (
            <div className={classes.easeInOut}>
              <Hidden smDown implementation="css">
                <div className={classes.preloaderDesktop}
                  style={{
                    background: "white",
                    position: "absolute",
                    width: "110%",
                    margin: "-25% 0% 0% -8%",
                    height: "180%",
                    zIndex: "99999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 33%",
                      position: "relative",
                      width: "25%"
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "2% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
              <Hidden mdUp implementation="css">
                <div className={classes.preloaderMobile}
                  style={{
                    background: "white",
                    position: "absolute",
                    width: "110%",
                    margin: "-30% 0% 0% -10%",
                    height: "110%",
                    zIndex: "9999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 20%",
                      position: "relative",
                      width: "50%"
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "15% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
            </div>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Orders);