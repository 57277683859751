/*eslint-disable*/
import React, { useState } from "react";
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { Link } from "react-router-dom";
import { LoremIpsum } from "lorem-ipsum";
import moment from "moment"

const randomDate = moment(new Date(+(new Date()) - Math.floor(Math.random() * 100000)))
  .format('MM/DD/YYYY');

function generateRandomDate() {
  return new Date(+(new Date()) - Math.floor(Math.random() * 10000000000));
}


const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 16,
    min: 4
  }
});



var mockData = [

]




export function addNewMetric(uid, selectedYear, startingMonth, allMetricsData, finishedSettingRange) {
  return (dispatch) => {
    dispatch(setAddedMetricsData())
    dispatch(getMetricsData(uid, selectedYear))
    if (finishedSettingRange) {
      window.location.reload()
    }
  }
}

export function setAddedMetricsData() {
  return {
    type: 'SET_ADDEDMETRICSDATA'
  }
}

export function updateMetric(uid, selectedYear, updatedCurrentYearMetric, allCurrentYearData, updatedNextYearMetric, allNextYearData, years) {

  console.log('updatedNextYearMetric', 'updatedCurrentYearMetric', updatedCurrentYearMetric)


  var batch = db.batch()
  var secondBatch = db.batch()
  var thirdBatch = db.batch()
  console.log("testing actions uid: ", updatedCurrentYearMetric.id)
  const ref = db.collection("TEST-users").doc(uid).collection("metrics").doc(updatedCurrentYearMetric.id).collection('data')

  return (dispatch) => {
    // return db.collection("TEST-users").doc(uid).collection("metrics").doc(updatedCurrentYearMetric.id).collection('data').doc(selectedYear).set(updatedCurrentYearMetric)


    for (var x = 0; x < years.length; x++) {
      var docRef = ref.doc(years[x])
      batch.update(docRef, { metricType: updatedCurrentYearMetric.metricType, metricDescription: updatedCurrentYearMetric.metricDescription, measureDefinition: updatedCurrentYearMetric.measureDefinition, owner: updatedCurrentYearMetric.owner, fiveYearTarget: updatedCurrentYearMetric.fiveYearTarget, oneYearTarget: updatedCurrentYearMetric.oneYearTarget, quarterTarget: updatedCurrentYearMetric.quarterTarget, baseline: updatedCurrentYearMetric.baseline })

    }
    batch.commit()
      .then(() => {
        for (var key in updatedCurrentYearMetric) {
          if (key !== 'metricType' || key !== 'metricDescription' || key !== 'measureDefinition' || key !== 'owner' || key !== 'fiveYearTarget' || key !== 'oneYearTarget' || key !== 'quarterTarget' || key !== 'baseline') {
            secondBatch.update(ref.doc(selectedYear), { [key]: updatedCurrentYearMetric[key] })
          }
        }
        secondBatch.commit()
          .then(() => {
            if (updatedNextYearMetric) {
              var nextYear = parseInt(selectedYear)
              nextYear = nextYear + 1
              nextYear = nextYear.toString()

              for (var key in updatedNextYearMetric) {
                if (key !== 'metricType' || key !== 'metricDescription' || key !== 'measureDefinition' || key !== 'owner' || key !== 'fiveYearTarget' || key !== 'oneYearTarget' || key !== 'quarterTarget' || key !== 'baseline') {
                  thirdBatch.update(ref.doc(nextYear), { [key]: updatedNextYearMetric[key] })
                }
              }
              thirdBatch.commit()
                .then(() => {
                  dispatch(setUpdatedMetric(allCurrentYearData))
                  dispatch(setUpdatedNextYearMetric(allNextYearData))
                })
            } else {
              dispatch(setUpdatedMetric(allCurrentYearData))
            }
          })
      })
  }
}


export function setUpdatedNextYearMetric(allNextYearData) {
  return {
    type: "SET_UPDATED_NEXT_YEAR_METRIC",
    allNextYearData
  }
}

export function enableDisableMetric(uid, selectedYear, updatedMetric, allData, str) {
  var batch = db.batch()
  return (dispatch) => {
    if (str === 'range') {
      db.collection("TEST-users").doc(uid).collection("metrics").doc(updatedMetric.id).collection('data').get()
        .then((results) => {
          console.log('results', results)
          results = results.docs
          results.forEach((doc) => {
            var docId = doc.id
            doc = doc.data()
            doc.status = updatedMetric.status
            var docRef = db.collection("TEST-users").doc(uid).collection("metrics").doc(updatedMetric.id).collection('data').doc(docId)
            batch.set(docRef, doc)
          })
          batch.commit()
            .then(() => {
              dispatch(setEnabledDisabledMetrics(allData))
            })
        })

    } else {
      return db.collection("TEST-users").doc(uid).collection("metrics").doc(updatedMetric.id).collection('data').get()
        .then((results) => {
          console.log('results', results)
          results = results.docs
          results.forEach((doc) => {
            var docId = doc.id
            doc = doc.data()
            doc.status = updatedMetric.status
            var docRef = db.collection("TEST-users").doc(uid).collection("metrics").doc(updatedMetric.id).collection('data').doc(docId)
            batch.set(docRef, doc)
          })
          batch.commit()
            .then(() => {
              dispatch(setEnabledDisabledMetrics(allData))
            })
        })
    }
  }
}

export function setNewRowIndex(uid, selectedYear, direction, data, index) {
  // console.log('set new row index', uid, selectedYear, direction, data, index)
  console.log('dataatindex', data[index])
  var batch = db.batch()
  const ref = db.collection("TEST-users").doc(uid).collection("metrics")
  return (dispatch) => {
    // return ref.orderBy('positionInTable').get()
    //   .then((results) => {
    // results = results.docs
    var toReturn = []
    data.forEach((doc) => {
      console.log('set new row index', doc.id)
      var docRef = ref.doc(doc.id).collection('data').doc(selectedYear)
      batch.set(docRef, doc);
      toReturn.push(doc)
    })
    if (direction === 'up') {
      toReturn[index].positionInTable -= 1
      toReturn[index - 1].positionInTable += 1
      var currentRef = ref.doc(toReturn[index].id).collection('data').doc(selectedYear)
      var prevRef = ref.doc(toReturn[index - 1].id).collection('data').doc(selectedYear)
      batch.set(currentRef, toReturn[index]);
      batch.set(prevRef, toReturn[index - 1]);
    }
    if (direction === 'down') {
      toReturn[index].positionInTable += 1
      toReturn[index + 1].positionInTable -= 1
      var currentRef = ref.doc(toReturn[index].id).collection('data').doc(selectedYear)
      var nextRef = ref.doc(toReturn[index + 1].id).collection('data').doc(selectedYear)
      batch.set(currentRef, toReturn[index]);
      batch.set(nextRef, toReturn[index + 1]);
    }
    batch.commit()
      .then(() => {
        dispatch(setUpdatedMetric(data))
      })
    // })
    // .catch((err) => {
    //   console.log('err', err)
    // })

  }
}

export function changeHeadersActions(value) {

  console.log('valueeee', value)
  return (dispatch) => {
    if (value === 'January') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", actions: "Actions" }

      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", actions: "Actions" }

      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'February') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'March') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'April') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'May') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", actions: "Actions" }

      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", actions: "Actions" }

      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'June') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'July') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'August') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'quarterTarget', baseline: "Baseline", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'September') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", sep: "Sep", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'October') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", oct: "Oct", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'November') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'quarterTarget', baseline: "Baseline", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", nov: "Nov", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
    if (value === 'December') {
      var recentColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", owner: "Owner", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", quarterTarget: 'Quarter Target', baseline: "Baseline", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", actions: "Actions" }
      var yearlyColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", actions: "Actions" }
      // var dataDownloadColumnHeaders = { metricType: "Metric Type", metricDescription: "Metric Description", measureDefinition: "Measure Defintion", fiveYearTarget: "5 Year Target", oneYearTarget: "1 Year Target", baseline: "Baseline", dec: "Dec", jan: "Jan", feb: "Feb", mar: "Mar", apr: "Apr", may: "May", jun: "Jun", jul: "Jul", aug: "Aug", sep: "Sep", oct: "Oct", nov: "Nov", status: "Status", actions: "Actions" }
      dispatch(setChangedHeaders(recentColumnHeaders, yearlyColumnHeaders))
    }
  }

}

export function setChangedHeaders(recent, yearly) {
  return {
    type: "SET_CHANGED_HEADERS",
    recent,
    yearly
  }
}
export function setEnabledDisabledMetrics(data) {
  return {
    type: "SET_ENABLED_DISABLED_METRICS",
    data
  }
}

export function setCurrentlyDeleting(boolean) {
  return {
    type: "SET_CURRENTLYDELETING",
    boolean
  }
}

export function deleteMetric(uid, selectedYear, deletedMetric, toReturn, currentDashboardStartingMonth, allData, metricsIdsArray) {
  console.log('toReturntoReturn', toReturn)
  var batch = db.batch()
  return (dispatch) => {
    dispatch(setCurrentlyDeleting(true))
    return db.collection("TEST-users").doc(uid).collection("cache").doc('mapping').collection('ids').doc('ids').get()
      .then((results) => {
        results = results.data().ids
        for (var x = 0; x < results.length; x++) {
          if (results[x] === deletedMetric.id) {
            results.splice(x, 1)
          }
        }
        const idsToReturn = results
        return db.collection("TEST-users").doc(uid).collection("cache").doc('mapping').collection('ids').doc('ids').update({ ids: results })
          .then(() => {
            const ref = db.collection("TEST-users").doc(uid).collection("metrics").doc(deletedMetric.id).collection('data')
            ref.onSnapshot((snapshot) => {
              snapshot.docs.forEach((doc) => {
                ref.doc(doc.id).delete()
              })
            })

            toReturn.forEach((doc) => {
              var docRef = db.collection("TEST-users").doc(uid).collection("metrics").doc(doc.id).collection('data').doc(selectedYear)
              batch.set(docRef, doc)
            })

            batch.commit()
              .then(() => {
                dispatch(setDeletedMetrics(toReturn, idsToReturn))
              })

          })
      })

  }
}

export function setUpdatedRange(data) {
  return {
    type: "SET_UPDATED_RANGE",
    data
  }
}



export function getMetricsData(uid, selectedYear) {
  console.log('originalgetrunning')
  return (dispatch) => {

    return db.collection("TEST-users").doc(uid).collection("cache").doc('mapping').collection('ids').doc('ids').get()
      .then((response) => {
        if (response.exists && response.data().ids.length > 0) {
          return db.collection("TEST-users").doc(uid).collection('cache').doc('monthToRemember').get()
            .then((snapshot) => {
              snapshot = snapshot.data()
              console.log('monthtorememsnapshot', snapshot)


              let monthToStartFilter = 'January'
              // let monthToStartFilter = snapshot.monthToRemember[snapshot.monthToRemember.length - 1]



              // may need some error handling for if they never filled in a month
              var abbreviatedMonth = monthToStartFilter.slice(0, 3).toLowerCase()

              var idsArray = response.data().ids
              var documentArray = []
              var nextYearsDocumentArray = []
              for (var x = 0; x < idsArray.length; x++) {
                var idToSearchFor = idsArray[x]
                var document = db.collection("TEST-users").doc(uid).collection('metrics').doc(idToSearchFor).collection('data').doc(selectedYear).get()
                  .then((results) => {
                    var data = results.data()
                    documentArray.push(data)
                    documentArray.sort((a, b) => {
                      return a.positionInTable - b.positionInTable;
                    });
                    dispatch(setMetricsData(documentArray, monthToStartFilter, idsArray))
                  })
              }

              // need to add an api call for every time the year changes


              var nextYear = parseInt(selectedYear)
              nextYear = nextYear + 1
              nextYear = nextYear.toString()
              for (var y = 0; y < idsArray.length; y++) {
                var idToSearchFor = idsArray[y]
                var document = db.collection("TEST-users").doc(uid).collection('metrics').doc(idToSearchFor).collection('data').doc(nextYear).get()
                  .then((results) => {
                    var data = results.data()
                    nextYearsDocumentArray.push(data)
                    nextYearsDocumentArray.sort((a, b) => {
                      return a.positionInTable - b.positionInTable;
                    });
                    dispatch(setNextYearsMetricData(nextYearsDocumentArray))
                    // dispatch(setDataLoaded())
                  })
              }
              dispatch(setNextYearsMetricDataLoaded())

            })
        } else {
          dispatch(setMetricsData([], "January"))
        }
      })
  }
}



export function getRangeOfYearsMetricData(uid, yearStart, yearEnd, metricsIdsArray, allData) {

  var yearsRangeArray = Array.from(Array(parseInt(yearEnd) + 1 - parseInt(yearStart)), (_, i) => (i + parseInt(yearStart)).toString())

  console.log('documentArrayxxx', yearStart, yearEnd, metricsIdsArray, allData)

  return (dispatch) => {
    if (yearStart === yearEnd) {
      dispatch(finishedSettingRange(false))
      dispatch(setMetricsData(allData, "January", metricsIdsArray))
    } else {
      dispatch(finishedSettingRange(false))
      var documentArray = []
      var idsObject = {}
      console.log('documentArray', documentArray)
      for (var x = 0; x < metricsIdsArray.length; x++) {
        const idToSearchFor = metricsIdsArray[x]
        for (var y = 0; y < yearsRangeArray.length; y++) {
          var document = db.collection("TEST-users").doc(uid).collection('metrics').doc(idToSearchFor).collection('data').doc(yearsRangeArray[y]).get()
            .then((results) => {
              const id = results.id
              console.log("testing getrangeyear: ", results.data())
              var data = results.data()
              data.id = idToSearchFor
              for (var key in data) {
                if (key === 'jan' || key === 'feb' || key === 'mar' || key === 'apr' || key === 'may' || key === 'jun' || key === 'jul' || key === 'aug' || key === 'sep' || key === 'oct' || key === 'nov' || key === 'dec') {
                  var newKey = key + data.year.slice(2)
                  // var newlyAddedKey = key + nextDoc.year.slice(2)
                  data[newKey] = data[key]
                  delete data[key]
                  // nextDoc[newlyAddedKey] = nextDoc[key]
                }
              }


              documentArray.push(data)
              documentArray.sort((a, b) => {
                return a.positionInTable - b.positionInTable;
              });
              dispatch(setRangeOfYearsMetricData(documentArray, yearsRangeArray.length))
              console.log('documentArrayxxx', documentArray)

            })
        }
      }
      dispatch(finishedSettingRange(true))
    }
  }
}

export function setDataLoaded() {
  return {
    type: "SET_DATA_LOADED"
  }
}


export function getCurrentDashboardExportData(data, startingMonth) {
  console.log('chekcingdataaaax', data)
  return (dispatch) => {
    var currentTableDataCSV = [['Metric Type', 'Metric Description', 'Measure Definition', 'Owner', '5 Year Target', '1 Year Target', 'Quarter Target', 'Baseline', startingMonth]]
    for (var x = 0; x < data.length; x++) {
      var current = data[x]
      if (current.status === 'Active') {
        currentTableDataCSV.push([current.metricType, current.metricDescription, current.measureDefinition, current.owner, current.fiveYearTarget, current.oneYearTarget, current.quarterTarget, current.baseline, current[startingMonth.slice(0, 3).toLowerCase()]])
        // console.log('dataToReturn', current[props.monthToStartFilter.slice(0,3).toLowerCase()])
      }
    }
    dispatch(setCurrentDashboardExportData(currentTableDataCSV))
    console.log('currentTableDataCSV', currentTableDataCSV)
  }
}

export function setCurrentDashboardExportData(csvData) {
  return {
    type: "SET_CURRENT_DASHBOARD_EXPORT_DATA",
    csvData
  }
}


export function getDataDownloadExportData(fromYear, toYear, data, exportMetricStatuses, dataDownloadColumnHeaders, str) {
  console.log('getDataDownloadExportData', fromYear, toYear, 'data:', data, exportMetricStatuses)
  var dataDownloadTableDataCSV = [['Metric Type', 'Metric Description', 'Owner', 'Measure Definition', '5 Year Target', '1 Year Target', 'Quarter Target', 'Baseline']]

  var newData = []
  for (var x = 0; x < data.length; x++) {
    if (exportMetricStatuses === 'both') {
      newData.push(data[x])
    }
    if (exportMetricStatuses === 'active') {
      if (data[x].status === 'Active') {
        newData.push(data[x])
      }
    }
    if (exportMetricStatuses === 'inactive') {
      if (data[x].status === 'Inactive') {
        newData.push(data[x])
      }
    }
  }

  var justMonths = []

  for (var key in dataDownloadColumnHeaders) {
    if (key === 'jan' || key === 'feb' || key === 'mar' || key === 'apr' || key === 'may' || key === 'jun' || key === 'jul' || key === 'aug' || key === 'sep' || key === 'oct' || key === 'nov' || key === 'dec') {
      dataDownloadTableDataCSV[0].push(key.slice(0, 1).toUpperCase() + key.slice(1) + '-' + fromYear.slice(2))
      justMonths.push(key)
    }
  }


  if (str === 'range') {
    var currentYear = fromYear.slice(2)
    var metricKeysArray = Object.keys(dataDownloadColumnHeaders)

    // make this a funciton to push recursivley
    var array = []
    // const recursiveFunction = () => {
    //   if (parseInt(currentYear) > parseInt(fromYear.slice(2))) {
    //     console.log('ending function')
    //   } else {
    var needToIterateAgain = true
    for (var i = 0; i < newData.length; i++) {
      var current = newData[i];
      console.log('currentcurrentcurrent', current)
      dataDownloadTableDataCSV.push([current.metricType, current.metricDescription, current.measureDefinition, current.owner, current.fiveYearTarget, current.oneYearTarget, current.quarterTarget, current.baseline, current[justMonths[0] + currentYear], current[justMonths[1] + currentYear], current[justMonths[2] + currentYear], current[justMonths[3] + currentYear], current[justMonths[4] + currentYear], current[justMonths[5] + currentYear], current[justMonths[6] + currentYear], current[justMonths[7] + currentYear], current[justMonths[8] + currentYear], current[justMonths[9] + currentYear], current[justMonths[10] + currentYear], current[justMonths[11] + currentYear]])
      var indexToRemember = dataDownloadTableDataCSV.length - 1

      const recursiveAgain = (yearToIncrease) => {
        var newCurrentYear = parseInt(yearToIncrease) + 1
        newCurrentYear = newCurrentYear.toString()
        if (current[justMonths[0] + newCurrentYear]) {
          dataDownloadTableDataCSV[0] = [...dataDownloadTableDataCSV[0], justMonths[0].charAt(0).toUpperCase() + justMonths[0].slice(1) + '-' + newCurrentYear, justMonths[1].charAt(0).toUpperCase() + justMonths[1].slice(1) + '-' + newCurrentYear, justMonths[2].charAt(0).toUpperCase() + justMonths[2].slice(1) + '-' + newCurrentYear, justMonths[3].charAt(0).toUpperCase() + justMonths[3].slice(1) + '-' + newCurrentYear, justMonths[4].charAt(0).toUpperCase() + justMonths[4].slice(1) + '-' + newCurrentYear, justMonths[5].charAt(0).toUpperCase() + justMonths[5].slice(1) + '-' + newCurrentYear, justMonths[6].charAt(0).toUpperCase() + justMonths[6].slice(1) + '-' + newCurrentYear, justMonths[7].charAt(0).toUpperCase() + justMonths[7].slice(1) + '-' + newCurrentYear, justMonths[8].charAt(0).toUpperCase() + justMonths[8].slice(1) + '-' + newCurrentYear, justMonths[9].charAt(0).toUpperCase() + justMonths[9].slice(1) + '-' + newCurrentYear, justMonths[10].charAt(0).toUpperCase() + justMonths[10].slice(1) + '-' + newCurrentYear, justMonths[11].charAt(0).toUpperCase() + justMonths[11].slice(1) + '-' + newCurrentYear]
          dataDownloadTableDataCSV[0] = new Set(dataDownloadTableDataCSV[0])
          dataDownloadTableDataCSV[0] = Array.from(dataDownloadTableDataCSV[0])


          dataDownloadTableDataCSV[indexToRemember] = [...dataDownloadTableDataCSV[indexToRemember], current[justMonths[0] + newCurrentYear], current[justMonths[1] + newCurrentYear], current[justMonths[2] + newCurrentYear], current[justMonths[3] + newCurrentYear], current[justMonths[4] + newCurrentYear], current[justMonths[5] + newCurrentYear], current[justMonths[6] + newCurrentYear], current[justMonths[7] + newCurrentYear], current[justMonths[8] + newCurrentYear], current[justMonths[9] + newCurrentYear], current[justMonths[10] + newCurrentYear], current[justMonths[11] + newCurrentYear]]
          var yearCheck = parseInt(newCurrentYear)
          yearCheck++
          yearCheck = yearCheck.toString()
          console.log('arrayarrayxxxxx', 'yearCheck', yearCheck)
          if (current[justMonths[0] + yearCheck]) {
            recursiveAgain(newCurrentYear)
          }
        }
      }
      recursiveAgain(currentYear)
    }
    // currentYear = parseInt(currentYear)
    // currentYear++
    // currentYear = currentYear.toString()
    // recursiveFunction()
    //   }
    // }
    // recursiveFunction()
    console.log('arrayarrayxxxxx', dataDownloadTableDataCSV)





  } else {

    for (var x = 0; x < newData.length; x++) {
      var current = newData[x]
      dataDownloadTableDataCSV.push([current.metricType, current.metricDescription, current.measureDefinition, current.owner, current.fiveYearTarget, current.oneYearTarget, current.quarterTarget, current.baseline, current[justMonths[0]], current[justMonths[1]], current[justMonths[2]], current[justMonths[3]], current[justMonths[4]], current[justMonths[5]], current[justMonths[6]], current[justMonths[7]], current[justMonths[8]], current[justMonths[9]], current[justMonths[10]], current[justMonths[11]]])
    }





    console.log('newDataxxxx', dataDownloadTableDataCSV)

  }




  return (dispatch) => {
    dispatch(setDataDownloadExportData(dataDownloadTableDataCSV))
  }
}

export function setDataDownloadExportData(csvData) {
  return {
    type: "SET_DATA_DOWNLOAD_DASHBOARD_EXPORT_DATA",
    csvData
  }
}


export function getYearlyDashboardExportData(data, startingMonth, yearlyColumnHeaders, year, nextYearData) {
  var yearlyTableDataCSV = [['Metric Type', 'Metric Description']]

  if (nextYearData) {

    var justMonths = []

    for (var key in yearlyColumnHeaders) {
      if (key === 'jan' || key === 'feb' || key === 'mar' || key === 'apr' || key === 'may' || key === 'jun' || key === 'jul' || key === 'aug' || key === 'sep' || key === 'oct' || key === 'nov' || key === 'dec') {
        // recentTableDataCSV[0].push(key.slice(0, 1).toUpperCase() + key.slice(1) + '-' + year.slice(2))
        justMonths.push(key)
      }
    }

    console.log('sortMonths', justMonths)


    var decemberIndex;
    for (var y = 0; y < justMonths.length; y++) {
      if (justMonths[y] === 'dec') {
        decemberIndex = y
      }
    }

    var nextYear = parseInt(year) + 1
    nextYear = nextYear.toString()

    console.log('topushhhhh', 'nextYear', nextYear)



    for (var x = 0; x < data.length; x++) {
      var toPush = []
      var current = data[x]
      if (current.status === 'Active') {
        toPush.push(current.metricType)
        toPush.push(current.metricDescription)
        for (var z = 0; z < justMonths.length; z++) {
          if (z <= decemberIndex) {
            toPush.push(current[justMonths[z]])
            yearlyTableDataCSV[0].push(justMonths[z].slice(0, 1).toUpperCase() + justMonths[z].slice(1) + '-' + year.slice(2))
          } else {
            for (var i = 0; i < nextYearData.length; i++) {
              var nextYearCurrent = nextYearData[i]
              toPush.push(nextYearCurrent[justMonths[z]])
              yearlyTableDataCSV[0].push(justMonths[z].slice(0, 1).toUpperCase() + justMonths[z].slice(1) + '-' + nextYear.slice(2))
            }

          }
        }
        yearlyTableDataCSV.push(toPush)
      }

    }


  } else {
    var justMonths = []

    for (var key in yearlyColumnHeaders) {
      if (key === 'jan' || key === 'feb' || key === 'mar' || key === 'apr' || key === 'may' || key === 'jun' || key === 'jul' || key === 'aug' || key === 'sep' || key === 'oct' || key === 'nov' || key === 'dec') {
        yearlyTableDataCSV[0].push(key.slice(0, 1).toUpperCase() + key.slice(1) + '-' + year.slice(2))
        justMonths.push(key)
      }
    }

    for (var x = 0; x < data.length; x++) {
      var current = data[x]
      console.log('yearlyColumnHeaders', current[justMonths[11]])
      yearlyTableDataCSV.push([current.metricType, current.metricDescription, current[justMonths[0]], current[justMonths[1]], current[justMonths[2]], current[justMonths[3]], current[justMonths[4]], current[justMonths[5]], current[justMonths[6]], current[justMonths[7]], current[justMonths[8]], current[justMonths[9]], current[justMonths[10]], current[justMonths[11]]])
    }

  }


  return (dispatch) => {
    dispatch(setYearlyDashboardExportData(yearlyTableDataCSV))
  }
}

export function setYearlyDashboardExportData(csvData) {
  return {
    type: "SET_YEARLY_DASHBOARD_EXPORT_DATA",
    csvData
  }
}




export function getRecentDashboardExportData(data, startingMonth, recentColumnHeaders, year, nextYearData) {
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

  var recentTableDataCSV = [['Metric Type', 'Metric Description', 'Measure Definition', 'Owner', '5 Year Target', '1 Year Target', 'Quarter Target', 'Baseline']]


  console.log('justMonths', startingMonth)
  console.log('justMonths', nextYearData)



  if (nextYearData) {

    const monthNames = {
      "jan": 1,
      "feb": 2,
      "mar": 3,
      "apr": 4,
      "may": 5,
      "jun": 6,
      "jul": 7,
      "aug": 8,
      "sep": 9,
      "oct": 10,
      "nov": 11,
      "dec": 12
    };


    var justMonths = []

    for (var key in recentColumnHeaders) {
      if (key === 'jan' || key === 'feb' || key === 'mar' || key === 'apr' || key === 'may' || key === 'jun' || key === 'jul' || key === 'aug' || key === 'sep' || key === 'oct' || key === 'nov' || key === 'dec') {
        // recentTableDataCSV[0].push(key.slice(0, 1).toUpperCase() + key.slice(1) + '-' + year.slice(2))
        justMonths.push(key)
      }
    }

    console.log('sortMonths', justMonths)


    var decemberIndex;
    for (var y = 0; y < justMonths.length; y++) {
      if (justMonths[y] === 'dec') {
        decemberIndex = y
      }
    }

    var nextYear = parseInt(year) + 1
    nextYear = nextYear.toString()

    console.log('topushhhhh', 'nextYear', nextYear)



    for (var x = 0; x < data.length; x++) {
      var toPush = []
      var current = data[x]
      if (current.status === 'Active') {
        toPush.push(current.metricType)
        toPush.push(current.metricDescription)
        toPush.push(current.measureDefinition)
        toPush.push(current.owner)
        toPush.push(current.fiveYearTarget)
        toPush.push(current.oneYearTarget)
        toPush.push(current.quarterTarget)
        toPush.push(current.baseline)
        for (var z = 0; z < justMonths.length; z++) {
          if (z <= decemberIndex) {
            toPush.push(current[justMonths[z]])
            recentTableDataCSV[0].push(justMonths[z].slice(0, 1).toUpperCase() + justMonths[z].slice(1) + '-' + year.slice(2))
          } else {
            for (var i = 0; i < nextYearData.length; i++) {
              var nextYearCurrent = nextYearData[i]
              toPush.push(nextYearCurrent[justMonths[z]])
              recentTableDataCSV[0].push(justMonths[z].slice(0, 1).toUpperCase() + justMonths[z].slice(1) + '-' + nextYear.slice(2))
            }

          }
        }
        recentTableDataCSV.push(toPush)
      }
    }


    console.log('topushhhhh', recentTableDataCSV)



  } else {
    var justMonths = []

    for (var key in recentColumnHeaders) {
      if (key === 'jan' || key === 'feb' || key === 'mar' || key === 'apr' || key === 'may' || key === 'jun' || key === 'jul' || key === 'aug' || key === 'sep' || key === 'oct' || key === 'nov' || key === 'dec') {
        recentTableDataCSV[0].push(key.slice(0, 1).toUpperCase() + key.slice(1) + '-' + year.slice(2))
        justMonths.push(key)
      }
    }



    for (var x = 0; x < data.length; x++) {
      var current = data[x]
      if (current.status === 'Active') {
        recentTableDataCSV.push([current.metricType, current.metricDescription, current.measureDefinition, current.owner, current.fiveYearTarget, current.oneYearTarget, current.quarterTarget, current.baseline, current[justMonths[0]], current[justMonths[1]], current[justMonths[2]], current[justMonths[3]], current[justMonths[4]], current[justMonths[5]]])
      }
    }
  }



  return (dispatch) => {
    dispatch(setRecentDashboardExportData(recentTableDataCSV))
  }
}

export function setRecentDashboardExportData(csvData) {
  return {
    type: "SET_RECENT_DASHBOARD_EXPORT_DATA",
    csvData
  }
}


export function updateRangeOfYearsMetricData(uid, dataObj, allRangeData, updatedData) {
  var recursiveCount = 0
  var batch = db.batch()
  return (dispatch) => {
    console.log('yearsCheck', dataObj)
    for (var key in dataObj) {
      if (parseInt(key)) {
        var yearString = '20' + key
        var current = dataObj[key]
        for (var keys in current) {
          db.collection("TEST-users").doc(uid).collection('metrics').doc(updatedData.id).collection('data').doc(yearString).update({ [keys]: current[keys] })

        }
        delete dataObj[key]
        // can now add to year collection by adding 20 to key just update!
        // then change any other for all of the documents
      }
    }

    return db.collection("TEST-users").doc(uid).collection('metrics').doc(updatedData.id).collection('data').get()
      .then((results) => {
        results = results.docs
        results.forEach((doc) => {
          var docId = doc.id
          doc = doc.data()
          for (var keys in dataObj) {
            if (!parseInt(keys)) {
              console.log(dataObj[keys])
              doc[keys] = dataObj[keys]
            }
          }
          var docRef = db.collection("TEST-users").doc(uid).collection("metrics").doc(updatedData.id).collection('data').doc(docId)
          batch.set(docRef, doc)
        })
        batch.commit()
          .then(() => {
            dispatch(setRangeAndAllMetricData())
          })
      })


  }
}

export function setRangeAndAllMetricData(allData, rangeData) {
  return {
    type: "SET_RANGE_AND_ALL_METRIC_DATA",

  }
}


export function setRangeOfYearsMetricData(documentArray, yearsQty) {
  return {
    type: "SET_RANGE_OF_YEARS_METRIC_DATA",
    range: documentArray,
    yearsQty
  }
}

export function finishedSettingRange(boolean) {
  return {
    type: "FINISHED_SETTING_RANGE",
    boolean
  }
}








export function getNewYearMetrics(uid, newYear, metricsIdsArray, monthToStartFilter) {
  return (dispatch) => {

    var documentArray = []

    for (var x = 0; x < metricsIdsArray.length; x++) {
      var idToSearchFor = metricsIdsArray[x]
      var document = db.collection("TEST-users").doc(uid).collection('metrics').doc(idToSearchFor).collection('data').doc(newYear).get()
        .then((results) => {
          var data = results.data()
          documentArray.push(data)
          documentArray.sort((a, b) => {
            return a.positionInTable - b.positionInTable;
          });
          dispatch(setMetricsData(documentArray, monthToStartFilter, metricsIdsArray))
        })
    }


    var nextYearsDocumentArray = []
    var nextYear = parseInt(newYear)
    nextYear = nextYear + 1
    nextYear = nextYear.toString()
    for (var y = 0; y < metricsIdsArray.length; y++) {
      var idToSearchFor = metricsIdsArray[y]
      var document = db.collection("TEST-users").doc(uid).collection('metrics').doc(idToSearchFor).collection('data').doc(nextYear).get()
        .then((results) => {
          var data = results.data()
          nextYearsDocumentArray.push(data)
          nextYearsDocumentArray.sort((a, b) => {
            return a.positionInTable - b.positionInTable;
          });
          dispatch(setNextYearsMetricData(nextYearsDocumentArray))
        })
    }
    dispatch(setNextYearsMetricDataLoaded())

  }
}





export function setNextYearsMetricData(nextYearsDocumentArray) {
  return {
    type: "SET_NEXTYEARSMETRICSDATA",
    nextYearsDocumentArray
  }
}

export function setNextYearsMetricDataLoaded() {
  return {
    type: "SET_NEXTYEARSMETRICSDATALOADED"
  }
}








// export function getFilteredMetricsData(uid, selectedYear, selectedMonth, allData) {
//   console.log('getFilteredMetricsDatadataa', allData)
//   const abbreviatedMonth = selectedMonth.slice(0, 3).toLowerCase()
//   var toReturn = []
//   var data = [...allData]

//   const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

//   const monthNumbers = {
//     "jan": 1,
//     "feb": 2,
//     "mar": 3,
//     "apr": 4,
//     "may": 5,
//     "jun": 6,
//     "jul": 7,
//     "aug": 8,
//     "sep": 9,
//     "oct": 10,
//     "nov": 11,
//     "dec": 12
//   };


//   // need to reorder data from alphabetized to chronological
//   for (var x = 0; x < data.length; x++) {
//     var current = data[x]
//     var tempMonthsArray = []
//     for (var key in current) {
//       if (months.includes(key)) {
//         tempMonthsArray.push({[key]: current[key]})
//         // delete current[key]
//       }
//     }
//     tempMonthsArray.sort(function(a, b) {
//       return monthNumbers[Object.keys(a)[0]] - monthNumbers[Object.keys(b)[0]]
//     })
//     for (var y = 0; y < tempMonthsArray.length; y++) {
//       var currentTemp = tempMonthsArray[y]
//       var count = 0
//       for (var key in currentTemp) {
//         if (currentTemp[key] !== 'N/A') {
//           count++
//           if (key === abbreviatedMonth && count === 1) {
//             toReturn.push(current)
//           }
//         }
//       }
//     }
//   }

//   console.log('alldatafilteredmetrics', 'tempmonthsarray:',toReturn)
//   return (dispatch) => {
//     dispatch(setMetricsData(allData, selectedMonth, toReturn))
//   }
// }

export function setMetricsData(dataRows, monthToStartFilter, idsArray) {
  return {
    type: 'SET_METRICSDATA',
    dataRows,
    monthToStartFilter,
    idsArray
  }
}


export function setDeletedMetrics(filteredData, idsToReturn) {
  console.log('deleteddddddd', filteredData)
  return {
    type: "SET_DELETED_METRICS",
    filteredData,
    idsToReturn
  }
}

export function setDeletedMetricsAndNewCurrentMonth() {
  return {
    type: "SET_DELETED_METRICS_NEW_CURRENT_MONTH",
  }
}

export function setUpdatedMetric(data) {
  return {
    type: "SET_UPDATED_METRIC",
    data
  }
}


export function setContentEditable(index) {
  return {
    type: "SET_DASHBOARD_EDITABLE",
    index
  }
}

export function setDeleteMetricModal(boolean) {
  return {
    type: "SET_DELETEMETRICMODAL",
    boolean
  }
}

// export function setFilteredDataHasLoaded() {
//   console.log('filteredDataHasLoadedxxx' )
//   return {
//     type: "SET_FILTERED_DATA_HAS_LOADED"
//   }
// }