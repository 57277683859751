import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetSharedNewslettersList = () => {
  console.log("newsletter")
  return (dispatch) => {
    db.collection("shared-newsletters")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            id: doc.id,
            displayName: docData.displayName ? docData.displayName : "N/A",
            author: docData.author ? docData.author : "N/A",
            category: docData.category ? docData.category : "N/A",
            description: docData.description ? docData.description : "N/A",
            type: docData.resourceType ? docData.resourceType : "N/A",
            title: docData.title ? docData.title : "N/A",
            uid: docData.uid ? docData.uid : "N/A",
            docURL: docData.docURL ? docData.docURL : "N/A",
            docURL: docData.docURL ? (
              <Button
                color="primary"
                fullWidth
                onClick={
                  () => window.open(docData.docURL)}
              >LINK</Button>)
               : "N/A",
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setSharedNewslettersList(data))
      })
  }
}

export const setSharedNewslettersList = (dataRows) => ({
  type: 'SET_SHARED_NEWSLETTERS_LIST',
  dataRows
})

