import React from "react";
import { Link } from 'react-router-dom'

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetBlogsList = () => {
  return (dispatch) => {
    db.collection("blogPosts").where('show', '==', true).orderBy('date')
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            author: docData.author ? docData.author : "N/A",
            date: docData.date ? moment(docData.date.toDate()).format('MM-DD-YYYY') : "N/A",
            markup: docData.markup ? docData.markup : "N/A",
            blogText: docData.blogText ? docData.blogText : "N/A",
            title: docData.title ? docData.title : "N/A",
            category: docData.category ? docData.category : "N/A",
            image: docData.image ? docData.image : "N/A",
            paragraph1: docData.paragraph1 ? docData.paragraph1 : "N/A",
            paragraph2: docData.paragraph2 ? docData.paragraph2 : "N/A",
            paragraph3: docData.paragraph3 ? docData.paragraph3 : "N/A",
            paragraph4: docData.paragraph4 ? docData.paragraph4 : "N/A",
            paragraph5: docData.paragraph5 ? docData.paragraph5 : "N/A",
            link: doc.id ? (
                <Link to={'/blogs/' + doc.id}  key={doc.id} >
          <Button
            color="primary"
            fullWidth
            // onClick={
            //   () => window.open(docData.id)}
          >LINK</Button>
          </Link>)
           : "N/A" 
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setBlogsList(data))
      })
  }
}

export const startSetBlogList = (blogID) => {
  return (dispatch) => {
    db.collection("blogPosts").doc(blogID)
      .get().then((snapshot) => {
        var docData = snapshot.data()
          if (docData) {
            var docObj = {
              id: snapshot.id,
              order: docData.order ? docData.order : "N/A",
              author: docData.author ? docData.author : "N/A",
              date: docData.date ? moment(docData.date.toDate()).format('MM-DD-YYYY') : "N/A",
              markup: docData.markup ? docData.markup : "N/A",
              blogText: docData.blogText ? docData.blogText : "N/A",
              title: docData.title ? docData.title : "N/A",
              category: docData.category ? docData.category : "N/A",
              image: docData.image ? docData.image : "N/A",
              paragraph1: docData.paragraph1 ? docData.paragraph1 : "N/A",
              paragraph2: docData.paragraph2 ? docData.paragraph2 : "N/A",
              paragraph3: docData.paragraph3 ? docData.paragraph3 : "N/A",
              paragraph4: docData.paragraph4 ? docData.paragraph4 : "N/A",
              paragraph5: docData.paragraph5 ? docData.paragraph5 : "N/A",
              link: snapshot.id ? (
                  <Link to={'/blogs/' + snapshot.id}  key={snapshot.id} >
            <Button
              color="primary"
              fullWidth
              // onClick={
              //   () => window.open(docData.id)}
            >LINK</Button>
            </Link>)
             : "N/A" 
            }
            return (docObj)
          } else {
            return (null)
          }
      
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setBlogList(data))
      })
  }
}



export const startSetBlogsComments = (blogID) => {
    return (dispatch) => {
      db.collection("blogPosts").doc(blogID).collection("comments").orderBy('updatedAt')
        .get().then((snapshot) => {
          const data = []
          // Parse data into array like in firebase.js
          snapshot.forEach((doc) => {
            var docData = doc.data()
            
            var docObj = {
              id: doc.id,
              name: docData.name,
              comment: docData.comment ? docData.comment : "N/A",
              updatedAt: docData.updatedAt ? moment(docData.updatedAt.toDate()).format('MM-DD-YYYY') : "N/A",
            }
            data.push(docObj);
          })
          return (data)
        }, (error) => {
          console.log('error fetching data: ', error)
        }).then(data => {        
          dispatch(setBlogComments(data))
        })
    }
  }

export const setBlogsList = (dataRows) => ({
  type: 'SET_BLOGS_LIST',
  dataRows
})

export const setBlogList = (dataRows) => ({
  type: 'SET_BLOG_LIST',
  dataRows
})

export const setBlogComments = (dataRows) => ({
    type: 'SET_BLOGS_COMMENTS',
    dataRows
  })
  
