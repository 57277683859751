/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import firebase, { db, storage } from "firebase/fbConfig.js"


import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import { addNewMetric } from "store/actions/metricsActions.jsx";
import moment from "moment"

import Style from "assets/jss/material-dashboard-pro-react/views/metricsStyle.jsx";
const metricsToMap = ['Purpose', 'Stakeholder Engagement', 'Health', 'Value Proposition', 'Competencies', 'Initiatives']


const AddMetricFormOtherDashboards = (props) => {
  const { classes, cards, setCards, setCard, submit, submitted, selectedMonth, selectedYear, uid, years, tableData } = props



  const [metricTypeInput, setMetricTypeInput] = useState('')
  const [metricDescriptionInput, setMetricDescriptionInput] = useState('')
  const [measureDefintionInput, setMeasureDefintionInput] = useState('')
  const [fiveYearTargetInput, setFiveYearTargetInput] = useState('')
  const [oneYearTargetInput, setOneYearTargetInput] = useState('')
  const [baselineInput, setBaselineInput] = useState('')
  const [currentMonthInput, setCurrentMonthInput] = useState('')
  const [januaryInput, setJanuaryInput] = useState('')
  const [februaryInput, setFebruaryInput] = useState('')
  const [marchInput, setMarchInput] = useState('')
  const [aprilInput, setAprilInput] = useState('')
  const [mayInput, setMayInput] = useState('')
  const [juneInput, setJuneInput] = useState('')
  const [julyInput, setJulyInput] = useState('')
  const [augustInput, setAugustInput] = useState('')
  const [septemberInput, setSeptemberInput] = useState('')
  const [octoberInput, setOctoberInput] = useState('')
  const [novemberInput, setNovemberInput] = useState('')
  const [decemberInput, setDecemberInput] = useState('')
  const [startDateInput, setStartDate] = useState('')
  const [metricsData, setMetricsData] = useState(null)

  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

  const monthNames = {
    "January": 1,
    "February": 2,
    "March": 3,
    "April": 4,
    "May": 5,
    "June": 6,
    "July": 7,
    "August": 8,
    "September": 9,
    "October": 10,
    "November": 11,
    "December": 12
  };


  console.log('monthandyear', props.uid)



  useEffect(() => {
    if (props.tableData) {
      setMetricsData(props.tableData)
      console.log('checkinglength', props.tableData.length)
    }
  }, [props])



  const submitValues = () => {
    var data = {
      metricType: metricTypeInput, measureDefinition: measureDefintionInput, metricDescription: metricDescriptionInput, fiveYearTarget: fiveYearTargetInput, oneYearTarget: oneYearTargetInput, baseline: baselineInput, jan: januaryInput, feb: februaryInput, mar: marchInput, apr: aprilInput, may: mayInput, jun: juneInput, jul: julyInput, aug: augustInput, sep: septemberInput, oct: octoberInput, nov: novemberInput, dec: decemberInput, status: "Active", currentMonth: 'N/A',
      // need to add this back once we are getting data
      positionInTable: tableData.length
    }


    var monthsArray = []
    for (var key in data) {
      if (data[key] === '') {
        data[key] = 'N/A'
      } else {
        if (data[key] && months.includes(key)) {
          monthsArray.push(key)
        }
      }
    }



    if (monthsArray.length === 0) {
      // they haven't added a month
      // display a snackbar



    } else {
      var lastMonthInputted = monthsArray[monthsArray.length -1]

      if (lastMonthInputted === 'jan') {
        lastMonthInputted = "January"
      }
      if (lastMonthInputted === 'feb') {
        lastMonthInputted = "February"
      }
      if (lastMonthInputted === 'mar') {
        lastMonthInputted = "March"
      }
      if (lastMonthInputted === 'apr') {
        lastMonthInputted = "April"
      }
      if (lastMonthInputted === 'may') {
        lastMonthInputted = "May"
      }
      if (lastMonthInputted === 'jun') {
        lastMonthInputted = "June"
      }
      if (lastMonthInputted === 'jul') {
        lastMonthInputted = "July"
      }
      if (lastMonthInputted === 'aug') {
        lastMonthInputted = "August"
      }
      if (lastMonthInputted === 'sep') {
        lastMonthInputted = "September"
      }
      if (lastMonthInputted === 'oct') {
        lastMonthInputted = "October"
      }
      if (lastMonthInputted === 'nov') {
        lastMonthInputted = "November"
      }
      if (lastMonthInputted === 'dec') {
        lastMonthInputted = "December"
      }

        const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        data.id = id
        // var doc = db.collection("TEST-users").doc(props.uid).collection("metrics").doc(id).collection('data').doc()
        var batch = db.batch()
        const ref = db.collection("TEST-users").doc(props.uid).collection("metrics").doc(id).collection('data')

        for (var x = 0; x < years.length; x++) {
          var docRef = ref.doc(years[x])
          batch.set(docRef, {})
        }
        batch.commit()
          .then(() => {
            return ref.doc(selectedYear).set(data)
              .then(() => {
                return db.collection("TEST-users").doc(props.uid).collection("cache").doc('mapping').collection('ids').doc(selectedYear).get()
                  .then((results) => {
                    if (!results.exists) {
                      console.log('resultssssxxxempty', results)
                      return db.collection("TEST-users").doc(props.uid).collection("cache").doc('mapping').collection('ids').doc(selectedYear).set({ids: [id]})
                        .then(() => {
                            return db.collection("TEST-users").doc(props.uid).collection('cache').doc('monthToRemember').set({monthToRemember: [lastMonthInputted]})
                            .then(() => {
                            props.addNewMetric(props.uid, selectedYear)
                            setMetricTypeInput('')
                            setMetricDescriptionInput('')
                            setMeasureDefintionInput('')
                            setFiveYearTargetInput('')
                            setOneYearTargetInput('')
                            setBaselineInput('')
                            setCurrentMonthInput('')
                            setJanuaryInput('')
                            setFebruaryInput('')
                            setMarchInput('')
                            setAprilInput('')
                            setMayInput('')
                            setJuneInput('')
                            setJulyInput('')
                            setAugustInput('')
                            setSeptemberInput('')
                            setOctoberInput('')
                            setNovemberInput('')
                            setDecemberInput('')
                            })
                        })
                    } else {
                      console.log('resultssssxxxhasdata', results.data())
                      results = results.data()
                      var idArray = []
                      results.ids.forEach((id) => {
                        idArray.push(id)
                      })
                      console.log('idArray', idArray)
                      return db.collection("TEST-users").doc(props.uid).collection("cache").doc('mapping').collection('ids').doc(selectedYear).update({ ids: [...idArray, id] })
                        .then(() => {
                          return db.collection("TEST-users").doc(props.uid).collection('cache').doc('monthToRemember').get()
                          .then((results) => {
                            results = results.data().monthToRemember
                            results.push(lastMonthInputted)
                            results.sort(function(a, b) {
                              return monthNames[a] - monthNames[b];
                            });
                            console.log('sortedresultspost', results)
                            // console.log('sortedresults', results)
                            return db.collection("TEST-users").doc(props.uid).collection('cache').doc('monthToRemember').set({monthToRemember: [...results]})
                            .then(() => {
                            props.addNewMetric(props.uid, selectedYear)
                            setMetricTypeInput('')
                            setMetricDescriptionInput('')
                            setMeasureDefintionInput('')
                            setFiveYearTargetInput('')
                            setOneYearTargetInput('')
                            setBaselineInput('')
                            setCurrentMonthInput('')
                            setJanuaryInput('')
                            setFebruaryInput('')
                            setMarchInput('')
                            setAprilInput('')
                            setMayInput('')
                            setJuneInput('')
                            setJulyInput('')
                            setAugustInput('')
                            setSeptemberInput('')
                            setOctoberInput('')
                            setNovemberInput('')
                            setDecemberInput('')
                            })
                          })
                        })
                    }
                  })
              })
          })
    }

  }



  const resetRequest = () => {
    window.location.reload();
  }


  return (
    <div className={classes.formContainer}>
          {/* <h1>other</h1> */}
      <GridContainer justify="center">
        <GridItem style={{ display: "flex", flexDirection: "column" }} xs={12}>
          <FormControl style={{ width: "100%" }} className={classes.formControl}>
            <InputLabel style={{ color: "black" }} shrink id="Metric Type">Metric Type</InputLabel>
            <Select
              defaultValue=''
              labelId='Metric Type'
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                formControl: classes.addUserInput
              }}
              value={metricTypeInput}
              onChange={(event) => setMetricTypeInput(event.target.value)}
              inputProps={{
                name: "simpleSelect",
                id: 'age-native-label-placeholder',
                // className: classes.addUserInput
              }}
            >
              {metricsToMap.map((value, index) => {
                return (
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelectedMultiple
                    }}
                    value={value}
                  >
                    {value}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <InputLabel className={classes.addUserLabel} id="Metric Type">Metric Description</InputLabel>
          <Input value={metricDescriptionInput} onChange={(e) => setMetricDescriptionInput(e.target.value)}></Input>
          <InputLabel className={classes.addUserLabel} id="Metric Type">Measure Definition</InputLabel>
          <Input value={measureDefintionInput} onChange={(e) => setMeasureDefintionInput(e.target.value)}></Input>


          <div className={classes.groupedInputDiv}>
            <div className={classes.singleGroupedInput}>
              <InputLabel className={classes.addUserLabel} id="Metric Type">5 Year Target</InputLabel>
              <Input value={fiveYearTargetInput} onChange={(e) => setFiveYearTargetInput(e.target.value)}></Input>
            </div>
            <div className={classes.singleGroupedInput}>
              <InputLabel className={classes.addUserLabel} id="Metric Type">1 Year Target</InputLabel>
              <Input value={oneYearTargetInput} onChange={(e) => setOneYearTargetInput(e.target.value)}></Input>
            </div>
            <div className={classes.singleGroupedInput}>
              <InputLabel className={classes.addUserLabel} id="Metric Type">Baseline</InputLabel>
              <Input value={baselineInput} onChange={(e) => setBaselineInput(e.target.value)}></Input>
            </div>
          </div>

          {/* <div className={classes.groupedInputDiv}>
            <div className={classes.singleGroupedInput}>
              <InputLabel className={classes.addUserLabel} id="Metric Type">Current Month</InputLabel>
              <Input value={currentMonthInput} onChange={(e) => setCurrentMonthInput(e.target.value)}></Input>
            </div>
            <div className={classes.singleGroupedInput}>
              <InputLabel className={classes.addUserLabel} id="Start Date">Start Date</InputLabel>
              <Input value={startDateInput} onChange={(e) => setStartDate(e.target.value)}></Input>
            </div>
          </div> */}

          <div className={classes.groupedInputDiv} >
            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="January" >January</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                onChange={(e) => setJanuaryInput(e.target.value)}
                value={januaryInput}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="January"></Input>
            </div>

            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="February" >February</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={februaryInput}
                onChange={(e) => setFebruaryInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="February"></Input>
            </div>

            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="March" >March</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={marchInput}
                onChange={(e) => setMarchInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="March"></Input>
            </div>
          </div>


          <div className={classes.groupedInputDiv} >
            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="April" >April</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={aprilInput}
                onChange={(e) => setAprilInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="April"></Input>
            </div>

            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="May" >May</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={mayInput}
                onChange={(e) => setMayInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="May"></Input>
            </div>

            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="June" >June</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={juneInput}
                onChange={(e) => setJuneInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="June"></Input>
            </div>
          </div>

          <div className={classes.groupedInputDiv} >
            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="July" >July</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={julyInput}
                onChange={(e) => setJulyInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="July"></Input>
            </div>

            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="August" >August</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={augustInput}
                onChange={(e) => setAugustInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="August"></Input>
            </div>

            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="September" >September</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={septemberInput}
                onChange={(e) => setSeptemberInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="September"></Input>
            </div>
          </div>

          <div className={classes.groupedInputDiv} >
            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="October" >October</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={octoberInput}
                onChange={(e) => setOctoberInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="October"></Input>
            </div>

            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="November" >November</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={novemberInput}
                onChange={(e) => setNovemberInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="November"></Input>
            </div>

            <div className={classes.singleGroupedInput}>
              <InputLabel style={{ width: "30%", paddingRight: "20px" }} className={classes.addUserLabel} id="December" >December</InputLabel>

              {/* add 30% width to each of these */}
              <Input
                value={decemberInput}
                onChange={(e) => setDecemberInput(e.target.value)}
                classes={{
                  formControl: classes.addUserInput
                }}
                labelId="December"></Input>
            </div>
          </div>

        </GridItem>
      </GridContainer>
      <Button
        onClick={() => submitValues()}
        type="submit"
        color="primary"
        style={{
          margin: "40px 0px 10px 0px",
          float: "right"
        }}
      >
        SUBMIT
      </Button>
    </div>

  )
}

AddMetricFormOtherDashboards.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.profile.user,
    submitted: state.playbookList.healthMetrics.submitted,
    data: state.metrics.data,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addNewMetric: (uid, selectedYear) => dispatch(addNewMetric(uid, selectedYear))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(AddMetricFormOtherDashboards);

