import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetGroupCallsList = (user, authUser) => {
  return (dispatch) => {
    db.collection("groupCalls").where('show', '==', true).orderBy('order')
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            type: docData.type ? docData.type : "N/A",
            length: docData.length ? docData.length : "N/A",
            start: docData.start ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
            end: docData.end ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
            title: docData.title ? docData.title : "N/A",
            date: docData.date ? docData.date : "N/A",
            presenters: docData.presenters ? docData.presenters : "N/A",
            description: docData.description ? docData.description : "N/A",
            zoom_link: docData.zoom_link ? docData.zoom_link : "N/A",
            time: docData.time ? docData.time : "N/A",
            register: docData.register ? (
              <Button
                color="primary"
                onClick={() => {
                  db.collection("TEST-users").doc(authUser.uid).collection("registeredGroupCalls").doc(doc.id).set({
                    id: doc.id,
                    user: user
                  }, { merge: true }).then(() => {
                    window.location.replace(`/courses/${doc.id}`)
                  })
                }}
                fullWidth
              >ADD TO CALENDAR</Button>)
               : "N/A",
            pdf1: docData.pdf1 ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.pdf1)}
          >{docData.pdf1Name}</Button>)
           : "N/A",
            pdf2: docData.pdf2 ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.pdf2)}
          >{docData.pdf2Name}</Button>)
           : "N/A",
            pdf3: docData.pdf3 ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.pdf3)}
          >{docData.pdf3Name}</Button>)
           : "N/A",
            pdf4: docData.pdf4 ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.pdf4)}
          >{docData.pdf4Name}</Button>)
           : "N/A",
           video: docData.video ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.video)}
          >DOWNLOAD</Button>)
           : "N/A",
           audio: docData.audio ? (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => window.open(docData.audio)}
          >DOWNLOAD</Button>)
           : "N/A",
           
           }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setGroupCallsList(data))
      })
  }
}





export const setGroupCallsList = (dataRows) => ({
  type: 'SET_GROUP_CALLS_LIST',
  dataRows
})

