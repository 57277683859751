import {
  bigNumbers,
  primaryColor,
  dangerColor,
  successColor,
  tooltip,
  bodyText,
  card,
  cardTitle,
  cardSubtitle,
  title
} from "assets/jss/material-dashboard-pro-react.jsx";

const metricsStyle = {
  tableDiv: {
      height: "800px",
      // overflow: "scroll",
       width: "100%",
  },
  tableHeaderCurrent: {
    height: "70px",
    paddingTop: "20px",
    paddingLeft: "40px",
    display: "flex",
    justifyContent: "space-between", 
    marginBottom: "50px",
  },
  tableHeaderDiv: {
    paddingTop: "20px",
    paddingLeft: "40px",
    display: "flex",
    justifyContent: "flex-end", marginBottom: "30px"
  },
  yearSelect: {
    fontSize: "25px",
    border: "none"
  },
  tooltip:{
    fontSize:"12px"
  },
  threeDots: {
    fontSize: "xx-large",
    float: "right",
    '&:hover': {
      cursor: "pointer"
    }
  },
  exportPopperDiv: {
    display: "flex",
    // height: "85px",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    // margin: "0px auto",
  },
  exportPopperSelections: {
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      background: "#F6F6F7"
    }
  },
  popperDiv: {
    display: "flex",
    // height: "85px",
    // flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 12px",
    height: "50px",
    // margin: "0px auto",
    "&:hover": {
      cursor: "pointer",
      background: "#F6F6F7"
    }
  },
  dataDownlodPopperDiv: {
    display: "flex",
    flexDirection: "column",
  },
  popperSelections: {
    margin: "0px",
    },
    addUserLabel: {
      marginTop: "20px",
      fontSize: "13px",
      color: "#3a3934 !important",
      fontFamily: `"Open Sans", serif`
    },
    addUserInput: {
      width: "100%",
      color: "black !important",
      fontSize: "13px",
    },

  cancelBtn: {
    width: "25%",
    border: "1px solid black",
    backgroundColor: "black",
    color: "white",
    borderRadius: "5px",
    height: "45px",
    fontSize: "15px",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "black",
    }
  },
  applyBtn: {
    width: "25%",
    // border: "1px solid #18A0FB",
    // backgroundColor: "white",
    // color: "#18A0FB",
    borderRadius: "5px",
    height: "45px",
    fontSize: "15px",
    marginLeft: "10px",
    textTransform: "capitalize",
    "&:hover": {
      cursor: "pointer"
    }
  },
  smallerDataDiv: {
    textAlign: "center",
     padding: "0px 10px",
     maxWidth: "100px"
  },
  biggerDataDiv: {
    textAlign: "center",
    maxWidth: "350px",
     minWidth: "300px",
     padding: "0px 10px"
  },
  noDataMessage: {
    height: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "30px",
    fontWeight: 400
  },
  table: {
    display: "flex", flexDirection: "column", paddingTop: "30px"
  },
  modalBody: {
    ...bodyText,
    color: "#000",
    marginBottom: '0',
    marginTop: '0'
  },
  groupedInputDiv: {
    display: "flex",
    justifyContent: "space-between"
  },
  singleGroupedInput: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    paddingRight: "20px"
  },
  button:{
    backgroundColor: "#FC662B",
    color: "white",
    fontSize: "12px",
    borderRadius: "5px",
    boxShadow: "4px 4px 0px 0px #e3470a",
    "&:hover": {
      backgroundColor: "#FC662B",
      color: "white",
      boxShadow: "2px 2px 0px 0px #e3470a"
    },
  },
  subTitle: {
    ...title,
  },

}

export default metricsStyle