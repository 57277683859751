import React from "react";

// Orders Data
const actions = {
    columns: [    
      {
        Header: "ACTION ID",
        accessor: "actionID",
        show: false
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "BODY",
        accessor: "description",
        show: false
      },
      {
        Header: "DUE",
        accessor: "dueDate"
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },    
      {
        Header: "STATUS",
        accessor: "status"
      },    
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  }
  ;
  // Orders Data
  const pendingActions = {
    columns: [  
      {
        Header: "VOL EMAIL",
        accessor: "email"
      },  
      {
        Header: "VOL UID",
        accessor: "volUID"      
      },
      {
        Header: "ACTION ID",
        accessor: "actionId"      
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "COMMENT",
        accessor: "comment"     
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },  
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  };
  
  // Orders Data
  const books = {
    columns: [ 
      {
        expander: true,
        Header: () => <strong>More</strong>,
        width: 65,
        Expander: ({ isExpanded, ...rest }) =>
          <div>
            {isExpanded
              ? <span>&#x25bc;</span>
              : <span>&#x25b6;</span>}
          </div>,
        style: {
          cursor: "pointer",
          fontSize: 20,
          padding: "0",
          textAlign: "center",
          userSelect: "none",
          color: "#4ec7bc"
        },
      },       
      {
        Header: "TITLE",
        accessor: "title"      
      },
      {
        Header: "AUTHOR(S)",
        accessor: "author"      
      },
      {
        Header: "SUMMARY",
        accessor: "summary"      
      },
      {
        Header: "CATEGORY",
        accessor: "category"      
      },
      {
        Header: "LINK",
        accessor: "link"
      }
    ],  
    dataLoaded: false
  };
  
  export const initState = {
    actions,
    pendingActions,
    books
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_BOOKS_LIST':
        console.log('SET_BOOKS_LIST')     
        return {
          ...state,
          books: {
            ...state.books,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
      default:
        return state
    }
  }
  