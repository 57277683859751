import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from "firebase/fbConfig.js";
import { Chart } from "react-google-charts";
import moment from "moment";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable, { useRowState } from "react-table";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from '@material-ui/core/FormControl';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Assess from "assets/img/assess.png";
import Connect from "assets/img/connect.png";
import Grow from "assets/img/grow.png";
import Step1 from "assets/img/step1.png";
import Step2 from "assets/img/step2.png";
import Step3 from "assets/img/step3.png";
import Step4 from "assets/img/step4.png";
import Step5 from "assets/img/step5.png";
import Strategy from "assets/img/strategy.png";
import PlaybookIMG from "assets/img/playbook.png";
import DashboardIMG from "assets/img/dashboard.png";
import CheckMark from "assets/img/check.png";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Edit from '@material-ui/icons/Edit';
import RotateLeft from '@material-ui/icons/RotateLeft'
import IconButton from '@material-ui/core/IconButton';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ReactDragListView from "react-drag-listview";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/valueBehaviorsStyle.jsx";
import { Grid, Menu, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import { openDialog, startLoad, startSaveList, submit } from "store/actions/playbookListActions.jsx";

import { jsPDF } from "jspdf";
import 'jspdf-autotable'
// Forms
import ValueBehaviorsDepartmentsForm from "components/Forms/ValueBehaviorsDepartmentsForm.jsx";
import ValueDepartmentsBehaviorsForm from "components/Forms/ValueDepartmentsBehaviorsForm.jsx";
import ValueDepartmentsBehaviorsForm2 from "../../components/Forms/ValueDepartmentsBehaviorsForm2.jsx";
import ValueBehaviorsEditorForm from "components/Forms/ValueBehaviorsEditorForm.jsx";
import { uniqueId } from "docx";
import { EditOutlined } from "@material-ui/icons";

const Transition = (props) => {
    return <Slide direction="down" {...props} />;
};
class ValueBehaviors extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            departmentsModal: false,
            departmentsLoaded: false,
            coreGoalsLoaded: false,
            coreValuesLoaded: false,
            coreDepartmentBehaviorsLoaded: false,
            playbookDataLoaded: false,
            coreGoalsValues: [],
            departments: [],
            coreDepartmentBehaviors: [],
            selectedGoal: "",
            selectedDepartment: "",
            behavior: ""
            // departmentValues: ""
        }
    }

    handleClickOpen = (modal) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
        });
    };


    handleEditOpen = (modal, rowData, key) => {
        console.log('rowData', rowData)
        console.log('key', key)
        var x = [];
        x[modal] = true;
        this.setState({
            editorModal: true,
            behavior: rowData.behavior
        });
        console.log('check for state', this.state)
    };

    handleOnChange = (e) => {
        console.log("this is the selcted goal value before: ", e.target.value);
        this.setState({ ...this.state, selectedGoal: e.target.value }, () => {
            console.log("checking this.state: ", this.state)
        })
        console.log("this is the selcted goal value:", this.state.selectedGoal)
    }

    editRow = (rowData, key) => {
        console.log('rowData', rowData)
        console.log('key', key)
        this.setState({
            editing: true
        })
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
        console.log("testing closing ")
    };

    loadCoreValues = async (uid) => {
        const valuesRef = db.collection('TEST-users').doc(uid).collection('lists').doc('coreValues')
        const doc = await valuesRef.get()
        const coreValues = doc.data()
        if (doc.exists) {
            let formattedValues = coreValues.values.map((value) => `Core: ${value.text}`)
            formattedValues.map((value) => this.state.coreGoalsValues.push(value))
            this.setState({
                coreValuesLoaded: true
            })
        } else {
            console.log('no core values')
        }
        this.loadCoreGoals(uid)
    };
    loadCoreGoals = async (uid) => {
        const goalsRef = db.collection('TEST-users').doc(uid).collection('lists').doc('coreGoals')
        const doc = await goalsRef.get()
        const coreGoals = doc.data()
        if (doc.exists) {
            let formattedGoals = coreGoals.values.map((goal) => `Goal: ${goal.text}`)
            formattedGoals.map((goal) => this.state.coreGoalsValues.push(goal))
            this.setState({
                coreGoalsLoaded: true
            })
        } else {
            console.log('no core goals')
        }
    };


    loadDepartments = async (uid) => {
        const departmentsRef = db.collection('TEST-users').doc(uid).collection('lists').doc('departments')
        const doc = await departmentsRef.get()
        const departments = doc.data()
        if (doc.exists) {
            departments.values.map((value) => this.state.departments.push(value))
            this.setState({
                departmentsLoaded: true
            })
        } else {
            console.log('no departments')
        }
    }

    loadCoreDepartmentBehavior = async (uid) => {
        const departmentsRef = db.collection('TEST-users').doc(uid).collection('lists').doc('coreDepartmentBehaviors')
        const doc = await departmentsRef.get()
        const departments = doc.data()
        if (doc.exists) {
            departments.values.map((value) => this.state.coreDepartmentBehaviors.push(value))
            this.setState({
                coreDepartmentBehaviorsLoaded: true
            })
        } else {
            console.log('no core departments')
        }
    }

    updateBehavior = (event) => {
        event.preventDefault()
        this.setState({
            behavior: event.target.value
        })
    }

    submitCoreDepartmentBehavior = async (uid, name, list) => {
        this.state.coreDepartmentBehaviors.push(list)
        const coreDepartmentBehaviorRef = db.collection('TEST-users').doc(uid).collection('lists').doc(name)
        const res = await coreDepartmentBehaviorRef.set({
            values: this.state.coreDepartmentBehaviors
        }).then(() => this.setState({
            submitted: true
        }))
    }


    sortValueDepartment = (selectedGoal, selectedDepartment) => {
        // Check if both drop downs are selected
        if (selectedGoal != "" && selectedDepartment != "") {
            let filteredResults = this.state.coreDepartmentBehaviors.filter((obj) => {
                return obj.coreGoalValue == selectedGoal && obj.department == selectedDepartment
            })
            this.setState({
                filteredResults: filteredResults,
                bothSelected: true
            })
        }
        // Check if only a goal is selected
        if (selectedGoal != "" && selectedDepartment == "") {
            let filteredResults = this.state.coreDepartmentBehaviors.filter((obj) => {
                return obj.coreGoalValue == selectedGoal
            })
            this.setState({
                filteredResults: filteredResults
            })
        }
        // Check if only a department is loaded
        if (selectedGoal == "" && selectedDepartment != "") {
            let filteredResults = this.state.coreDepartmentBehaviors.filter((obj) => {
                return obj.department == selectedDepartment
            })
            this.setState({
                filteredResults: filteredResults
            })
        }

    }

    clearSelected = () => {
        this.setState({
            selectedGoal: "",
            selectedDepartment: "",
            allGoals: true,
            allDepartments: true,
        })
    }

    deleteRow = (rowData) => {
        let filteredResults = this.state.coreDepartmentBehaviors.filter((el) => {
            return el != rowData
        })
        let confirm = window.confirm("Are you sure you would like to delete this?")
        if (confirm) {
            this.deleteFirebaseItem(filteredResults)
        }
    }

    deleteFirebaseItem = async (filteredResults) => {
        const coreDepartmentBehaviorRef = db.collection('TEST-users').doc(this.props.authUser.uid).collection('lists').doc('coreDepartmentBehaviors')
        const res = await coreDepartmentBehaviorRef.set({
            values: filteredResults
        }).then(() => this.setState({
            submitted: true
        }))
    }

    deleteSavedDoc = async () => {
        await db.collection('TEST-users').doc(this.props.authUser.uid).collection('lists').doc('saved-Dropdown').delete()
            .then(() => {
                window.location.reload()
            })
    }

    increaseIndex = (rowData, key) => {
        console.log('rowData', rowData)
        console.log('before key', key)
        key++
        console.log('after key', key)
    }

    decreaseIndex = (rowData, key) => {
        console.log('rowData', rowData)
        console.log('before key', key)
        key--
        console.log('after key', key)
    }

    printValueBehaviors = (dataset) => {
        const doc = new jsPDF();
        doc.setDrawColor(77, 171, 41);
        const now = moment().format('MMMM Do, yyyy')
        doc.setFontSize(15);
        doc.text(`Revision: ${now}`, 5, 10);
        doc.setFont("helvetica", "bold");
        doc.text("Value Behaviors", 5, 21);
        // doc.setFontSize(12);
        // doc.text("Value Name:", 5, 30);
        // doc.setFontSize(9);
        // doc.setFont("helvetica", "normal");
        // var coreGoalValues = doc.splitTextToSize(dataset.filter((item) => item.coreGoalValue !== "").map((item) => { return item.coreGoalValue }), 60);
        // doc.text(5, 35, coreGoalValues);

        // doc.setFontSize(12);
        // doc.setFont("helvetica", "bold");
        // doc.text("Department Name:", 60, 30);
        // doc.setFontSize(9);
        // doc.setFont("helvetica", "normal");
        // var departments = doc.splitTextToSize(dataset.filter((item) => item.department !== "").map((item) => { return item.department }), 60);
        // doc.text(60, 35, departments);

        // doc.setFontSize(12);
        // doc.setFont("helvetica", "bold");
        // doc.text("Behavior:", 115, 30);
        // doc.setFontSize(9);
        // doc.setFont("helvetica", "normal");
        // var behaviors = doc.splitTextToSize(dataset.filter((item) => item.behavior !== "").map((item) => { return item.behavior }), 90);


        // Testing
        var col = ["Value Name", "Department Name", "Behavior"];
        var rows = [];
        dataset.map((item) => {
            var temp = [doc.splitTextToSize(item.coreGoalValue), doc.splitTextToSize(item.department), doc.splitTextToSize(item.behavior)]
            rows.push(temp)
        });

        doc.autoTable(col, rows, {
            startY: 30, tableWidth: 'auto', margin: 15, styles: { halign: "left", cellWidth: 60, overflow: "linebreak", fontSize: 12, fillColor: (220, 220, 220), textColor: "black" },
        })





        // doc.text(115, 35, behaviors);

        doc.save("value-behaviors.pdf");
    }

    // checkUser = () =>{
    //     firebase.auth().onAuthStateChanged(async function (user) {
    //         if (user) {
    //             // User is signed in.
    //             return true
    //         } 
    //         return false
    //     });
    // }

    headers = [
        { label: "Value Name", key: "valuename" },
        { label: "Department Name", key: "departmentname" },
        { label: "Behavior", key: "behavior" }
    ];

    // getData = (dataset) => {
    //     dataset.map((item) => {

    //     })
    // }

    async componentDidUpdate() {
        console.log("outside if statement")
        if (this.props.authUser != undefined && this.state.dataLoaded === false) {
            console.log("inside if statement")
            const doc = await db.collection('TEST-users').doc(this.props.authUser.uid).collection('lists').doc('saved-Dropdown').get()
            if (doc.exists) {
                const info = doc.data()
                this.setState({ ...this.state, selectedGoal: info.coreGoal, selectedDepartment: info.department, dataLoaded: true })
            }
            else {
                console.log("doesnt exist")
            }
        }
    }

    render() {

        const {
            tagline,
            user,
            classes,
            authUser,
            uid
        } = this.props

        if (!!authUser && !this.state.coreGoalsLoaded && !this.state.coreValuesLoaded && !this.state.departmentsLoaded && !this.state.coreDepartmentBehaviorsLoaded && !this.state.playbookDataLoaded) {
            // this.loadCoreGoals(authUser.uid);
            this.loadCoreValues(authUser.uid);
            this.loadDepartments(authUser.uid);
            this.loadCoreDepartmentBehavior(authUser.uid);
            this.props.loadPlaybookListData(authUser.uid);
        }

        const resetRequest = async () => {
            // await db.collection('TEST-users').doc(uid).collection('lists').doc('saved-Dropdown').delete()
            window.location.reload();
        }



        return (
            <div>
                {authUser ?
                    <div>
                        {console.log("authuser ", authUser)}
                        {this.state.submitted ?
                            <GridContainer justify="center">
                                <GridItem xs={11}>
                                    {resetRequest(authUser.uid)}
                                    <h4 className={classes.textPrimary}>Department Set!</h4>
                                </GridItem>
                            </GridContainer>
                            :
                            <div>
                                <Card>
                                <h1 className={classes.titleHeader}>{this.props.user.companyName} Value Behaviors</h1>
                                <div className={classes.spaceAround}>
                                    <h3>{this.props.user.firstName} {this.props.user.lastName}</h3>
                                    <h3>{this.props.tagline}</h3>
                                </div>
                                </Card>
                                <GridContainer>
                                    {/* {console.log('core department behaviors', this.state.coreDepartmentBehaviors)} */}
                                    <GridItem xs={12} sm={4}>
                                        <Card style={{ padding: "30px", height: "100%" }}>
                                            <GridContainer
                                                style={{ margin: "0px 0px -60px 0px" }}
                                            >
                                                <GridItem xs={9}></GridItem>
                                                <GridItem xs={3} sm={3} md={3}>
                                                    <div>
                                                        <Button
                                                            className={classes.dashboardModalBtn}
                                                            onClick={() =>
                                                                this.handleClickOpen("departmentsModal")
                                                            }
                                                        >
                                                            <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                                        </Button>
                                                    </div>
                                                </GridItem>
                                                <h3 className={classes.title}>Departments</h3>
                                                <GridContainer>
                                                    {this.state.departments.length == 0 ?
                                                        <GridItem xs={6}>
                                                            <p>You have not added any deparments yet, click the button on the right to begin adding.</p>
                                                        </GridItem>
                                                        :
                                                        <GridItem xs={12}>
                                                            <ul>
                                                                {this.state.departments.map((department) =>
                                                                    <li>{department.text}</li>
                                                                )}
                                                            </ul>
                                                        </GridItem>
                                                    }

                                                </GridContainer>
                                            </GridContainer>
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={8}>
                                        <Card style={{ padding: "30px", height: "100%" }}>
                                            <GridContainer>
                                                <GridItem xs={11}>
                                                    <h3 className={classes.title}>Behaviors for Values by Department</h3>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <div style={{ display: "flex", justifyContent: "left", }}>
                                                        <IconButton
                                                            style={{ color: "black", background: "white" }}
                                                            onClick={this.deleteSavedDoc}
                                                        >
                                                            <RotateLeft className={classes.modalCloseButton} style={{ fontSize: "35px", color: "#F4BF4F" }} />
                                                        </IconButton>
                                                    </div>
                                                </GridItem>
                                            </GridContainer>

                                            <GridContainer>
                                                <GridItem xs={2}>
                                                    {/* {console.log("testing local storage values", this.state.selectedGoal)} */}
                                                    <h6 style={{ color: "#50c6bc", fontWeight: "600" }}>CORE/GOAL VALUES</h6>
                                                </GridItem>
                                                <GridItem xs={3}>

                                                    <FormControl
                                                        className={classes.formControl}>
                                                        <Select
                                                            style={{ width: "200px" }}
                                                            disableUn
                                                            MenuProps={{
                                                                className: classes.selectMenu
                                                            }}
                                                            classes={{
                                                                formControl: classes.addUserInput
                                                            }}
                                                            onChange={this.handleOnChange.bind(this)}
                                                            value={this.state.selectedGoal}
                                                        >
                                                            <MenuItem value="">

                                                            </MenuItem>
                                                            {this.state.coreGoalsValues.map((value, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={index}
                                                                        classes={{
                                                                            root: classes.selectMenuItem,
                                                                            selected: classes.selectMenuItemSelectedMultiple
                                                                        }}
                                                                        value={value}
                                                                    >
                                                                        {value}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={2}>
                                                    <h6 style={{ color: "#50c6bc", fontWeight: "600" }}>DEPARTMENT</h6>
                                                </GridItem>
                                                <GridItem xs={3}>
                                                    <FormControl className={classes.formControl}>
                                                        <Select
                                                            style={{ width: "200px" }}
                                                            disableUn
                                                            // MenuProps={{
                                                            //     className: classes.selectMenu
                                                            // }}
                                                            classes={{
                                                                formControl: classes.addUserInput
                                                            }}
                                                            value={this.state.selectedDepartment}
                                                            onChange={(event) => {
                                                                this.setState({
                                                                    selectedDepartment: event.target.value
                                                                })
                                                            }}
                                                        >
                                                            <MenuItem value="">

                                                            </MenuItem>
                                                            {console.log("checking from dropdown", this.state.selectedDepartment)}
                                                            {this.state.departments.map((department, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={index}
                                                                        classes={{
                                                                            root: classes.selectMenuItem,
                                                                            selected: classes.selectMenuItemSelectedMultiple
                                                                        }}
                                                                        value={department.text}
                                                                    >
                                                                        <Typography>
                                                                            <div style={{ whiteSpace: "normal" }}>
                                                                                {department.text}
                                                                            </div>
                                                                        </Typography>


                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>

                                                </GridItem>
                                                <GridItem xs={2}>
                                                    <Button
                                                        onClick={() => {
                                                            this.sortValueDepartment(this.state.selectedGoal, this.state.selectedDepartment)
                                                        }}
                                                        style={{ margin: "0 0 10px 0" }}
                                                        className={classes.button}
                                                        >
                                                        Select
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                            <GridItem xs={12} style={{ position: "relative" }}>
                                                {this.state.filteredResults ?
                                                    <>
                                                        <div>
                                                            <Button
                                                                style={{ position: "absolute", bottom: "-10px", left: "-10px" }}
                                                                className={classes.button}
                                                                onClick={() => this.printValueBehaviors(this.state.filteredResults)}
                                                            >
                                                                PRINT SORTED VALUE BEHAVIORS
                                                            </Button>
                                                            <Button
                                                                className={classes.button}
                                                                style={{ position: "absolute", bottom: "-10px", right: "-10px" }}
                                                            >
                                                                <CSVLink data={this.state.filteredResults.map(x => ({ valuename: x.coreGoalValue, departmentname: x.department, behavior: x.behavior }))} headers={this.headers} style={{ color: "white" }}>
                                                                    Download CSV
                                                                </CSVLink>
                                                            </Button>
                                                        </div>
                                                    </>

                                                    :
                                                    <>
                                                        <div >
                                                            <Button
                                                                className={classes.button}
                                                                style={{ position: "absolute", bottom: "-10px", left: "-10px" }}
                                                                onClick={() => this.printValueBehaviors(this.state.coreDepartmentBehaviors)}
                                                            >
                                                                PRINT ALL VALUE BEHAVIORS
                                                            </Button>
                                                            <Button
                                                                className={classes.button}
                                                                style={{ position: "absolute", bottom: "-10px", right: "-10px" }}
                                                            >
                                                                <CSVLink data={this.state.coreDepartmentBehaviors.map(x => ({ valuename: x.coreGoalValue, departmentname: x.department, behavior: x.behavior }))} headers={this.headers} style={{ color: "white" }}>
                                                                    Download CSV
                                                                </CSVLink>
                                                            </Button>
                                                        </div>
                                                    </>

                                                }
                                            </GridItem>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} style={{ margin: "50px 0 0 0" }}>
                                        <Card style={{ padding: "20px" }}>
                                            {this.state.filteredResults ?
                                                <Table>
                                                    {console.log("filteredResults length", this.state.filteredResults.length)}
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.valueHeader} style={{ width: "30%" }}>
                                                                Value Name
                                                            </TableCell>
                                                            <TableCell className={classes.tableHeader}>
                                                                Department Name
                                                            </TableCell>
                                                            <TableCell className={classes.tableHeader}>
                                                                Behavior
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.state.bothSelected && this.state.filteredResults.length > 0 ?
                                                                    <Button
                                                                        style={{ marginRight: "-240px" }}
                                                                        onClick={(e) =>
                                                                            this.handleClickOpen("behaviorsModal2")
                                                                        }
                                                                        className={classes.addBehaviorButton}
                                                                        >
                                                                        Add New Behavior
                                                                    </Button>
                                                                    :
                                                                    this.state.bothSelected && this.state.filteredResults.length === 0 ?
                                                                        <Button
                                                                            onClick={() =>
                                                                                this.handleClickOpen("behaviorsModal")
                                                                            }
                                                                            className={classes.addBehaviorButton}
                                                                            >
                                                                            Add New Behavior
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            disabled
                                                                            className={classes.addBehaviorButton}
                                                                            style={{ margin: "0 0 0 -140px" }}
                                                                            onClick={() =>
                                                                                this.handleClickOpen("behaviorsModal")
                                                                            }
                                                                            >
                                                                            Select a Value and Department above to add a Behavior
                                                                        </Button>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.filteredResults.map((rowData, key) => {

                                                            return (
                                                                <TableRow>
                                                                    {console.log("filterresults:", rowData)}
                                                                    <TableCell>
                                                                        {rowData.coreGoalValue}
                                                                    </TableCell>
                                                                    <TableCell className={classes.TableBody}>
                                                                        {rowData.department}
                                                                    </TableCell>
                                                                    <TableCell className={classes.TableBody}>
                                                                        {rowData.behavior}
                                                                    </TableCell>
                                                                    <TableCell />
                                                                    {this.state.bothSelected ?
                                                                        <TableCell
                                                                            className={classes.editorCell}>
                                                                            <Button
                                                                                className={classes.editButton}
                                                                                onClick={() =>
                                                                                    this.handleEditOpen("editorModal", rowData, key)
                                                                                }
                                                                            >
                                                                                <Edit />
                                                                            </Button>
                                                                            <div >
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        this.deleteRow(rowData)
                                                                                    }}
                                                                                    className={classes.editButton}
                                                                                >
                                                                                    <DeleteOutlineOutlinedIcon style={{ fontSize: "large", }} />
                                                                                </Button>
                                                                            </div>
                                                                            {/* <div onClick={() => {
                                                                                this.decreaseIndex(rowData, key)
                                                                            }}>
                                                                                <ArrowUpward className={classes.editorIcon} />
                                                                            </div>
                                                                            <div onClick={() => {
                                                                                this.increaseIndex(rowData, key)
                                                                            }}>
                                                                                <ArrowDownward className={classes.editorIcon} />
                                                                            </div> */}
                                                                        </TableCell>
                                                                        :
                                                                        <TableCell style={{ float: "right" }}>
                                                                            Select this Value and Department to Edit
                                                                        </TableCell>
                                                                    }
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                                :
                                                <Table>
                                                    <TableHead >
                                                        <TableRow >
                                                            <TableCell className={classes.valueHeader} style={{ width: "30%" }}>
                                                                Value Name
                                                            </TableCell>
                                                            <TableCell className={classes.tableHeader}>
                                                                Department Name
                                                            </TableCell>
                                                            <TableCell className={classes.tableHeader}>
                                                                Behavior
                                                            </TableCell>
                                                            <TableCell />
                                                            <TableCell style={{ marginRight: "100px" }}>
                                                                {this.state.bothSelected ?
                                                                    <Button
                                                                        onClick={() =>
                                                                            this.handleClickOpen("behaviorsModal")
                                                                        }
                                                                        className={classes.addBehaviorButton}
                                                                        >
                                                                        Add Behavior
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        disabled
                                                                        style={{ margin: "0 0 0 -140px" }}
                                                                        onClick={() =>
                                                                            this.handleClickOpen("behaviorsModal")
                                                                        }
                                                                        className={classes.addBehaviorButton}
                                                                        >
                                                                        Select a Value and Department above to add a Behavior
                                                                    </Button>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody >
                                                        {this.state.coreDepartmentBehaviors.map((rowData, key) => {
                                                            return (
                                                                <TableRow>
                                                                    {console.log("seeing values for row : ", rowData)}
                                                                    <TableCell >
                                                                        {rowData.coreGoalValue}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {rowData.department}
                                                                    </TableCell>
                                                                    <TableCell className={classes.TableBody}>
                                                                        {rowData.behavior}
                                                                    </TableCell>
                                                                    <TableCell />
                                                                    <TableCell>
                                                                        Select this Value and Department to Edit
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            }
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                                {/* Departments Modal */}
                                <Dialog
                                    className={classes.modalContainer}
                                    classes={{
                                        root: classes.modalRoot,
                                        paper: classes.modal + " " + classes.modalLarge,
                                    }}
                                    open={this.state.departmentsModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => this.handleClose("departmentsModal")}
                                    aria-labelledby="shipment-modal-slide-title"
                                    aria-describedby="shipment-modal-slide-description"
                                >
                                    <DialogTitle
                                        id="shipment-modal-slide-title"
                                        disableTypography
                                        className={classes.modalHeader}
                                    >
                                        <Button
                                            simple
                                            className={classes.modalCloseButton}
                                            key="close"
                                            aria-label="Close"
                                            onClick={() =>
                                                this.handleClose("departmentsModal")
                                            }
                                        >
                                            {" "}
                                            <Close className={classes.modalClose} />
                                        </Button>
                                    </DialogTitle>
                                    <DialogContent
                                        id="shipment-modal-slide-description"
                                        className={classes.modalBody}
                                    >
                                        <ValueBehaviorsDepartmentsForm
                                            uid={authUser.uid}
                                        />
                                    </DialogContent>
                                </Dialog>
                                {/* Behaviors Model */}
                                <Dialog
                                    className={classes.modalContainer}
                                    classes={{
                                        root: classes.modalRoot,
                                        paper: classes.modal + " " + classes.modalLarge,
                                    }}
                                    open={this.state.behaviorsModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => this.handleClose("behaviorsModal")}
                                    aria-labelledby="shipment-modal-slide-title"
                                    aria-describedby="shipment-modal-slide-description"
                                >
                                    <DialogTitle
                                        id="shipment-modal-slide-title"
                                        disableTypography
                                        className={classes.modalHeader}
                                    >
                                        <Button
                                            simple
                                            className={classes.modalCloseButton}
                                            key="close"
                                            aria-label="Close"
                                            onClick={() =>
                                                this.handleClose("behaviorsModal")
                                            }
                                        >
                                            {" "}
                                            <Close className={classes.modalClose} />
                                        </Button>
                                    </DialogTitle>
                                    <DialogContent
                                        id="shipment-modal-slide-description"
                                        className={classes.modalBody}
                                    >
                                        <ValueDepartmentsBehaviorsForm
                                            coreGoalsValues={this.state.coreGoalsValues}
                                            departments={this.state.departments}
                                            selectedGoal={this.state.selectedGoal}
                                            selectedDepartment={this.state.selectedDepartment}
                                            coreDepartmentBehaviors={this.state.coreDepartmentBehaviors}
                                            authUser={authUser}
                                            uid={authUser.uid}
                                        />
                                    </DialogContent>
                                </Dialog>

                                {/* This is for handling adding a new behavior which already has values in the table */}
                                <Dialog
                                    className={classes.modalContainer}
                                    classes={{
                                        root: classes.modalRoot,
                                        paper: classes.modal + " " + classes.modalLarge,
                                    }}
                                    open={this.state.behaviorsModal2}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => this.handleClose("behaviorsModal2")}
                                    aria-labelledby="shipment-modal-slide-title"
                                    aria-describedby="shipment-modal-slide-description"
                                >
                                    <DialogTitle
                                        id="shipment-modal-slide-title"
                                        disableTypography
                                        className={classes.modalHeader}
                                    >
                                        <Button
                                            simple
                                            className={classes.modalCloseButton}
                                            key="close"
                                            aria-label="Close"
                                            onClick={() =>
                                                this.handleClose("behaviorsModal2")
                                            }
                                        >
                                            {" "}
                                            <Close className={classes.modalClose} />
                                        </Button>
                                    </DialogTitle>
                                    <DialogContent
                                        id="shipment-modal-slide-description"
                                        className={classes.modalBody}
                                    >
                                        <ValueDepartmentsBehaviorsForm2
                                            behavior={this.state.behavior}
                                            coreGoalsValues={this.state.coreGoalsValues}
                                            departments={this.state.departments}
                                            selectedGoal={this.state.selectedGoal}
                                            selectedDepartment={this.state.selectedDepartment}
                                            coreDepartmentBehaviors={this.state.coreDepartmentBehaviors}
                                            authUser={authUser}
                                            uid={authUser.uid}
                                        />
                                    </DialogContent>
                                </Dialog>
                                {/* Editor Model */}
                                <Dialog
                                    className={classes.modalContainer}
                                    classes={{
                                        root: classes.modalRoot,
                                        paper: classes.modal + " " + classes.modalLarge,
                                    }}
                                    open={this.state.editorModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => this.handleClose("editorModal")}
                                    aria-labelledby="shipment-modal-slide-title"
                                    aria-describedby="shipment-modal-slide-description"
                                >
                                    <DialogTitle
                                        id="shipment-modal-slide-title"
                                        disableTypography
                                        className={classes.modalHeader}
                                    >
                                        <Button
                                            simple
                                            className={classes.modalCloseButton}
                                            key="close"
                                            aria-label="Close"
                                            onClick={() =>
                                                this.handleClose("editorModal")
                                            }
                                        >
                                            {" "}
                                            <Close className={classes.modalClose} />
                                        </Button>
                                    </DialogTitle>
                                    <DialogContent
                                        id="shipment-modal-slide-description"
                                        className={classes.modalBody}
                                    >
                                        <ValueBehaviorsEditorForm
                                            behavior={this.state.behavior}
                                            coreGoalsValues={this.state.coreGoalsValues}
                                            departments={this.state.departments}
                                            selectedGoal={this.state.selectedGoal}
                                            selectedDepartment={this.state.selectedDepartment}
                                            coreDepartmentBehaviors={this.state.coreDepartmentBehaviors}
                                            authUser={authUser}
                                            uid={authUser.uid}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </div>
                        }
                    </div>
                    :
                    null
                }
            </div>
        )
    }


}

const mapStateToProps = (state) => {
    console.log("testestset: ", state)
    return {
        user: state.profile.user,
        tagline: state.profile.user.tagline,
        authUser: state.auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openPlaybookListDialog: (name) => dispatch(openDialog(name)),
        loadPlaybookListData: (uid) => dispatch(startLoad(uid)),
        // submit: (uid, cards) => dispatch(startSaveList(uid, 'coreDepartmentBehaviors', cards)).then(() => dispatch(submit('coreDepartmentBehaviors')))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ValueBehaviors)

