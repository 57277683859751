import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetStudiesList = () => {
  return (dispatch) => {
    db.collection("researchstudies")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()

          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            author: docData.author ? (docData.author.slice(0, 60) + "...") : "N/A",
            authorFull: docData.author ? docData.author : "N/A",
            title: docData.title ? docData.title : "N/A",
            source: docData.source ? docData.source : "N/A",
            summary: docData.description ? (docData.description.slice(0, 60) + "...") : "N/A",
            description: docData.description ? docData.description : "N/A",
            category: docData.category ? docData.category : "N/A",
            topic: docData.topic ? docData.topic : "N/A",
            link: docData.link ? (
              <center>
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                onClick={
                  () => window.open(docData.link)}
              >DOWNLOAD</Button></center>)
              : "N/A"
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        console.log(data)
        dispatch(setStudiesList(data))
      })
  }
}

export const startSetPurposeStudiesList = (category) => {
  return (dispatch) => {
    db.collection("researchstudies").where(category, '==', true)
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()

          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            author: docData.author ? docData.author : "N/A",
            title: docData.title ? docData.title : "N/A",
            source: docData.source ? docData.source : "N/A",
            summary: docData.summary ? docData.summary : "N/A",
            category: docData.category ? docData.category : "N/A",
            link: docData.link ? (
              <center>
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                onClick={
                  () => window.open(docData.link)}
              >DOWNLOAD</Button></center>)
              : "N/A"
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        dispatch(setStudiesList(data))
      })
  }
}

export const setStudiesList = (dataRows) => ({
  type: 'SET_STUDIES_LIST',
  dataRows
})

