/*eslint-disable*/
import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import axios from "axios";
import firebase, { db } from "firebase/fbConfig.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import CloseIcon from '@material-ui/icons/Close';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class Form extends React.Component {
  state = {
    comment: "",
    messageSent: false,
    messageError: false,
  };
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      query: "",
    };

    // this.compareIgnoreCase = this.compareIgnoreCase.bind(this)
    // this.filterNotification = this.filterNotification.bind(this)
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
    });
  };

  loadData = (uid) => {
    const loadingData = db.collection("TEST-users").doc(this.props.authUser.uid).collection("notifications")
    .orderBy('createdTime', 'desc')
    .onSnapshot((snapshot) => {
      console.log('data', snapshot)
        const data = [];
          // Parse data into array like in firebase.js
          snapshot.forEach((doc) => {
            var docData = doc.data();
            var docObj = {
              id: doc.id,
              ...docData,
            };
            data.push(docObj);
          });
          this.setState({
            messagesData: data,
            messagesDataLoaded: true,
          });
          return data;
      }, (err) => {
        console.log('err', err)
      })
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // update firestore document with PENDING and comment
    db.collection("contact-messages")
      .doc()
      .set(
        {
          uid: this.state.uid,
          email: this.state.email,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          message: this.state.message,
          updatedAt: new Date(),
        },
        { merge: true }
      )
      .then(() => {
        console.log(``);
        this.setState({
          comment: "",
          messageSent: true,
        });
      })
      .catch((err) => {
        console.log(`${err}`);
      });
  };

  refreshNotification = () => {
    // update firestore document with PENDING and comment
    db.collection("TEST-users")
      .doc(this.props.authUser.uid)
      .set(
        {
          notification: false,
          notificationUpdatedAt: new Date(),
        },
        { merge: true }
      )
      .then(() => {
        console.log(``);
        this.setState({
          notificationRefreshed: true,
        });
      })
      .catch((err) => {
        console.log(`${err}`);
      });
  };

  resetRequest = () => {
    window.location.reload();
  };

  compareIgnoreCase(field, criteria) {
    return field.userName.toLowerCase().includes(criteria.toLowerCase())
  }

  filterNotification = (notification, criteria) => {
    return !criteria || this.compareIgnoreCase(notification, criteria);
  }

  getDate = (message) => {
    var date = new Date(message.createdTime.seconds * 1000);

    function zerofill(i) {
      return (i < 10 ? '0' : '') + i;
  }
    var day = zerofill(date.getDate());
    var month = zerofill(date.getMonth()+1);
    var year = date.getFullYear();
    var formattedTime = month + '-' + day + '-' + year

    return formattedTime
  }

  removeNotification = (index, notification) => (event) => {
    event.preventDefault();
    console.log('clicked', 'index', index, 'notification', notification, 'event', event)
    // db.collection("TEST-users")
    console.log('nid', notification.id)
    const userId = this.props.authUser.uid
    // firebase.database().ref(`TEST-users/${userId}/notifications/${notification.id}`).remove()
    db.collection("TEST-users").doc(userId).collection("notifications").doc(notification.id).delete()
    .then(() => {
      console.log('successfully deleted')
    })
    .catch((err) => {
      console.log('err', err)
    })
    // let data = db
    // .collection("TEST-users")
    // .doc(this.props.authUser.uid)
    // .collection("notifications")
    // .doc(notification.id)
    // .remove()
  }


  render() {
    const { AuthBool, classes, modalClose, authUser } = this.props;
    //console.log(authUser)
    // reset modal when closed

    console.log('message data', this.state.messagesData)
    if (this.state.messageSent) {
      this.resetRequest();
    }
    if (AuthBool) {
      if (!this.state.messagesDataLoaded) {
        this.loadData();
      }
    }
    if (this.props.authUser) {
      if (!this.state.notificationRefreshed) {
        this.refreshNotification();
      }
    }
    return (
      <div>

        <CustomInput
          formControlProps={{
            // className: classes.top + ' ' + classes.search,
            style: { width: '100%' },
          }}
          id="notificationsQuery"
          inputProps={{
            placeholder: 'Search...',
            onChange: (event) => this.setState({
              query: event.target.value
            }),
            inputProps: {
              'aria-label': 'Search',
              className: classes.searchInput,
            },
          }}
        />

        {console.log("checking states: ", this.state)}

        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Feedback Submitted!</h4>
            </GridItem>
          </GridContainer>
        ) : (
            <div>
              {this.state.messagesData &&
                this.state.messagesData
                  .filter((message) => this.filterNotification(message, this.state.query))
                  .map((message, index) => {
                    return (
                      <Link to={`/chat/${message.uid}`}>
                        <Card className={classes.notification}>
                        <div className={classes.closingX}>
                              <GridItem
                               onClick={this.removeNotification(index, message)}
                               s={12} >
                                <CloseIcon />
                              </GridItem>
                            </div>
                          <CardBody className={classes.notificationBody}>
                            <div className={classes.notificationType}>{message.name}!</div>
                            <div>{this.getDate(message)}</div>
                          </CardBody>
                        </Card>
                      </Link>
                    );
                  })}
            </div>
          )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeNotification: (nid) => dispatch({ type: 'NOTIFICATIONS_REMOVE', nid })
  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Form);
