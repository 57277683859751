import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button.jsx";

import Notification from "./Notification.jsx"

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

import dashBG from "assets/img/backgrounds/dashboard.jpeg"
import profileBG from "assets/img/backgrounds/profile.jpeg"
import coreBeliefsBG from "assets/img/backgrounds/core-beliefs.jpeg"


function Header({ ...props }) {
  function makeBrand() {
    var name;
    props.routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    if (name) {
      return name;
    } else {
      return "Application";
    }
  }

  function click() {
    console.log("wow")
  }

  const { classes, color, companyName, rtlActive } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });

  let name = makeBrand()
  let background = ""
  let backgroundPosition = ""
  if (name === "Dashboard") {
    background = dashBG;
    backgroundPosition = "0% 24%"
  } else if (name === "Profile") {
    background = profileBG;
    backgroundPosition = "24% 24%"
  } else if (name === "Core Beliefs") {
    background = coreBeliefsBG;
    backgroundPosition = "24% 10%"
  } else {
    background = "yoyos"
  }

  return (
    <AppBar
      className={classes.appBar + appBarClasses}
      style={{
        background: `linear-gradient(45deg,rgba(236,86,52,0.8),rgba(78,199,188,0.8)),url(${background})`,
        backgroundPosition: `${backgroundPosition}`,
        zIndex: "0"
      }}
    >
      <div className={classes.appBarOverlay}>.
        <Toolbar className={classes.container}>
          <Hidden smDown implementation="css">
            <div className={sidebarMinimize}>
              {props.miniActive ? (
                <Button
                  justIcon
                  round
                  style={{backgroundColor: "#FC662B"}}
                  onClick={props.sidebarMinimize}
                >
                  <ViewList className={classes.sidebarMiniIcon} />
                </Button>
              ) : (
                  <Button
                    justIcon
                    round
                    style={{backgroundColor: "#FC662B"}}
                    onClick={props.sidebarMinimize}
                  >
                    <MoreVert className={classes.sidebarMiniIcon} />
                  </Button>
                )}
            </div>
          </Hidden>
          <div className={classes.flex} style={{ color: "white" }}>
            {/* Here we create navbar brand, based on route name */}
            <Button href="#" className={classes.title} color="transparent">
              {makeBrand()}
            </Button>
            
            <Hidden smDown implementation="css">
            <span style={{
              float: "right",
              marginTop: "-2.2rem",
              marginRight:"1rem",
              textAlign: "right"
            }}>Welcome {companyName}
            </span>
            </Hidden>
          </div>
          {/* <span style={{
              float: "right",
              marginTop: ".75rem",
              marginRight: "2rem"
            }}>
              <Link to="/notifications"><Notification /></Link>
            </span> */}

          <Hidden smDown className={classes.headerLinksWrapper} implementation="css">
            <HeaderLinks rtlActive={rtlActive} />
          </Hidden>
          <Hidden mdUp implementation="css">
            <Button
              className={classes.appResponsive}
              style={{
                color: "#fff"
              }}
              color="transparent"
              justIcon
              aria-label="open drawer"
              //onClick={click()}
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
        </Toolbar>
      </div>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
};

export default withStyles(headerStyle)(Header);
