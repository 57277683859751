// ##############################
// // // Dashboard View styles
// #############################

import {
    bigNumbers,
    primaryColor,
    dangerColor,
    successColor,
    tooltip,
    bodyText,
    card,
    cardTitle,
    cardSubtitle,
    title
  } from "assets/jss/material-dashboard-pro-react.jsx";
  
  import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
  import cardImagesStyles from "assets/jss/material-dashboard-pro-react/cardImagesStyles.jsx";
  import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";
  
  const dashboardStyle = theme => ({ 
    ...modalStyle(theme),
    ...cardImagesStyles,
    // Profile Pic
    profilePic: {
      borderRadius: "4px",
      display: "block",
      width: "100%",
      marginBottom: "5px"
    },
    primary: {
      backgroundColor: primaryColor,
      color: "#FFFFFF",    
    },
    dangerIcon: {
      color: dangerColor
    },
    successIcon: {
      color: successColor
    },
    bigNumbers: {
      ...bigNumbers    
    },
    ...card,
    ...hoverCardStyle,
    tooltip,
    cardTitle: {
      ...cardTitle,
      marginTop: "0px",
      marginBottom: "3px"
    }, 
    title: {
      ...title,
      marginTop: "0",
      marginBottom: "0",
      padding: "10px",
      color: "#fff"
    },
    modalTitle: {
      ...title,
      color: "#fff",
      marginBottom: '0',
      marginTop: '0'
    },
    modalBody: {
      ...bodyText,
      color: "#fff",
      marginBottom: '0',
      marginTop: '0'
    },
    subTitle: {
    ...title,
    color: "black",
    fontWeight: "300",
  },
    body: {
      ...bodyText
    },  
    cardSubtitle: {
      ...title,
      marginTop: "0",
      marginBottom: "0"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },
    cardProductTitle: {
      ...cardTitle,
      marginTop: "0px",
      marginBottom: "3px",
      textAlign: "center"
    },
    cardCategory: {
      color: "#999999",
      fontSize: "14px",
      paddingTop: "10px",
      marginBottom: "0",
      marginTop: "0",
      margin: "0"
    },
    cardProductDesciprion: {
      textAlign: "center",
      color: "#999999"
    },
    stats: {
      color: "#999999",
      fontSize: "12px",
      lineHeight: "22px",
      display: "inline-flex",
      "& svg": {
        position: "relative",
        top: "4px",
        width: "16px",
        height: "16px",
        marginRight: "3px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        position: "relative",
        top: "4px",
        fontSize: "16px",
        marginRight: "3px"
      }
    },
    productStats: {
      paddingTop: "7px",
      paddingBottom: "7px",
      margin: "0"
    },
    successText: {
      color: successColor
    },
    upArrowCardCategory: {
      width: 14,
      height: 14
    },
    underChartIcons: {
      width: "17px",
      height: "17px"
    },
    price: {
      color: "inherit",
      "& h4": {
        marginBottom: "0px",
        marginTop: "0px"
      }
    },
    center: {
      textAlign: "center"
    },
    dashboardModalBtn: {
        width: "50%",
        margin: "0 auto"
    },
    scores: {
        fontSize: "1.3rem",
        textAlign: "center"
    },
    blackBar: {
        backgroundColor: "#333"
    },
    tealBar: {
        backgroundColor: "#4ec7bc",
        padding: "-10px"
    },
    img: {
        width: "100%"
    },
    button:{
      backgroundColor: "#FC662B",
      color: "white",
      fontSize: "12px",
      borderRadius: "5px",
      boxShadow: "4px 4px 0px 0px #e3470a",
      "&:hover": {
        backgroundColor: "#FC662B",
        color: "white",
        boxShadow: "2px 2px 0px 0px #e3470a"
      },
    }
  });
  
  export default dashboardStyle;
  