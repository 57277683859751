/*eslint-disable*/
import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetPracticesList = () => {
  return (dispatch) => {
    db.collection("practices").orderBy('order')
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()

          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            title: docData.title ? docData.title : "N/A",
            description: docData.description ? docData.description : "N/A",
            category: docData.category ? docData.category : "N/A",
            topic: docData.topiclong ? docData.topiclong : "N/A",
            purpose: docData.purpose ? docData.purpose : "N/A",
            link: docData.link ? (
              <center>
                <Button
                  style={{
                    width: "85%",
                    backgroundColor: "#FC662B",
                    color: "white",
                    fontSize: "12px",
                    borderRadius: "5px",
                    boxShadow: "4px 4px 0px 0px #e3470a",
                    "&:hover": {
                      backgroundColor: "#FC662B",
                      color: "white",
                      boxShadow: "2px 2px 0px 0px #e3470a"
                    },
                  }}
                  fullWidth
                  onClick={
                    () => window.open(docData.link)}
                >DOWNLOAD</Button>
              </center>
            )
              : "N/A"
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        dispatch(setPracticesList(data))
      })
  }
}

export const startSetPurposePracticesList = (category) => {
  return (dispatch) => {
    db.collection("practices").where(category, '==', true)
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()

          var docObj = {
            id: doc.id,
            order: docData.order ? docData.order : "N/A",
            title: docData.title ? docData.title : "N/A",
            description: docData.description ? docData.description : "N/A",
            category: docData.category ? docData.category : "N/A",
            topic: docData.topiclong ? docData.topiclong : "N/A",
            order: docData.order ? docData.order : "N/A",
            link: docData.link ? (
              <Button
                color="primary"
                fullWidth
                onClick={
                  () => window.open(docData.link)}
              >DOWNLOAD</Button>)
              : "N/A"
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        console.log(data)
        dispatch(setPracticesList(data))
      })
  }
}

export const setPracticesList = (dataRows) => ({
  type: 'SET_PRACTICES_LIST',
  dataRows
})

