import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetFeedbackList = () => {
  return (dispatch) => {
    db.collection("feedback")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            ...docData,
            id: doc.id,
            displayName: docData.displayName ? docData.displayName : "N/A",
            page: docData.page ? docData.page : "N/A",
            comment: docData.comment ? docData.comment : "N/A",
            updatedAt: docData.updatedAt ? moment(docData.updatedAt.toDate()).format('MM-DD-YYYY HH:mm') : "N/A",
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setFeedbackList(data))
      })
  }
}

export const setFeedbackList = (dataRows) => ({
  type: 'SET_FEEDBACK_LIST',
  dataRows
})

