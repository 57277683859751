import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js"
import { Chart } from 'react-google-charts'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'

import marked from "marked";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/practicesStyle.jsx";
import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"
import BlogCommentForm from "components/Forms/BlogCommentForm.jsx"

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import { setFirstAssessment } from "store/actions/assessmentActions"
import { startSetUsersList } from "store/actions/adminActions"
import { startSetUpcomingCoursesList } from "store/actions/coursesActions"
import DocumentUpload from "components/CustomUpload/DocumentUpload.jsx"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}



class Principles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updatePhotoModal: false,
      learnDashboardModal: false,
      markdownFlag: false
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = (courseID, uid) => {
    let data = db.collection("TEST-users").doc(uid).collection("registeredCourses").doc(courseID)
      .get().then((snapshot) => {
        var docData = snapshot.data()
        if (docData) {
          var docObj = {
            id: snapshot.id,
            title: docData.title ? docData.title : "N/A",
            }
          return (docObj)
        } else {
          return (null)
        }
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(docObj => {
        this.setState({
          courseData: docObj,
          dataLoaded: true
        })
      })

      
    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
  }

  registerCourse = (courseID, uid) => {
    db.collection("TEST-users").doc(uid).collection("registeredCourses").doc(courseID).set({
        attended: true,
        updatedAt: new Date()
      }, {merge: true}).then(() => {
        console.log(``)
        this.setState({
          registered: true
        })
      }).catch((err) => {
        console.log(`${err}`)
      })
    
  }

  // Load data for Table
  // if there are no actions make a default one
  deleteComment = (id) => {
    console.log("delete" + id)
      db.collection("blogPosts").doc("blog1").collection("comments").doc(id).delete().then(() => {
        console.log(``)
            window.location.reload();
      }).catch((err) => {
        console.log(`${err}`)
      })
  }

  componentDidMount() {
  }

  render() {
    const { AuthBool, classes, user, upcomingCourses } = this.props;
    
    const input = '# This is a header\n\nAnd this is a paragraph'
    if (AuthBool) {
      if (!this.state.dataLoaded) {
          this.loadData(this.props.courseID, this.props.uid)
      }
  }

      this.registerCourse(this.props.courseID, this.props.uid)

console.log(this.state)
    return (
      <div>
      {this.state.registered ? <Redirect to="/grow/courses" /> : null}

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
         
        </GridItem>
      </GridContainer>
      </div>
    );
  } 
}

const mapStateToProps = (state) => {
  console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    blogs: state.blogs.blogs,
    blog: state.blogs.blog,
        comments: state.blogs.blogComments,
        upcomingCourses: state.courses.upcomingCourses

  }
}

const mapDispatchToProps = (dispatch) => {
  // console.log(this.props)
  // console.log(this.state)
    return {
      startSetUpcomingCoursesList: dispatch(startSetUpcomingCoursesList())
    }
  }
  

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Principles);