import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import { db } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class Form extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      condition: this.props.user.purposeOriginCondition && this.props.user.purposeOriginCondition,
      problem: '',
      solution: '',
      messageSent: false,
      messageError: false,
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "", condition: this.props.user.purposeOriginCondition && this.props.user.purposeOriginCondition, problem: this.props.user.purposeOriginProblem && this.props.user.purposeOriginProblem, solution: this.props.user.purposeOriginSolution && this.props.user.purposeOriginSolution });
      }.bind(this),
      700
    );
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log('recievingprops', nextProps)
  //   this.setState({
  //     condition: nextProps.condition,
  //     problem: nextProps.problem,
  //     solution: nextProps.solution
  //   }, () => console.log('recievingpropsstate', this.state))
  // }




  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid, name) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
      name: name
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();

    // update firestore document with PENDING and declaration
    db.collection("TEST-users").doc(this.state.uid).set({
      purposeOriginCondition: this.state.condition ? this.state.condition : (this.props.user.purposeOriginCondition ? this.props.user.purposeOriginCondition : ''),
      purposeOriginProblem: this.state.problem ? this.state.problem : (this.props.user.purposeOriginProblem ? this.props.user.purposeOriginProblem : ''),
      purposeOriginSolution: this.state.solution ? this.state.solution : (this.props.user.purposeOriginSolution ? this.props.user.purposeOriginSolution : ''),
      updatedAt: new Date()
    }, { merge: true }).then(() => {
      console.log(``)
      this.setState({
        condition: '',
        problem: '',
        solution: '',
        messageSent: true
      })
    }).catch((err) => {
      console.log(`${err}`)
    })
  }

  resetRequest = () => {
    window.location.reload();
  }

  render() {
    const { classes, authUser, user } = this.props;

    // reset modal when closed
    if (this.state.messageSent) { this.resetRequest() }
    console.log(this.state)
    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Purpose Origin Set!</h4>

            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer}>
            <form onSubmit={this.handleSubmit}
            >
              <GridContainer justify="center">
                <Tooltip
                  id="tooltip-top"
                  title="Underlying Condition: That condition in your stakeholder world that all desire (e.g. peace, health, full self-expression, love, etc.) and that you/your organization to making it accessible to all. (Max of 15 words)"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}>
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}

                    <CustomInput
                      labelText="Desired Underlying Condition"
                      id="condition"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.condition || '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />

                  </GridItem>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Specific Problem: Something that is in the way of that Desired Underlying Condition being accessible to all that your organization wants to address. (Max of 15 words)"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}>
                  <GridItem xs={11}>

                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}

                    <CustomInput
                      labelText="Specific Problem"
                      id="problem"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.problem || '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />

                  </GridItem>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Solution: How your organization will be addressing the Specific Problem; either searching for the solution or offering a solution. This is the reason why your organization exists: to address the solution to this Specific Problem. (Max of 15 words)"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}>
                  <GridItem xs={11}>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}

                    <CustomInput
                      labelText="Solution"
                      id="solution"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.solution || '',
                        required: true,
                        type: "text",
                        onChange: (event) => this.handleChange(event, authUser.uid)
                      }}
                    />

                  </GridItem>
                </Tooltip>
              </GridContainer>
              <Button
                type="submit"
                className={classes.button}
                style={{marginLeft:"40px"}}
              >
                SUBMIT
              </Button>
            </form>
          </div>

        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  console.log('recievingprops', state.profile.user)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);