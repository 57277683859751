// ##############################
// // // Dashboard View styles
// #############################

import {
  bigNumbers,
  primaryColor,
  dangerColor,
  successColor,
  tooltip,
  bodyText,
  card,
  cardTitle,
  cardSubtitle,
  title
} from "assets/jss/material-dashboard-pro-react.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import cardImagesStyles from "assets/jss/material-dashboard-pro-react/cardImagesStyles.jsx";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";

const dashboardStyle = theme => ({ 
  ...modalStyle(theme),
  ...cardImagesStyles,
  // Profile Pic
  profilePic: {
    // borderRadius: "50%",
    display: "block",
    width: "50%",
    marginTop: "5%",
    zIndex: "9",
    margin: "0 auto",
    boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  profilePicWrapper: {
    margin: "0 auto",
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",    
  },
  dangerIcon: {
    color: dangerColor
  },
  successIcon: {
    color: successColor
  },
  bigNumbers: {
    ...bigNumbers    
  },
  ...card,
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  }, 
  title: {
    ...title,
    color: "#ec5634"
  },
  modalTitle: {
    ...title,
    color: "black",
    marginBottom: '0',
    marginTop: '0'
  },
  modalBody: {
    ...bodyText,
    color: "#fff",
    marginBottom: '0',
    marginTop: '0'
  },
  subTitle: {    
    ...title,
    color: primaryColor,
    fontSize:"20px",
    marginTop: "0",
    marginBottom: "0"
  },
  body: {
    ...bodyText,
    fontSize:"16px"
  },  
  profileBody: {
    ...bodyText,
    width: "50%",
    color: "#fff",
    textAlign: "right",
    margin: "0 auto",
    marginTop: "-10px"
  },  
  cardSubtitle: {
    ...title,
    marginTop: "0",
    marginBottom: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: "#999999",
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: "#999999"
  },
  stats: {
    color: "#999999",
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  center: {
    textAlign: "center"
  },
  button: {
    borderRadius: "5px",
    color:"white",
    backgroundColor: "#FC662B",
    boxShadow: "4px 4px 0px 0px #755c26",
    "&:hover": {
      backgroundColor: "#FC662B",
      color: "white",
      boxShadow: "2px 2px 0px 0px #755c26"
    }, 
  }
});

export default dashboardStyle;
