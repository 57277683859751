import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"
import moment from 'moment'
import { Link } from 'react-router-dom'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Accordion from "components/Accordion/Accordion.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/threadsStyle";
import { Grid } from "@material-ui/core";

// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions'
import { tableToFirestoreObjects, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetUsersList } from "store/actions/adminActions"
import { startSetThreadsList, startGetCurrentThread } from "store/actions/threadsActions"
import { startSetMessagesList } from "store/actions/messagesActions"
import avatar from "assets/img/faces/user.png";
import DefaultProfile from "assets/img/default-avatar.png"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: '',
      refresh: false,
      messageSize: null,
      messages: [],
      query: db.collection("TEST-users").doc(this.props.uid).collection("threads").doc(this.props.id).collection("messages")
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.messages.length !== prevState.messages.length) {
        const messagesDiv = document.getElementById('messages');
        messagesDiv.scrollTop = messagesDiv.scrollHeight;
    }
}

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = (uid, id) => {
    let data = db.collection("TEST-users").doc(uid).collection("threads").doc(id).collection("messages").orderBy('createdTime')
    .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          var docObj = {
            id: doc.id,
            message: docData.message ? docData.message : "N/A",
            uid: docData.uid ? docData.uid : "N/A",
            createdTime: docData.createdTime ? docData.createdTime : "N/A"
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        this.setState({
          data: data,
          messages: data,
          dataLoaded: true,
          refresh: false
        })
      })
      
    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
  }


//   loadData = () => {
//     let usersData = this.props.users.dataRows.map((prop, key) => {
//       let colObj = { ...prop }
//       const volUID = colObj.volUID
//       const username = colObj.username
//       // Load Data for Summary Cards and add buttons     
//       return {
//         ...colObj,
//         // status: (<span style={{ color: "red" }}>assigned</span>),
//         id: key,
//         actions: (
//           <Button
//             color="primary"
//             fullWidth
//             onClick={
//               () => this.handleClickOpen("assignActionModal",
//                 volUID
//               )}
//           >ASSIGN ACTION</Button>)
//       }
//     })

//     this.setState({
//       usersData,
//       dataLoaded: true
//     })
//   }
  refresh = () => {
    this.setState({
        refresh: true
      })
  }

  changeMessageSize = (size) => {
    this.setState({
        messageSize: size
      })
  }

  handleChange = (docID) => {
    console.log(docID)
  }
  componentWillMount() {
  }

  render() {
    const { AuthBool, classes, threads, messages, currentThread, user, uid, id } = this.props;
    const searchButton =
    classes.top +
    " " +
    classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded
        && !this.state.dataLoaded) {
        this.loadData(uid, id)
      }
    }

    if (this.state.refresh) { this.loadData(uid, id) }
    
    let observer = this.state.query.onSnapshot(querySnapshot => {
      console.log(`Received query snapshot of size ${querySnapshot.size}`);
      if(this.state.messageSize === null) {
      this.changeMessageSize(querySnapshot.size)
      } else if (this.state.messageSize !== querySnapshot.size) {
        this.changeMessageSize(querySnapshot.size)
        this.loadData(uid, id)
      }
      // ...
    }, err => {
      console.log(`Encountered error: ${err}`);
    });

    let profile; 
    
    // if (this.props.users.dataRows !== undefined) {
    //   //let index = props.users.dataRows.indexOf(id);
    //   let obj = this.props.users.dataRows.find(o => o.volUID === id);
    //   console.log(this.props.users.dataRows)
    //   console.log(obj)
    //   profile =  obj.profileURL
    // }

    console.log(user)

    // let profilePhoto = DefaultProfile
    // if (AuthBool) {
    //   (user.dataLoaded && user.profileURL) ? (
    //     profilePhoto = user.profileURL
    //   ) : (
    //       // add link to profile photo to firestore
    //       profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
    //       // add profile photo to firebase storage        
    //       // update redux with link
    //     )
    // }

    //console.log(this.state)
    return (
      <div>
        {
          this.state.dataLoaded ? (
              <div id='messages'>
              {this.state.data && this.state.data.map(message => {
                  if (message.uid === uid) {
                return (
                <div>
                      <GridContainer>
                          <GridItem xs={9}>
                          <Card style={{marginTop: "0px"}}>
                          <CardBody>
        
                          <p style={{marginTop: "-10px", textAlign: "right"}} className={classes.cardCategory}>
                                  {message.message} 
                              </p>
                              </CardBody>
                              </Card>
            
                          </GridItem>
                          <GridItem xs={2} >

                          <img src={user.profileURL} style={{ borderRadius: "50%", height: "50px" }} alt="..." />
                      </GridItem>
                      </GridContainer>
                    </div>
                    )} else {
                        return (
                            <div>
                                    <GridContainer>
                                        <GridItem xs={2} >
              
                                            <img src={profile} style={{ borderRadius: "50%", height: "50px" }} alt="..." />
                                        </GridItem>
                                        <GridItem xs={9}>
                                        <Card style={{marginTop: "0px"}}>
                                        <CardBody>
        
                                        <p  style={{marginTop: "-10px"}} className={classes.cardCategory}>
                                                {message.message} 
                                            </p>
                                            </CardBody>
                                            </Card>
            
                                        </GridItem>
                                    </GridContainer>
                                  </div>              
                        )
                    }
            })}


              
              </div>    
          ) : (
              <p className={classes.cardSubtitle}>Loading...</p>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
    user: state.profile.user,
    threads: state.threads.threads,
    messages: state.messages.messages,
    currentThread: state.threads.currentThread
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
//   console.log(ownProps)
  return {
    startSetUsersList: dispatch(startSetUsersList()),
    startGetCurrentThread: dispatch(startGetCurrentThread(ownProps.uid, ownProps.id)),
    startSetMessagesList: dispatch(startSetMessagesList(ownProps.uid, ownProps.id))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Users);
