import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import {
  setList,
  setListItem,
  startSaveList,
  submit,
} from "store/actions/playbookListActions.jsx";
import CoreGoalsForm from "./CoreGoalsForm.jsx";
import CoreValuesForm from "./CoreValuesForm.jsx";
import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class Form extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  render() {
    const {
      classes,
      submit,
      submitted,
      uid,
      coreValues,
      coreGoals
    } = this.props;

    const resetRequest = () => {
      window.location.reload();
    }


    return (
      <div>
        {submitted ? (
          <GridContainer justify="center">
            <GridItem xs={11}> {resetRequest()}
              <h4 className={classes.textPrimary}>
                Core Goals and Values Set!
              </h4>
            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submit(uid, coreValues, coreGoals);
              }}
            >
              <h5 style={{marginLeft:"30px"}} className={classes.subTitle}>What are your Core Values?</h5>
              <CoreValuesForm uid={this.props.uid} />
              <h5 style={{marginLeft:"30px"}} className={classes.subTitle}>What are your Goal Values?</h5>
              <CoreGoalsForm uid={this.props.uid} />
              <Button
                type="submit"
                className={classes.button}
                style={{marginLeft:"25px"}}
              >
                SUBMIT
              </Button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.profile.user,
    submitted: state.playbookList.coreValuesGoals.submitted,
    uid: state.auth.user.uid,
    coreValues: state.playbookList.coreValues.values,
    coreGoals: state.playbookList.coreGoals.values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (uid, coreValues, coreGoals) =>
      Promise.all([
        dispatch(startSaveList(uid, "coreValues", coreValues)),
        dispatch(startSaveList(uid, "coreGoals", coreGoals)),
      ]).then(() => dispatch(submit("coreValuesGoals"))),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Form);
