import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"

// Get Users for Users Panel
export const startSetUsersList = () => {
  return (dispatch) => {
    db.collection("TEST-users")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var groups
          if (docData.groups) {
            docData.groups.length === 0 ?
            groups = 'no groups'
            : (
              docData.groups.forEach(group => {
                groups ? groups += ", " + group: groups = group
              }))
              
              var termGroups
              docData.termGroups.length === 0 ?
              termGroups = 'no term groups'
                : (
                  docData.termGroups.forEach(termGroup => {
                    termGroups ? termGroups += ", " + termGroup: termGroups = termGroup
                  }))
    
          if (docData.disable) {
          } else {
            var docObj = {
              UID: doc.id,
              companyName: docData.companyName ? docData.companyName : "N/A",
              firstName: (docData.firstName && !docData.namePrivacy) ? docData.firstName : "N/A",
              lastName: (docData.lastName && !docData.namePrivacy) ? docData.lastName : "N/A",
              username: docData.username,
              phone: (docData.phone && !docData.contactPrivacy) ? docData.phone : "N/A",
              email: (docData.email && !docData.contactPrivacy) ? docData.email : "N/A",
              businessType: docData.businessType ? docData.businessType : "N/A",
              peLevelName: docData.peLevelName ? docData.peLevelName : "N/A",
              seLevelName: docData.seLevelName ? docData.seLevelName : "N/A",
              saLevelName: docData.saLevelName ? docData.saLevelName : "N/A",
              aaLevelName: docData.aaLevelName ? docData.aaLevelName : "N/A",
              purposeDeclaration: docData.purposeDeclaration ? docData.purposeDeclaration : "N/A",
              groups: ((docData.groups[0] ? docData.groups[0] + ", " : "") + (docData.groups[1] ? docData.groups[1] + ", " : "") + (docData.groups[2] ? docData.groups[2] + ", " : "") + (docData.groups[3] ? docData.groups[3] + ". " : "") + (docData.groups[4] ? docData.groups[4] + ", " : "")),
              termGroups,
              score: docData.score
            }
            data.push(docObj);
          }
         
        }})
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setUsersList(data))
      })
  }
}

export const setUsersList = (dataRows) => ({
  type: 'SET_ADMIN_USERS_LIST',
  dataRows
})

