// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle,
  dangerColor,
  whiteColor,
  linkText
} from "assets/jss/material-dashboard-pro-react.jsx";

const loginPageStyle = theme => ({  
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  formContainer: {
    margin: "0 auto"
  },
  cardTitle: {
    ...cardTitle,
    color: "#fff",
    fontSize: '24px'
  },
  textCenter: {
    textAlign: "center"    
  },
  linkText,
  textDanger: {
    fontSize: '12px',
    color: dangerColor,
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF"
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: whiteColor
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    margin: "20px",
    padding: "10px 120px",
    background:"#FC662B"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  button:{
    backgroundColor: "#FC662B",
    color: "white",
    fontSize: "12px",
    borderRadius: "5px",
    boxShadow: "4px 4px 0px 0px #e3470a",
    "&:hover": {
      backgroundColor: "#FC662B",
      color: "white",
      boxShadow: "2px 2px 0px 0px #e3470a"
    },
  }
});

export default loginPageStyle;
