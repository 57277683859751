import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import {
  setList,
  setListItem,
  startSaveList,
  submit,
  setCommunityStakeholderForm
} from "store/actions/playbookListActions.jsx";
import DraggableCardContainer from "components/Draggable/DraggableCardContainer.jsx";
import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

function CardContent({ card, setCard }) {
  console.log('stateeexc', card)
  return (
    <GridItem xs={11}>
      <CustomInput
        labelText="Member Name"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          value: card ? card.text : "",
          required: false,
          type: "text",
          onChange: (event) => setCard({ text: event.target.value }),
        }}
      />
    </GridItem>
  );
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      newSubmitted: false
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  render() {
    const {
      classes,
      cards,
      setCards,
      setCard,
      submit,
      submitted,
      uid,
      noTextErrorString
    } = this.props;


    const resetRequest = () => {
      window.location.reload();
    }

    const setForm = (boolean) => {
      this.props.setForm(boolean)
    }

    return (
      <div>
        {submitted ? (
          <div className={classes.formContainer}>
            <GridContainer justify="center">
              <GridItem xs={11}>
                <h4 className={classes.textPrimary}>Stakeholder Community Set!</h4>
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <div className={classes.formContainer}>
            <form onSubmit={e => { e.preventDefault(); submit(uid, cards); }}
            >
              <GridContainer justify="center">
                <DraggableCardContainer
                  cards={cards}
                  setCards={setCards}
                  setCard={setCard}
                  renderContent={CardContent}
                />
              </GridContainer>
              {noTextErrorString !== '' ? (
                <h6 style={{ textAlign: "center", fontSize: "14px" }}>{noTextErrorString}</h6>
              ) : (<div></div>)}
              <Button
                type="submit"
                className={classes.button}
                style={{marginLeft:"25px"}}
              >
                SUBMIT
              </Button>
            </form>
          </div>
        )}
        {/* )} */}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  console.log('stateeexcerrors', state.playbookList.noTextErrorString)
  return {
    user: state.profile.user,
    cards: state.playbookList.members.values,
    submitted: state.playbookList.members.submitted,
    uid: state.auth.user.uid,
    noTextErrorString: state.playbookList.noTextErrorString
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCards: list => dispatch(setList('members', list)),
    setCard: (index, item) => dispatch(setListItem('members', index, item)),
    submit: (uid, cards) => dispatch(startSaveList(uid, 'members', cards)).then(() => dispatch(submit('members'))),
    setForm: (boolean) => dispatch(setCommunityStakeholderForm(boolean)),

  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Form);
