/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slider from "react-slick";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import Popper from "@material-ui/core/Popper";
import moment from "moment"
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import BlockIcon from '@material-ui/icons/Block';
import { Tooltip, IconButton } from "@material-ui/core";


// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import { Info } from "@material-ui/icons";
// import MuiAlert from '@material-ui/lab/Alert';

// core components
import Heading from "components/Heading/Heading.jsx";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import Fade from '@material-ui/core/Fade';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Instruction from "components/Instruction/Instruction.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/metricsStyle.jsx";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import ReactTable from "react-table";
import MetricsCarousel from './MetricsCarousel.jsx'
import { LoremIpsum } from "lorem-ipsum";
import { NavLink } from "react-router-dom";

import reorder from 'array-rearrange'
import sort from 'sort-ids'

import { getMetricsData, updateMetric, deleteMetric, enableDisableMetric, changeHeadersActions, setNewRowIndex, setDeleteMetricModal, getNewYearMetrics, getRangeOfYearsMetricData, updateRangeOfYearsMetricData, setUpdatedRange, getCurrentDashboardExportData, getRecentDashboardExportData, getYearlyDashboardExportData, getDataDownloadExportData } from "store/actions/metricsActions.jsx";

import loader from "assets/img/preloader.gif"
import { db, firebase } from "firebase/fbConfig.js"

// components
import AddMetricFormCurrentDashboard from "components/Forms/AddMetricFormCurrentDashboard.jsx";
import AddMetricFormOtherDashboards from "components/Forms/AddMetricFormOtherDashboards.jsx";

import { CSVLink, CSVDownload } from "react-csv";

//set NODE_OPTIONS=--max_old_space_size=4096

const randomDate = moment(new Date(+(new Date()) - Math.floor(Math.random() * 100000)))
  .format('MM/DD/YYYY');

function generateRandomDate() {
  return new Date(+(new Date()) - Math.floor(Math.random() * 10000000000));
}

const headers = []



const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 16,
    min: 4
  }
});


const metricTypeOptions = ['Purpose', 'Stakeholder Engagement', 'Health', 'Value Proposition', 'Competencies', 'Initiatives']

var currentDashboardTableHeaders = ['Metric Type', 'Metric Description', 'Measure Definition', 'Owner', '5 Year Target', '1 Year Target', 'Quarter Target', 'Baseline', 'Current Month', 'Actions']

var recentDashboardTableHeaders = ['Metric Type', 'Metric Description', 'Measure Definition', 'Owner', '5 Year Target', '1 Year Target', 'Quarter Target', 'Baseline', 'Jan-21', 'Feb-21', 'Mar-21', 'Apr-21', 'May-21', 'Jun-21', 'Jul-21', 'Aug-21', 'Actions']


var yearlyDashboardTableHeaders = ['Metric Description', 'Jan-21', 'Feb-21', 'Mar-21', 'Apr-21', 'May-21', 'Jun-21', 'Jul-21', 'Aug-21', 'Sep-21', 'Oct-21', 'Nov-21', 'Dec-21', 'Actions']

var dataDownloadDashboardTableHeaders = ['Metric Type', 'Metric Description', 'Measure Definition', 'Owner', '5 Year Target', '1 Year Target', 'Quarter Target', 'Baseline', 'Jan\n21', 'Feb-21', 'Mar-21', 'Apr-21', 'May-21', 'Status', 'Actions']

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Metrics = (props) => {
  const { classes, user, authUser, dataLoaded, yearsQty, finishedSettingRange, currentDashboardCSVData, recentDashboardCSVData, yearlyDashboardCSVData, dataDownloadTableDataCSV } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportPopperOpen, setExportPopperOpen] = useState(false)
  const [editPopperOpen, setEditPopperOpen] = useState(false)
  const [learnAboutDashboardOpen, setLearnAboutDashboardOpen] = useState(false)
  const [closeLearnAboutDashboardOpen, setCloseLearnAboutDashboardOpen] = useState(false)
  const [chooseActiveOrInactiveModal, setChooseActiveOrInactiveModal] = useState(false)
  const [exportMetricStatuses, setExportMetricStatuses] = useState('both')
  const [editRow, setEditRow] = useState(false)
  const [tableData, setTableData] = useState([])
  const [filteredTableData, setFilteredTableData] = useState([])
  const [finishedLoading, setFinishedLoading] = useState(false)
  const [indexOfMetric, setIndexOfMetric] = useState(null)
  const [editingMetric, setEditingMetric] = useState(false)
  const [currentTableData, setCurrentTableData] = useState([])
  const [recentColumnHeaders, setRecentColumnHeaders] = useState([])
  const [yearlyColumnHeaders, setYearlyColumnHeaders] = useState([])
  const [dataDownloadColumnHeaders, setDataDownloadColumnsHeaders] = useState([])
  const [currentDashboardStartingMonth, setCurrentDashboardStartingMonth] = useState('')
  const [changedMonths, setChangedMonths] = useState(false)

  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'))
  const [selectedEndYear, setSelectedEndYear] = useState(moment().format('YYYY'))
  const [selectedYearLastTwoDigits, setSelectedYearLastTwoDigits] = useState(selectedYear.slice(2))
  const [selectedEndYearLastTwoDigits, setSelectedEndYearLastTwoDigits] = useState(selectedEndYear.slice(2))
  const [monthsToDisplay, setMonthsToDisplay] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])
  const [addMetricCurrentDashboardsModalOpen, setAddMetricCurrentDashboardsModalOpen] = useState(false)
  const [recentTrendingDashboardModalOpen, setRecentTrendingDashboardModalOpen] = useState(false)
  const [uid, setUid] = useState(null)
  const [nextYearsData, setNextYearsData] = useState([])
  const [rangeTableData, setRangeTableData] = useState([])
  console.log('rangeToReturn', 'rangeTableData', rangeTableData)
  const [editRangeData, setEditRangeData] = useState({})
  const [currentTableDataCSVFormat, setCurrentTableDataCSVFormat] = useState([])
  const year = (new Date()).getFullYear();
  const [buttonClicked, setButtonClicked] = useState(false)
  const [stringClicked, setStringClicked] = useState(false)

  var years = Array.from(Array(2041 - 2018), (_, i) => (i + 2018).toString())

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


  let editCurrentDashboardInput = useRef({
    metricType: '', metricDescription: '', measureDefinition: '', owner: '', fiveYearTarget: '0', oneYearTarget: '0', quarterTarget: '', baseline: '', currentMonth: ''
  })

  let editRecentDashboardInput = useRef({
    metricType: '', metricDescription: '', measureDefinition: '', owner: '', fiveYearTarget: '0', oneYearTarget: '0', quarterTarget: '', baseline: '', jan: 'N/A', feb: 'N/A', mar: 'N/A', apr: 'N/A', may: 'N/A', jun: 'N/A', jul: 'N/A', aug: 'N/A'
  })

  let editRecentDashboardNextYearsInput = useRef({
    jan: 'N/A', feb: 'N/A', mar: 'N/A', apr: 'N/A', may: 'N/A', jun: 'N/A', jul: 'N/A', aug: 'N/A', sep: 'N/A', oct: 'N/A', nov: 'N/A', dec: 'N/A'
  })

  let editYearlyDashboardNextYearsInput = useRef({
    jan: 'N/A', feb: 'N/A', mar: 'N/A', apr: 'N/A', may: 'N/A', jun: 'N/A', jul: 'N/A', aug: 'N/A', sep: 'N/A', oct: 'N/A', nov: 'N/A', dec: 'N/A'
  })




  let editYearlyDashboardInput = useRef({
    metricType: '', metricDescription: '', jan: 'N/A', feb: 'N/A', mar: 'N/A', apr: 'N/A', may: 'N/A', jun: 'N/A', jul: 'N/A', aug: 'N/A', sep: 'N/A', oct: 'N/A', nov: 'N/A', dec: 'N/A'
  })

  let editDataDownloadDashboardInput = useRef({
    metricType: '', metricDescription: '', measureDefinition: '', owner: '', fiveYearTarget: '0', oneYearTarget: '0', quarterTarget: '', baseline: '', currentMonth: '', jan: '', feb: '', mar: '', apr: '', may: '', startDate: '', status: '',
  })

  useEffect(() => {
    var dataToReturn = props.data

    setTableData(dataToReturn)

    if (!currentDashboardStartingMonth) {
      setCurrentDashboardStartingMonth(props.monthToStartFilter)
    }
    if (props.rangeOfData && props.finishedSettingRange) {
      var data = props.rangeOfData

      var idObj = {}
      for (var x = 0; x < data.length; x++) {
        console.log('data[x].id', data[x].id)
        var idToCheck = data[x].id

        if (!idObj[idToCheck]) {
          idObj[idToCheck] = data[x]
        } else {
          var objectToCheck = data[x]
          for (var key in objectToCheck) {
            if (!idObj[idToCheck][key]) {
              idObj[idToCheck][key] = objectToCheck[key]
            }
          }
        }
      }


      var toReturn = []
      for (var keys in idObj) {
        toReturn.push(idObj[keys])
      }

      console.log('tooreutrn', toReturn)

      setRangeTableData(toReturn)
    }


    setFinishedLoading(true)
    setRecentColumnHeaders(props.recentColumnHeaders)
    setYearlyColumnHeaders(props.yearlyColumnHeaders)
    setDataDownloadColumnsHeaders(props.dataDownloadColumnHeaders)
    if (props.submitted) {
      setAddMetricCurrentDashboardsModalOpen(false)
    }
    if (props.authUser) {
      setUid(props.authUser.uid)
    }
    if (props.nextYearsDataLoaded) {
      setNextYearsData(props.nextYearsData)
    }



  }, [props])

  // useEffect(() => {
  //   if (props.finishedSettingRange) {
  //     setRangeTableData(props.rangeOfData)
  //   } else {
  //     setRangeTableData([])
  //   }
  // }, [props.finishedSettingRange])


  useEffect(() => {
    if (props.monthToStartFilter !== "January") {
      props.changeHeadersActions(props.monthToStartFilter)
    }
  }, [props.monthToStartFilter])


  useEffect(() => {
    if (uid && selectedYear) {
      props.getMetricsData(uid, selectedYear)
    }
  }, [uid])

  const handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  const handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }



  const submitEditMetric = (table) => {

    // to return and next year to return contain edits accordingly, now just need to make api call


    var toReturn = [...tableData]
    var nextYearToReturn = [...nextYearsData]

    if (table === 'current') {
      var currentEdits = editCurrentDashboardInput.current
      for (var key in currentEdits) {
        if (key !== 'positionInTable' && currentEdits[key]) {
          toReturn[indexOfMetric][key] = currentEdits[key]
        }
        if (key === 'positionInTable') {
          toReturn[indexOfMetric][key] = toReturn[indexOfMetric][key]
        }
        if (key !== 'positionInTable' && !currentEdits[key]) {
          toReturn[indexOfMetric][key] = toReturn[indexOfMetric][key]
        }
      }
      var updatedMetric = toReturn[indexOfMetric]
      if (updatedMetric['currentMonth']) {
        delete updatedMetric['currentMonth']
      }
      var nextYearUpdatedMetric = false
      var nextYearToReturn = false
      props.updateMetric(uid, selectedYear, updatedMetric, toReturn, nextYearUpdatedMetric, nextYearToReturn, years)
    }

    if (table === 'recent') {
      var currentEdits = editRecentDashboardInput.current
      var nextYearCurrentEdits = editRecentDashboardNextYearsInput.current
      for (var key in currentEdits) {
        if (key !== 'positionInTable' && currentEdits[key]) {
          toReturn[indexOfMetric][key] = currentEdits[key]
        }
        if (key === 'positionInTable') {
          toReturn[indexOfMetric][key] = toReturn[indexOfMetric][key]
        }
        if (key !== 'positionInTable' && !currentEdits[key]) {
          toReturn[indexOfMetric][key] = toReturn[indexOfMetric][key]
        }
        // if (key === 'metricType' || key === 'metricDescription' || key === 'measureDefinition' || key === 'fiveYearTarget' || key === 'oneYearTarget' || key === 'baseline') {
        //   nextYearToReturn[indexOfMetric][key] = currentEdits[key]
        // }
      }


      var nextYearEditsValues = Object.values(nextYearCurrentEdits)
      var hadToEditNextYearValues = false
      for (var x = 0; x < nextYearEditsValues.length; x++) {
        if (nextYearEditsValues[x] !== 'N/A') {
          hadToEditNextYearValues = true
        }
      }


      if (hadToEditNextYearValues) {
        for (var keys in nextYearCurrentEdits) {
          if (nextYearCurrentEdits[keys] !== 'N/A') {
            nextYearToReturn[indexOfMetric][keys] = nextYearCurrentEdits[keys]
          } else {
            nextYearToReturn[indexOfMetric][keys] = nextYearToReturn[indexOfMetric][keys]
          }
        }

        var updatedMetric = toReturn[indexOfMetric]
        var nextYearUpdatedMetric = nextYearToReturn[indexOfMetric]
        if (updatedMetric['currentMonth']) {
          delete updatedMetric['currentMonth']
        }
        props.updateMetric(uid, selectedYear, updatedMetric, toReturn, nextYearUpdatedMetric, nextYearToReturn, years)

      } else {
        var updatedMetric = toReturn[indexOfMetric]
        if (updatedMetric['currentMonth']) {
          delete updatedMetric['currentMonth']
        }
        var nextYearUpdatedMetric = false
        var nextYearToReturn = false
        props.updateMetric(uid, selectedYear, updatedMetric, toReturn, nextYearUpdatedMetric, nextYearToReturn, years)

      }

    }

    if (table === 'yearly') {
      var currentEdits = editYearlyDashboardInput.current
      var nextYearCurrentEdits = editYearlyDashboardNextYearsInput.current
      for (var key in currentEdits) {
        if (key !== 'positionInTable' && currentEdits[key]) {
          toReturn[indexOfMetric][key] = currentEdits[key]
        }
        if (key === 'positionInTable') {
          toReturn[indexOfMetric][key] = toReturn[indexOfMetric][key]
        }
        if (key !== 'positionInTable' && !currentEdits[key]) {
          toReturn[indexOfMetric][key] = toReturn[indexOfMetric][key]
        }
      }

      var nextYearEditsValues = Object.values(nextYearCurrentEdits)
      var hadToEditNextYearValues = false
      for (var x = 0; x < nextYearEditsValues.length; x++) {
        if (nextYearEditsValues[x] !== 'N/A') {
          hadToEditNextYearValues = true
        }
      }


      if (hadToEditNextYearValues) {
        for (var keys in nextYearCurrentEdits) {
          if (nextYearCurrentEdits[keys] !== 'N/A') {
            nextYearToReturn[indexOfMetric][keys] = nextYearCurrentEdits[keys]
          } else {
            nextYearToReturn[indexOfMetric][keys] = nextYearToReturn[indexOfMetric][keys]
          }
        }

        var updatedMetric = toReturn[indexOfMetric]
        var nextYearUpdatedMetric = nextYearToReturn[indexOfMetric]
        props.updateMetric(uid, selectedYear, updatedMetric, toReturn, nextYearUpdatedMetric, nextYearToReturn, years)

      } else {

        var updatedMetric = toReturn[indexOfMetric]
        if (updatedMetric['currentMonth']) {
          delete updatedMetric['currentMonth']
        }
        var nextYearUpdatedMetric = false
        var nextYearToReturn = false
        props.updateMetric(uid, selectedYear, updatedMetric, toReturn, nextYearUpdatedMetric, nextYearToReturn, years)

      }

    }

    if (table === 'dataDownload') {
      var currentEdits = editDataDownloadDashboardInput.current
      for (var key in currentEdits) {
        if (key !== 'positionInTable' && currentEdits[key]) {
          toReturn[indexOfMetric][key] = currentEdits[key]
        }
        if (key === 'positionInTable') {
          toReturn[indexOfMetric][key] = toReturn[indexOfMetric][key]
        }
        if (key !== 'positionInTable' && !currentEdits[key]) {
          toReturn[indexOfMetric][key] = toReturn[indexOfMetric][key]
        }
      }
      var updatedMetric = toReturn[indexOfMetric]
      if (updatedMetric['currentMonth'] || !updatedMetric['currentMonth']) {
        delete updatedMetric['currentMonth']
      }
      if (updatedMetric['startDate'] || !updatedMetric['startDate']) {
        delete updatedMetric['startDate']
      }
      var nextYearUpdatedMetric = false
      var nextYearToReturn = false
      props.updateMetric(uid, selectedYear, updatedMetric, toReturn, nextYearUpdatedMetric, nextYearToReturn, years)
    }
    // var updatedMetric = toReturn[indexOfMetric]
    // var nextYearUpdatedMetric = nextYearToReturn[indexOfMetric]
    // if (updatedMetric['currentMonth']) {
    //   delete updatedMetric['currentMonth']
    // }
    // props.updateMetric(uid, selectedYear, updatedMetric, toReturn, nextYearUpdatedMetric, nextYearToReturn, years)
    setTableData(toReturn)
    setEditingMetric(false)
    // setAnchorEl(anchorEl ? null : event.currentTarget);

  }


  const deleteMetric = (e) => {
    var toReturn = [...tableData]
    var rangeToReturn = [...rangeTableData]
    var deletedMetric = toReturn[indexOfMetric]
    var idToDelete = toReturn[indexOfMetric].id
    for (var x = 0; x < toReturn.length; x++) {
      if (x > indexOfMetric) {
        toReturn[x].positionInTable -= 1
      }
    }

    if (rangeToReturn.length > 0) {
      for (var x = 0; x < rangeToReturn.length; x++) {
        if (x > indexOfMetric) {
          rangeToReturn[x].positionInTable -= 1
        }
      }

      rangeToReturn.splice(indexOfMetric, 1)
      props.setUpdatedRange(rangeToReturn)
      // setRangeTableData(rangeToReturn)
    }



    console.log('rangeToReturn', rangeToReturn)



    var allData = [...props.data]
    for (var y = 0; y < allData.length; y++) {
      if (allData[y].id === idToDelete) {
        allData.splice(y, 1)
      }
    }


    toReturn.splice(indexOfMetric, 1)
    // setTableData(toReturn)
    setEditingMetric(false)
    // setAnchorEl(anchorEl ? null : event.currentTarget)
    props.deleteMetric(uid, selectedYear, deletedMetric, toReturn, currentDashboardStartingMonth, allData, props.metricsIdsArray)
    setEditPopperOpen(false)
  }


  const changeHeaders = (value) => {
    setSelectedMonth(value)
    props.changeHeadersActions(value)
  }

  const filterData = (e, header) => {
    var input = e.target.value
    let data = [...tableData]
    const filtered = data.filter(d => {
      if (d[header].toLowerCase().includes(input.toLowerCase())) {
        return d
      }
    })
    setFilteredTableData(filtered)
  }

  const cancelEditUser = () => {
    setEditingMetric(false)
    setEditPopperOpen(false)
    setEditRangeData({})
    // setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const enableDisable = (str) => {
    if (str === 'range') {
      var toReturn = [...rangeTableData]
      if (toReturn[indexOfMetric].status === 'Inactive') {
        toReturn[indexOfMetric].status = 'Active'
      } else {
        toReturn[indexOfMetric].status = 'Inactive'
      }

      var toReturnRegular = [...tableData]
      if (toReturnRegular[indexOfMetric].status === 'Inactive') {
        toReturnRegular[indexOfMetric].status = 'Active'
      } else {
        toReturnRegular[indexOfMetric].status = 'Inactive'
      }

      var updatedMetric = toReturn[indexOfMetric]
      props.enableDisableMetric(uid, selectedYear, updatedMetric, toReturnRegular, 'range')
      setRangeTableData(toReturn)
      setTableData(toReturnRegular)
      setEditPopperOpen(false)
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else {
      var toReturn = [...tableData]
      if (toReturn[indexOfMetric].status === 'Inactive') {
        toReturn[indexOfMetric].status = 'Active'
      } else {
        toReturn[indexOfMetric].status = 'Inactive'
      }
      var updatedMetric = toReturn[indexOfMetric]
      setTableData(toReturn)
      props.enableDisableMetric(uid, selectedYear, updatedMetric, toReturn)
      setEditPopperOpen(false)
      setAnchorEl(anchorEl ? null : event.currentTarget);


    }
  }


  const handleEditUserInput = (e, param, table, objOfValuesNeedToBeUpdatedForNextYear) => {
    var input = e.target.value
    if (table === 'current') {
      editCurrentDashboardInput.current['currentMonth'] = currentDashboardStartingMonth.slice(0, 3).toLowerCase()
      if (param === 'currentMonth') {
        editCurrentDashboardInput.current[currentDashboardStartingMonth.slice(0, 3).toLowerCase()] = input
      } else {
        editCurrentDashboardInput.current[param] = input
      }
    }


    if (table === 'recent') {
      var editKeys = Object.keys(editRecentDashboardInput.current)
      var nextYearKeys
      if (objOfValuesNeedToBeUpdatedForNextYear) {
        nextYearKeys = Object.keys(objOfValuesNeedToBeUpdatedForNextYear)
      }

      if (nextYearKeys && nextYearKeys.length > 0) {

        if (!nextYearKeys.includes(param)) {
          editRecentDashboardInput.current[param] = input
        } else {
          editRecentDashboardNextYearsInput.current[param] = input || 'N/A'
        }
      } else {
        editRecentDashboardInput.current[param] = input
      }
    }


    if (table === 'yearly') {
      var editKeys = Object.keys(editYearlyDashboardInput.current)
      var nextYearKeys
      if (objOfValuesNeedToBeUpdatedForNextYear) {
        nextYearKeys = Object.keys(objOfValuesNeedToBeUpdatedForNextYear)
      }

      if (nextYearKeys && nextYearKeys.length > 0) {

        if (!nextYearKeys.includes(param)) {
          editYearlyDashboardInput.current[param] = input
        } else {
          editYearlyDashboardNextYearsInput.current[param] = input
        }
      } else {
        editYearlyDashboardInput.current[param] = input
      }
    }

    if (table === 'dataDownload') {
      editDataDownloadDashboardInput.current[param] = input
    }
  }


  const handleEditRangeDataDownload = (e, param) => {

    setEditRangeData({ ...editRangeData, [param]: e.target.value })
  }

  const handleFix = async () => {
    setButtonClicked(true)
    for (let i = 0; i < props.metricsIdsArray.length; i++) {
      for (let j = 2018; j < 2041; j++) {
        var batch = db.batch()
        const ref = await db.collection("TEST-users").doc(uid).collection("metrics").doc(props.metricsIdsArray[i]).collection('data').doc(j.toString())
          .get()
          .then((doc) => {
            batch.update(doc.ref, { id: props.metricsIdsArray[i] })
          })
        batch.commit()
      }
      if (i === props.metricsIdsArray.length - 1) {
        setButtonClicked(false)
        db.collection("TEST-users").doc(uid).set({
          fixed: true
        }, { merge: true })
        window.location.reload();
      }
    }
  }

  const handleTest = async () => {
    setStringClicked(true)
    for (let i = 0; i < props.metricsIdsArray.length; i++) {
      console.log("doc id: ", props.metricsIdsArray[i])
      for (let j = 2018; j < 2041; j++) {
        var batch = db.batch()
        await db.collection("TEST-users").doc(uid).collection("metrics").doc(props.metricsIdsArray[i]).collection('data').doc(j.toString())
          .get()
          .then((doc) => {
            if (doc.data().jan === "") {
              batch.update(doc.ref, { jan: "N/A" })
            }
            if (doc.data().feb === "") {
              batch.update(doc.ref, { feb: "N/A" })
            }
            if (doc.data().mar === "") {
              batch.update(doc.ref, { mar: "N/A" })
            }
            if (doc.data().apr === "") {
              batch.update(doc.ref, { apr: "N/A" })
            }
            if (doc.data().may === "") {
              batch.update(doc.ref, { may: "N/A" })
            }
            if (doc.data().jun === "") {
              batch.update(doc.ref, { jun: "N/A" })
            }
            if (doc.data().jul === "") {
              batch.update(doc.ref, { jul: "N/A" })
            }
            if (doc.data().aug === "") {
              batch.update(doc.ref, { aug: "N/A" })
            }
            if (doc.data().sep === "") {
              batch.update(doc.ref, { sep: "N/A" })
            }
            if (doc.data().oct === "") {
              batch.update(doc.ref, { oct: "N/A" })
            }
            if (doc.data().nov === "") {
              batch.update(doc.ref, { nov: "N/A" })
            }
            if (doc.data().dec === "") {
              batch.update(doc.ref, { dec: "N/A" })
            }
          })
        batch.commit()
      }
      if (i === props.metricsIdsArray.length - 1) {
        setStringClicked(false)
        db.collection("TEST-users").doc(uid).set({
          fixedString: true
        }, { merge: true })
          .then(() => {
            window.location.reload();
          })
      }

    }
  }
  const submitRangeDataDownload = (index) => {

    // setEditingMetric(false)

    var yearsCheck = {}

    var keys = Object.keys(rangeTableData[index])
    for (var x = 0; x < keys.length; x++) {
      var current = keys[x]
      if (current.slice(0, 3) === 'jan' || current.slice(0, 3) === 'feb' || current.slice(0, 3) === 'mar' || current.slice(0, 3) === 'apr' || current.slice(0, 3) === 'may' || current.slice(0, 3) === 'jun' || current.slice(0, 3) === 'jul' || current.slice(0, 3) === 'aug' || current.slice(0, 3) === 'sep' || current.slice(0, 3) === 'oct' || current.slice(0, 3) === 'nov' || current.slice(0, 3) === 'dec') {
        yearsCheck[parseInt(current.slice(3))] = {}
      }
    }




    var arrayOfData = []
    var values = { ...editRangeData }

    // if (!values.metricType) {
    //   values.metricType = rangeTableData[index].metricType
    // }
    // if (!values.metricDescription) {
    //   values.metricDescription = rangeTableData[index].metricDescription
    // }
    // if (!values.measureDefinition) {
    //   values.measureDefinition = rangeTableData[index].measureDefinition
    // }
    // if (!values.oneYearTarget) {
    //   values.oneYearTarget = rangeTableData[index].oneYearTarget
    // }
    // if (!values.baseline) {
    //   values.baseline = rangeTableData[index].baseline
    // }
    // if (!values.fiveYearTarget) {
    //   values.fiveYearTarget = rangeTableData[index].fiveYearTarget
    // }


    for (var key in values) {
      if (key.slice(0, 3) === 'jan' || key.slice(0, 3) === 'feb' || key.slice(0, 3) === 'mar' || key.slice(0, 3) === 'apr' || key.slice(0, 3) === 'may' || key.slice(0, 3) === 'jun' || key.slice(0, 3) === 'jul' || key.slice(0, 3) === 'aug' || key.slice(0, 3) === 'sep' || key.slice(0, 3) === 'oct' || key.slice(0, 3) === 'nov' || key.slice(0, 3) === 'dec') {
        yearsCheck[parseInt(key.slice(3))][key.slice(0, 3)] = values[key]
      } else {
        yearsCheck[key] = values[key]
      }
    }

    for (var keys in yearsCheck) {
      if (Object.values(yearsCheck[keys]).length === 0) {
        delete yearsCheck[keys]
      }
    }
    var allData = [...tableData]
    var allRangeData = [...rangeTableData]
    for (var key in editRangeData) {
      if (key.length === 5) {
        allData[index][key.slice(0, 3)] = editRangeData[key]
        allRangeData[index][key] = editRangeData[key]
      } else {
        allData[index][key] = editRangeData[key]
        allRangeData[index][key] = editRangeData[key]
      }
    }

    console.log('alldata', allData[index])


    props.updateRangeOfYearsMetricData(uid, yearsCheck, editRangeData, rangeTableData[index])
    setRangeTableData(allRangeData)
    setTableData(allData)
    setEditingMetric(false)

  }





  const filterCurrentDashboardTableDataByMonth = (value) => {

    // need to perform getMetricsData but change month to start filter to be value
    // setCurrentDashboardStartingMonth(value)
    setChangedMonths(true)
    props.changeHeadersActions(value)
    // props.getFilteredMetricsData(uid, selectedYear, value, props.data)
  }
  const moveRowIndex = (direction) => {
    var data = [...tableData]
    if (direction === 'up' && indexOfMetric !== 0) {
      props.setNewRowIndex(uid, selectedYear, direction, data, indexOfMetric)
      var previous = data[indexOfMetric - 1]
      var current = data[indexOfMetric]
      data[indexOfMetric] = previous
      data[indexOfMetric - 1] = current
      setTableData(data)
      setIndexOfMetric(indexOfMetric - 1)
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setEditPopperOpen(!editPopperOpen)
    }
    if (direction === 'down' && indexOfMetric !== tableData.length - 1) {
      props.setNewRowIndex(uid, selectedYear, direction, data, indexOfMetric)
      var next = data[indexOfMetric + 1]
      var current = data[indexOfMetric]
      data[indexOfMetric] = next
      data[indexOfMetric + 1] = current
      setTableData(data)
      setIndexOfMetric(indexOfMetric + 1)
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setEditPopperOpen(!editPopperOpen)
    }
  }

  console.log('recentColumnHeaders', recentColumnHeaders)

  const renderTableHeader = (table) => {
    var headers = []

    if (table === 'dataDownload') {
      for (let x = 0; x < dataDownloadDashboardTableHeaders.length; x++) {
        if (x < 8) {
          headers.push(dataDownloadDashboardTableHeaders[x])
        }
      }

      if (rangeTableData.length > 0 && finishedSettingRange) {
        // number of different metrics is rangeTableData.length / yearsQty
        var numberOfDifferentMetrics = rangeTableData.length / yearsQty
        var increasingTwoDigits = parseInt(selectedYearLastTwoDigits)
        for (var z = 0; z < yearsQty; z++) {
          for (var x = 0; x < monthsToDisplay.length; x++) {
            if (monthsToDisplay[x] === "Dec") {
              headers.push(monthsToDisplay[x] + '\n' + increasingTwoDigits.toString())
              increasingTwoDigits++
            } else {
              headers.push(monthsToDisplay[x] + '\n' + increasingTwoDigits.toString())
            }
          }
        }

      } else {
        for (let y = 0; y < monthsToDisplay.length; y++) {
          if (y < 5) {
            headers.push(monthsToDisplay[y])
          }
        }
      }
      headers.push('Status', 'Actions')
      headers = [...new Set(headers)]
    }


    if (table === 'current') {
      return currentDashboardTableHeaders.map((key, index) => {
        // if (key === 'Metric Description') {
        //   return (
        //     <th style={{ width: "350px", display: "flex", flexDirection: "column", borderBottom: "1px solid #D7D7D7" }} key={index}><span style={{height: "50px", paddingTop: "16px"}}>{key}</span>   <TextField onChange={(e) => filterData(e, 'metricDescription')} /> </th>
        //   )
        // }

        // if (key === 'Measure Definition') {
        //   return (
        //     <th style={{ borderBottom: "1px solid #D7D7D7", width: "350px" }} key={index}><span style={{height: "50px", paddingTop: "16px"}}>{key}</span>    <TextField style={{ width: "100%", marginBottom: "1px" }} onChange={(e) => filterData(e, 'measureDefinition')} /> </th>
        //   )
        //

        return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px" } : { borderBottom: "1px solid #D7D7D7", width: "200px" }} key={index}>
          {monthsToDisplay.includes(key) ? `${key}-${selectedYearLastTwoDigits}` : key}
        </th>

      })
    }

    if (table === 'recent') {
      var indexToKeepTrackOf = null
      return Object.values(recentColumnHeaders).map((key, index) => {
        if (key === 'Dec') {
          indexToKeepTrackOf = index
          return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === 'Owner' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px", textAlign: "center", verticalAlign: "center" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap", width: "100px", }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${selectedYearLastTwoDigits}` : key}</th>
        }
        if (indexToKeepTrackOf) {
          if (index > indexToKeepTrackOf) {
            let increasedSelectedYearLastTwoDigits = parseInt(selectedYearLastTwoDigits) + 1
            return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === 'Owner' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px", textAlign: "center", verticalAlign: "center" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap", width: "150px" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${increasedSelectedYearLastTwoDigits}` : key}</th>
          }
        } else {
          return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === 'Owner' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px", textAlign: "center", verticalAlign: "center" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap", width: "150px" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${selectedYearLastTwoDigits}` : key}</th>
        }
      })
    }

    if (table === 'yearly') {
      return Object.values(yearlyColumnHeaders).map((key, index) => {
        if (key === 'Dec') {
          indexToKeepTrackOf = index
          return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap", width: "200px" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap", width: "100px" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${selectedYearLastTwoDigits}` : key}   </th>
        }
        if (indexToKeepTrackOf) {
          if (index > indexToKeepTrackOf) {
            const increasedSelectedYearLastTwoDigits = parseInt(selectedYearLastTwoDigits) + 1
            return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap", width: "200px" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap", width: "150px" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${increasedSelectedYearLastTwoDigits}` : key}   </th>
          }
        } else {
          return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap", width: "200px" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap", width: "150px" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${selectedYearLastTwoDigits}` : key}   </th>
        }
      })
    }

    if (table === 'dataDownload') {
      if (rangeTableData.length > 0 && finishedSettingRange) {
        return headers.map((key, index) => {

          var increasingTwoDigits = selectedYearLastTwoDigits
          var count = 0
          if (key === 'Dec' && count === 0) {
            indexToKeepTrackOf = index
            count++
            return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", width: "40px", whiteSpace: "pre-wrap" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${increasingTwoDigits}` : key}    </th>

          }
          if (indexToKeepTrackOf) {
            if (index > indexToKeepTrackOf) {
              increasingTwoDigits++
              return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", width: "40px", whiteSpace: "pre-wrap" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${increasingTwoDigits}` : key}  </th>
            }
          } else {
            return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", width: "40px", whiteSpace: "pre-wrap" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${selectedYearLastTwoDigits}` : key}    </th>
          }
        })

      } else {
        return Object.values(dataDownloadColumnHeaders).map((key, index) => {
          if (key === 'Dec') {
            indexToKeepTrackOf = index
            return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", width: "40px", whiteSpace: "pre-wrap" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${selectedYearLastTwoDigits}` : key}    </th>
          }
          if (indexToKeepTrackOf) {
            if (index > indexToKeepTrackOf) {
              let increasedSelectedYearLastTwoDigits = parseInt(selectedYearLastTwoDigits) + 1
              return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", width: "40px", whiteSpace: "pre-wrap" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${increasedSelectedYearLastTwoDigits}` : key}   </th>
            }
          } else {
            return <th style={key === 'Metric Description' || key === 'Measure Definition' || key === '5 Year Target' || key === '5 Year Target' || key === '1 Year Target' || key === 'Quarter Year Target' || key === 'Baseline' ? { borderBottom: "1px solid #D7D7D7", width: "200px", textAlign: "center", verticalAlign: "center", whiteSpace: "pre-wrap" } : { borderBottom: "1px solid #D7D7D7", textAlign: "center", verticalAlign: "center", width: "40px", whiteSpace: "pre-wrap" }} key={index}>{monthsToDisplay.includes(key) ? `${key}\n${selectedYearLastTwoDigits}` : key}   </th>
          }
        })
      }
    }
  }

  const editMetric = (event, table) => {
    setEditPopperOpen(!editPopperOpen)
    var values = tableData[indexOfMetric]
    for (var key in values) {
      if (table === 'current') {
        editCurrentDashboardInput.current[key] = values[key]
      }
      if (table === 'recent') {
        editRecentDashboardInput.current[key] = values[key]
      }
      if (table === 'yearly') {
        editYearlyDashboardInput.current[key] = values[key]
      }
      if (table === 'dataDownload') {
        editDataDownloadDashboardInput.current[key] = values[key]
      }

    }

    setAnchorEl(anchorEl ? null : event.currentTarget);
    setEditingMetric(true)
  }


  const setSpecificMetric = (event, index) => {
    setEditPopperOpen(!editPopperOpen)
    setAnchorEl(event.currentTarget);
    setIndexOfMetric(index)
  }


  const editableSortData = (metric, table, index) => {
    var nextYearMetric = nextYearsData[index]
    if (!metric.owner) {
      metric.owner = "N/A"
    }
    if (!metric.quarterTarget) {
      metric.quarterTarget = "N/A"
    }
    if (table === 'recent') {
      var array = []
      var samePropertiesObj = {}
      var samePropertiesObjNextYearsData = {}
      for (var keys in metric) {
        if (Object.keys(recentColumnHeaders).includes(keys)) {
          samePropertiesObj[keys] = metric[keys]
        }
      }
      for (var key in nextYearMetric) {
        if (Object.keys(recentColumnHeaders).includes(key)) {
          samePropertiesObjNextYearsData[key] = nextYearMetric[key]
        }
      }
      var columnHeaderKeys = Object.keys(recentColumnHeaders)

      var needNextYearsData = false
      var sortedObj = {}
      var objOfValuesNeedToBeUpdatedForNextYear = {}
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[0]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[1]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[2]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[3]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[4]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[5]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[6]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
          if (key === 'dec') {
            needNextYearsData = true
          }
        }
      }


      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          if (key === columnHeaderKeys[7]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {

          // metric type
          if (key === columnHeaderKeys[7]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }



      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[8]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[8]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[9]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[9]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[10]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[10]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[11]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[11]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[12]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[12]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[13]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[13]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }



      array = Object.values(sortedObj)
      var sortedKeysArray = Object.keys(sortedObj)
      return array.map((value, index) => {
        if (index === 0) {
          return (
            <td style={{ textAlign: "center" }}>
              <select style={{ marginLeft: "25px" }}
                onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'recent')}
                type="text" defaultValue={value} className={classes.editInput}>
                {metricTypeOptions.map((data, index) => {
                  return (
                    <option value={data}>{data}</option>
                  )
                })}
              </select></td>
          )
        }
        if (index === 1) {
          return (
            <td style={{ textAlign: "center" }}> <textarea onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'recent')} type="text" defaultValue={value} className={classes.editInput}></textarea></td>
          )
        }
        if (index === 2) {
          return (
            <td style={{ textAlign: "center" }}> <textarea onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'recent')} type="text" defaultValue={value} className={classes.editInput}></textarea></td>
          )
        }
        if (index === 3) {
          return (
            <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'recent')} type="text" defaultValue={value} className={classes.editInput}></input></td>
          )
        }
        if (index === 4) {
          return (
            <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'recent')} type="text" defaultValue={value} className={classes.editInput}></input></td>
          )
        }
        if (index === 5) {
          return (
            <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'recent')} type="text" defaultValue={value} className={classes.editInput}></input></td>
          )
        }
        return (
          <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'recent', objOfValuesNeedToBeUpdatedForNextYear)} type="text" defaultValue={value} className={classes.editInput}></input></td>
        )
      })

    }



    if (table === 'yearly') {
      var array = []
      var samePropertiesObj = {}
      var samePropertiesObjNextYearsData = {}
      for (var keys in metric) {
        if (Object.keys(yearlyColumnHeaders).includes(keys)) {
          samePropertiesObj[keys] = metric[keys]
        }
      }
      for (var key in nextYearMetric) {
        if (Object.keys(yearlyColumnHeaders).includes(key)) {
          samePropertiesObjNextYearsData[key] = nextYearMetric[key]
        }
      }
      var columnHeaderKeys = Object.keys(yearlyColumnHeaders)

      var sortedObj = {}
      var needNextYearsData = false
      var objOfValuesNeedToBeUpdatedForNextYear = {}
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[0]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[1]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[2]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
          if (key === 'dec') {
            needNextYearsData = true
          }
        }
      }
      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[3]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[3]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }



      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[4]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[4]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[5]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[5]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[6]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[6]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[7]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[7]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[8]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[8]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[9]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[9]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[10]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[10]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[11]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[11]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[12]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[12]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[13]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
            objOfValuesNeedToBeUpdatedForNextYear[key] = 'N/A'
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[13]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }



      array = Object.values(sortedObj)
      var sortedKeysArray = Object.keys(sortedObj)

      return array.map((value, index) => {
        if (index === 0) {
          return (
            <td style={{ textAlign: "center" }}>
              <select style={{ marginLeft: "25px" }}
                onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'yearly')}
                type="text" defaultValue={value} className={classes.editInput}>
                {metricTypeOptions.map((data, index) => {
                  return (
                    <option value={data}>{data}</option>
                  )
                })}
              </select></td>
          )
        }

        if (index === 1) {
          return (
            <td style={{ textAlign: "center" }}> <textarea onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'yearly')} type="text" defaultValue={value} className={classes.editInput}></textarea></td>
          )
        }
        return (
          <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'yearly', objOfValuesNeedToBeUpdatedForNextYear)} type="text" defaultValue={value} className={classes.editInput}></input></td>
        )
      })

    }





    if (table === 'dataDownload') {
      var array = []
      var samePropertiesObj = {}
      for (var keys in metric) {
        if (Object.keys(dataDownloadColumnHeaders).includes(keys)) {
          samePropertiesObj[keys] = metric[keys]
        }
      }
      var columnHeaderKeys = Object.keys(dataDownloadColumnHeaders)

      var sortedObj = {}
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[0]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[1]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[2]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[3]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[4]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[5]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[6]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[7]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[8]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[9]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[10]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[11]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[12]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[13]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[14]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[15]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[16]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[17]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[18]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[19]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[20]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      array = Object.values(sortedObj)
      var sortedKeysArray = Object.keys(sortedObj)
      return array.map((value, index) => {
        if (index === 0) {
          return (
            <td style={{ textAlign: "center" }}>
              <select style={{ marginLeft: "25px" }}
                onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'dataDownload')}
                type="text" defaultValue={value} className={classes.editInput}>
                {metricTypeOptions.map((data, index) => {
                  return (
                    <option value={data}>{data}</option>
                  )
                })}
              </select></td>
          )
        }
        if (index === 1) {
          return (
            <td style={{ textAlign: "center" }}> <textarea onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'dataDownload')} type="text" defaultValue={value} className={classes.editInput}></textarea></td>
          )
        }
        if (index === 2) {
          return (
            <td style={{ textAlign: "center" }}> <textarea onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'dataDownload')} type="text" defaultValue={value} className={classes.editInput}></textarea></td>
          )
        }
        // if (index === 19) {
        //   return (
        //     // <td style={{ textAlign: "center" }}> <textarea   onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'dataDownload')}type="text" defaultValue={value} className={classes.editInput}></textarea></td>
        //     <td style={{ textAlign: "center" }} className={classes.editInput}>{value}</td>
        //   )
        // }

        if (value === 'Active' || value === 'Inactive') {
          return (
            <td style={{ textAlign: "center" }} className={classes.editInput}>{value}</td>
          )
        }
        return (
          <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, sortedKeysArray[index], 'dataDownload')} type="text" defaultValue={value} className={classes.editInput}></input></td>
        )
      })

    }







  }


  const sortData = (metric, table, index) => {
    var nextYearMetric = nextYearsData[index]
    // console.log("testing sortData", metric, table, index)
    if (!metric.owner) {
      metric.owner = "N/A"
    }
    if (!metric.quarterTarget) {
      metric.quarterTarget = "N/A"
    }
    if (table === 'recent') {
      var array = []
      var samePropertiesObj = {}
      var samePropertiesObjNextYearsData = {}
      for (var keys in metric) {
        if (Object.keys(recentColumnHeaders).includes(keys)) {
          samePropertiesObj[keys] = metric[keys]
        }
      }
      for (var key in nextYearMetric) {
        if (Object.keys(recentColumnHeaders).includes(key)) {
          samePropertiesObjNextYearsData[key] = nextYearMetric[key]
        }
      }



      var columnHeaderKeys = Object.keys(recentColumnHeaders)
      var sortedObj = {}

      var needNextYearsData = false
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[0]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[1]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[2]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[3]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[4]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[5]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[6]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
          if (key === 'dec') {
            needNextYearsData = true
          }
        }
      }


      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[7]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[7]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }


      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[8]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[8]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }



      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[9]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[9]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }



      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[10]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[10]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }


      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[11]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[11]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }


      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[12]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[12]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[13]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[13]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }




      array = Object.values(sortedObj)
      return array.map((value, index) => {
        return (
          <td style={index === 1 ? {
            textAlign: "center",
            maxWidth: "350px",
            minWidth: "300px",
            padding: "0px 10px"
          } : index === 2 ? {
            textAlign: "center",
            maxWidth: "350px",
            minWidth: "300px",
            padding: "0px 10px"
          } : {
            textAlign: "center",
            padding: "0px 10px",
            maxWidth: "100px"
          }} key={index}>{value}</td>

        )
      })

    }


    if (table === 'yearly') {
      var array = []
      var samePropertiesObj = {}
      var samePropertiesObjNextYearsData = {}
      for (var keys in metric) {
        if (Object.keys(yearlyColumnHeaders).includes(keys)) {
          samePropertiesObj[keys] = metric[keys]
        }
      }
      for (var key in nextYearMetric) {
        if (Object.keys(yearlyColumnHeaders).includes(key)) {
          samePropertiesObjNextYearsData[key] = nextYearMetric[key]
        }
      }
      var columnHeaderKeys = Object.keys(yearlyColumnHeaders)

      var sortedObj = {}

      var needNextYearsData = false
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[0]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[1]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[2]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
          if (key === 'dec') {
            needNextYearsData = true
          }
        }
      }
      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[3]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[3]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }



      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[4]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[4]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[5]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[5]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[6]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[6]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[7]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[7]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[8]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[8]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[9]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[9]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[10]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[10]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[11]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[11]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[12]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[12]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      if (needNextYearsData) {
        for (var key in samePropertiesObjNextYearsData) {
          // metric type
          if (key === columnHeaderKeys[13]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObjNextYearsData[key] }
          }
        }
      } else {
        for (var key in samePropertiesObj) {
          // metric type
          if (key === columnHeaderKeys[13]) {
            sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
            if (key === 'dec') {
              needNextYearsData = true
            }
          }
        }
      }

      array = Object.values(sortedObj)
      return array.map((value, index) => {
        return (
          <td style={index === 1 ? {
            textAlign: "center",
            maxWidth: "350px",
            minWidth: "300px",
            padding: "0px 10px"
          } : {
            textAlign: "center",
            padding: "0px 10px",
            maxWidth: "100px"
          }} key={index}>{value}</td>

        )
      })

    }



    if (table === 'dataDownload') {
      var array = []
      var samePropertiesObj = {}
      for (var keys in metric) {
        if (Object.keys(dataDownloadColumnHeaders).includes(keys)) {
          samePropertiesObj[keys] = metric[keys]
        }
      }
      var columnHeaderKeys = Object.keys(dataDownloadColumnHeaders)

      var sortedObj = {}

      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[0]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[1]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[2]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[3]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[4]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[5]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[6]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[7]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[8]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[9]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[10]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[11]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[12]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[13]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[14]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[15]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[16]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[17]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[18]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[19]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      for (var key in samePropertiesObj) {
        // metric type
        if (key === columnHeaderKeys[20]) {
          sortedObj = { ...sortedObj, [key]: samePropertiesObj[key] }
        }
      }
      array = Object.values(sortedObj)
      return array.map((value, index) => {
        return (
          <td style={index === 1 ? {
            textAlign: "center",
            maxWidth: "350px",
            minWidth: "300px",
            padding: "0px 10px"
          } : index === 2 ? {
            textAlign: "center",
            maxWidth: "350px",
            minWidth: "300px",
            padding: "0px 10px"
          } : {
            textAlign: "center",
            padding: "0px 10px",
            maxWidth: "100px"
          }} key={index}>{value}</td>

        )
      })
    }

  }


  const sortRangeDataDownload = (metric, index, str) => {
    console.log('metriccc', metric)
    var sortedObj = {}
    sortedObj.metricType = metric.metricType
    sortedObj.metricDescription = metric.metricDescription
    sortedObj.measureDefinition = metric.measureDefinition
    sortedObj.owner = metric.owner ? metric.owner : "N/A"
    sortedObj.fiveYearTarget = metric.fiveYearTarget
    sortedObj.oneYearTarget = metric.oneYearTarget
    sortedObj.quarterTarget = metric.quarterTarget ? metric.quarterTarget : "N/A"
    sortedObj.baseline = metric.baseline
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

    const monthNumbers = {
      "jan": 1,
      "feb": 2,
      "mar": 3,
      "apr": 4,
      "may": 5,
      "jun": 6,
      "jul": 7,
      "aug": 8,
      "sep": 9,
      "oct": 10,
      "nov": 11,
      "dec": 12
    };

    var metricKeysArray = Object.keys(metric)
    var yearToLookFor = false
    for (var x = 0; x < metricKeysArray.length; x++) {
      var current = metricKeysArray[x]
      if (current.slice(0, 3) === 'jan' || current.slice(0, 3) === 'feb' || current.slice(0, 3) === 'mar' || current.slice(0, 3) === 'apr' || current.slice(0, 3) === 'may' || current.slice(0, 3) === 'jun' || current.slice(0, 3) === 'jul' || current.slice(0, 3) === 'aug' || current.slice(0, 3) === 'sep' || current.slice(0, 3) === 'oct' || current.slice(0, 3) === 'nov' || current.slice(0, 3) === 'dec') {
        if (!yearToLookFor) {
          yearToLookFor = current.slice(3)
        } else {
          yearToLookFor = parseInt(yearToLookFor)
          current = parseInt(current)
          if (current < yearToLookFor) {
            yearToLookFor = current
          }
        }
      }
    }

    yearToLookFor = yearToLookFor.toString()
    var tempMonthsArray = []
    var tempMonthsObj = {}
    var indexToStartSort = 12
    var indexToEndSort = 24
    var recursive = false
    var temp = []

    const recursivelySort = (newYear) => {
      for (var y = 0; y < metricKeysArray.length; y++) {
        var current = metricKeysArray[y]
        if (months.includes(current.slice(0, 3)) && current.slice(3) === yearToLookFor) {

          temp.push({ [current]: metric[current] })
          temp.sort(function (a, b) {
            return monthNumbers[Object.keys(a)[0].slice(0, 3)] - monthNumbers[Object.keys(b)[0].slice(0, 3)]
          })
          var idk = parseInt(yearToLookFor) + 1
          idk = idk.toString
          if (!metric['jan' + idk]) {
            tempMonthsArray.push(temp)
          }
        }
        if (months.includes(current.slice(0, 3)) && current.slice(3) !== yearToLookFor && y === metricKeysArray.length - 1) {
          yearToLookFor = parseInt(yearToLookFor)
          yearToLookFor++
          yearToLookFor = yearToLookFor.toString()
          tempMonthsArray.push(temp)
          if (metric['jan' + yearToLookFor]) {
            temp = []
            recursive = true
            recursivelySort(yearToLookFor)
          }
        }
      }
    }

    recursivelySort()
    tempMonthsArray = tempMonthsArray.flat()
    for (var i = 0; i < tempMonthsArray.length; i++) {
      var current = tempMonthsArray[i]
      for (var keys in current) {
        sortedObj[keys] = current[keys]
      }
    }
    sortedObj.status = metric.status
    var sortedArray = Object.values(sortedObj)
    console.log('sortedArray', sortedArray)
    var sortedKeysArray = Object.keys(sortedObj)
    if (str === 'editing') {
      return sortedArray.map((value, index) => {
        if (index === 0) {
          return (
            <td style={{ textAlign: "center" }}>
              <select style={{ marginLeft: "25px" }}
                onChange={(e) => handleEditRangeDataDownload(e, sortedKeysArray[index])}
                type="text" defaultValue={value} className={classes.editInput}>
                {metricTypeOptions.map((data, index) => {
                  return (
                    <option value={data}>{data}</option>
                  )
                })}
              </select></td>
          )
        }
        if (index === 1) {
          return (
            <td style={{ textAlign: "center" }}> <textarea onChange={(e) => handleEditRangeDataDownload(e, sortedKeysArray[index])} type="text" defaultValue={value} className={classes.editInput}></textarea></td>
          )
        }
        if (index === 2) {
          return (
            <td style={{ textAlign: "center" }}> <textarea onChange={(e) => handleEditRangeDataDownload(e, sortedKeysArray[index])} type="text" defaultValue={value} className={classes.editInput}></textarea></td>
          )
        }

        if (value === 'Active' || value === 'Inactive') {
          return (
            <td style={{ textAlign: "center" }} className={classes.editInput}>{value}</td>
          )
        }

        return (
          <td style={{ textAlign: "center" }}> <input onChange={(e) => handleEditRangeDataDownload(e, sortedKeysArray[index])} type="text" defaultValue={value} className={classes.editInput}></input></td>
        )







      })
    } else {
      return sortedArray.map((value, index) => {
        return (
          <td style={index === 1 ? {
            textAlign: "center",
            maxWidth: "350px",
            minWidth: "300px",
            padding: "0px 10px"
          } : index === 2 ? {
            textAlign: "center",
            maxWidth: "350px",
            minWidth: "300px",
            padding: "0px 10px"
          } : {
            textAlign: "center",
            padding: "0px 10px",
            maxWidth: "100px"
          }} key={index}>{value}</td>

        )
      })

    }

  }

  const renderTableData = (table) => {
    console.log('tableData', tableData)
    if (table === 'current') {
      return tableData.map((metric, index) => {
        if (editingMetric && metric.status === 'Active') {
          return (
            index === indexOfMetric ? (
              <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>

                <td style={{ textAlign: "center" }}>
                  <select style={{ marginLeft: "25px" }}
                    onChange={(e) => handleEditUserInput(e, 'metricType', 'current')}
                    type="text" defaultValue={metric.metricType} className={classes.editInput}>
                    {metricTypeOptions.map((data, index) => {
                      return (
                        <option value={data}>{data}</option>
                      )
                    })}
                  </select></td>
                <td style={{ textAlign: "center" }}> <textarea onChange={(e) => handleEditUserInput(e, 'metricDescription', 'current')} type="text" defaultValue={metric.metricDescription || 'N/A'} className={classes.editInput}></textarea></td>
                <td style={{ textAlign: "center" }}>
                  <textarea style={{ width: "250px" }} onChange={(e) => handleEditUserInput(e, 'measureDefinition', 'current')} type="text" defaultValue={metric.measureDefinition || 'N/A'} className={classes.editInput}></textarea></td>
                <td style={{ textAlign: "center" }}> <textarea onChange={(e) => handleEditUserInput(e, 'owner', 'current')} type="text" defaultValue={metric.owner || 'N/A'} className={classes.editInput}></textarea></td>
                {/* <td style={{ textAlign: "center" }}>
                    <select style={{ marginLeft: "25px" }} onChange={(e) => handleEditUserInput(e, 'roles')} type="text" defaultValue={role} className={classes.editInput}>
                      <option value="warehouseManager">Warehouse Manager</option>
                      <option value="systemAdmin">System Admin</option>
                      <option value="shippingReceivingOffice">Shipping Receiving Office</option>
                      <option value="operator">Operator</option>
                    </select></td> */}
                <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, 'fiveYearTarget', 'current')} type="text" defaultValue={metric.fiveYearTarget || 'N/A'} className={classes.editInput}></input></td>
                <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, 'oneYearTarget', 'current')} type="text" defaultValue={metric.oneYearTarget || 'N/A'} className={classes.editInput}></input></td>
                <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, 'quarterTarget', 'current')} type="text" defaultValue={metric.quarterTarget || 'N/A'} className={classes.editInput}></input></td>
                <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, 'baseline', 'current')} type="text" defaultValue={metric.baseline || 'N/A' || 'N/A'} className={classes.editInput}></input></td>
                <td style={{ textAlign: "center" }}>  <input style={{ marginLeft: "20px" }} onChange={(e) => handleEditUserInput(e, 'currentMonth', 'current')} type="text" defaultValue={metric[currentDashboardStartingMonth.slice(0, 3).toLowerCase()]} className={classes.editInput}></input></td>
                <td style={{ textAlign: "center" }}>  <div style={{ display: "flex", justifyContent: "center" }}>
                  <button onClick={() => submitEditMetric('current')} className={classes.goButton} style={{ marginRight: "15px" }}>Save</button>
                  <button className={classes.editCancelBtn} onClick={cancelEditUser}>Cancel</button>
                </div></td>
              </tr>
            ) : (<tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
              <td style={{ textAlign: "center" }}>{metric.metricType || 'N/A'}</td>
              <td style={{ textAlign: "center", maxWidth: "250px", minWidth: "200px" }}>{metric.metricDescription || 'N/A'}</td>
              <td style={{ textAlign: "center", maxWidth: "250px", minWidth: "200px" }}>{metric.measureDefinition || 'N/A'}</td>
              <td style={{ textAlign: "center" }}>{metric.owner || 'N/A'}</td>
              <td style={{ textAlign: "center" }}>{metric.fiveYearTarget || 'N/A'}</td>
              <td style={{ textAlign: "center" }}>{metric.oneYearTarget || 'N/A'}</td>
              <td style={{ textAlign: "center" }}>{metric.quarterTarget || 'N/A'}</td>
              <td style={{ textAlign: "center" }}>{metric.baseline || 'N/A'}</td>
              <td style={{ textAlign: "center" }}>{metric[currentDashboardStartingMonth.slice(0, 3).toLowerCase()]}</td>
              {/* <ClickAwayListener onClickAway={() => setPopperOpen(!popperOpen)}> */}
              <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                <MoreVertOutlinedIcon
                  aria-describedby={'transitions-popper'}
                  onClick={(event) => {
                    setSpecificMetric(event, index)
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                  }
                  }
                  className={classes.threeDots} />
                <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} >
                      <Card style={{ width: "154px", display: "flex", zIndex: 1000 }}>

                        <div onClick={(event) => editMetric(event, 'current')} className={classes.popperDiv}>
                          <div className={classes.popperSelections}>
                            Edit
                            <EditOutlinedIcon />
                          </div>

                        </div>
                        <div onClick={(event) => {
                          props.setDeleteMetricModal(true)
                          setAnchorEl(anchorEl ? null : event.currentTarget)
                          setEditPopperOpen(false)

                        }} className={classes.popperDiv}>
                          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} className={classes.popperSelections}>
                            Delete
                            <DeleteOutlineOutlinedIcon />
                          </div>

                        </div>


                      </Card>
                    </Fade>
                  )}
                </Popper>
              </td>
              {/* </ClickAwayListener> */}
            </tr>)
          )
        } else if (metric.status === 'Active') {
          return (
            <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
              <td className={classes.smallerDataDiv}>{metric.metricType || 'N/A'}</td>
              <td className={classes.biggerDataDiv}>{metric.metricDescription || 'N/A'}</td>
              <td className={classes.biggerDataDiv}>{metric.measureDefinition || 'N/A'}</td>
              <td className={classes.smallerDataDiv}>{metric.owner || 'N/A'}</td>
              <td className={classes.smallerDataDiv}>{metric.fiveYearTarget || 'N/A'}</td>
              <td className={classes.smallerDataDiv}>{metric.oneYearTarget || 'N/A'}</td>
              <td className={classes.smallerDataDiv}>{metric.quarterTarget || 'N/A'}</td>
              <td className={classes.smallerDataDiv}>{metric.baseline || 'N/A'}</td>
              <td className={classes.smallerDataDiv}>{metric[currentDashboardStartingMonth.slice(0, 3).toLowerCase()]}</td>
              {/* <ClickAwayListener onClickAway={() => setPopperOpen(!popperOpen)}> */}
              <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                <MoreVertOutlinedIcon
                  aria-describedby={'transitions-popper'}
                  onClick={(event) => {
                    setSpecificMetric(event, index)
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                  }
                  }
                  className={classes.threeDots} />
                <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} >
                      <Card style={{ width: "154px", display: "flex" }}>

                        <div onClick={(event) => editMetric(event, 'current')} className={classes.popperDiv}>
                          <p className={classes.popperSelections}>
                            Edit
                          </p>
                          <EditOutlinedIcon />

                        </div>

                        {/* <div onClick={() => moveRowIndex('up')} className={classes.popperDiv}>
                          <p className={classes.popperSelections}>
                            Move Up
                          </p>
                          <ExpandLessIcon />

                        </div>
                        <div onClick={() => moveRowIndex('down')} className={classes.popperDiv}>
                          <p className={classes.popperSelections}>
                            Move down
                          </p>
                          <ExpandMoreIcon />

                        </div> */}

                        <div onClick={(event) => {
                          props.setDeleteMetricModal(true)
                          setAnchorEl(anchorEl ? null : event.currentTarget)
                          setEditPopperOpen(false)

                        }} className={classes.popperDiv}>
                          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} className={classes.popperSelections}>
                            Delete
                            <DeleteOutlineOutlinedIcon />
                          </div>

                        </div>

                      </Card>
                    </Fade>
                  )}
                </Popper>
              </td>
              {/* </ClickAwayListener> */}
            </tr>
          )
        }
      })
    }




    if (table === 'recent') {
      return tableData.map((metric, index) => {
        if (editingMetric && metric.status === 'Active') {
          return (
            index === indexOfMetric ? (
              <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
                {editableSortData(metric, table, index)}

                <td style={{ textAlign: "center" }}>  <div style={{ display: "flex", justifyContent: "center" }}>
                  <button onClick={() => submitEditMetric('recent')} className={classes.goButton} style={{ marginRight: "15px" }}>Save</button>
                  <button className={classes.editCancelBtn} onClick={cancelEditUser}>Cancel</button>
                </div></td>
              </tr>
            ) : (<tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
              {sortData(metric, 'recent', index)}
              <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                <MoreVertOutlinedIcon
                  aria-describedby={'transitions-popper'}
                  onClick={(event) => {
                    setSpecificMetric(event, index)
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                  }
                  }
                  className={classes.threeDots} />
                <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} >
                      <Card style={{ width: "154px", display: "flex" }}>

                        <div onClick={(event) => editMetric(event, 'recent')} className={classes.popperDiv}>
                          <p className={classes.popperSelections}>
                            Edit
                          </p>
                          <EditOutlinedIcon />
                        </div>
                        <div onClick={(event) => {
                          props.setDeleteMetricModal(true)
                          setAnchorEl(anchorEl ? null : event.currentTarget)
                          setEditPopperOpen(false)

                        }} className={classes.popperDiv}>
                          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} className={classes.popperSelections}>
                            Delete
                            <DeleteOutlineOutlinedIcon />
                          </div>

                        </div>

                      </Card>
                    </Fade>
                  )}
                </Popper>
              </td>
              {/* </ClickAwayListener> */}
            </tr>)
          )
        } else if (metric.status === "Active") {
          return (
            <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
              {sortData(metric, 'recent', index)}
              <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                <MoreVertOutlinedIcon
                  aria-describedby={'transitions-popper'}
                  onClick={(event) => {
                    setSpecificMetric(event, index)
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                  }
                  }
                  className={classes.threeDots} />
                <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} >
                      <Card style={{ width: "154px", display: "flex" }}>

                        <div onClick={(event) => editMetric(event, 'recent')} className={classes.popperDiv}>
                          <p className={classes.popperSelections}>
                            Edit
                          </p>
                          <EditOutlinedIcon />
                        </div>
                        <div onClick={(event) => {
                          props.setDeleteMetricModal(true)
                          setAnchorEl(anchorEl ? null : event.currentTarget)
                          setEditPopperOpen(false)

                        }} className={classes.popperDiv}>
                          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} className={classes.popperSelections}>
                            Delete
                            <DeleteOutlineOutlinedIcon />
                          </div>

                        </div>

                      </Card>
                    </Fade>
                  )}
                </Popper>
              </td>
              {/* </ClickAwayListener> */}
            </tr>
          )
        }
      })
    }









    if (table === 'yearly') {
      return tableData.map((metric, index) => {
        if (editingMetric && metric.status === "Active") {
          return (
            index === indexOfMetric ? (
              <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
                {editableSortData(metric, 'yearly', index)}
                <td style={{ textAlign: "center" }}>  <div style={{ display: "flex", justifyContent: "center" }}>
                  <button onClick={() => submitEditMetric('yearly')} className={classes.goButton} style={{ marginRight: "15px" }}>Save</button>
                  <button className={classes.editCancelBtn} onClick={cancelEditUser}>Cancel</button>
                </div></td>
              </tr>
            ) : (<tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
              {sortData(metric, 'yearly', index)}
              <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                <MoreVertOutlinedIcon
                  aria-describedby={'transitions-popper'}
                  onClick={(event) => {
                    setSpecificMetric(event, index)
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                  }
                  }
                  className={classes.threeDots} />
                <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} >
                      <Card style={{ width: "154px", display: "flex" }}>

                        <div onClick={(event) => editMetric(event, 'yearly')} className={classes.popperDiv}>
                          <p className={classes.popperSelections}>
                            Edit
                          </p>
                          <EditOutlinedIcon />
                        </div>
                        <div onClick={(event) => {
                          props.setDeleteMetricModal(true)
                          setAnchorEl(anchorEl ? null : event.currentTarget)
                          setEditPopperOpen(false)

                        }} className={classes.popperDiv}>
                          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} className={classes.popperSelections}>
                            Delete
                            <DeleteOutlineOutlinedIcon />
                          </div>

                        </div>

                      </Card>
                    </Fade>
                  )}
                </Popper>
              </td>
              {/* </ClickAwayListener> */}
            </tr>)
          )
        } else if (metric.status === "Active") {
          return (
            <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
              {sortData(metric, 'yearly', index)}
              {/* <ClickAwayListener onClickAway={() => setPopperOpen(!popperOpen)}> */}
              <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                <MoreVertOutlinedIcon
                  aria-describedby={'transitions-popper'}
                  onClick={(event) => {
                    setSpecificMetric(event, index)
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                  }
                  }
                  className={classes.threeDots} />
                <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} >
                      <Card style={{ width: "154px", display: "flex" }}>

                        <div onClick={(event) => editMetric(event, 'yearly')} className={classes.popperDiv}>
                          <p className={classes.popperSelections}>
                            Edit
                          </p>
                          <EditOutlinedIcon />
                        </div>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} onClick={(event) => {
                          props.setDeleteMetricModal(true)
                          setAnchorEl(anchorEl ? null : event.currentTarget)
                          setEditPopperOpen(false)

                        }} className={classes.popperDiv}>
                          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} onClick={(event) => {
                            props.setDeleteMetricModal(true)
                            setAnchorEl(anchorEl ? null : event.currentTarget)
                            setEditPopperOpen(false)

                          }} className={classes.popperSelections}>
                            Delete
                            <DeleteOutlineOutlinedIcon />
                          </div>

                        </div>

                      </Card>
                    </Fade>
                  )}
                </Popper>
              </td>
              {/* </ClickAwayListener> */}
            </tr>
          )
        }
      })
    }





    if (table === 'dataDownload') {

      if (rangeTableData.length > 0 && finishedSettingRange) {
        return rangeTableData.map((metric, index) => {
          // need to add editing metric conditional
          if (editingMetric) {
            return (
              index === indexOfMetric ? (
                <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
                  {sortRangeDataDownload(metric, index, 'editing')}

                  <td style={{ textAlign: "center" }}>  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button onClick={() => submitRangeDataDownload(index)} className={classes.goButton} style={{ marginRight: "15px" }}>Save</button>
                    <button className={classes.editCancelBtn} onClick={cancelEditUser}>Cancel</button>
                  </div></td>
                </tr>
              ) : (<tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
                {sortRangeDataDownload(metric, index)}
                <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                  <MoreVertOutlinedIcon
                    aria-describedby={'transitions-popper'}
                    onClick={(event) => {
                      setSpecificMetric(event, index)
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                    }
                    }
                    className={classes.threeDots} />
                  <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} >
                        <Card style={{ width: "154px", display: "flex" }}>

                          <div onClick={(event) => editMetric(event, 'dataDownload')} className={classes.popperDiv}>
                            <p className={classes.popperSelections}>
                              Edit
                            </p>
                            <EditOutlinedIcon />
                          </div>

                          <div onClick={() => moveRowIndex('up')} className={classes.popperDiv}>
                            <p className={classes.popperSelections}>
                              Move Up
                            </p>
                            <ExpandLessIcon />

                          </div>
                          <div onClick={() => moveRowIndex('down')} className={classes.popperDiv}>
                            <p className={classes.popperSelections}>
                              Move down
                            </p>
                            <ExpandMoreIcon />

                          </div>

                          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} onClick={(event) => {
                            props.setDeleteMetricModal(true)
                            setAnchorEl(anchorEl ? null : event.currentTarget)
                            setEditPopperOpen(false)

                          }} className={classes.popperDiv}>
                            <div className={classes.popperSelections}>
                              Delete
                              <DeleteOutlineOutlinedIcon />
                            </div>

                          </div>

                        </Card>
                      </Fade>
                    )}
                  </Popper>
                </td>
                {/* </ClickAwayListener> */}
              </tr>)
            )
          } else {
            return (
              <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
                {sortRangeDataDownload(metric, index)}
                {/* <ClickAwayListener onClickAway={() => setPopperOpen(!popperOpen)}> */}
                <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                  <MoreVertOutlinedIcon
                    aria-describedby={'transitions-popper'}
                    onClick={(event) => {
                      setSpecificMetric(event, index)
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                    }
                    }
                    className={classes.threeDots} />
                  <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} >
                        <Card style={{ width: "154px", display: "flex" }}>

                          <div
                            className={classes.dataDownlodPopperDiv}>
                            <div onClick={(event) => editMetric(event, 'dataDownload')} className={classes.popperDiv}>
                              <p className={classes.popperSelections}>
                                Edit
                              </p>
                              <EditOutlinedIcon />
                            </div>

                            <div onClick={() => moveRowIndex('up')} className={classes.popperDiv}>
                              <p className={classes.popperSelections}>
                                Move Up
                              </p>
                              <ExpandLessIcon />

                            </div>
                            <div onClick={() => moveRowIndex('down')} className={classes.popperDiv}>
                              <p className={classes.popperSelections}>
                                Move down
                              </p>
                              <ExpandMoreIcon />

                            </div>

                            <div onClick={(event) => {
                              props.setDeleteMetricModal(true)
                              setAnchorEl(anchorEl ? null : event.currentTarget)
                              setEditPopperOpen(false)

                            }} className={classes.popperDiv}>
                              <p className={classes.popperSelections}>
                                Delete
                              </p>
                              <DeleteOutlineOutlinedIcon />
                            </div>
                            {tableData[indexOfMetric].status === "Active" ? (
                              <div onClick={() => enableDisable('range')} className={classes.popperDiv}>
                                <p className={classes.popperSelections}>
                                  Deactivate
                                </p>
                                <BlockIcon />
                              </div>
                            ) : (
                              <div onClick={() => enableDisable('range')} className={classes.popperDiv}>
                                <p className={classes.popperSelections}>
                                  Activate
                                </p>
                                <AddIcon /> </div>)
                            }

                          </div>

                        </Card>
                      </Fade>
                    )}
                  </Popper>
                </td>
                {/* </ClickAwayListener> */}
              </tr>
            )



          }
        })



      } else {
        return tableData.map((metric, index) => {
          if (editingMetric) {
            return (
              index === indexOfMetric ? (
                <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
                  {editableSortData(metric, 'dataDownload', index)}

                  <td style={{ textAlign: "center" }}>  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button onClick={() => submitEditMetric('dataDownload')} className={classes.goButton} style={{ marginRight: "15px" }}>Save</button>
                    <button className={classes.editCancelBtn} onClick={cancelEditUser}>Cancel</button>
                  </div></td>
                </tr>
              ) : (<tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
                {sortData(metric, "dataDownload", index)}
                <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                  <MoreVertOutlinedIcon
                    aria-describedby={'transitions-popper'}
                    onClick={(event) => {
                      setSpecificMetric(event, index)
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                    }
                    }
                    className={classes.threeDots} />
                  <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} >
                        <Card style={{ width: "154px", display: "flex" }}>

                          <div onClick={(event) => editMetric(event, 'dataDownload')} className={classes.popperDiv}>
                            <p className={classes.popperSelections}>
                              Edit
                            </p>
                            <EditOutlinedIcon />
                          </div>

                          <div onClick={() => moveRowIndex('up')} className={classes.popperDiv}>
                            <p className={classes.popperSelections}>
                              Move Up
                            </p>
                            <ExpandLessIcon />

                          </div>
                          <div onClick={() => moveRowIndex('down')} className={classes.popperDiv}>
                            <p className={classes.popperSelections}>
                              Move down
                            </p>
                            <ExpandMoreIcon />

                          </div>
                          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} onClick={(event) => {
                            props.setDeleteMetricModal(true)
                            setAnchorEl(anchorEl ? null : event.currentTarget)
                            setEditPopperOpen(false)

                          }} className={classes.popperDiv}>
                            <div className={classes.popperSelections}>
                              Delete
                              <DeleteOutlineOutlinedIcon />
                            </div>

                          </div>

                        </Card>
                      </Fade>
                    )}
                  </Popper>
                </td>
                {/* </ClickAwayListener> */}
              </tr>)
            )
          } else {
            return (
              <tr style={index % 2 === 1 ? { background: "#EEEEEE", height: "55px" } : { height: "55px" }} key={index}>
                {sortData(metric, "dataDownload", index)}
                {/* <ClickAwayListener onClickAway={() => setPopperOpen(!popperOpen)}> */}
                <td style={{ display: "flex", justifyContent: "center", height: "100px", alignItems: "center" }}>
                  <MoreVertOutlinedIcon
                    aria-describedby={'transitions-popper'}
                    onClick={(event) => {
                      setSpecificMetric(event, index)
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                    }
                    }
                    className={classes.threeDots} />
                  <Popper style={!anchorEl ? { display: "none" } : {}} placement={'right'} id={'transitions-popper'} open={editPopperOpen} anchorEl={anchorEl} transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} >
                        <Card style={{ width: "154px", display: "flex" }}>

                          <div
                            className={classes.dataDownlodPopperDiv}>
                            <div onClick={(event) => editMetric(event, 'dataDownload')} className={classes.popperDiv}>
                              <p className={classes.popperSelections}>
                                Edit
                              </p>
                              <EditOutlinedIcon />
                            </div>

                            <div onClick={() => moveRowIndex('up')} className={classes.popperDiv}>
                              <p className={classes.popperSelections}>
                                Move Up
                              </p>
                              <ExpandLessIcon />

                            </div>
                            <div onClick={() => moveRowIndex('down')} className={classes.popperDiv}>
                              <p className={classes.popperSelections}>
                                Move down
                              </p>
                              <ExpandMoreIcon />

                            </div>
                            <div onClick={(event) => {
                              props.setDeleteMetricModal(true)
                              setAnchorEl(anchorEl ? null : event.currentTarget)
                              setEditPopperOpen(false)

                            }} className={classes.popperDiv}>
                              <p className={classes.popperSelections}>
                                Delete
                              </p>
                              <DeleteOutlineOutlinedIcon />
                            </div>
                            {tableData[indexOfMetric].status === "Active" ? (
                              <div onClick={() => enableDisable()} className={classes.popperDiv}>
                                <p className={classes.popperSelections}>
                                  Deactivate
                                </p>
                                <BlockIcon />
                              </div>
                            ) : (
                              <div onClick={() => enableDisable()} className={classes.popperDiv}>
                                <p className={classes.popperSelections}>
                                  Activate
                                </p>
                                <AddIcon /> </div>)
                            }

                          </div>

                        </Card>
                      </Fade>
                    )}
                  </Popper>
                </td>
                {/* </ClickAwayListener> */}
              </tr>
            )
          }
        })
      }
    }

  }





  return (
    finishedLoading && (

      <GridContainer>
        <GridContainer style={{ backgroundColor: "#E5E5E5", height: "60px", paddingBottom:"5px"  }}>
          <GridItem xs={6}>
            <h3
              style={{
                fontFamily: "'Merriweather Sans', sans-serif",
                textTransform: "uppercase",
                color: "#3a3934",
                fontWeight: "600",
                verticalAlign: "center"
              }}
            >
              Welcome to your dashboard</h3>
          </GridItem>
          <GridItem xs={6} style={{ justifyContent: "flex-end", display: "flex", marginTop: "7px",verticalAlign:"center" }}>
            <Button
              className={classes.button}
              style={{ height: "35px", marginRight: "15px" }}
              onClick={() => setLearnAboutDashboardOpen(true)}
            // onClick={() => this.handleClickOpen("learnDashboardModal")}
            >
              LEARN ABOUT DASHBOARD
            </Button>
            <Dialog
                        classes={{
                          root: classes.modalRoot,
                          paper: classes.modal + " " + classes.modalSmall
                        }}
                        open={learnAboutDashboardOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setLearnAboutDashboardOpen(false)}
                        aria-labelledby="shipment-modal-slide-title"
                        aria-describedby="shipment-modal-slide-description"
                      >
                        <DialogContent
                          id="shipment-modal-slide-description"
                          className={classes.modalBody}
                          style={{ backgroundColor: "#eee", color: "#333" }}
                        >
                          <h2>Learn About the Dashboard</h2>
                          <p>The Dashboard allows you to track and store metric data for six different types of metrics: Purpose
                            Metrics, Health Metrics, Stakeholder Community Engagement Metrics, Value Proposition Metrics, Key
                            Competency Metrics and Initiative Metrics.</p>

                          <p><b>Monthly Data:</b> All data is stored under a particular year and month, or period. All periods in a particular
                            view will be displayed in a forward period sequence. </p>

                          <p><b>Views:</b> The Dashboard provides 4 different views</p>
                          <p>1. Current View (one month)</p>
                          <p>2. Recent Trending View (six months)</p>
                          <p>3. Yearly Trending View (twelve months)</p>
                          <p>4. Data Download View (any date range)</p>
                          <p><b>Date Specifier:</b> In the upper left hand corner of each view you will be able to set the starting period
                            (year and month). This date can be changed at any time. Views 1-3 will have the starting period always
                            show the last date specified, no matter in which view the date is last changed. In the Data Download
                            View, a range of periods is specified (year, month to year, month). </p>
                          <p><b>Columns:</b> All views have similar columns: Type (choose one of the six metric types listed in drop down
                            menu), Metric Description (describing the metric – when describing an Engagement metric, start with
                            the key stakeholder name), Measure Definition (describing the unit of measure), 5 Year Target (BHAG
                            amount), 1 Year Target (current year target amount), Baseline (starting point amount), Month (starting
                            with the Date Specified Period), Status (View 4 only: Active or Inactive), and Actions Menus displayed as
                            three dots (Edit and Delete in Views 1-3, Edit, Delete, Move Up or Down, and Deactivate/Activate in
                            View 4).</p>
                          <p><b>Actions Menus:</b> To open up an Actions Menu, click on the three dots,a menu will appear and click on the action you want.  If you don’t want to take any actions, click on the three dots again to hide the menu.</p>
                          <p><b>Creating a New Metric:</b>To create a new metric, first set the Date Specifier and then click on “+” button in the lower right hand corner. A data entry screen will appear where you can add as much data as you want for a specific metric. Make sure you enter at a minimum the type of metric. If you enter period data, the year for that data will always be the year entered in the Date Specifier.  Click on Submit button in the lower right hand cornerto create the metric. The metric will then appear on the screen.</p>
                          <p><b>Edit Data:</b> To edit or add data to the metric, click on the three dots in the Actions column in any view and click on Edit, which will open up the metric cells for entry and editing. When you have completed editing, click on Save.  Be cognizant of the period showing in the Data Specifier.  If you don’t want to make any of the edits, click on Cancel.</p>
                          <p><b>Delete a Metric:</b> If you want to delete a metric click on the three dots and click on Delete.  It will ask you if you really want to delete this metric.  If you delete the metric, it is gone forever.  Once you start the delete function, don’t stop the process until it has completed the full cycle.  It might take up to 30 seconds to perform the deletion.</p>
                          <p><b>Move a Metric:</b>  To change the priority of the metrics, use the move function, which can be found under Actions in the Data Download View.  Place your cursor on the row you want to move to highlight it, then click on the three dots to open up the menu, and then press the up or down symbol to move the row up or down one row at a time.</p>
                          <p><b>Deactivating/Activating a Metric:</b> If you are not using one of your metrics at the present time and don’t want to delete it, you can deactivate it. What that does is remove the metric from Views 1-3.  It will always be present in View 4 in an inactive status. To deactivate a metric, move to the Data Download View, place your cursor on the metric in question, click on the three dots to open the menu and then click on Deactivate.  You can always reactivate the metric by going back to the Data Download View, placing your cursor on the metric in questionand clicking on Activatein the Action Menu.</p>
                          <p><b>Export Function:</b> All of your views can be exported into a CVS format (Excel spreadsheet format). To export a view,  just click on the Exportbutton in the upper right hand corner. In the Data Download view, you will be given an option to export all metrics or just active ones.</p>
                          <h5><b>Enjoy using your Dashboard</b></h5>
                        </DialogContent>
                      </Dialog>
          </GridItem>
        </GridContainer>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            tabs={[
              {
                id: "current",
                tabName: "Current Dashboard",
                // tabIcon: () => ,
                tabContent: (
                  <div>
                    <div className={classes.tableDiv}>
                      <div className={classes.tableHeaderCurrent}>
                        <div style={{ display: "flex" }}>
                          <FormControl style={{ width: "150px", marginRight: "30px" }} className={classes.formControl}>
                            <InputLabel style={{ color: "black" }} shrink id="Year">Year</InputLabel>
                            <Select
                              // defaultValue='January'
                              disableUn
                              labelId='Year'
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                formControl: classes.addUserInput
                              }}
                              value={selectedYear}
                              onChange={(event) => {
                                setSelectedYear(event.target.value)
                                setSelectedYearLastTwoDigits(event.target.value.slice(2))
                                props.getNewYearMetrics(uid, event.target.value, props.metricsIdsArray, props.monthToStartFilter)
                              }}
                              inputProps={{
                                name: "simpleSelect",
                                id: 'age-native-label-placeholder',
                                // className: classes.addUserInput
                              }}
                            >
                              {years.map((value, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={value}
                                  >
                                    {value}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>

                          <div>
                            <FormControl style={{ width: "150px" }} className={classes.formControl}>
                              <InputLabel style={{ color: "black" }} shrink id="Month">Month</InputLabel>
                              <Select
                                // defaultValue='January'
                                disableUn
                                labelId='Month'
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  formControl: classes.addUserInput
                                }}
                                value={currentDashboardStartingMonth}
                                onChange={(event) => {
                                  setCurrentDashboardStartingMonth(event.target.value)
                                  filterCurrentDashboardTableDataByMonth(event.target.value)
                                }}
                                inputProps={{
                                  name: "simpleSelect",
                                  id: 'age-native-label-placeholder',
                                  // className: classes.addUserInput
                                }}
                              >
                                {months.map((value, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelectedMultiple
                                      }}
                                      value={value}
                                    >
                                      {value}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* <GridItem xs={6} sm={3} md={3}>
                          <Button
                            color="primary"
                            onClick={() => setLearnAboutDashboardOpen(true)}
                          // onClick={() => this.handleClickOpen("learnDashboardModal")}
                          >
                            LEARN ABOUT DASHBOARD
                          </Button>
                        </GridItem> */}
                        {buttonClicked === true ?
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                style={{
                                  marginTop: "15px",
                                  marginLeft: "60px",
                                  position: "relative",
                                  width: "40%"
                                }}
                                src={loader}
                                alt="..."
                              />
                            </div>
                          </>
                          : null}
                        <GridItem>
                          {user.fixed === false || user.fixed === undefined ?
                            <Button
                              onClick={() => handleFix()}
                              className={classes.button}
                              style={{ width: "120px" }}
                            >
                              Fix Function
                            </Button>
                            : null}
                          <Button 
                          className={classes.button}
                          onClick={(event) => {
                            props.getCurrentDashboardExportData(tableData, currentDashboardStartingMonth)
                            setEditPopperOpen(false)
                            setExportPopperOpen(!exportPopperOpen)
                            setAnchorEl(anchorEl ? null : event.currentTarget)
                          }} 
                          style={!editingMetric ? { marginRight: "10px", visibility: "visible" } : { visibility: "hidden" }} className={classes.button}>Export</Button>
                        </GridItem>
                      </div>
                      <Popper
                        placement={'right-start'} id={'transitions-popper'} open={exportPopperOpen} anchorEl={anchorEl} transition>
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} >
                            <Card style={{ width: "154px", display: "flex" }}>
                              <div
                                //  onClick={(event) => editUser(event)}

                                className={classes.exportPopperDiv}>
                                {/* <p className={classes.exportPopperSelections}>
                                  Export To PDF
                                </p> */}
                                <CSVLink data={currentDashboardCSVData}>
                                  <p onClick={() => {
                                    setExportPopperOpen(!exportPopperOpen)
                                    setAnchorEl(anchorEl ? null : event.currentTarget)
                                    props.getCurrentDashboardExportData(tableData, currentDashboardStartingMonth)
                                  }} style={{ color: "black", margin: "5px 0px 5px 0px" }} className={classes.exportPopperSelections}>
                                    Export To CSV
                                  </p>
                                </CSVLink>
                              </div>

                            </Card>
                          </Fade>
                        )}
                      </Popper>
                      {/* <ReactTable
                     data={tableData}
                      style={{ height: "600px", overflow: "scroll" }} columns={props.metrics.currentDashboardMetrics.columns} /> */}
                      <table style={{ borderCollapse: "collapse", height: "650px", width: "100%", display: "flex", flexDirection: "column", overflowY: "scroll" }} id='current'>
                        {dataLoaded && tableData && tableData.length > 0 ? (
                          <>


                            <tbody>
                              <thead style={{ position: "sticky", top: 0, backgroundColor: "#4ABEB5", height: "80px", color: "#f2f2f2", }}>
                                {renderTableHeader('current')}
                              </thead>
                              {renderTableData('current')}
                            </tbody>
                          </>

                        ) : dataLoaded && tableData && tableData.length === 0 ? (<div className={classes.noDataMessage}>You Haven't Entered Any Data. Click The Plus Button To Get Started.</div>) : (<div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: "80px"
                          }}
                        >
                          <img
                            style={{
                              // margin: "65% 0% 0% 38%",
                              // position: "relative",
                              // width: "20%"
                            }}
                            src={loader}
                            alt="..."
                          />
                        </div>)}

                      </table>

                    </div>
                    {/* <Fab onClick={() => setAddMetricCurrentDashboardsModalOpen(true)} style={{ float: "right", background: "#4ec7bc", color: "white" }} aria-label="add">
                      <AddIcon />
                    </Fab> */}
                  </div>
                )
              },
              {
                tabName: "Recent Trending Dashboard",
                tabContent: (
                  <div>
                    <div className={classes.tableDiv}>
                      <div className={classes.tableHeaderCurrent}>
                        <div style={{ display: "flex" }}>
                          <FormControl style={{ width: "150px", marginRight: "30px" }} className={classes.formControl}>
                            <InputLabel style={{ color: "black" }} shrink id="Year">Year</InputLabel>
                            <Select
                              // defaultValue='January'
                              disableUn
                              labelId='Year'
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                formControl: classes.addUserInput
                              }}
                              value={selectedYear}
                              onChange={(event) => {
                                setSelectedYear(event.target.value)
                                setSelectedYearLastTwoDigits(event.target.value.slice(2))
                                props.getNewYearMetrics(uid, event.target.value, props.metricsIdsArray, props.monthToStartFilter)
                              }}
                              inputProps={{
                                name: "simpleSelect",
                                id: 'age-native-label-placeholder',
                                // className: classes.addUserInput
                              }}
                            >
                              {years.map((value, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={value}
                                  >
                                    {value}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>

                          <div>
                            <FormControl style={{ width: "150px" }} className={classes.formControl}>
                              <InputLabel style={{ color: "black" }} shrink id="Month">Month</InputLabel>
                              <Select
                                // defaultValue='January'
                                disableUn
                                labelId='Month'
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  formControl: classes.addUserInput
                                }}
                                value={currentDashboardStartingMonth}
                                onChange={(event) => {
                                  setCurrentDashboardStartingMonth(event.target.value)
                                  filterCurrentDashboardTableDataByMonth(event.target.value)

                                  props.getNewYearMetrics(uid, selectedYear, props.metricsIdsArray, props.monthToStartFilter)
                                }}
                                inputProps={{
                                  name: "simpleSelect",
                                  id: 'age-native-label-placeholder',
                                  // className: classes.addUserInput
                                }}
                              >
                                {months.map((value, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelectedMultiple
                                      }}
                                      value={value}
                                    >
                                      {value}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <Button 
                        className={classes.button}
                        onClick={(event) => {
                          setEditPopperOpen(false)
                          setExportPopperOpen(!exportPopperOpen)
                          setAnchorEl(anchorEl ? null : event.currentTarget);
                        }} style={!editingMetric ? { marginRight: "25px", visibility: "visible" } : { visibility: "hidden" }} className={classes.button}>Export</Button>
                      </div>
                      <Popper
                        style={!anchorEl ? { display: "none" } : {}}


                        placement={'right-start'} id={'transitions-popper'} open={exportPopperOpen} anchorEl={anchorEl} transition>
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} >
                            <Card style={{ width: "154px", display: "flex" }}>
                              <div
                                //  onClick={(event) => editUser(event)}

                                className={classes.exportPopperDiv}>
                                <CSVLink data={recentDashboardCSVData}>
                                  <p onClick={() => {



                                    if (currentDashboardStartingMonth === 'August' || currentDashboardStartingMonth === 'September' || currentDashboardStartingMonth === 'October' || currentDashboardStartingMonth === 'November' || currentDashboardStartingMonth === 'December') {
                                      props.getRecentDashboardExportData(tableData, currentDashboardStartingMonth, recentColumnHeaders, selectedYear, nextYearsData)

                                    } else {
                                      props.getRecentDashboardExportData(tableData, currentDashboardStartingMonth, recentColumnHeaders, selectedYear)
                                    }
                                    setExportPopperOpen(!exportPopperOpen)
                                    setAnchorEl(anchorEl ? null : event.currentTarget)
                                  }} style={{ color: "black", margin: "5px 0px 5px 0px" }} className={classes.exportPopperSelections}>
                                    Export To CSV
                                  </p>
                                </CSVLink>

                              </div>

                            </Card>
                          </Fade>
                        )}
                      </Popper>



                      <table style={{ borderCollapse: "collapse", height: "650px", width: "100%", display: "flex", flexDirection: "column", overflowY: "scroll" }} id='recent'>
                        {tableData && nextYearsData && tableData.length > 0 ? (
                          <>
                            <tbody>
                              <thead style={{ position: "sticky", top: 0, backgroundColor: "#4ABEB5", height: "80px", color: "#f2f2f2", align: "center" }}>
                                {renderTableHeader('recent')}
                              </thead>
                              {renderTableData('recent')}
                            </tbody>
                          </>
                        ) : (<div className={classes.noDataMessage}>You Haven't Entered Any Data. Click The Plus Button To Get Started.</div>)}
                      </table>
                    </div>
                  </div>
                )
              },
              {
                tabName: "Yearly Trending Dashboard",
                // tabIcon: BugReport,
                tabContent: (
                  <div>
                    <div className={classes.tableDiv}>
                      <div className={classes.tableHeaderCurrent}>
                        <div style={{ display: "flex" }}>
                          <FormControl style={{ width: "150px", marginRight: "30px" }} className={classes.formControl}>
                            <InputLabel style={{ color: "black" }} shrink id="Year">Year</InputLabel>
                            <Select
                              // defaultValue='January'
                              disableUn
                              labelId='Year'
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                formControl: classes.addUserInput
                              }}
                              value={selectedYear}
                              onChange={(event) => {
                                setSelectedYear(event.target.value)
                                setSelectedYearLastTwoDigits(event.target.value.slice(2))
                                props.getNewYearMetrics(uid, event.target.value, props.metricsIdsArray, props.monthToStartFilter)
                              }}
                              inputProps={{
                                name: "simpleSelect",
                                id: 'age-native-label-placeholder',
                                // className: classes.addUserInput
                              }}
                            >
                              {years.map((value, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={value}
                                  >
                                    {value}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>

                          <div>
                            <FormControl style={{ width: "150px" }} className={classes.formControl}>
                              <InputLabel style={{ color: "black" }} shrink id="Month">Month</InputLabel>
                              <Select
                                // defaultValue='January'
                                disableUn
                                labelId='Month'
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  formControl: classes.addUserInput
                                }}
                                value={currentDashboardStartingMonth}
                                onChange={(event) => {
                                  setCurrentDashboardStartingMonth(event.target.value)
                                  filterCurrentDashboardTableDataByMonth(event.target.value)
                                  props.getNewYearMetrics(uid, selectedYear, props.metricsIdsArray, props.monthToStartFilter)
                                }}
                                inputProps={{
                                  name: "simpleSelect",
                                  id: 'age-native-label-placeholder',
                                  // className: classes.addUserInput
                                }}
                              >
                                {months.map((value, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelectedMultiple
                                      }}
                                      value={value}
                                    >
                                      {value}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <Button 
                        className={classes.button}
                        onClick={(event) => {
                          setEditPopperOpen(false)
                          setExportPopperOpen(!exportPopperOpen)
                          setAnchorEl(anchorEl ? null : event.currentTarget);
                        }} style={!editingMetric ? { marginRight: "25px", visibility: "visible" } : { visibility: "hidden" }} className={classes.button}>Export</Button>
                      </div>
                      <Popper
                        style={!anchorEl ? { display: "none" } : {}}


                        placement={'right-start'} id={'transitions-popper'} open={exportPopperOpen} anchorEl={anchorEl} transition>
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} >
                            <Card style={{ width: "154px", display: "flex" }}>
                              <div
                                //  onClick={(event) => editUser(event)}

                                className={classes.exportPopperDiv}>
                                <CSVLink data={yearlyDashboardCSVData}>
                                  <p onClick={() => {



                                    if (currentDashboardStartingMonth !== 'January') {
                                      props.getYearlyDashboardExportData(tableData, currentDashboardStartingMonth, yearlyColumnHeaders, selectedYear, nextYearsData)

                                    } else {
                                      props.getYearlyDashboardExportData(tableData, currentDashboardStartingMonth, yearlyColumnHeaders, selectedYear)
                                    }
                                    setExportPopperOpen(!exportPopperOpen)
                                    setAnchorEl(anchorEl ? null : event.currentTarget)
                                  }} style={{ color: "black", margin: "5px 0px 5px 0px" }} className={classes.exportPopperSelections}>
                                    Export To CSV
                                  </p>
                                </CSVLink>

                              </div>

                            </Card>
                          </Fade>
                        )}
                      </Popper>
                      <table style={{ borderCollapse: "collapse", height: "650px", width: "100%", display: "flex", flexDirection: "column", overflowY: "scroll" }} id='yearly'>
                        {tableData && nextYearsData && tableData.length > 0 ? (
                          <tbody>
                            <thead style={{ position: "sticky", top: 0, backgroundColor: "#4ABEB5", height: "80px", color: "#f2f2f2" }}>
                              {renderTableHeader('yearly')}
                            </thead>
                            {renderTableData('yearly')}
                          </tbody>
                        ) : (<div className={classes.noDataMessage}>You Haven't Entered Any Data. Click The Plus Button To Get Started.</div>)}
                      </table>
                    </div>
                  </div>
                )
              },
              {
                tabName: "Data Download",
                // tabIcon: Cloud,
                tabContent: (
                  <div>
                    <div className={classes.tableDiv}>
                      <div style={{ justifyContent: "space-between" }} className={classes.tableHeaderDiv}>
                        <div style={{ display: "flex" }}>
                          <FormControl style={{ width: "150px", marginRight: "30px" }} className={classes.formControl}>
                            <InputLabel style={{ color: "black" }} shrink id="Year">From</InputLabel>
                            <Select
                              // defaultValue='January'
                              disableUn
                              labelId='Year'
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                formControl: classes.addUserInput
                              }}
                              value={selectedYear}
                              onChange={(event) => {
                                setSelectedYear(event.target.value)
                                setSelectedYearLastTwoDigits(event.target.value.slice(2))
                                props.getNewYearMetrics(uid, event.target.value, props.metricsIdsArray, props.monthToStartFilter)
                              }}
                              inputProps={{
                                name: "simpleSelect",
                                id: 'age-native-label-placeholder',
                                // className: classes.addUserInput
                              }}
                            >
                              {years.map((value, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={value}
                                  >
                                    {value}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>

                          <FormControl style={{ width: "150px", marginRight: "30px" }} className={classes.formControl}>
                            <InputLabel style={{ color: "black" }} shrink id="Year">To</InputLabel>
                            <Select
                              // defaultValue='January'
                              disableUn
                              labelId='Year'
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                formControl: classes.addUserInput
                              }}
                              value={selectedEndYear}
                              onChange={(event) => {
                                setSelectedEndYear(event.target.value)
                                // setSelectedYearLastTwoDigits(event.target.value.slice(2))
                                setSelectedEndYearLastTwoDigits(event.target.value.slice(2))
                                props.getRangeOfYearsMetricData(uid, selectedYear, event.target.value, props.metricsIdsArray, tableData)
                              }}
                              inputProps={{
                                name: "simpleSelect",
                                id: 'age-native-label-placeholder',
                                // className: classes.addUserInput
                              }}
                            >
                              {
                                years.map((value, index) => {
                                  if (parseInt(value) >= parseInt(selectedYear)) {
                                    return (
                                      <MenuItem
                                        key={index}
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelectedMultiple
                                        }}
                                        value={value}
                                      >
                                        {value}
                                      </MenuItem>
                                    )
                                  }
                                })}
                            </Select>
                          </FormControl>
                        </div>
                        {stringClicked === true ?
                          <>
                            <div
                              style={{
                                display: "flex",
                                // alignItems: "left",
                                // justifyContent: "left",
                              }}
                            >
                              <img
                                style={{
                                  marginLeft: "100px",
                                  // position: "relative",
                                  width: "40%"
                                }}
                                src={loader}
                                alt="..."
                              />
                            </div>
                          </>
                          : null}
                        <GridItem>
                          {user.fixedString === false || user.fixedString === undefined ?
                            <>
                              <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="If your data in the Data Download tab has empty cells, please click the Fix Empty String button and refresh the page"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                  <Info style={{ color: "#4ABEB5" }} />
                                </IconButton>
                              </Tooltip>
                              <Button
                                onClick={() => handleTest()}
                                className={classes.button}
                                style={{ width: "150px" }}
                              >
                                Fix Empty String
                              </Button>
                            </>
                            : null}
                        </GridItem>
                        <Button 
                        className={classes.button}
                        onClick={(event) => {
                          setExportPopperOpen(!exportPopperOpen)
                          setEditPopperOpen(false)
                          setAnchorEl(anchorEl ? null : event.currentTarget);
                        }}
                          style={!editingMetric ? { marginRight: "25px", visibility: "visible", width: "150px", height: "40px" } : { visibility: "hidden", width: "150px" }} className={classes.button}>Export
                        </Button>
                      </div>
                      <Popper
                        //  style={!anchorEl ? { display: "none" } : {}}


                        placement={'right-start'} id={'transitions-popper'} open={exportPopperOpen} anchorEl={anchorEl} transition>
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} >
                            <Card style={{ width: "154px", display: "flex" }}>
                              <div
                                //  onClick={(event) => editUser(event)}

                                className={classes.exportPopperDiv}>
                                <p onClick={() => {
                                  setChooseActiveOrInactiveModal(!chooseActiveOrInactiveModal)
                                  setExportPopperOpen(!exportPopperOpen)
                                  // setAnchorEl(anchorEl ? null : event.currentTarget)
                                }} className={classes.exportPopperSelections}>
                                  Export To CSV
                                </p>

                              </div>

                            </Card>
                          </Fade>
                        )}
                      </Popper>
                      <table style={{ borderCollapse: "collapse", height: "675px", width: "100%", display: "flex", flexDirection: "column", overflowY: "scroll" }} id='dataDownload'>
                        {tableData && nextYearsData && tableData.length > 0 ? (
                          <tbody>
                            <thead style={{ position: "sticky", top: 0, backgroundColor: "#4ABEB5", height: "80px", color: "#f2f2f2" }}>
                              {renderTableHeader('dataDownload')}
                            </thead>
                            {renderTableData('dataDownload')}
                          </tbody>
                        ) : (<div className={classes.noDataMessage}>You Haven't Entered Any Data. Click The Plus Button To Get Started.</div>)}
                      </table>
                    </div>
                  </div>
                )
              }

            ]}
          />
          <Dialog
            open={chooseActiveOrInactiveModal}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            onClose={() => {
              setChooseActiveOrInactiveModal(!chooseActiveOrInactiveModal)
              setAnchorEl(anchorEl ? null : event.currentTarget);
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            {/* <DialogContent style={{ backgroundColor: "#f9f9f9", height: "600px" }}>
          <DialogContentText id="alert-dialog-slide-description"> */}

            <GridContainer justify="center">
              <GridItem style={{ height: "337px", marginTop: "15px" }} xs={11}>
                {/* {resetRequest()} */}

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h6 style={{ textTransform: "none", fontSize: "22px", fontWeight: 500, color: "black" }}>Would you like to export active metrics, inactive metrics, or both? </h6>
                  <CloseIcon className={classes.xIcon}

                    onClick={() => {
                      setChooseActiveOrInactiveModal(!chooseActiveOrInactiveModal)
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                    }}

                  />
                </div>


                <div style={{ display: "flex", flexDirection: "column", height: "30%", marginBottom: "52px" }}>
                  <InputLabel className={classes.addUserLabel} id="Metric Status">Metric Status</InputLabel>
                  <Select
                    labelId='Metric Status'
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select,
                      formControl: classes.addUserInput
                    }}
                    value={exportMetricStatuses}
                    onChange={(event) => setExportMetricStatuses(event.target.value)}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select",
                      // className: classes.addUserInput
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                      }}
                      value="active"
                    >

                      Active
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                      }}
                      value="inactive"
                    >
                      Inactive
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                      }}
                      value="both"
                    >
                      Both
                    </MenuItem>
                  </Select>

                </div>
                <div className={classes.modalBtnDiv} >
                  <Button
                    onClick={() => {
                      setChooseActiveOrInactiveModal(!chooseActiveOrInactiveModal)
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                    }} className={classes.cancelBtn} >
                    Cancel
                  </Button>
                  <CSVLink data={dataDownloadTableDataCSV}>

                    <Button 
                    className={classes.button}
                    onClick={() => {
                      if (selectedYear === selectedEndYear) {
                        props.getDataDownloadExportData(selectedYear, selectedEndYear, tableData, exportMetricStatuses, dataDownloadColumnHeaders)
                      } else {
                        props.getDataDownloadExportData(selectedYear, selectedEndYear, rangeTableData, exportMetricStatuses, dataDownloadColumnHeaders, 'range')
                      }
                      setChooseActiveOrInactiveModal(!chooseActiveOrInactiveModal)
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                    }} className={classes.applyBtn} >
                      Export
                    </Button>
                  </CSVLink>
                </div>

              </GridItem>
            </GridContainer>
            {/* </DialogContentText>
        </DialogContent> */}
          </Dialog>




          <Dialog
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={addMetricCurrentDashboardsModalOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setAddMetricCurrentDashboardsModalOpen(false)}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                simple
                style={{ color: "black", width: "50px" }}
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => setAddMetricCurrentDashboardsModalOpen(false)}
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
            </div>
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{ paddingTop: "0px" }}
            >
              <h3 className={classes.subTitle}style={{ padding: "0px 30px 10px 30px", fontSize: "25px", textAlign: "center" }}>Add A New Metric To The Dashboard</h3>


            </DialogTitle>
            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >

              <AddMetricFormCurrentDashboard currentDashboardStartingMonth={currentDashboardStartingMonth} years={years} uid={uid} selectedYear={selectedYear} tableData={tableData} />
            </DialogContent>
          </Dialog>










          <Dialog
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={props.deleteMetricModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.setDeleteMetricModal(false)}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                simple
                style={{ color: "black", width: "50px" }}
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => props.setDeleteMetricModal(false)}
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
            </div>

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{ paddingTop: "0px" }}
            >
              {!props.currentlyDeleting ? (
                <h3 style={{ padding: "0px 30px 20px 30px", fontSize: "25px", textAlign: "center" }}>Are you sure you want to delete this metric?</h3>
              ) : (
                <h3 style={{ padding: "0px 30px 20px 30px", fontSize: "25px", textAlign: "center" }}>Please wait while the metric is deleted...</h3>
              )}


            </DialogTitle>
            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              {!props.currentlyDeleting ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                  <Button color='danger' onClick={(event) => deleteMetric(event)}>
                    Yes, Delete
                  </Button>
                  <Button color='primary' onClick={() => props.setDeleteMetricModal(false)}>
                    No, Nevermind
                  </Button>
                </div>

              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px"
                  }}
                >
                  <img
                    style={{
                      // margin: "65% 0% 0% 38%",
                      // position: "relative",
                      // width: "20%"
                    }}
                    src={loader}
                    alt="..."
                  />
                </div>
              )}

            </DialogContent>
          </Dialog>

          <div style={{ float: "right", marginRight: "30px" }}>
            <Fab onClick={() => setAddMetricCurrentDashboardsModalOpen(true)} style={{ background: "#F4BF4F", color: "white" }} aria-label="add">
              <AddIcon />
            </Fab>
          </div>



        </GridItem>

      </GridContainer>
    )
  )


}


const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    practices: state.practices.practices,
    SESWorksheetsTable: state.SESWorksheetsTable.engagementWorksheets,
    metrics: state.metrics,
    data: state.metrics.data,
    nextYearsData: state.metrics.nextYearsMetrics,
    nextYearsDataLoaded: state.metrics.nextYearsDataLoaded,
    submitted: state.metrics.submitted,
    recentColumnHeaders: state.metrics.recentColumnHeaders,
    yearlyColumnHeaders: state.metrics.yearlyColumnHeaders,
    dataDownloadColumnHeaders: state.metrics.dataDownloadColumnHeaders,
    deleteMetricModal: state.metrics.deleteMetricModal,
    currentlyDeleting: state.metrics.currentlyDeleting,
    monthToStartFilter: state.metrics.monthToStartFilter,
    dataLoaded: state.metrics.dataLoaded,
    metricsIdsArray: state.metrics.metricsIdsArray,
    rangeOfData: state.metrics.rangeOfData,
    yearsQty: state.metrics.yearsQty,
    finishedSettingRange: state.metrics.finishedSettingRange,
    currentDashboardCSVData: state.metrics.currentDashboardCSVData,
    recentDashboardCSVData: state.metrics.recentDashboardCSVData,
    yearlyDashboardCSVData: state.metrics.yearlyDashboardCSVData,
    dataDownloadTableDataCSV: state.metrics.dataDownloadTableDataCSV
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // settingContentEditable: (table, index) => dispatch(setContentEditable(table, index))
    getMetricsData: (uid, selectedYear) => dispatch(getMetricsData(uid, selectedYear)),
    updateMetric: (uid, selectedYear, updatedCurrentYearMetric, allCurrentYearData, updatedNextYearMetric, allNextYearData, years) => dispatch(updateMetric(uid, selectedYear, updatedCurrentYearMetric, allCurrentYearData, updatedNextYearMetric, allNextYearData, years)),
    deleteMetric: (uid, selectedYear, deletedMetric, toReturn, currentDashboardStartingMonth, allData, metricsIdsArray) => dispatch(deleteMetric(uid, selectedYear, deletedMetric, toReturn, currentDashboardStartingMonth, allData, metricsIdsArray)),
    enableDisableMetric: (uid, selectedYear, updatedMetric, allData, str) => dispatch(enableDisableMetric(uid, selectedYear, updatedMetric, allData, str)),
    changeHeadersActions: (value) => dispatch(changeHeadersActions(value)),
    setNewRowIndex: (uid, selectedYear, direction, data, indexOfMetric) => dispatch(setNewRowIndex(uid, selectedYear, direction, data, indexOfMetric)),
    setDeleteMetricModal: (boolean) => dispatch(setDeleteMetricModal(boolean)),
    getNewYearMetrics: (uid, newYear, metricsIdsArray, monthToStartFilter) => dispatch(getNewYearMetrics(uid, newYear, metricsIdsArray, monthToStartFilter)),
    getRangeOfYearsMetricData: (uid, yearStart, yearEnd, metricsIdsArray, allData) => dispatch(getRangeOfYearsMetricData(uid, yearStart, yearEnd, metricsIdsArray, allData)),
    updateRangeOfYearsMetricData: (uid, dataObj, allRangeData, updatedData) => dispatch(updateRangeOfYearsMetricData(uid, dataObj, allRangeData, updatedData)),
    setUpdatedRange: (data) => dispatch(setUpdatedRange(data)),
    getCurrentDashboardExportData: (data, startingMonth) => dispatch(getCurrentDashboardExportData(data, startingMonth)),
    getRecentDashboardExportData: (data, startingMonth, recentColumnHeaders, selectedYear, nextYearData) => dispatch(getRecentDashboardExportData(data, startingMonth, recentColumnHeaders, selectedYear, nextYearData)),
    getYearlyDashboardExportData: (data, startingMonth, recentColumnHeaders, year, nextYearData) => dispatch(getYearlyDashboardExportData(data, startingMonth, recentColumnHeaders, year, nextYearData)),
    getDataDownloadExportData: (fromYear, toYear, data, exportMetricStatuses, dataDownloadColumnHeaders, str) => dispatch(getDataDownloadExportData(fromYear, toYear, data, exportMetricStatuses, dataDownloadColumnHeaders, str))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Metrics);