import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import firebase, { db, storage } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class Form extends React.Component {
    state = {
        comment: '',
        messageSent: false,
        messageError: false
    }
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden"
        };
    }
    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }
    handleChange = (e, uid, name) => {
        this.setState({
            [e.target.id]: e.target.value,
            uid: uid,
            name: name
        })
    }
    handleImageChange(e, fileType) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                [fileType]: {
                    file,
                    imagePreviewUrl: reader.result,
                    imageChanged: true
                }
            });
        };
        reader.readAsDataURL(file);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const image = this.state.image.file
        const markdown = this.state.markdown.file
        // create storage ref
        var imageStorageRef = storage.ref('blogPosts/' + `${this.state.blogID}/` + image.name)
        var markdownStorageRef = storage.ref('blogPosts/' + `${this.state.blogID}/` + markdown.name)
        // upload file
        var uploadImageTask = imageStorageRef.put(image)
        var uploadMarkdownTask = imageStorageRef.put(markdown)
        // update progress bar
        uploadImageTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and 
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var Progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.setState({ uploadProgress: 'Progress is ' + Progress + '% done' });
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    this.setState({ uploadProgress: ' Upload is paused' });
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    this.setState({ uploadProgress: 'Upload is running' });
                    break;
            }
        }, (error) => {
            // Handle unsuccessful uploads
            console.log(error)
        }, () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadImageTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                this.setState({
                    uploadProgress: 'Upload finished. Updating Firestore',
                    imageURL: downloadURL
                });

                // update progress bar
                uploadMarkdownTask.on('state_changed', (snapshot) => {
                    // Observe state change events such as progress, pause, and 
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var Progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.setState({ uploadProgress: 'Progress is ' + Progress + '% done' });
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            this.setState({ uploadProgress: ' Upload is paused' });
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            this.setState({ uploadProgress: 'Upload is running' });
                            break;
                    }
                }, (error) => {
                    // Handle unsuccessful uploads
                    console.log(error)
                }, () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadMarkdownTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        this.setState({ uploadProgress: 'Upload finished. Updating Firestore' });

                        db.collection("blogPosts").doc((this.state.blogID && this.state.blogID)).set({
                            title: this.state.title,
                            author: this.state.author,
                            image: this.state.imageURL,
                            blogText: downloadURL,
                            show: false,
                            uid: this.props.authUser.uid,
                            date: new Date()
                        }, { merge: true }).then(() => {
                            window.location.reload()
                        })
                    });
                })
            });
        })
    }

    resetRequest = () => {
        window.location.reload();
    }

    render() {
        const { classes, modalClose, authUser, blogID } = this.props;
        console.log(this.state)
        // reset modal when closed
        if (this.state.messageSent) { this.resetRequest() }

        return (
            <div>
                {this.state.messageSent ? (
                    <GridContainer justify="center">
                        <GridItem xs={11}>
                            <h4 className={classes.textPrimary}>Title Submitted!</h4>

                        </GridItem>
                    </GridContainer>
                ) : (
                        <div className={classes.formContainer}>
                            <form onSubmit={this.handleSubmit}
                            >
                                <GridContainer justify="center">
                                    <GridItem xs={11}>
                                        <CustomInput
                                            labelText="ID"
                                            id="blogID"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                required: true,
                                                type: "text",
                                                onChange: (event) => this.handleChange(event)
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={11}>
                                        {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                                        <CustomInput
                                            labelText="Title"
                                            id="title"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                required: true,
                                                type: "text",
                                                onChange: (event) => this.handleChange(event)
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={11}>
                                        <CustomInput
                                            labelText="Author"
                                            id="author"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                required: true,
                                                type: "text",
                                                onChange: (event) => this.handleChange(event)
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={11}>
                                        <p style={{ textAlign: "center", width: "100%" }}>Image</p>
                                        <div className="picture-container">
                                            <div className="picture">
                                                <img
                                                    src={this.state.imagePreviewUrl}
                                                    className="picture-src"
                                                    alt="..."
                                                />
                                                <input type="file" onChange={e => this.handleImageChange(e, "image")} />
                                            </div>
                                        </div>

                                    </GridItem>
                                    Text Markdown File
                  <GridItem xs={11}>
                                        <div className="picture-container">
                                            <div className="picture">
                                                <img
                                                    src={this.state.imagePreviewUrl}
                                                    className="picture-src"
                                                    alt="..."
                                                />
                                                <input type="file" onChange={e => this.handleImageChange(e, "markdown")} />
                                            </div>
                                        </div>

                                    </GridItem>
                                </GridContainer>

                                <Button
                                    type="submit"
                                    color="primary"
                                    style={{
                                        marginLeft: "10px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    SUBMIT
              </Button>
                            </form>
                        </div>

                    )}
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    console.log(state)
    return {
        AuthBool: !!state.auth.user,
        authUser: state.auth.user,
        user: state.profile.user,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(Form);