// Orders Data
const actions = {
  columns: [
    {
      Header: "ACTION ID",
      accessor: "actionID",
      show: false,
    },
    {
      Header: "TITLE",
      accessor: "title",
    },
    {
      Header: "BODY",
      accessor: "description",
      show: false,
    },
    {
      Header: "DUE",
      accessor: "dueDate",
    },
    {
      Header: "DAYS DUE",
      accessor: "days",
      show: false,
    },
    {
      Header: "POINTS",
      accessor: "points",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false,
    },
  ],
  dataLoaded: false,
};
// Orders Data
const pendingActions = {
  columns: [
    {
      Header: "VOL EMAIL",
      accessor: "email",
    },
    {
      Header: "VOL UID",
      accessor: "volUID",
    },
    {
      Header: "ACTION ID",
      accessor: "actionId",
    },
    {
      Header: "TITLE",
      accessor: "title",
    },
    {
      Header: "COMMENT",
      accessor: "comment",
    },
    {
      Header: "DAYS DUE",
      accessor: "days",
      show: false,
    },
    {
      Header: "POINTS",
      accessor: "points",
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false,
    },
  ],
  dataLoaded: false,
};

// Orders Data
const newsletters = {
  columns: [
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "PDF",
      accessor: "pdf",
    },
  ],
  dataLoaded: false,
};

// Orders Data
const upcomingPodcasts = {
  columns: [
    {
      Header: "TYPE",
      accessor: "type",
    },
    {
      Header: "LENGTH",
      accessor: "length",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "TIME",
      accessor: "time",
    },
    {
      Header: "TITLE",
      accessor: "title",
    },
    {
      Header: "PRESENTERS",
      accessor: "presenters",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "REGISTER",
      accessor: "register",
    },
  ],
  dataLoaded: false,
};

export const initState = {
  newsletters,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_NEWSLETTERS_LIST":
      console.log("SET_NEWSLETTERS_LIST");
      return {
        ...state,
        newsletters: {
          ...state.newsletters,
          dataRows: action.dataRows,
          dataLoaded: true,
        },
      };
    default:
      return state;
  }
};
