import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { Chart } from 'react-google-charts';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/dashboardFFPOStyle.jsx";
import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import { setFirstAssessment } from "store/actions/assessmentActions"
import AssessmentResults from "./AssessmentResults";

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updatePhotoModal: false,
      learnDashboardModal: false
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
    this.setState({
      dataLoaded: true
    })

    if (this.props.assessments.assessments.length === 0) {
      setFirstAssessment(this.props.authUser)
      console.log("set action for " + this.props.authUser)
    }

  }




  // Load data for Table
  // if there are no actions make a default one



  render() {
    const { AuthBool, classes, user, assessments } = this.props;

    let profilePhoto = DefaultProfile
    if (user.termGroups) {
      //console.log(user.termGroups.length)

      if (user.termGroups.length < 2) {
        return <Redirect to='/profile' />
      }
    }
    //if (!auth.uid) return <Redirect to='/home' />


    if (AuthBool) { // fixes freeze on logout
      if (user.dataLoaded) {
        user.profileURL ? (
          profilePhoto = user.profileURL
        ) : (
          // add link to profile photo to firestore
          profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
          // add profile photo to firebase storage
          // update redux with link
        )
      }
    }

    let lastAssessmentPurposePercent
    //console.log(user)
    //console.log(assessments.dataLoaded)
    if (user.dataLoaded && assessments.dataLoaded
      && !this.state.dataLoaded) {
      //console.log("load data")
      this.loadData()
      // console.log(user)
      // console.log(user.levelUpdatedAt.toDate())
      // console.log(moment(user.levelUpdatedAt.toDate()).format('MM-DD-YYYY'))
    }

    //console.log(assessments.assessments.length)

    let chartData = [['Date', 'Actions', 'Strategy', 'Stakeholders', 'Purpose']];

    //console.log(assessments.assessments.length)
    for (var i = (assessments.assessments.length - 2); i > -1; i--) {
      //console.log(assessments.assessments[i])
      let assessmentData = [
        moment(assessments.assessments[i].updatedAt.toDate()).format('MM-DD-YYYY'),
        Math.round((assessments.assessments[i].aaPercent * 100)),
        Math.round((assessments.assessments[i].saPercent * 100)),
        Math.round((assessments.assessments[i].sePercent * 100)),
        Math.round((assessments.assessments[i].pePercent * 100))
      ]

      chartData.push(assessmentData);
    };

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          {this.state.dataLoaded ? (
            <div>
              {/* MOBILE VIEW */}
              <Hidden mdUp implementation="css">
                <GridContainer justify="center">
                  <GridItem xs={6} sm={4} md={4}>
                    <Link to="/assess/path">
                      <Button
                        className={classes.button}
                        onClick={() => this.handleClickOpen("learnDashboardModal")}
                      >
                        INTRODUCTION
                      </Button>
                    </Link>
                  </GridItem>
                  {/* <GridItem xs={3} sm={3} md={3}>
                <Link to="/tools/playbook">
                    <Button
                        color="primary"
                      >Playbook</Button>
                      </Link>
                      <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Tools%2FGomez%20Foods%20Playbook%20_Feb%203%202020.pdf?alt=media&token=b56f43b8-fde7-45cf-8ff7-1cc68f16e368"><Button
                        color="primary"
                      >Playbook</Button></a>
                      </GridItem> */}
                  <GridItem xs={6} sm={4} md={4}>
                    <Link to="/grow/consultants">
                      <Button
                        className={classes.button}
                        onClick={() => this.handleClickOpen("learnDashboardModal")}
                      >
                        SCHEDULE A CALL
                      </Button>
                    </Link>
                  </GridItem>
                  <GridItem xs={6} sm={3} md={3}>
                    <Button
                      className={classes.button}
                      onClick={() => this.handleClickOpen("learnDashboardModal")}
                    >
                      LEARN ABOUT HOMEPAGE
                    </Button>
                  </GridItem>
                </GridContainer>
                <h4><b>Purpose:</b> <i>{user.purposeDeclaration ? user.purposeDeclaration : null}</i></h4>
                <GridContainer>
                  <GridItem xs={3} sm={3} md={3}>
                    {/* <Link to="/assess/path"> */}
                    <p className={classes.metricHeader}>PURPOSE EMBRACED</p>
                    <p className={classes.metricHeader}>{user.peLevelName}</p>
                    <p className={classes.metricSubHeader}>
                      {user.pePercent ? (
                        Math.round(user.pePercent * 100) + '%'
                      ) : (
                        'N/A'
                      )}
                    </p>
                    {/* </Link> */}
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    {/* <Link to="/assess/path"> */}
                    <p className={classes.metricHeader}>STRATEGY ALIGNED</p>
                    <p className={classes.metricHeader}>{user.saLevelName}</p>
                    <p className={classes.metricSubHeader}>
                      {user.saPercent ? (
                        Math.round(user.saPercent * 100) + '%'
                      ) : (
                        'N/A'
                      )}


                    </p>
                    {/* </Link> */}
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    {/* <Link to="/assess/path"> */}
                    <p className={classes.metricHeader}>STAKEHOLDERS ENGAGED</p>
                    <p className={classes.metricHeader}> {user.seLevelName}</p>
                    <p className={classes.metricSubHeader}>
                      {user.sePercent ? (
                        Math.round(user.sePercent * 100) + '%'
                      ) : (
                        'N/A'
                      )}

                    </p>
                    {/* </Link> */}
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    {/* <Link to="/assess/path"> */}
                    <p className={classes.metricHeader}>ACTIONS ALIGNED</p>
                    <p className={classes.metricHeader}>{user.aaLevelName}</p>
                    <p className={classes.metricSubHeader}>
                      {user.aaPercent ? (
                        Math.round(user.aaPercent * 100) + '%'
                      ) : (
                        'N/A'
                      )}
                    </p>
                    {/* </Link> */}
                  </GridItem>
                </GridContainer>

                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSmall
                  }}
                  open={this.state.learnDashboardModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => this.handleClose("learnDashboardModal")}
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                  style={{ marginTop: "-10px" }}
                >
                  <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.handleClose("learnDashboardModal")}
                  >
                    {" "}
                    <Close className={classes.modalClose} />
                  </Button>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                    style={{ backgroundColor: "#eee", color: "#333" }}
                  >
                    <h2>Understanding Your Homepage</h2>
                    <p>Welcome to your Personal Homepage and congratulations for completing the organizational assessment. This means you have formally started your journey of mastering Purposeful Leadership.  Here are some tips to using the Homepage.</p>

                    <p><b>Purpose Declaration:</b>  At the top of the Homepage will be your Company name and Purpose Declaration, if available.  This information will be entered through your Profile page.</p>

                    <p><b>Assessment Results:</b> At the top of the Homepage is a graphic representation of your assessment results showing your current level of mastery of the four practice areas of Purposeful Leadership.
                      If you want to review your answers to the assessment questions or update your answers,
                      just click on <b>SEE ASSESSMENT DETAILS</b>, which can be found below the graphic. To take the Assessment, click on <b>TAKE THE ASSESSMENT</b>, which is located below that.
                      Before starting the assessment, please read the instructions by clicking on the LEARN ABOUT ASSESSMENT button located at the top of the Assessment landing page.</p>
                    <p><b>Metrics:</b> The Homepage also contains the following metrics:</p>

                    <p><b><u>Assessment Score:</u></b> The Homepage displays numerically and graphically your assessment score (0% to 100%) denoting your level of mastery in each of the four practice areas of Purposeful Leadership. Having a score of 100% means that your organization has fully mastered Purposeful Leadership in that particular practice area.  Your goal is to attain scores of 100% in all four practice areas.</p>

                    <p><b><u>Assessment Score Trend Lines:</u></b> In addition, the Homepage displays your assessment scores over time so you can follow your progress in mastering purposeful leadership.</p>
                  </DialogContent>
                </Dialog>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <AssessmentResults lastAssessment={assessments.assessments[0]} purposeLevel={user.peLevelName} purposePercent={user.pePercent} stakeholdersPercent={user.sePercent} stakeholdersLevel={user.seLevelName} strategyPercent={user.saPercent} strategyLevel={user.saLevelName} actionsPercent={user.aaPercent} actionsLevel={user.aaLevelName} />
                    <p className={classes.center}>Taken by {user.firstName + ' ' + user.lastName}</p>
                    <p className={classes.center}>Last Updated: {user.levelUpdatedAt ? moment(user.levelUpdatedAt.toDate()).format('MM-DD-YYYY') : null}</p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {/* this.state.dataLoaded ?
                  this.props.assessments.assessments.map(assessment => {
                    return <p>{assessment.question1}</p>
                  }) :
                  <p>Loading...</p>
                */}
                    {/*this.props.user.saPercent ? <p className={classes.body}>{this.props.user.saPercent}</p> : <p className={classes.body}>Click UPDATE to add company name</p>*/}

                    <h1 className={classes.title}>
                      SUGGESTED LEARNING PATHS</h1>
                    <h3 className={classes.subtitle}>
                      Select a category to explore and grow your organizational purpose</h3>
                    <GridContainer className={classes.scores}>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={3}>
                            <p>Purpose Embraced: </p>
                          </GridItem>
                          <GridItem xs={3} style={{ marginLeft: "40px" }}>
                            {user.peLevelName}
                          </GridItem>
                          <GridItem xs={3} style={{ marginLeft: "35px" }}>

                            {user.pePercent ? (
                              Math.round(user.pePercent * 100) + '%'
                            ) : (
                              'N/A'
                            )}

                          </GridItem>
                          <GridItem xs={3}>
                            {/* <Link to="/assess/purpose">
                              <Button
                                color="primary"
                              >
                                EXPLORE PATH
                              </Button>
                            </Link> */}
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={3}>
                            <p>Strategy Aligned: </p>
                          </GridItem>
                          <GridItem xs={3} style={{ marginLeft: "40px" }}>
                            {user.saLevelName}
                          </GridItem>
                          <GridItem xs={3} style={{ marginLeft: "35px" }}>
                            {user.saPercent ? (
                              Math.round(user.saPercent * 100) + '%'
                            ) : (
                              'N/A'
                            )}

                          </GridItem>
                          <GridItem xs={3}>
                            {/* <Link to="/assess/strategy">
                              <Button
                                color="primary"
                              >
                                EXPLORE PATH
                              </Button>
                            </Link> */}
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={3}>
                            <p>Stakeholders Engaged: </p>
                          </GridItem>
                          <GridItem xs={3} style={{ marginLeft: "40px" }}>
                            {user.seLevelName}
                          </GridItem>
                          <br />
                          <GridItem xs={3} style={{ marginLeft: "35px" }}>
                            {user.sePercent ? (
                              Math.round(user.sePercent * 100) + '%'
                            ) : (
                              'N/A'
                            )}
                          </GridItem>
                          <GridItem xs={3}>
                            {/* <Link to="/assess/stakeholders">
                              <Button
                                color="primary"
                              >
                                EXPLORE PATH
                              </Button>
                            </Link> */}
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={3}>
                            <p>Actions Aligned: </p>
                          </GridItem>
                          <GridItem xs={3} style={{ marginLeft: "40px" }}>
                            {user.aaLevelName}
                          </GridItem>
                          <GridItem xs={3} style={{ marginLeft: "35px" }}>
                            {user.aaPercent ? (
                              Math.round(user.aaPercent * 100) + '%'
                            ) : (
                              'N/A'
                            )}
                          </GridItem>
                          <GridItem xs={3}>
                            {/* <Link to="/assess/actions">
                              <Button
                                color="primary"
                              >
                                EXPLORE PATH
                              </Button>
                            </Link> */}
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    <h4>Update your assessment for the latest information and recommendations.</h4>
                    {
                      (assessments.assessments.length === 0) ? <p>Loading...</p> :
                        <Chart
                          width={'100%'}
                          height={'300px'}
                          chartType="SteppedAreaChart"
                          loader={<div>Loading Chart</div>}
                          data={chartData}
                          options={{
                            title: "Historical Assessment Results",
                            vAxis: { title: 'Category Scores', viewWindow: { max: 400 } },
                            backgroundColor: "#eeeeee",
                            isStacked: true,
                          }}
                          rootProps={{ 'data-testid': '1' }}
                        />
                    }

                  </GridItem>


                </GridContainer>
              </Hidden>

              {/* DESKTOP VIEW */}
              <Hidden smDown implementation="css">
                <GridContainer style={{ backgroundColor: "#E5E5E5", height: "60px", paddingBottom: "5px" }}>
                  <GridItem xs={6}>
                    <h3
                      style={{
                        fontFamily: "'Merriweather Sans', sans-serif",
                        textTransform: "uppercase",
                        color: "#3a3934",
                        fontWeight: "600",
                        verticalAlign: "center"
                      }}
                    >
                      Welcome to your personal homepage</h3>
                  </GridItem>
                  <GridItem xs={6} style={{ justifyContent: "flex-end", display: "flex", marginTop: "7px", verticalAlign: "center" }}>
                    <Link to="/assess/path">
                      <Button
                        className={classes.button}
                        style={{ height: "35px", marginRight: "15px" }}
                        // onClick={() => this.handleClickOpen("learnDashboardModal")}
                      >
                        INTRODUCTION
                      </Button>
                    </Link>

                    {/* <GridItem xs={4} sm={4} md={4}> */}
                    <Link to="/grow/consultants">
                      <Button
                        className={classes.button}
                        style={{ height: "35px", marginRight: "15px" }}
                        // onClick={() => this.handleClickOpen("learnDashboardModal")}
                      >
                        SCHEDULE A CALL
                      </Button>
                    </Link>

                    <Button
                      className={classes.button}
                      style={{ height: "35px" }}
                      onClick={() => this.handleClickOpen("learnDashboardModal")}
                    >
                      LEARN ABOUT HOMEPAGE
                    </Button>
                  </GridItem>
                </GridContainer>
                <center>
                  <Card style={{ width: "50%", textAlign: "center" }}>
                    <h4 className={classes.body} style={{ fontSize: "28px" }}><b>Purpose:</b> <i>{user.purposeDeclaration ? user.purposeDeclaration : null}</i></h4>
                  </Card>
                </center>
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSmall
                  }}
                  open={this.state.learnDashboardModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => this.handleClose("learnDashboardModal")}
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                  style={{ marginTop: "-20px" }}
                >
                  <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.handleClose("learnDashboardModal")}
                  >
                    {" "}
                    <Close className={classes.modalClose} />
                  </Button>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                    style={{ backgroundColor: "#eee", color: "#333" }}
                  >
                    <h2>Understanding Your Homepage</h2>
                    <p>Welcome to your Personal Homepage and congratulations for completing the organizational assessment.
                      This means you have formally started your journey of mastering Purposeful Leadership.  Here are some tips to using the Homepage.</p>

                    <p><b>Purpose Declaration:</b>  At the top of the Homepage will be your Company name and Purpose Declaration, if available.
                      This information will be entered through your Profile page</p>

                    <p><b>Assessment Results:</b> At the top of the Homepage is a graphic representation of your assessment results showing your current level of mastery of the four practice areas of Purposeful Leadership.
                      If you want to review your answers to the assessment questions or update your answers,
                      just click on <b>SEE ASSESSMENT DETAILS</b>, which can be found below the graphic. To take the Assessment, click on <b>TAKE THE ASSESSMENT</b>, which is located below that.
                      Before starting the assessment, please read the instructions by clicking on the LEARN ABOUT ASSESSMENT button located at the top of the Assessment landing page.</p>


                    <p><b>Metrics:</b> The Homepage also contains the following metrics:</p>

                    <p><b><u>Assessment Score:</u></b> The Homepage displays numerically and graphically your assessment score (0% to 100%) denoting your level of mastery in each of the four practice areas of Purposeful Leadership. Having a score of 100% means that your organization has fully mastered Purposeful Leadership in that particular practice area.  Your goal is to attain scores of 100% in all four practice areas.</p>

                    <p><b><u>Assessment Score Trend Lines:</u></b> In addition, the Homepage displays your assessment scores over time so you can follow your progress in mastering purposeful leadership.</p>
                  </DialogContent>
                </Dialog>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <Card style={{ padding: "20px" }}>
                      <AssessmentResults lastAssessment={assessments.assessments[0]} purposeLevel={user.peLevelName} purposePercent={user.pePercent} stakeholdersPercent={user.sePercent} stakeholdersLevel={user.seLevelName} strategyPercent={user.saPercent} strategyLevel={user.saLevelName} actionsPercent={user.aaPercent} actionsLevel={user.aaLevelName} />
                      <p className={classes.center} style={{ marginTop: "10px" }}>Taken by {user.firstName + ' ' + user.lastName}</p>
                      <p className={classes.center}>Last Updated: {user.levelUpdatedAt ? moment(user.levelUpdatedAt.toDate()).format('MM-DD-YYYY') : null}</p>
                    </Card>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Card style={{ padding: "20px", backgroundColor: "white" }}>
                      <center>
                        <h1 className={classes.title}>
                          Historical Assessment Results</h1></center>
                      {/* <GridContainer className={classes.scores}>
                      </GridContainer> */}
                      {
                        (assessments.assessments.length === 0) ? <p>Loading...</p> :
                          <Chart
                            width={'100%'}
                            height={'300px'}
                            chartType="SteppedAreaChart"
                            loader={<div>Loading Chart</div>}
                            data={chartData}
                            options={{
                              vAxis: { title: 'Category Scores', viewWindow: { max: 400 }, titleFontSize: 22 },
                              backgroundColor: "white",
                              isStacked: true,
                            }}
                            rootProps={{ 'data-testid': '1' }}
                          />
                      }
                      <br />
                    </Card>
                    <GridContainer>
                      <GridItem xs={3} sm={3} md={3}>
                        <Card >
                          <p className={classes.metricSubHeader} style={{ fontSize: "40px" }}>
                            {user.pePercent ? (
                              Math.round(user.pePercent * 100) + '%'
                            ) : (
                              'N/A'
                            )}
                          </p>
                          <p className={classes.metricHeader} style={{ fontSize: "18px" }}>{user.peLevelName} </p>
                          <p className={classes.metricHeader} style={{ fontSize: "22px", lineHeight: "1.5em", paddingBottom: "10px", whiteSpace: "pre-wrap" }}>PURPOSE {"\n"}EMBRACED</p>

                        </Card>

                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <Card>
                          <p className={classes.metricSubHeader} style={{ fontSize: "40px" }}>
                            {user.saPercent ? (
                              Math.round(user.saPercent * 100) + '%'
                            ) : (
                              'N/A'
                            )}
                          </p>
                          <p className={classes.metricHeader} style={{ fontSize: "18px" }}>{user.saLevelName}</p>
                          <p className={classes.metricHeader} style={{ fontSize: "22px", lineHeight: "1.5em", paddingBottom: "10px", whiteSpace: "pre-wrap" }}>STRATEGY {"\n"}ALIGNED </p>
                        </Card>

                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <Card>
                          <p className={classes.metricSubHeader} style={{ fontSize: "40px" }}>
                            {user.sePercent ? (
                              Math.round(user.sePercent * 100) + '%'
                            ) : (
                              'N/A'
                            )}

                          </p>
                          <p className={classes.metricHeader} style={{ fontSize: "18px" }}> {user.seLevelName}</p>
                          <p className={classes.metricHeader} style={{ fontSize: "22px", lineHeight: "1.5em", paddingBottom: "10px", whiteSpace: "pre-wrap" }}>STAKEHOLDERS{"\n"} ENGAGED</p>
                        </Card>
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <Card>
                          <p className={classes.metricSubHeader} style={{ fontSize: "40px" }}>
                            {user.aaPercent ? (
                              Math.round(user.aaPercent * 100) + '%'
                            ) : (
                              'N/A'
                            )}
                          </p>
                          <p className={classes.metricHeader} style={{ fontSize: "18px" }}>{user.aaLevelName}</p>
                          <p className={classes.metricHeader} style={{ fontSize: "22px", lineHeight: "1.5em", paddingBottom: "10px", whiteSpace: "pre-wrap" }}>ACTIONS{"\n"} ALIGNED </p>
                        </Card>

                      </GridItem>
                    </GridContainer>
                  </GridItem>

                </GridContainer>
              </Hidden>
            </div>

          ) : (
            <div className={classes.easeInOut}>
              <Hidden smDown implementation="css">
                <div className={classes.preloaderDesktop}
                  style={{
                    background: "white",
                    position: "absolute",
                    width: "110%",
                    margin: "-25% 0% 0% -8%",
                    height: "180%",
                    zIndex: "99999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 33%",
                      position: "relative",
                      width: "25%"
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "2% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
              <Hidden mdUp implementation="css">
                <div className={classes.preloaderMobile}
                  style={{
                    background: "yellow",
                    position: "absolute",
                    width: "110%",
                    margin: "-30% 0% 0% -10%",
                    height: "110%",
                    zIndex: "9999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 20%",
                      position: "relative",
                      width: "50%"
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "15% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
            </div>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("test state", state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Orders);