import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/jobApplicationStyle";
import { Grid } from "@material-ui/core";

// Forms
import JobApplicationForm from "components/Forms/JobApplicationForm.jsx"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false
    };
  }
  render() {
    const { classes } = this.props;

    console.log(this.props)
    // Format and upload data to firestore
    // updateFirestoreWithTableData('TEST-actions-list',(tableToFirestoreObjects(this.props.actionsData)))
    // updateFirestoreWithTableData('TEST-users',(tableToFirestoreObjects(this.props.users)))

    // // add uid to firestore documents
    // if(this.state.dataLoaded === true){
    //   this.props.actionsData.dataRows.map(row => addFieldToDocument(row[0], {volUID: "TestID"}))
    // }  

    return (
      <GridContainer justify="center">
        <GridItem xs={12} lg={11} xl={10}>
          <h1 className={classes.title}>KHALIL FOR CONGRESS JOB APPLICATION</h1>
          <JobApplicationForm />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    actionUsers: state.actions.users
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Orders);
