import React from 'react'
import Carousel from 'react-slick';
import Slider from "react-slick";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Style from "assets/jss/material-dashboard-pro-react/views/metricsCarouselStyle.jsx";
import { connect } from "react-redux";
import compose from "recompose/compose";
import withStyles from "@material-ui/core/styles/withStyles";
import "slick-carousel/slick/slick.css";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import "slick-carousel/slick/slick-theme.css";

const MetricsCarousel = (props) => {
  const classes = props.classes
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "620px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "0px" }}>Monthly Metrics Cards</h2>
      <Carousel className={classes.carousel} accessibility centerMode {...settings}>
        <Card className={classes.cardStyle}>
          <div className={classes.cardDiv}>
            <h3 className={classes.cardTitle}>Health Goal Metrics</h3>
            <div className={classes.cardInputDiv}>
              <InputLabel id="Metric Description">Metric Description</InputLabel>
              <Select
                labelId='Metric Description'
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                // value={this.state.simpleSelect}
                // onChange={this.handleSimple}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelectedMultiple
                  }}
                  value="18"
                >
                  Miami
                            </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelectedMultiple
                  }}
                  value="19"
                >
                  Los Angeles
                            </MenuItem>
              </Select>
              <CustomInput
                labelText="Measure Definition"
                style={{
                  marginTop: '0',
                  paddingTop: '0'
                }}
                formControlProps={{
                  fullWidth: false,
                  width: "50%"
                }}
                inputProps={{
                  required: true,
                  type: "text",
                  // value: this.state.queryString,
                  // onChange: (event) => this.handleChange(event)
                }}
              />
              <div className={classes.flexSpaceEvenly}>
                <CustomInput
                className={classes.targetInput}
                  labelText="5 Year Target"
                  style={{
                    marginTop: '0',
                    paddingTop: '0',
                  }}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    // value: this.state.queryString,
                    // onChange: (event) => this.handleChange(event)
                  }}
                />
                       <CustomInput
                   classes={{
                     input: classes.targetInput
                   }}
                  labelText="1 Year Target"
                  style={{
                    marginTop: '0',
                    paddingTop: '0',
                  }}
                  formControlProps={{
                    fullWidth: false,

                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    // value: this.state.queryString,
                    // onChange: (event) => this.handleChange(event)
                  }}
                />
                        <CustomInput
                   classes={{
                     input: classes.targetInput
                   }}
                  labelText="Baseline"
                  style={{
                    marginTop: '0',
                    paddingTop: '0',
                  }}
                  formControlProps={{
                    fullWidth: false,

                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    // value: this.state.queryString,
                    // onChange: (event) => this.handleChange(event)
                  }}
                />
              </div>
              <div className={classes.flexSpaceEvenly}>
              <CustomInput
                   classes={{
                     input: classes.targetInput
                   }}
                  labelText="Current Month"
                  style={{
                    marginTop: '0',
                    paddingTop: '0',
                  }}
                  formControlProps={{
                    fullWidth: false,

                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    // value: this.state.queryString,
                    // onChange: (event) => this.handleChange(event)
                  }}
                />
                        <CustomInput
                   classes={{
                     input: classes.targetInput
                   }}
                  labelText="Original Start Date"
                  style={{
                    marginTop: '0',
                    paddingTop: '0',
                  }}
                  formControlProps={{
                    fullWidth: false,

                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    // value: this.state.queryString,
                    // onChange: (event) => this.handleChange(event)
                  }}
                />
                        <CustomInput
                   classes={{
                     input: classes.targetInput
                   }}
                  labelText="Current Start Date"
                  style={{
                    marginTop: '0',
                    paddingTop: '0',
                  }}
                  formControlProps={{
                    fullWidth: false,

                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    // value: this.state.queryString,
                    // onChange: (event) => this.handleChange(event)
                  }}
                />

                </div>
                <div className={classes.flexSpaceEvenly}>
                 <CustomInput
                  labelText="Active"
                  style={{
                    marginTop: '0',
                    paddingTop: '0',
                  }}
                  formControlProps={{
                    fullWidth: false,
                    className: {
                      formControl: classes.targetInputs,
                    }
                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    // value: this.state.queryString,
                    // onChange: (event) => this.handleChange(event)
                  }}
                />
                       <CustomInput
                  labelText="Priority"
                  style={{
                    marginTop: '0',
                    paddingTop: '0',
                  }}
                  formControlProps={{
                    fullWidth: false,
                    className: {
                      formControl: classes.targetInputs,
                    }
                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    // value: this.state.queryString,
                    // onChange: (event) => this.handleChange(event)
                  }}
                />
                 <CustomInput
                  labelText="Order"
                  style={{
                    marginTop: '0',
                    paddingTop: '0',
                  }}
                  formControlProps={{
                    fullWidth: false,
                    className: {
                      formControl: classes.targetInputs,
                    }
                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    // value: this.state.queryString,
                    // onChange: (event) => this.handleChange(event)
                  }}
                />
                </div>
            </div>
          </div>
        </Card>
        <Card className={classes.cardStyle}>
          <div className={classes.cardDiv}>
            <h3 className={classes.cardTitle}>SES Goal Metrics</h3>
          </div>
        </Card>
        <Card className={classes.cardStyle}>
          <div className={classes.cardDiv}>
            <h3 className={classes.cardTitle}>Competency Goal Metrics</h3>
          </div>
        </Card>
        <Card className={classes.cardStyle}>
          <div className={classes.cardDiv}>
            <h3 className={classes.cardTitle}>Customer Promise Metrics</h3>
          </div>
        </Card>
        <Card className={classes.cardStyle}>
          <div className={classes.cardDiv}>
            <h3 className={classes.cardTitle}>Beneficiary Promise Metrics</h3>
          </div>
        </Card>
        <Card className={classes.cardStyle}>
          <div className={classes.cardDiv}>
            <h3 className={classes.cardTitle}>Initiative Metrics</h3>
          </div>
        </Card>
        <Card className={classes.cardStyle}>
          <div className={classes.cardDiv}>
            <h3 className={classes.cardTitle}>Purpose Goal Metrics</h3>
          </div>
        </Card>
      </Carousel>
    </div>
  )
}


const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};



export default compose(connect(
  mapStateToProps, mapDispatchToProps
), withStyles(Style)
)(MetricsCarousel);