import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import { db } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import VendorPicUpload from "components/CustomUpload/vendorPicUpload.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class Form extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false
  }
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid, displayName) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
      displayName: displayName
    })
    console.log(this.state)
  }
  handleSubmit = (e) => {
    e.preventDefault();
      // update firestore document with PENDING and comment
      db.collection("shared-glossary").doc().set({
        ...this.state,
        updatedAt: new Date()
      }, {merge: true}).then(() => {
        console.log(``)
        this.setState({
          comment: '',       
          messageSent: true
        })       
      }).catch((err) => {
        console.log(`${err}`)
      })
  }

  resetRequest = () => {
    window.location.reload();
  }

  assignPic = (downloadURL, field) => {
    this.setState({
      docURL: downloadURL
    });
    // this.props.onAssignUser(userID, firstName, lastName, email)
  }


  render() {
    const { classes, modalClose, authUser } = this.props;
    console.log(this.state)
    // reset modal when closed
    if (this.state.messageSent) { this.resetRequest() }

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Feedback Submitted!</h4>

            </GridItem>
          </GridContainer>
        ) : (
            <div className={classes.formContainer}>
              <form onSubmit={this.handleSubmit}
              >
                <GridContainer justify="center">
                  <GridItem xs={11}>
                    <h5 className={classes.bodyText}>If you want to  share a term, provide as much information as you know.  If you are asking us to add a particular term to the Glossary, just write the term name on the first line and we will do the rest.</h5>
                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                                        <CustomInput
                      labelText="Term"
                      id="term"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: false,
                        type: "text",
                        multiline: true,
                        rows: 1,
                        onChange: (event) => this.handleChange(event, authUser.uid, authUser.displayName)
                      }}
                    />

                    <CustomInput
                      labelText="Practice"
                      id="practice"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: false,
                        type: "text",
                        multiline: true,
                        rows: 1,
                        onChange: (event) => this.handleChange(event, authUser.uid, authUser.displayName)
                      }}
                    />
                    <CustomInput
                      labelText="Definition"
                      id="definition"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: false,
                        type: "text",
                        multiline: true,
                        rows: 1,
                        onChange: (event) => this.handleChange(event, authUser.uid, authUser.displayName)
                      }}
                    />
                     <CustomInput
                      labelText="Category"
                      id="category"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: false,
                        type: "text",
                        multiline: true,
                        rows: 1,
                        onChange: (event) => this.handleChange(event, authUser.uid, authUser.displayName)
                      }}
                    />
                     <CustomInput
                      labelText="Topic"
                      id="topic"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: false,
                        type: "text",
                        multiline: true,
                        rows: 1,
                        onChange: (event) => this.handleChange(event, authUser.uid, authUser.displayName)
                      }}
                    />
                    <CustomInput
                      labelText="Link"
                      id="link"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: false,
                        type: "text",
                        multiline: true,
                        rows: 1,
                        onChange: (event) => this.handleChange(event, authUser.uid, authUser.displayName)
                      }}
                    />
                    <CustomInput
                    labelText="Email (optional)"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: false,
                      type: "text",
                      multiline: true,
                      rows: 1,
                      onChange: (event) => this.handleChange(event, authUser.uid, authUser.displayName)
                    }}
                  />
                      {/* <VendorPicUpload onAssignPic={this.assignPic} /> */}



                  </GridItem>
                </GridContainer>
                <center>
                <Button
                  type="submit"
                  className={classes.button}
                  style={{
                    marginLeft: "15px",
                    marginBottom: "10px",
                  }}
                >
                  SUBMIT
              </Button>
              </center>
              </form>
            </div>

          )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    //console.log(state)
    return {
      AuthBool: !!state.auth.user,
      authUser: state.auth.user,
      user: state.profile.user,
    }
  }
  
export default compose(
    connect(mapStateToProps),
    withStyles(styles)
  )(Form);