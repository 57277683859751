// brokers Data
  const pages = {
    header: "FFPO"
  };
  
  const initState = {
    pages: pages
  }
  
  export default (state = initState, action) => {
    switch (action.type) {    
      default:
        return state
    }
  }
  