/*eslint-disable*/
import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { Chart } from 'react-google-charts';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/practicesStyle.jsx";
import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"
import SharePracticeForm from "../../components/Forms/SharePracticeForm";

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import { setFirstAssessment } from "store/actions/assessmentActions"
import { startSetUsersList } from "store/actions/adminActions"
import { startSetPracticesList } from "store/actions/practicesActions"
import { getEngagementWorksheetsData, triggerDeleteModal, permDelete } from "store/actions/engagementWorksheetsActions"
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
  docx,
  SectionType,
  Header
} from "docx";

import { saveAs } from "file-saver";


const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Principles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updatePhotoModal: false,
      learnDashboardModal: false
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  componentDidMount = () => {
    console.log('madeItX', this.props)
    if (this.props.authUser && !this.state.dataLoaded) {
      this.props.getData(this.props.authUser.uid)
    }
  }

  componentDidUpdate = () => {
    console.log('madeItX', this.props)
    if (this.props.authUser && !this.state.dataLoaded) {
      this.props.getData(this.props.authUser.uid)
    }
  }



  loadData = () => {
    var engagementWorksheetsData = this.props.SESWorksheetsTable.dataRows
    var engagementWorksheetsColumns = this.props.SESWorksheetsTable.columns
    var dataLoaded = this.props.SESWorksheetsTable.dataLoaded
    console.log('yesssss', 'herez', engagementWorksheetsData)

    this.setState({
      engagementWorksheetsData,
      engagementWorksheetsColumns,
      dataLoaded: true
    }, () => console.log('yesssss', 'here', this.state.engagementWorksheetsData))
  }

  generate = () => {
    var actionsArray = []
    db.collection("TEST-users")
      .doc(this.props.authUser.uid)
      .collection("strategy-sheet")
      .get()
      .then((snapshot) => {
        console.log('snapxss', snapshot)
        snapshot.forEach((doc) => {

          doc = doc.data()
          console.log('snapxss', doc)
          if (doc.text !== undefined && doc.actions !== undefined) {
            // console.log('generatesnap', doc)
            var docData = {
              name: doc.name || doc.text,
              actions: doc.actions
            }
            actionsArray.push(docData)
          }
        })


        var values = actionsArray.map((actionsArrayData) => {
          // console.log('generatesnapdata', data)
          var actionsValues = []
          for (var x = 0; x < actionsArrayData.actions.length; x++) {
            actionsValues.push(actionsArrayData.actions[x].text + ' - ' + actionsArrayData.name)

          }
          return actionsValues
        })
        values = values.flat()
        console.log('generatesnaparray', values)





        const doc = new Document({
          sections: [{
            headers: {
              default: new Header({
                children: [new Paragraph({
                  text: "Stakeholder Actions" + '\n',
                  heading: HeadingLevel.TITLE,
                  alignment: AlignmentType.CENTER,
                }),],
              }),
            },
            children: [
              new Paragraph({
                children:
                  values.map((value) => {
                    return new TextRun({
                      text: value + '\n',
                      break: 1,
                    })
                  })
              }),
            ],
          }]
        });

        console.log('generatesnapdoc', doc)

        Packer.toBlob(doc).then(blob => {
          console.log(blob);
          saveAs(blob, "Stakeholder Actions");
          console.log("Document created successfully");
        });

      })


  }

  render() {
    const { AuthBool, classes, user, practices, assessments, SESWorksheetsTable } = this.props;
    let profilePhoto = DefaultProfile


    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
        row[id] !== undefined ?
          String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
          :
          true
      );
    }

    if (user.termGroups) {
      //console.log(user.termGroups.length)

      if (user.termGroups.length < 2) {
        return <Redirect to='/profile' />
      }
    }

    if (AuthBool) { // fixes freeze on logout
      if (user.dataLoaded) {
        user.profileURL ? (
          profilePhoto = user.profileURL
        ) : (
          // add link to profile photo to firestore
          profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
          // add profile photo to firebase storage
          // update redux with link
        )
      }
    }

    let lastAssessmentPurposePercent
    //console.log(user)
    //console.log(assessments.dataLoaded)
    if (SESWorksheetsTable.dataLoaded
      && !this.state.dataLoaded) {
      //console.log("load data")
      this.loadData()
      // console.log(user)
      // console.log(user.levelUpdatedAt.toDate())
      // console.log(moment(user.levelUpdatedAt.toDate()).format('MM-DD-YYYY'))
    }

    //console.log(assessments.assessments.length)

    let chartData = [['Date', 'Actions', 'Strategy', 'Stakeholders', 'Purpose']];

    //console.log(assessments.assessments.length)
    for (var i = (assessments.assessments.length - 1); i > -1; i--) {
      //console.log(assessments.assessments[i])
      let assessmentData = [
        moment(Date(assessments.assessments[i].updatedAt)).format('MM-DD-YYYY'),
        (assessments.assessments[i].aaPercent * 100),
        (assessments.assessments[i].saPercent * 100),
        (assessments.assessments[i].sePercent * 100),
        (assessments.assessments[i].pePercent * 100)
      ]

      chartData.push(assessmentData);
    };



    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            {this.state.dataLoaded ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <GridContainer style={{ backgroundColor: "#E5E5E5", height: "60px", paddingBottom: "5px" }}>
                  <GridItem xs={6}>
                    <h3
                      style={{
                        fontFamily: "'Merriweather Sans', sans-serif",
                        textTransform: "uppercase",
                        color: "#3a3934",
                        fontWeight: "600",
                        verticalAlign: "center"
                      }}
                    >
                      Welcome to Your SES Worksheets</h3>
                  </GridItem>

                  <GridItem xs={6}
                    style={{ justifyContent: "flex-end", display: "flex", marginTop: "7px", verticalAlign: "center" }}
                  >
                    <Button
                      className={classes.button}
                      style={{ height: "35px", marginRight: "15px" }}
                      onClick={() => this.generate()}
                    >
                      Export SES Actions
                    </Button>
                  </GridItem>
                </GridContainer>
                <Card style={{ padding: "10px" }}>
                  <ReactTable
                    data={this.state.engagementWorksheetsData.map((prop, key) => {
                      return {
                        id: key,
                        stakeholderName: prop.stakeholderName,
                        accountability: prop.accountability.text ? prop.accountability.text : prop.accountability,
                        status: prop.status,
                        actions: prop.actions
                      }
                    })}
                    filterable
                    defaultFilterMethod={filterCaseInsensitive}

                    columns={this.props.SESWorksheetsTable.columns}
                    defaultPageSize={10}
                    defaultSorted={[
                      {
                      }
                    ]}
                    //showPaginationTop
                    showPaginationBottom
                    style={{
                      color: "#000",
                      marginTop: "50px"
                    }}
                    className="-striped -highlight"
                  />
                </Card>

                {this.props.deleteModal ? (
                  <Dialog
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.props.deleteModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClick={() => this.props.triggerDeleteModal(false)}
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                      //  onClick={() => this.handleClose("deleteStakeHolder")}
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h3>Are you sure you want to delete this stakeholder?</h3>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <Button
                        type="submit"
                        onClick={() => this.props.permDelete(this.props.stakeholderData.stakeholderid, this.props.stakeholderData.stakeholderName, this.props.stakeholderData.uid)}
                        color="danger"
                        style={{
                          marginLeft: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        onClick={() => this.props.triggerDeleteModal(false)}
                        style={{
                          marginLeft: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </DialogContent>
                  </Dialog>

                ) : (null)}

              </div>
            ) : (
              <div className={classes.easeInOut}>
                <Hidden smDown implementation="css">
                  <div className={classes.preloaderDesktop}
                    style={{
                      background: "white",
                      position: "absolute",
                      width: "110%",
                      margin: "-25% 0% 0% -8%",
                      height: "180%",
                      zIndex: "99999",
                    }}
                  >
                    <img
                      style={{
                        margin: "30% 0% 0% 33%",
                        position: "relative",
                        width: "25%"
                      }}
                      src={Logo}
                      alt="..."
                    />
                    <img
                      style={{
                        margin: "2% 0% 0% 38%",
                        position: "relative",
                        width: "20%"
                      }}
                      src={Loader}
                      alt="..."
                    />
                  </div>
                </Hidden>
                <Hidden mdUp implementation="css">
                  <div className={classes.preloaderMobile}
                    style={{
                      background: "white",
                      position: "absolute",
                      width: "110%",
                      margin: "-30% 0% 0% -10%",
                      height: "110%",
                      zIndex: "9999",
                    }}
                  >
                    <img
                      style={{
                        margin: "30% 0% 0% 20%",
                        position: "relative",
                        width: "50%"
                      }}
                      src={Logo}
                      alt="..."
                    />
                    <img
                      style={{
                        margin: "15% 0% 0% 38%",
                        position: "relative",
                        width: "20%"
                      }}
                      src={Loader}
                      alt="..."
                    />
                  </div>
                </Hidden>
              </div>
            )}
          </GridItem>
        </GridContainer>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall
          }}
          open={this.state.updatePhotoModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("updatePhotoModal")}
          aria-labelledby="shipment-modal-slide-title"
          aria-describedby="shipment-modal-slide-description"
        >
          <DialogTitle
            id="shipment-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => this.handleClose("updatePhotoModal")}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h2 className={classes.modalTitle} style={{ color: "black" }}>SUGGEST PRACTICE</h2>
          </DialogTitle>
          <DialogContent
            id="shipment-modal-slide-description"
            className={classes.modalBody}
          >
            {/* FORM */}
            {AuthBool &&
              <SharePracticeForm
                currentPic={profilePhoto}
                username={this.props.user.username}
                uid={this.props.authUser.uid}
              />
            }

            <div
              style={{
                textAlign: "center"
              }}
            >
              <Button
                color="danger"
                key="close"
                aria-label="Close"
                onClick={() => this.handleClose("updatePhotoModal")}
              >
                CANCEL
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('stateeeez', 'stakeholder', state.SESWorksheetsTable.engagementWorksheets.data)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    practices: state.practices.practices,
    SESWorksheetsTable: state.SESWorksheetsTable.engagementWorksheets,
    deleteModal: state.SESWorksheetsTable.engagementWorksheets.deleteModal,
    stakeholderData: state.SESWorksheetsTable.engagementWorksheets.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSetPracticesList: dispatch(startSetPracticesList()),
    getData: (uid) => dispatch(getEngagementWorksheetsData(uid)),
    triggerDeleteModal: (boolean) => dispatch(triggerDeleteModal(boolean)),
    permDelete: (id, name, uid) => dispatch(permDelete(id, name, uid))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Principles);