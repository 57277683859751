import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"
import Radar from 'react-d3-radar'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/assessmentResultsStyle.jsx";
import { Grid } from "@material-ui/core";

// Forms
import UpdateAssessmentForm from "components/Forms/updateAssessmentForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"


// Firebase Redux Integration
import { startSetUsersList } from "store/actions/adminActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class AssessmentResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      assessmentDetailsModal: false,
      assessmentModal: false,
      volUID: '',
      username: ''
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop }
      const volUID = colObj.volUID
      const username = colObj.username
      // Load Data for Summary Cards and add buttons
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => this.handleClickOpen("assignActionModal",
                volUID
              )}
          >ASSIGN ACTION</Button>)
      }
    })

    this.setState({
      usersData,
      dataLoaded: true
    })
  }

  handleChange = (docID) => {
    console.log(docID)
  }

  render() {
    const { AuthBool, classes, user, purposePercent, purposeLevel, stakeholdersPercent, stakeholdersLevel, strategyPercent, strategyLevel, actionsPercent, actionsLevel, lastAssessment, lastUpdated, firstName, lastName } = this.props;
    if (AuthBool) {
      if (this.props.users.dataLoaded
        && !this.state.dataLoaded) {
        this.loadData()
      }
    }
    //console.log(purposePercent)
    //console.log(stakeholdersPercent)
    //console.log(strategyPercent)
    //console.log(actionsPercent)
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <h1 className={classes.title}>Assessment Results</h1>
            <div className={classes.radar}>
              <Radar
                width={350}
                height={350}
                padding={0}
                domainMax={100}
                highlighted={null}
                onHover={(point) => {
                  if (point) {
                    //console.log('hovered over a data point');
                  } else {
                    //console.log('not over anything');
                  }
                }}
                data={{
                  variables: [
                    { key: 'purposeEmbraced', label: 'Purpose Embraced' },
                    { key: 'stakeholdersEngaged', label: 'Stakeholders Engaged' },
                    { key: 'strategyAligned', label: 'Strategy Aligned' },
                    { key: 'actionsAligned', label: 'Actions Aligned' },
                  ],
                  sets: [
                    {
                      key: 'everyone',
                      label: 'Everyone',
                      values: {
                        purposeEmbraced: (purposePercent * 100),
                        stakeholdersEngaged: (stakeholdersPercent * 100),
                        strategyAligned: (strategyPercent * 100),
                        actionsAligned: (actionsPercent * 100)
                      },
                    },
                  ],
                }}
              />
            </div>
            <center>
              <Button
                className={classes.button}
                style={{ width: "300px", marginRight: "20px" }}
                onClick={() => this.handleClickOpen("assessmentModal")}
              >
                START ASSESSMENT
              </Button>
              <Button
                className={classes.button2}
                onClick={() => this.handleClickOpen("assessmentDetailsModal")}
                style={{ width: "300px", marginRight: "20px"}}
              >
                UPDATE ASSESSMENT
              </Button>
            </center>

            <Dialog
              // maxWidth="md"
              classes={{
                root: classes.modalRoot,
                // paper: classes.modal
                paper: classes.modal + " " + classes.modalLarge
              }}
              open={this.state.assessmentModal}
              TransitionComponent={Transition}
              keepMounted
              // onClose={() => this.handleClose("assessmentModal")}
              aria-labelledby="shipment-modal-slide-title"
              aria-describedby="shipment-modal-slide-description"
            >
              <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => this.handleClose("assessmentModal")}
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <DialogContent
                id="shipment-modal-slide-description"
                className={classes.modalBody}
              >
                <TakeAssessmentForm
                  user={this.props.user}
                  uid={this.props.authUser.uid}
                // modalOpen={this.state.updateProfileModal}
                />
              </DialogContent>
            </Dialog>

            <Dialog
              fullScreen
              classes={{
                root: classes.modalRoot,
                // paper: classes.modal
                paper: classes.modal + " " + classes.modalLarge
              }}
              open={this.state.assessmentDetailsModal}
              TransitionComponent={Transition}
              keepMounted
              // onClose={() => this.handleClose("assessmentDetailsModal")}
              aria-labelledby="shipment-modal-slide-title"
              aria-describedby="shipment-modal-slide-description"
            >
              <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => this.handleClose("assessmentDetailsModal")}
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              {/* <DialogContent
                id="shipment-modal-slide-description"
                className={classes.modalBody}

              > */}
              <UpdateAssessmentForm
              // purposePercent={purposePercent}
              // stakeholdersPercent={stakeholdersPercent}
              // strategyPercent={strategyPercent}
              // actionsPercent={actionsPercent}
              // purposeLevel={purposeLevel}
              // stakeholdersLevel={stakeholdersLevel}
              // strategyLevel={strategyLevel}
              // actionsLevel={actionsLevel}
              // lastAssessment={lastAssessment}
              // user={user}
              // uid={this.props.authUser.uid}
              />
              {/* </DialogContent> */}
            </Dialog>
          </GridItem>
        </GridContainer>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
    authUser: state.auth.user,
    user: state.profile.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSetUsersList: dispatch(startSetUsersList())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(AssessmentResults);