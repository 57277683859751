// /*eslint-disable*/

import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { startSaveSESList, submit } from "store/actions/playbookListActions.jsx";
import { setList } from "store/actions/SESActions.jsx";
import { setSESListItem } from "store/actions/SESActions.jsx";
import DraggableCardContainer from "components/Draggable/DraggableCardContainer.jsx"
import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

function CardContent({ card, setCard }) {
  return (
    <GridItem xs={11}>
      <CustomInput
        labelText="How will you inspire?"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          defaultValue: card ? card.text : '',
          required: false,
          type: "text",
          onChange: (event) => setCard({ text: event.target.value }),
        }}
      />
      <CustomInput
        labelText="How will you inspire?"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          defaultValue: card ? card.text : '',
          required: false,
          type: "text",
          onChange: (event) => setCard({ text: event.target.value }),
        }}
      />
    </GridItem>
  );
}

class Form extends React.Component {

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      populate: false,
      purposeOriginCondition: "test"
    };
  }

  async componentDidMount() {
    if (this.props.authUser && !this.state.dataLoaded) {
      db.collection("TEST-users").doc(this.props.uid).collection("strategy-sheet").doc(this.props.stakeholderId)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            snapshot = snapshot.data()
            this.setState({
              purposeOriginCondition: snapshot.purposeOriginCondition || '',
              purposeOriginProblem: snapshot.purposeOriginProblem || '',
              purposeOriginSolution: snapshot.purposeOriginSolution || '',
            });
          }
        })
    }
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  async componentDidUpdate() {
    console.log("true or false", this.state.populate)
    if (this.state.populate === true) {
      await db.collection("TEST-users").doc(this.props.uid)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            snapshot = snapshot.data()
            this.setState({
              purposeOriginCondition: snapshot.purposeOriginCondition || '',
              purposeOriginProblem: snapshot.purposeOriginProblem || '',
              purposeOriginSolution: snapshot.purposeOriginSolution || '',
            });
          }
          this.setState({
            populate: false
          })
          // console.log("testing snapshot values: ", this.state.purposeOriginCondition)
        })
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleChange = (e, uid, name) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
      name: name
    })
    console.log('test state', this.state)
  }


  handleSubmit = (uid, stakeholderId) => {
    // console.log('purpose condition', this.state.purposeOriginCondition)
    // console.log('purpose problem', this.state.purposeOriginProblem)
    // console.log('purpose solution', this.state.purposeOriginSolution)
    // update firestore document with PENDING and declaration
    db.collection("TEST-users")
      .doc(uid)
      .collection("strategy-sheet")
      .doc(stakeholderId)
      .set({
        purposeOriginCondition: this.state.purposeOriginCondition ? this.state.purposeOriginCondition : '',
        purposeOriginProblem: this.state.purposeOriginProblem ? this.state.purposeOriginProblem : '',
        purposeOriginSolution: this.state.purposeOriginSolution ? this.state.purposeOriginSolution : '',
        updatedAt: new Date()
      }, { merge: true }).then(() => {
        this.setState({
          purposeOriginCondition: '',
          purposeOriginProblem: '',
          purposeOriginSolution: '',
          messageSent: true,
          submitted: true
        })
        window.location.reload()
      }).catch((err) => {
        console.log(`${err}`)
      })
  }

  render() {
    const { communicationStrategy, classes, cards, setCards, setCard, submit, submitted, uid, authUser } = this.props;
    const stakeholderId = this.props.stakeholderId

    const resetRequest = () => {
      window.location.reload();
    }

    return (
      <div>
        {this.state.submitted ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              {/* {resetRequest()} */}
              <h4 className={classes.textPrimary}>Communication Strategy Set!</h4>
            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer}>
            {/* <form onSubmit={e => { e.preventDefault(); submit(uid, cards, stakeholderId); }}
              > */}
            <form onSubmit={e => { e.preventDefault(); this.handleSubmit(uid, stakeholderId) }}
            >
              {/* <GridContainer justify="center">
                  <DraggableCardContainer cards={cards} setCards={setCards} setCard={setCard} renderContent={CardContent} />
                </GridContainer> */}
              {!this.state.populate ?
                <>
                  <GridContainer justify="center">
                    {/* {console.log('form state', this.state)}
                    {console.log('form props', this.props)} */}
                    <GridItem xs={11}>
                      {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                      <CustomInput
                        labelText="What desired condition do you want?"
                        id="purposeOriginCondition"
                        formControlProps={{
                          fullWidth: true
                        }}

                        inputProps={{
                          // placeholder: this.state.purposeOriginCondition,
                          value: this.state.purposeOriginCondition || '',
                          required: false,
                          type: "text",
                          onChange: (event) => this.handleChange(event, authUser.uid)
                        }}
                      />
                    </GridItem>
                    <GridItem xs={11}>
                      {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                      <CustomInput
                        labelText="What specific problem prevents that condition?"
                        id="purposeOriginProblem"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // placeholder: this.state.purposeOriginProblem,
                          value: this.state.purposeOriginProblem || '',
                          required: false,
                          type: "text",
                          onChange: (event) => this.handleChange(event, authUser.uid)
                        }}
                      />

                    </GridItem>
                    <GridItem xs={11}>
                      {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                      <CustomInput
                        labelText="How will you solve that problem"
                        id="purposeOriginSolution"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // placeholder: this.state.purposeOriginSolution,
                          value: this.state.purposeOriginSolution || '',
                          required: false,
                          type: "text",
                          onChange: (event) => this.handleChange(event, authUser.uid)
                        }}
                      />

                    </GridItem>
                  </GridContainer>
                  <Button
                    type="submit"
                    className={classes.button}
                    style={{ marginLeft: "20px" }}
                  >
                    SUBMIT
                  </Button>
                  <Button
                    className={classes.button}
                    style={{
                      float: "right",
                      marginRight: "10px",
                      marginBottom: "10px",
                      width: "100px"
                    }}
                    onClick={() => this.setState({
                      populate: true
                    })}
                  >
                    POPULATE
                  </Button>
                </>
                :
                <>
                  <GridContainer justify="center">
                    {/* {console.log('form state', this.state)}
                    {console.log('form props', this.props)} */}
                    <GridItem xs={11}>
                      {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                      <CustomInput
                        labelText="What desired condition do you want?"
                        id="purposeOriginCondition"
                        formControlProps={{
                          fullWidth: true
                        }}

                        inputProps={{
                          // placeholder: this.state.purposeOriginCondition,
                          value: this.state.purposeOriginCondition,
                          required: false,
                          type: "text",
                          onChange: (event) => this.handleChange(event, authUser.uid)
                        }}
                      />
                    </GridItem>
                    <GridItem xs={11}>
                      {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                      <CustomInput
                        labelText="What specific problem prevents that condition?"
                        id="purposeOriginProblem"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // placeholder: this.state.purposeOriginProblem,
                          value: this.state.purposeOriginProblem,
                          required: false,
                          type: "text",
                          onChange: (event) => this.handleChange(event, authUser.uid)
                        }}
                      />

                    </GridItem>
                    <GridItem xs={11}>
                      {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                      <CustomInput
                        labelText="How will you solve that problem"
                        id="purposeOriginSolution"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // placeholder: this.state.purposeOriginSolution,
                          value: this.state.purposeOriginSolution,
                          required: false,
                          type: "text",
                          onChange: (event) => this.handleChange(event, authUser.uid)
                        }}
                      />

                    </GridItem>
                  </GridContainer>
                  <Button
                    type="submit"
                    className={classes.button}
                    style={{ marginLeft: "20px" }}
                  >
                    SUBMIT
                  </Button>
                  <Button
                    className={classes.button}
                    style={{
                      float: "right",
                      marginRight: "10px",
                      marginBottom: "10px",
                      width: "100px"
                    }}
                    onClick={() => this.setState({
                      populate: true
                    })}
                  >
                    POPULATE
                  </Button>
                </>
              }
            </form>
            {console.log("testing values: ", this.state.purposeOriginSolution)}
          </div>

        )}
      </div>

    );
  }

}


Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.profile.user,
    cards: state.SES.communicationStrategy.values,
    communicationStrategy: state.SES.communicationStrategy,
    submitted: state.playbookList.communicationStrategy.submitted,
    uid: state.auth.user.uid,
    authUser: state.auth.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCards: list => dispatch(setList('communicationStrategy', list)),
    setCard: (index, item) => dispatch(setSESListItem('communicationStrategy', index, item)),
    submit: (uid, cards, stakeholderId) => dispatch(startSaveSESList(uid, 'communicationStrategy', cards, stakeholderId)).then(() => dispatch(submit('communicationStrategy')))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Form);
