import { db } from "firebase/fbConfig.js"


export function setState(stateList) {
  return {
    type: 'SET_STATE',
    stateList
  }
}

export function resetStateFromPlaybook() {
  return {
    type: 'RESET_STATE_FROM_PLAYBOOK',
  }
}


export function startSESLoadList(uid, stakeholderId) {
  var members = []
  var stakeholderName = ''
  return (dispatch) => {
    return db.collection("TEST-users").doc(uid).collection("lists").doc("all-stakeholders").get()
      .then(snapshot => {
        snapshot = snapshot.data().values
        for (var x = 0; x < snapshot.length; x++) {
          if (snapshot[x].id === stakeholderId) {
            stakeholderName = snapshot[x].text
          }
        }
        members = snapshot
        return db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc(stakeholderId)
          .set({ name: stakeholderName, status: "active" }, { merge: true })

          .then(() => {
            db.collection("TEST-users").doc(uid).collection("strategy-sheet").doc(stakeholderId).get()
              .then((list) => {
                dispatch(setSESList(list.data(), members, stakeholderName));
              })
          })
      })
  }
}

export function setUpdatedMemberName(updatedStakeholders, stakeholderId) {
  return {
    type: 'PLAYBOOK_SetSES_STAKEHOLDERS',
    updatedStakeholders,
    stakeholderId
  }
}

export function setStakeholderHasBeenEdited(boolean) {
  return {
    type: 'STAKEHOLDERHASBEENEDITED',
    boolean
  }
}


export function resetLoading() {
  return {
    type: 'RESET_LOADING'
  }
}

export function resetStakeholder(stakeholderName) {
  return {
    type: 'RESET_STAKEHOLDER',
    stakeholderName
  }
}


export function setSESListItem(name, index, item) {
  return {
    type: 'PLAYBOOK_SET_SES_LIST_ITEM',
    name,
    index,
    item
  }
}



export function setSESList(list, members, stakeholderName) {
  return {
    type: 'PLAYBOOK_SetSES_LIST',
    list,
    members,
    stakeholderName
  }
}

export function setList(name, list) {
  return {
    type: 'PLAYBOOK_SET_LIST',
    name,
    list
  }
}


export function openDialog(name) {
  return {
    type: 'PLAYBOOK_LIST_OPEN_DIALOG',
    name
  }
}

export function closeDialog(name) {
  return {
    type: 'PLAYBOOK_LIST_CLOSE_DIALOG',
    name
  }
}
