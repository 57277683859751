import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js"
import { Chart } from 'react-google-charts'
import moment from 'moment'
import { Link } from 'react-router-dom'

import marked from "marked";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/practicesStyle.jsx";
import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"
import BlogCommentForm from "components/Forms/BlogCommentForm.jsx"

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import { setFirstAssessment } from "store/actions/assessmentActions"
import { startSetUsersList } from "store/actions/adminActions"
import { startSetBlogList, startSetBlogsComments } from "store/actions/blogsActions"
import DocumentUpload from "components/CustomUpload/DocumentUpload.jsx"
import registerCoursesMap from "./RegisterCoursesMap";

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}



class Principles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updatePhotoModal: false,
      learnDashboardModal: false,
      markdownFlag: false
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = (blogID) => {
    let blogsData =  db.collection("blogPosts").doc(blogID)
          .get().then((snapshot) => {
            var docData = snapshot.data()
              if (docData) {
                var docObj = {
                  id: snapshot.id,
                  order: docData.order ? docData.order : "N/A",
                  author: docData.author ? docData.author : "N/A",
                  date: docData.date ? moment(docData.date.toDate()).format('MM-DD-YYYY') : "N/A",
                  markup: docData.markup ? docData.markup : "N/A",
                  blogText: docData.blogText ? docData.blogText : "N/A",
                  title: docData.title ? docData.title : "N/A",
                  category: docData.category ? docData.category : "N/A",
                  image: docData.image ? docData.image : "N/A",
                  paragraph1: docData.paragraph1 ? docData.paragraph1 : "N/A",
                  paragraph2: docData.paragraph2 ? docData.paragraph2 : "N/A",
                  paragraph3: docData.paragraph3 ? docData.paragraph3 : "N/A",
                  paragraph4: docData.paragraph4 ? docData.paragraph4 : "N/A",
                  paragraph5: docData.paragraph5 ? docData.paragraph5 : "N/A",
                  link: snapshot.id ? (
                      <Link to={'/blogs/' + snapshot.id}  key={snapshot.id} >
                <Button
                  color="primary"
                  fullWidth
                  // onClick={
                  //   () => window.open(docData.id)}
                >LINK</Button>
                </Link>)
                 : "N/A" 
                }
                return (docObj)
              } else {
                return (null)
              }
            }, (error) => {
                console.log('error fetching data: ', error)
              }).then(docObj => {
                this.setState({
                  data: docObj,
                  dataLoaded: true
                })
              })
  }

  // Load data for Table
  // if there are no actions make a default one
  deleteComment = (id) => {
    console.log("delete" + id)
      db.collection("blogPosts").doc("blog1").collection("comments").doc(id).delete().then(() => {
        console.log(``)
            window.location.reload();
      }).catch((err) => {
        console.log(`${err}`)
      })
  }

  componentDidMount() {
  }

  render() {
    const { AuthBool, classes, authUser, user, blogs, blog, comments, assessments } = this.props;
    const { markdown, data } = this.state;
  //console.log(this.state.practicesData)
    let profilePhoto = DefaultProfile

    var pathname = this.props.location.pathname
    var n = pathname.lastIndexOf('/');
    var courseID = pathname.substring(n + 1);

    
    const input = '# This is a header\n\nAnd this is a paragraph'
    
    if (AuthBool) { // fixes freeze on logout
      if (user.dataLoaded) {
        user.profileURL ? (
          profilePhoto = user.profileURL
        ) : (
            // add link to profile photo to firestore
            profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
            // add profile photo to firebase storage        
            // update redux with link
          )
      }
    }

    let lastAssessmentPurposePercent
    //console.log(user)
    //console.log(assessments.dataLoaded)
    if (!this.state.dataLoaded) {
      //console.log("load data")
      this.loadData(courseID)
      // console.log(user)
      // console.log(user.levelUpdatedAt.toDate())
      // console.log(moment(user.levelUpdatedAt.toDate()).format('MM-DD-YYYY'))  
    }

    console.log(this.props)
    console.log(authUser)

    if (data && !this.state.markdownFlag) {

      const readmePath = data.blogText;

      fetch(readmePath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          this.setState({
            markdown: marked(text),
            markdownFlag: true
          })
        })
  
    }



    return (
      <div>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
            <registerCoursesMap courseID={courseID} uid={authUser.uid}/>
        </GridItem>
      </GridContainer>
      </div>
    );
  } 
}

const mapStateToProps = (state) => {
  console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    blogs: state.blogs.blogs,
    blog: state.blogs.blog,
        comments: state.blogs.blogComments
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    var pathname = ownProps.location.pathname
    var n = pathname.lastIndexOf('/');
    var blogID = pathname.substring(n + 1);

  }
  

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Principles);