import React from "react";
import PropTypes from "prop-types";
import { db, firebase } from "firebase/fbConfig.js"
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import axios from 'axios'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from '@material-ui/icons/Check';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/profileFormStyle.jsx";

import { updateAssessment } from "store/actions/assessmentActions.jsx"

// Assets
import precincts from "variables/precincts.jsx"
import Question from "./Questions/Question";

class Form extends React.Component {
  state = {
    showQ1: false,
    showQ2: false,
    showQ3: false,
    showQ4: false,
    showQ5: false,
    showQ6: false,
    showQ7: false,
    showQ8: false,
    showQ9: false,
    showQ10: false,
    showQ11: false,
    showQ12: false,
    showQ13: false,
    showQ14: false,
    showQ15: false,
    showQ16: false,
    showQ17: false,
    showQ18: false,
    showQ19: false,
    showQ20: false,
    showQ21: false,
    showQ22: false,
    showQ23: false,
    showQ24: false,
    showQ25: false,
    showQ26: false,
    showQ27: false,
    showQ28: false,
    showQ29: false,
    showQ30: false,
    showUpdatedModal: false
  }

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      ...this.state,
    };
  }

  componentDidMount = () => {
    const lastAssessment = this.props.assessments.assessments[0]
    if (!this.state.dataLoaded && lastAssessment) {
      this.setTestScores(lastAssessment)
    }
    if (!this.state.questionsData && lastAssessment) {
      this.loadQuestionsData()
    }
  }

  componentDidUpdate = () => {
    const lastAssessment = this.props.assessments.assessments[0]
    if (!this.state.dataLoaded && lastAssessment) {
      this.setTestScores(lastAssessment)
    }
    if (!this.state.questionsData && lastAssessment) {
      this.loadQuestionsData()
    }
  }

  //Phone number code modified from
  // https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript
  isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };

  enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
      event.preventDefault();
    }
  };

  formatToPhone = (event) => {
    if (this.isModifierKey(event)) {
      return;
    }

    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    let targetValue
    if (input.length > 6) { targetValue = `(${zip}) ${middle} - ${last}`; }
    else if (input.length > 3) { targetValue = `(${zip}) ${middle}`; }
    else if (input.length > 0) { targetValue = `(${zip}`; }
    target.value = targetValue
    this.setState({
      phone: targetValue
    })
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  handleChange = (e) => {
    let value = e.target.value
    if (e.target.id === "firstName" || e.target.id === "lastName") {
      value = value.charAt(0).toUpperCase() + value.slice(1)
    }
    console.log(value)
    this.setState({
      [e.target.id]: value
    })
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    updateAssessment(this.state, this.props.authUser.uid)
  }

  handleToggle(value, question) {
    const { question1 } = this.state;
    console.log(this.state)
    if (this.state[question] === value) {
      this.setState({
        [question]: null,
      });
    } else {
      this.setState({
        [question]: value,
      });
    }
  }

  setTestScores(lastAssessment) {
    this.setState({
      question1: lastAssessment.question1,
      question2: lastAssessment.question2,
      question3: lastAssessment.question3,
      question4: lastAssessment.question4,
      question5: lastAssessment.question5,
      question6: lastAssessment.question6,
      question7: lastAssessment.question7,
      question8: lastAssessment.question8,
      question9: lastAssessment.question9,
      question10: lastAssessment.question10,
      question11: lastAssessment.question11,
      question12: lastAssessment.question12,
      question13: lastAssessment.question13,
      question14: lastAssessment.question14,
      question15: lastAssessment.question15,
      question16: lastAssessment.question16,
      question17: lastAssessment.question17,
      question18: lastAssessment.question18,
      question19: lastAssessment.question19,
      question20: lastAssessment.question20,
      question21: lastAssessment.question21,
      question22: lastAssessment.question22,
      question23: lastAssessment.question23,
      question24: lastAssessment.question24,
      question25: lastAssessment.question25,
      question26: lastAssessment.question26,
      question27: lastAssessment.question27,
      question28: lastAssessment.question28,
      question29: lastAssessment.question29,
      question30: lastAssessment.question30,
      dataLoaded: true
    })
  }
  loadQuestionsData = (id) => {
    let data = db.collection("questions").orderBy("order")
      .get().then((snapshot) => {
        //console.log(snapshot)
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          var docObj = {
            id: doc.id,
            ...docData
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        // data
        this.setState({
          questionsData: data,
          questionsDataLoaded: true,
        })
      })
  }

  render() {
    const { classes, user, uid, authUser } = this.props;
    console.log('propppxxx', authUser)
    const lastAssessment = this.props.assessments.assessments[0]
    const purposeLevel = this.props.user.peLevelName
    const purposePercent = this.props.user.pePercent
    const stakeholdersPercent = this.props.user.sePercent
    const stakeholdersLevel = this.props.user.seLevelName
    const strategyPercent = this.props.user.saPercent
    const strategyLevel = this.props.user.saLevelName
    const actionsPercent = this.props.user.aaPercent
    const actionsLevel = this.props.user.aaLevelName


    const selectedValue = this.state.selectedValue;
    const setSelectedValue = this.state.setSelectedValue;

    console.log(this.state)
    return (
      <div className={classes.formContainer} style={{ padding: "0px 20px 0px 20px" }}>
        <form onSubmit={this.handleSubmit}
        >
          <GridContainer justify="center">
            <GridItem xs={12}>
              {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
              {/*<Question order="1" content={this.state.question1content} questionState={this.state.question1} field="question1" answer1={this.state.question1answer1} answer2={this.state.question1answer2} answer3={this.state.question1answer3} answer4={this.state.question1answer4} answer5={this.state.question1answer5}/>*/}
              <div style={{ color: "#333", textAlign: "center" }}>
                <p className={classes.bodyText}>Purpose Embraced Score: {Math.round(purposePercent * 100)}%</p>
                {purposeLevel}
                <p className={classes.bodyText}>Stakeholders Engaged Score: {Math.round(stakeholdersPercent * 100)}%</p>
                {stakeholdersLevel}
                <p className={classes.bodyText}>Strategy Aligned Score: {Math.round(strategyPercent * 100)}%</p>
                {strategyLevel}
                <p className={classes.bodyText}>Actions Aligned Score:  {Math.round(actionsPercent * 100)}%</p>
                {actionsLevel}
              </div>
              {this.state.questionsData &&
                this.state.questionsData.map((question) => (

                  <div className={classes.question}>
                    {question.order == 31 ? null :
                      <div>
                        <h5 className={classes.bodyText}>QUESTION {question.order}</h5>
                        <h5 className={classes.bodyText}>{question.content}</h5><br />
                        <p className={classes.bodyText}>Category: {question.category_name}<br />
                          Topic: {question.topic}</p><br />
                        <Button
                          className={classes.button2}
                          onClick={() => this.setState({
                            ["showQ" + question.order]: !this.state["showQ" + question.order]
                          })}
                        >
                          Update Answer
                        </Button>
                        {this.state["showQ" + question.order] ? <div>
                          <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.handleToggle(0, 'question' + question.order)}
                                  checked={this.state["question" + question.order] === 0 ? true : false}
                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                  icon={<Check className={classes.uncheckedIcon} />}
                                  color="primary"
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{ label: classes.bodyText }}
                              label={question.answer1}
                            />
                          </div><br />
                          <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.handleToggle(1, 'question' + question.order)}
                                  checked={this.state["question" + question.order] === 1 ? true : false}
                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                  icon={<Check className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{ label: classes.bodyText }}
                              label={question.answer2}
                            />
                          </div><br />
                          <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.handleToggle(2, 'question' + question.order)}
                                  checked={this.state["question" + question.order] === 2 ? true : false}
                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                  icon={<Check className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{ label: classes.bodyText }}
                              label={question.answer3}
                            />
                          </div><br />
                          <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.handleToggle(3, 'question' + question.order)}
                                  checked={this.state["question" + question.order] === 3 ? true : false}
                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                  icon={<Check className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{ label: classes.bodyText }}
                              label={question.answer4}
                            />
                          </div><br />
                          <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.handleToggle(4, 'question' + question.order)}
                                  checked={this.state["question" + question.order] === 4 ? true : false}
                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                  icon={<Check className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{ label: classes.bodyText }}
                              label={question.answer5}
                            />
                          </div>
                          <center>
                            <Button
                              type="submit"
                              className={classes.button2}
                              onClick={() => console.log('asdfasdf')}
                              style={{
                                marginLeft: "10px",
                                marginBottom: "10px"
                              }}
                            >
                              Save Changes
                            </Button>
                          </center>
                        </div> : null}
                        <br /><br />
                        <p className={classes.bodyText}>Master Level Answer: {question.correct_answer}
                          <br /><br />
                          Explanation: {question.explanation}
                        </p>
                        <br />

                      </div>
                    }
                  </div>

                ))
              }
            </GridItem>
          </GridContainer>
          <center>
            <Button
              type="submit"
              className={classes.button2}
              style={{
                marginLeft: "10px",
                marginBottom: "10px"
              }}
            >
              Save Changes

            </Button>
          </center>
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  console.log('propppxxxasses', state.assessment)
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
  }
}


export default compose(connect(mapStateToProps), withStyles(styles))(Form);



