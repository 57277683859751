import React from "react";
import PropTypes from "prop-types";
import { db, firebase } from "firebase/fbConfig.js"
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/profileFormStyle.jsx";

import { updateUser } from "store/actions/profileActions.jsx"


class Form extends React.Component {
    state = {
        GROUPS: [],
    }

    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            ...this.state,
            hideData: this.props.user.hideData,
            emailError: '',
            emailChange: false // when user wants to change email
        };
    }

    componentDidMount = () => {
        this.setState({
            emailError: '',
        })
    }
    triggerHideData() {
        this.setState({
            hideData: false
        });
        console.log(this.state)
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    // validateEmail = () => {
    //     const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     if (!emailRegex.test(this.state.email)) {
    //         this.setState({
    //             emailError: "Invalid email"
    //         })
    //         return false
    //     }
    //     return true
    // }

    handleSubmit = async (e) => {
        e.preventDefault();
        // const isValid = this.validateEmail()
        // if (isValid) {
            console.log("email is: ", this.state.email)
            await firebase.auth().currentUser.updateEmail(this.state.email).then(() => {
                console.log("success")
                db.collection("TEST-users").doc(this.props.uid).update({
                    email: this.state.email
                })
                .then(() => {
                    this.setState({
                        emailError: ''
                    })
                    window.location.reload()
                })
                
            }).catch((error) => {
                console.log("error: ", error)
                this.setState({
                    emailError: error.message
                })
            })
        // }
    }

    render() {
        const { classes } = this.props;
        if (this.state.hideData === undefined) {
            this.triggerHideData()
        }
        return (
            <div className={classes.formContainer}>
                {console.log("this props: ", this.props, "\n this state", this.state.email)}
                <form onSubmit={this.handleSubmit}
                >
                    <GridContainer justify="center">
                        <GridItem xs={11}>
                            {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                            {/* <h5 className={classes.bodyText}>EMAIL: {this.props.user.email}</h5> */}
                            <CustomInput
                                labelText="Representative Email"
                                id="email"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "email",
                                    onChange: (event) => this.handleChange(event),
                                    defaultValue: this.props.user.email ? this.props.user.email : ''
                                }}
                            />
                            {
                                this.state.emailError ? (
                                    <p className={classes.errorText}>{this.state.emailError}</p>
                                ) : null
                            }
                        </GridItem>
                        <Button
                            type="submit"
                            className={classes.button}
                            style={{
                                marginBottom: "10px"
                            }}
                        >
                            Save Changes
                        </Button>
                    </GridContainer>
                </form>
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authUser: state.auth.user,
        user: state.profile.user,
        assessments: state.assessment,
    }
}


export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(Form);

