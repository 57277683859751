import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";


// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/questionStyle";
import { Grid } from "@material-ui/core";

// Forms
import JobApplicationForm from "components/Forms/JobApplicationForm.jsx"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Question extends React.Component {
    state = {
        question1: '',
        question2: ''
      }
    
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false
    };
  }

  handleToggle(value, field) {
    const { question1 } = this.state;
    console.log(this.state)

    if (this.state.question1 === value) {
        this.setState({
            [field]: '',
          });
    } else {
    this.setState({
      [field]: value,
    });}

  }

  render() {
    const { classes, order, content, questionState, field, answer1, answer2, answer3, answer4, answer5 } = this.props;

    //console.log(this.props)
    // Format and upload data to firestore
    // updateFirestoreWithTableData('TEST-actions-list',(tableToFirestoreObjects(this.props.actionsData)))
    // updateFirestoreWithTableData('TEST-users',(tableToFirestoreObjects(this.props.users)))

    // // add uid to firestore documents
    // if(this.state.dataLoaded === true){
    //   this.props.actionsData.dataRows.map(row => addFieldToDocument(row[0], {volUID: "TestID"}))
    // }  
//console.log(this.state.question1 === "0")
    return (
      <GridContainer justify="center">
        <GridItem xs={12} lg={11} xl={10}>
            <h5 className={classes.bodyText}>Question {order}</h5>
            <h5 className={classes.bodyText}>{content}</h5>
            <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => this.handleToggle('0', field)}
                  checked={this.state.question1 === "0" ? true : false}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{ label: classes.bodyText }}
              label={answer1}
            />
          </div><br />
          <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() => this.handleToggle('1', field)}
                checked={this.state.question1 === "1" ? true : false}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{ label: classes.bodyText }}
            label={answer2}
            />
        </div><br />
        <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() => this.handleToggle('2', field)}
                checked={this.state.question1 === "2" ? true : false}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{ label: classes.bodyText }}
            label={answer3}
          />
        </div><br />
        <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() => this.handleToggle('3', field)}
                checked={this.state.question1 === "3" ? true : false}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{ label: classes.bodyText }}
            label={answer4}
          />
        </div><br />
        <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              onClick={() => this.handleToggle('4', field)}
              checked={this.state.question1 === "4" ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.bodyText }}
          label={answer5}
          />
      </div><br />

        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    actionUsers: state.actions.users
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Question);
