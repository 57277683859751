import * as firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage'
console.log('firebase', firebase)
// Initialize Firebase from .env files
const projectId = process.env.REACT_APP_PROJECT_ID;

var config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  projectId: projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

firebase.initializeApp(config);
var storage = firebase.storage();
firebase.firestore().settings({});
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
var db = firebase.firestore();
var functions = firebase.functions()
var sendEmail = functions.httpsCallable('sendEmail')
var deleteFn = firebase.functions().httpsCallable('recursiveDelete')
export { db, deleteFn, firebase, sendEmail, googleAuthProvider, storage, firebase as default };
