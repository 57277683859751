import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import { db } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class Form extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false
  }
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid, displayName) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
      displayName: displayName
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    // update firestore document with PENDING and comment
    db.collection("feedback").doc().set({
      uid: this.state.uid,
      displayName: this.state.displayName,
      comment: this.state.comment,
      page: window.location.pathname,

      updatedAt: new Date()
    }, { merge: true }).then(() => {
      console.log(``)
      this.setState({
        comment: '',
        messageSent: true
      })
    }).catch((err) => {
      console.log(`${err}`)
    })
  }

  resetRequest = () => {
    window.location.reload();
  }

  render() {
    const { classes, modalClose, authUser } = this.props;
    //console.log(authUser)
    // reset modal when closed
    if (this.state.messageSent) { this.resetRequest() }

    return (
      <div>
        {console.log(this.state)}
        {this.state.messageSent ? (
          <GridContainer justify="center" >
            <GridItem xs={11}>
              <h4 className={classes.subTitle}>Feedback Submitted!</h4>

            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer} style={{marginTop:"-20px"}}>
            <form onSubmit={this.handleSubmit}
            >
              <GridContainer justify="center">
                <GridItem xs={11}>
                  <h5 className={classes.subTitle}>Leave Feedback</h5>
                  {this.state.messageError ? <h5 className={classes.subTitle}>There Has Been An Error</h5> : null}
                  <CustomInput
                    labelText="Comment"
                    id="comment"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      type: "text",
                      multiline: true,
                      rows: 5,
                      onChange: (event) => this.handleChange(event, authUser.uid, authUser.displayName)
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                type="submit"
                className={classes.button}
                style={{
                  marginLeft: "30px",
                  marginBottom: "10px"
                }}
              >
                SUBMIT
              </Button>
            </form>
          </div>

        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);