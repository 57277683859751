import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from "firebase/fbConfig.js";
import { Chart } from "react-google-charts";
import moment from "moment";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
// import InfoIcon from '@material-ui/icons-material/Info';
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { EditOutlined } from "@material-ui/icons";
import Assess from "assets/img/assess.png";
import Connect from "assets/img/connect.png";
import Grow from "assets/img/grow.png";
import Step1 from "assets/img/step1.png";
import Step2 from "assets/img/step2.png";
import Step3 from "assets/img/step3.png";
import Step4 from "assets/img/step4.png";
import Step5 from "assets/img/step5.png";
import Strategy from "assets/img/strategy.png";
import PlaybookIMG from "assets/img/playbook.png";
import DashboardIMG from "assets/img/dashboard.png";
import CheckMark from "assets/img/check.png";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import InfoIcon from "assets/img/info.png";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import ReactDragListView from "react-drag-listview";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/playbookStyle.jsx";
import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

// Forms
import AccountabilityStructureForm from "components/Forms/AccountabilityStructureForm.jsx";
import PurposeDeclarationForm from "components/Forms/PurposeDeclarationForm.jsx";
import TaglineForm from "components/Forms/TaglineForm.jsx";
import BusinessDescriptionForm from "components/Forms/BusinessDescriptionForm.jsx";
import PurposeOriginForm from "components/Forms/PurposeOriginForm.jsx";
import PurposeEnvisionedForm from "components/Forms/PurposeEnvisionedForm.jsx";
import StakeholderCommunityForm from "components/Forms/StakeholderCommunityForm.jsx";
import CoreValuesForm from "components/Forms/CoreValuesForm.jsx";
import CoreGoalsForm from "components/Forms/CoreGoalsForm.jsx";
import PurposeMetricsForm from "components/Forms/PurposeMetricsForm.jsx";
import HealthMetricsForm from "components/Forms/HealthMetricsForm.jsx";
import TargetMarketForm from "components/Forms/TargetMarketForm.jsx";
import CustomerValuePropositionForm from "components/Forms/CustomerValuePropositionForm.jsx";
import StaffValuePropositionForm from "components/Forms/StaffValuePropositionForm.jsx";
import KeyResourcesForm from "components/Forms/KeyResourcesForm.jsx";
import CustomerRelationshipsForm from "components/Forms/CustomerRelationshipsForm.jsx";
import KeyCompetenciesForm from "components/Forms/KeyCompetenciesForm.jsx";
import KeyPartnershipsForm from "components/Forms/KeyPartnershipsForm.jsx";
import RevenueStreamsForm from "components/Forms/RevenueStreamsForm.jsx";
import CostStructureForm from "components/Forms/CostStructureForm.jsx";
import KeyActivitiesForm from "components/Forms/KeyActivitiesForm.jsx";
import KeyChannelsForm from "components/Forms/KeyChannelsForm.jsx";
import CombinedValuesGoalsForm from "components/Forms/CombinedValuesGoalsForm.jsx";
import CombinedCustomerStaffValuePropositionForm from "components/Forms/CombinedCustomerStaffValuePropositionForm.jsx";
import ProfileForm from "components/Forms/profileForm.jsx";
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx";
import {
  openDialog,
  closeDialog,
  startLoad,
  startLoadList,
  setCommunityStakeholderForm
} from "store/actions/playbookListActions.jsx";

import {
  startSESLoadList,
} from "store/actions/SESActions.jsx";

// Assets
import DefaultProfile from "assets/img/default-avatar.png";

// Firebase Redux Integration
import {
  tableToFirestoreObjects,
  updateFirestoreWithOneObject,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from "store/actions/firestoreActions";
import {
  startSetActionsList,
  startToggleCompleted,
  setUserScore,
} from "store/actions/actionsActions";
import { setFirstAssessment } from "store/actions/assessmentActions";
import { startSetUsersList } from "store/actions/adminActions";
import { startSetPracticesList } from "store/actions/practicesActions";
import { resetLoading, resetStateFromPlaybook } from "store/actions/SESActions";
import pathImg from "assets/img/path/path-image1.png";
import maturityLevel from "assets/img/path/MaturityLevel.png";

// import { html2canvas } from "html2canvas";
import { jsPDF } from "jspdf";
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};



class Playbook extends React.Component {

  constructor(props) {
    super(props);

    const data = [];
    for (let i = 1, len = 7; i < len; i++) {
      data.push({
        title: `rows${i}`,
      });
    }
    this.state = {
      data,
      dataLoaded: false,
      playBookDataLoaded: false,
      accountabilityStructureModal: false,
      coreValuesModal: false,
      combinedValuesGoalsModal: false,
      combinedCustomerStaffValueModal: false,
      purposeDeclarationModal: false,
      taglineModal: false,
      businessDescription: false,
      purposeOriginModal: false,
      stakeholderCommunityModal: false,
      purposeMetricsModal: false,
      healthMetricsModal: false,
      targetMarketModal: false,
      valuePropositionModal: false,
      keyResourcesModal: false,
      customerRelationshipsModal: false,
      keyCompetenciesModal: false,
      keyPartnershipsModal: false,
      revenueStreamsModal: false,
      costStructureModal: false,
      keyActivitiesModal: false,
      keyChannelsModal: false,
      learnPlaybookModal: false,
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  componentDidMount() {
    if (this.props.authUser && !this.state.dataLoaded) {
      this.props.loadPlaybookListData(this.props.authUser.uid);
      resetLoading()
      this.setState({ dataLoaded: true });
      this.props.setForm(false)
      this.props.resetStateFromPlaybook()
      console.log('mounted', 'updated')
    }
  }

  componentDidUpdate() {
    if (this.props.authUser && !this.state.dataLoaded) {
      this.props.loadPlaybookListData(this.props.authUser.uid);
      resetLoading()
      this.setState({ dataLoaded: true });
      this.props.setForm(false)
      this.props.resetStateFromPlaybook()
    }
  }


  loadPlayBookData = (uid) => {
    let data = db
      .collection("TEST-users")
      .doc(uid)
      .collection("playbook")
      .get()
      .then(
        (snapshot) => {
          const data = {};
          // Parse data into array like in firebase.js
          snapshot.forEach((doc) => {
            var docData = doc.data();
            var docObj = {
              id: doc.id,
              ...docData,
            };
            data[docObj.id] = docObj;
          });
          return data;
        },
        (error) => {
          console.log("error fetching data: ", error);
        }
      )
      .then((data) => {
        this.setState({
          playBookData: data,
          playBookDataLoaded: true,
        });
      });
  };



  printPlayBook = () => {
    // Find true center of page, then calculate expected text block lenghts to avoid unevenness
    const doc = new jsPDF();
    // doc.rect(pixel from left, pixel from the top,width,height)
    // doc.text(pixel left to right ,pixel height)
    doc.setDrawColor(77, 171, 41);
    // Purpose Delcaartion Recantagnle
    // doc.rect(4, 10, 55, 25)
    const now = moment().format('MMMM Do, yyyy')
    doc.setFontSize(12);
    doc.text(`Revision: ${now}`, 5, 10);
    doc.setFont("helvetica", "bold");
    doc.text("Purpose Declaration:", 5, 21);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    var purposeDeclaration = doc.splitTextToSize(this.props.user.purposeDeclaration || 'N/A', 60);
    doc.text(5, 25, purposeDeclaration);
    // Tagline Recantagele
    // doc.rect(64, 5, 71, 22)
    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.text(this.props.user.companyName + " Playbook", 105, 5, 'center');
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    const taglineText = this.props.user.tagline || 'N/A'
    var tagline = doc.splitTextToSize(taglineText, 60);
    doc.text(80, 10, tagline);
    // Purpose Envisionied Recantgle
    // doc.rect(141, 10, 64, 30)
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Purpose Envisioned:", 147, 5);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    var purposeEnvisioned = doc.splitTextToSize(this.props.purposeEnvisioned.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
    doc.text(147, 10, purposeEnvisioned);
    // Purpose Orgigin
    // doc.rect(pixel from left, pixel from the top,width,height)
    // doc.text(pixel left to right ,pixel height)
    // doc.rect(65, 34, 72, 40)

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Purpose Origin:", 71, 21);
    doc.setFontSize(9);

    doc.setFont("helvetica", "normal");
    var purposeOriginArray = ["Desired Underlying Condition: ", this.props.user.purposeOriginCondition, "Specific Problem: ", this.props.user.purposeOriginProblem, "Solution: ", this.props.user.purposeOriginSolution]


    var xAxis;
    var yAxis;

    for (var x = 0; x < purposeOriginArray.length; x++) {
      if (x === 0) {
        xAxis = 71
        yAxis = 26
        doc.setFont("helvetica", "bold");
        doc.text(xAxis, yAxis, purposeOriginArray[x])
      }
      if (x === 1) {
        let value = doc.splitTextToSize(purposeOriginArray[x], 65);
        xAxis = 71
        yAxis = 31
        doc.setFont("helvetica", "normal");
        doc.text(xAxis, yAxis, value)
      }
      if (x === 2) {
        xAxis = 71
        yAxis = 45
        doc.setFont("helvetica", "bold");
        doc.text(xAxis, yAxis, purposeOriginArray[x])
      }
      if (x === 3) {
        let value = doc.splitTextToSize(purposeOriginArray[x], 65)
        xAxis = 71
        yAxis = 50
        doc.setFont("helvetica", "normal");
        doc.text(xAxis, yAxis, value)
      }

      if (x === 4) {
        xAxis = 71
        yAxis = 60
        doc.setFont("helvetica", "bold");
        doc.text(xAxis, yAxis, purposeOriginArray[x])
      }

      if (x === 5) {
        let value = doc.splitTextToSize(purposeOriginArray[x], 65)
        xAxis = 71
        yAxis = 65
        doc.setFont("helvetica", "normal");
        doc.text(xAxis, yAxis, value)
      }

    }


    // var purposeOrigin = doc.splitTextToSize(purposeOriginArray.map((item, index) => {
    //   console.log('indexxxx', index)
    //    return '\u2022 ' + item +  '                                                                                                                                                           ' }), 70);
    // doc.text(70, 27, purposeOrigin);
    // doc.text("Desired Underlying Condition: " + this.props.user.purposeOriginCondition, 67, 45);
    // doc.text("Specific Problem: " + this.props.user.purposeOriginProblem, 67, 55);
    // doc.text("Solution: " + this.props.user.purposeOriginSolution, 67 , 65);
    // StakeHolder Recatangle
    // Needs to fix list to adjust list to count at list instead of string
    // doc.rect(4, 40, 55, 40)
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Stakeholder Community:", 5, 50);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    var stakeholderCommunity = doc.splitTextToSize(this.props.members.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 50);
    doc.text(5, 55, stakeholderCommunity);
    // core Values Recatangle
    // Needs to fix list to adjust list to count at list instead of string
    // doc.rect(141, 45, 64, 50)
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Core Values: ", 147, 80);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    var coreValues = doc.splitTextToSize(this.props.coreValues.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
    doc.text(147, 85, coreValues);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Goal Values: ", 147, 110);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    var coreGoals = doc.splitTextToSize(this.props.coreGoals.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
    doc.text(147, 115, coreGoals);
    // doc.rect(pixel from left, pixel from the top,width,height)
    // doc.text(pixel left to right ,pixel height)
    // Purpose Meterics
    // doc.rect(4, 85, 55, 30)
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Purpose Metrics:", 147, 50);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    var purposeMetrics = doc.splitTextToSize(this.props.purposeMetrics.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
    doc.text(147, 55, purposeMetrics);
    // Accountability Structure
    // need direct path for vales to pass them in for Accountability Structure
    // doc.rect(65, 80, 72, 70)
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Accountability Structure: ", 71, 90);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    var accountabilityStructure = doc.splitTextToSize(this.props.accountabilityStructure.values.filter((item) => item.text !== "").map((item) => { return item.text }), 65);
    doc.text(71, 95, accountabilityStructure, { maxWidth: 63 })
    // Health Metrics
    // doc.rect(141, 100, 64, 55)
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Health Metrics: " + "", 5, 100);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    var healthMetrics = doc.splitTextToSize(this.props.healthMetrics.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 50);
    doc.text(5, 105, healthMetrics);

    // Bussiness Model Sign
    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.text("Business Model", 105, 150, 'center');
    // Value Proposition
    // doc.rect(65, 164, 71, 61)
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    var bizDescript = doc.splitTextToSize(this.props.user.businessDescription, 75)
    doc.text(bizDescript, 68, 155)
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Customer Value Proposition: ", 71, 165);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    var customerValues = doc.splitTextToSize(this.props.customerValues.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 65);
    doc.text(71, 170, customerValues);


    if (this.props.staffValues.values.length > 0) {
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Purpose Beneficiary Value Proposition: ", 71, 210, { maxWidth: 65 });
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var staffValues = doc.splitTextToSize(this.props.staffValues.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 65);
      doc.text(71, 220, staffValues, { maxWidth: 63 });
      // Key Resources
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Resources:", 147, 150);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyResources = doc.splitTextToSize(this.props.keyResources.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(147, 155, keyResources);
      // Key PartnerShip
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Partnerships: ", 147, 190);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyPartnerships = doc.splitTextToSize(this.props.keyPartnerships.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(147, 195, keyPartnerships);
      // Key Activities
      // doc.rect(141, 250, 65, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Activities:", 147, 215);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyActivities = doc.splitTextToSize(this.props.keyActivities.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(147, 220, keyActivities);
      // Key Competencies
      // doc.rect(65, 230, 71, 28)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Competencies:", 71, 240);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyCompetencies = doc.splitTextToSize(this.props.keyCompetencies.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(71, 245, keyCompetencies);
      //  Cost Structures
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Cost Structures: ", 147, 255);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var costStructures = doc.splitTextToSize(this.props.costStructures.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 65);
      doc.text(147, 260, costStructures, { maxWidth: 63 });

      // Target Market
      // doc.rect(4, 120, 55, 35)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Target Market:", 5, 150);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var targetMarkets = doc.splitTextToSize(this.props.targetMarkets.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(5, 155, targetMarkets);

      // Customer Relationship
      // doc.rect(4, 160, 55, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Customer Relationships: ", 5, 190);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var customerRelationships = doc.splitTextToSize(this.props.customerRelationships.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(5, 195, customerRelationships);
      // Revunue Stream
      // doc.rect(4, 205, 55, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Revenue Streams:", 5, 225);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var revenueStreams = doc.splitTextToSize(this.props.revenueStreams.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(5, 230, revenueStreams);
      // Key Channels
      // doc.rect(4, 250, 55, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Channels: ", 5, 250);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyChannels = doc.splitTextToSize(this.props.keyChannels.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(5, 255, keyChannels);
    }
    //-------------------------------------------------------------------
    //-------------------------------------------------------------------
    else {
      // Key Resources
      // doc.rect(141, 160, 65, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Resources:", 147, 150);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyResources = doc.splitTextToSize(this.props.keyResources.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(147, 155, keyResources);
      // Key PartnerShip
      // doc.rect(141, 205, 65, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Partnerships: ", 147, 190);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyPartnerships = doc.splitTextToSize(this.props.keyPartnerships.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(147, 195, keyPartnerships);
      // Key Activities
      // doc.rect(141, 250, 65, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Activities:", 147, 215);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyActivities = doc.splitTextToSize(this.props.keyActivities.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(147, 220, keyActivities);
      // Key Competencies
      // doc.rect(65, 230, 71, 28)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Competencies:", 71, 215);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyCompetencies = doc.splitTextToSize(this.props.keyCompetencies.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(71, 220, keyCompetencies);
      //  Cost Structures
      // doc.rect(65, 262, 71, 32)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Cost Structures: ", 147, 255);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var costStructures = doc.splitTextToSize(this.props.costStructures.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 65);
      doc.text(147, 260, costStructures, { maxWidth: 63 });

      // Target Market
      // doc.rect(4, 120, 55, 35)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Target Market:", 5, 150);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var targetMarkets = doc.splitTextToSize(this.props.targetMarkets.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(5, 155, targetMarkets);

      // Customer Relationship
      // doc.rect(4, 160, 55, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Customer Relationships: ", 5, 190);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var customerRelationships = doc.splitTextToSize(this.props.customerRelationships.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(5, 195, customerRelationships);
      // Revunue Stream
      // doc.rect(4, 205, 55, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Revenue Streams:", 5, 225);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var revenueStreams = doc.splitTextToSize(this.props.revenueStreams.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(5, 230, revenueStreams);
      // Key Channels
      // doc.rect(4, 250, 55, 40)
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Key Channels: ", 5, 250);
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      var keyChannels = doc.splitTextToSize(this.props.keyChannels.values.filter((item) => item.text !== "").map((item) => { return '\u2022 ' + item.text }), 60);
      doc.text(5, 255, keyChannels);




      // doc.setFontSize(12);

    }
    doc.save("playbook.pdf");
  }


  render() {
    const {
      authUser,
      classes,
      user,
      accountabilityStructure,
      purposeEnvisioned,
      openPlaybookListDialog,
      closePlaybookListDialog,
      members,
      purposeMetrics,
      healthMetrics,
      targetMarkets,
      keyResources,
      customerRelationships,
      keyCompetencies,
      revenueStreams,
      costStructures,
      keyActivities,
      keyChannels,
      keyPartnerships,
      coreValues,
      coreGoals,
      customerValues,
      staffValues,
      tagline,
    } = this.props;


    if (!!authUser && user.dataLoaded && !this.state.playBookDataLoaded) {
      console.log(user);
      this.loadPlayBookData(authUser.uid);
    }

    let playbook;
    if (this.state.playBookDataLoaded) {
      playbook = this.state.playBookData;
    }

    if (user.termGroups) {
      if (user.termGroups.length < 2) {
        return <Redirect to="/profile" />;
      }
    }

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const data = [...that.state.data];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        that.setState({ data });
      },
      nodeSelector: "li",
      handleSelector: "a",
    };
    // console.log(user.accountabilityStructureModel)
    return (
      <div id="divToPrint">
        {this.state.dataLoaded ? (

          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <div>
                <GridContainer style={{ backgroundColor: "#E5E5E5", height: "60px", paddingBottom: "5px" }}>
                  <GridItem xs={6}>
                    <h3
                      style={{
                        fontFamily: "'Merriweather Sans', sans-serif",
                        textTransform: "uppercase",
                        color: "#3a3934",
                        fontWeight: "600",
                        verticalAlign: "center"
                      }}
                    >
                      Welcome to Your Playbook</h3>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}
                    style={{ justifyContent: "flex-end", display: "flex", marginTop: "7px", verticalAlign: "center" }}
                  >
                    <Button
                      className={classes.button}
                      style={{ height: "35px", marginRight: "15px" }}
                      onClick={() => this.printPlayBook()}
                    >
                      PRINT PLAYBOOK
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ height: "35px", marginRight: "5px" }}
                      onClick={() => this.handleClickOpen("learnPlaybookModal")}
                    >
                      LEARN ABOUT THE PLAYBOOK
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ padding: "30px" }}>
                      <OpenInNewIcon
                        onClick={() => this.handleClickOpen("taglineModal")}
                        style={{ margin: "0px 0px -1% 96%", position: "relative", zIndex: "1", color: "#F4BF4F" }}
                      />
                      <center>
                        {this.props.user.companyName ? (
                          <h2 className={classes.subTitle}>
                            <b>
                              {this.props.user.companyName.toUpperCase()}{" "}
                              <span style={{ color: "#4ABEB5",}}>PLAYBOOK</span>
                            </b>
                          </h2>
                        ) : (
                          <p className={classes.body}>Enter Company Name on Profile Page</p>
                        )}

                        <Tooltip
                          id="tooltip-top"
                          title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration and reminds people why you exist. (Max of 10 words/1 line)"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <img src={InfoIcon} className={classes.infoIcon}></img>
                        </Tooltip>
                        {this.props.user.tagline ? (
                          <h4>{user.tagline ? user.tagline : null}</h4>
                        ) : (
                          <p>Add A Tagline By Clicking The Box Arrow Icon In The Top Right Corner...</p>
                        )}
                      </center>
                    </Card>
                    <GridContainer>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen(
                                      "purposeDeclarationModal"
                                    )
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Purpose Declaration: A statement of an organization’s commitment or promise to its Stakeholder World of the difference it wants to make in that world. Generally begins: “We are committed to...” This declaration should be meaningful and important to the organization’s stakeholder world. It should provide the overriding context for everything the organization does: its Purpose. (Max of 18 words/3 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Purpose Declaration</h3>
                          {this.props.user.purposeDeclaration ? (
                            <p>{this.props.user.purposeDeclaration}</p>
                          ) : (
                            <p className={classes.body} style={{fontSize:"18px"}}>Declare your purpose</p>
                          )}
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("purposeOriginModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Purpose Origin: A process to assist in discovering how your Purpose originated. Based on the definition of Purpose: A Solution to a Specific Problem that is in the way of fulfilling a Desired Underlying Condition."
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Purpose Origin</h3>
                          <ul>
                            <Tooltip
                              id="tooltip-top"
                              title="Underlying Condition: That condition in your stakeholder world that all desire (e.g. peace, health, full self-expression, love, etc.) and that you/your organization to making it accessible to all. (Max of 14 words/2 lines)"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <li>
                                <b className={classes.body} style={{fontWeight:"bold"}}>Desired Underlying Condition:</b>
                                <br />
                                {this.props.user.purposeOriginCondition ? (
                                  <p className={classes.body}>
                                    {this.props.user.purposeOriginCondition}
                                  </p>
                                ) : (
                                  <p className={classes.body}>Declare desired underlying condition</p>
                                )}
                              </li>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Specific Problem: Something that is in the way of that Desired Underlying Condition being accessible to all that your organization wants to address. (Max of 14 words/2 lines)"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <li>
                                <b className={classes.body} style={{fontWeight:"bold"}}>Specific Problem:</b>
                                <br />
                                {this.props.user.purposeOriginProblem ? (
                                  <p className={classes.body}>{this.props.user.purposeOriginProblem}</p>
                                ) : (
                                  <p className={classes.body}>Declare specific problem</p>
                                )}
                              </li>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Solution: How your organization will be addressing the Specific Problem; either searching for the solution or offering a solution. This is the reason why your organization exists: to address the solution to this Specific Problem. (Max of 14 words/2 lines)"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <li>
                                <b className={classes.body} style={{fontWeight:"bold"}}>Solution:</b>
                                <br />
                                {this.props.user.purposeOriginSolution ? (
                                  <p className={classes.body}>{this.props.user.purposeOriginSolution}</p>
                                ) : (
                                  <p className={classes.body}>Declare solution</p>
                                )}
                              </li>
                            </Tooltip>
                          </ul>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    openPlaybookListDialog("purposeEnvisioned")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Purpose Envisioned: A list of bullet points describing what its stakeholder world would look like when its Purpose is fulfilled. What results would show up in that world that are not there now? (Max of 8 bullet points/8 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Purpose Envisioned</h3>
                          <ul>
                            {purposeEnvisioned.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() => {
                                    this.handleClickOpen(
                                      "stakeholderCommunityModal"
                                    )
                                    this.props.setForm(true)
                                  }
                                  }
                                >
                                  <b style={{ fontSize: "18px", }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Stakeholder Community: A list of the key stakeholders that are critical for the organization to achieve its Purpose; that is for the organization to be successful. A key stakeholder can be an individual, a group of individuals, another organization or a group of organizations. They can be individuals or organizations. A key stakeholder will appear in one or more of the other elements of the Playbook. (Max of 10 stakeholders/10 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Stakeholder Community</h3>
                          <ul>
                            {members.values ? members.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <Link to={{ pathname: `/tools/strategy-sheet/${item.id}`, nameProp: { name: item.text } }}><li style={{ color: "blue", textDecoration: "underline" }} key={item.id}>{item.text}</li></Link>;
                              }) : <li></li>}
                          </ul>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen(
                                      "accountabilityStructureModal"
                                    )
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Accountability Structure: Consider major functions or roles in the organization and those people who are accountable for them. Please limit primary roles to five and sub-roles under each primary role to three. (Max of 14 roles/14 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Accountability Structure</h3>

                          {accountabilityStructure.values.length > 0 ? accountabilityStructure.values
                            .filter((item) => item.text !== "")
                            .map((item) => {
                              return <span
                                // style={{ textAlign: "center" }}
                                key={item.id}>{item.text}</span>;
                            }) : (null)}
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("purposeMetricsModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Purpose Metrics: A list of Measures that would reflect the envisioned results of a Purpose fulfilled as stated under Vision (Max of 4 measures/4 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Purpose Metrics</h3>
                          <ul>
                            {purposeMetrics.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("healthMetricsModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Health Metrics: Measures or indicators that reflect the fiscal health of the organization as it relates to business model performance and Purpose fulfillment. These measures should be leading indicators to provide ample time to make any course corrections. (Max of 4 metrics/4 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Health Metrics</h3>
                          <ul>
                            {healthMetrics.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}></GridItem>
                      <GridItem xs={12} sm={4}>
                        {this.state.playBookDataLoaded && (
                          <Card style={{ padding: "30px" }}>
                            <GridContainer
                              style={{ margin: "0px 0px -60px 0px" }}
                            >
                              <GridItem xs={9}></GridItem>
                              <GridItem xs={3} sm={3} md={3}>
                                <div className={classes.dashboardModalBtn}>
                                  <Button
                                    style={{ background: "#F4BF4F" }}
                                    onClick={() =>
                                      this.handleClickOpen("coreValuesModal")
                                    }
                                  >
                                    <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                  </Button>
                                </div>
                              </GridItem>
                            </GridContainer>
                            <Tooltip
                              id="tooltip-top"
                              title="Core Values: Valued rules of behavior that are non-negotiable—that is, they must be adhered to or you cannot remain with the organization. Core values are already present in the organization, even if they have not been distinguished. (Max of 8 values/8 lines)"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img src={InfoIcon} className={classes.infoIcon}></img>
                            </Tooltip>
                            <h3 className={classes.boldBody}>Core Values</h3>
                            <ul>
                              {coreValues.values
                                .filter((item) => item.text !== "")
                                .map((item) => {
                                  return <li key={item.id}>{item.text}</li>;
                                })}
                            </ul>
                            <Tooltip
                              id="tooltip-top"
                              title="Goal Values: Valued rules of behavior that an organization aspires to but may not have been already present in the organization. Rules of behavior considered to be critical to fulfill on the organization’s Purpose. All Purposeful Organizations must have present either as a core or goal value, the following two values: (1) transparency/open and (2) Minimize harm/mindful (Max of 6 values/6 lines)"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img src={InfoIcon} className={classes.infoIcon}></img>
                            </Tooltip>
                            <h3 className={classes.boldBody}>Goal Values</h3>
                            <ul>
                              {coreGoals.values
                                .filter((item) => item.text !== "")
                                .map((item) => {
                                  return <li key={item.id}>{item.text}</li>;
                                })}
                            </ul>
                          </Card>
                        )}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <br />
                        <br />
                        <Card style={{ padding: "30px" }}>
                          <OpenInNewIcon
                            color="primary"
                            onClick={() => this.handleClickOpen("businessDescriptionModal")}
                            style={{ margin: "0px 0px -1% 96%", position: "relative", zIndex: "1", color: "#F4BF4F" }}
                          />
                          <center>
                            <b>
                              <h2 className={classes.subTitle}>
                                <b>
                                  BUSINESS
                                  <span style={{ color: "#4ABEB5"}}>
                                    {" "}
                                    MODEL
                                  </span>
                                </b>
                                <br />
                              </h2>
                            </b>
                            <Tooltip
                              id="tooltip-top"
                              title="A short description that describes the type of business model you are using to fulfill your Purpose. (Max of 10 words/1 line)"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <img src={InfoIcon} className={classes.infoIcon}></img>
                            </Tooltip>
                            {this.props.user.businessDescription ? (
                              <h4>
                                {user.businessDescription
                                  ? user.businessDescription
                                  : null}
                              </h4>
                            ) : (
                              <p className={classes.body}>Add A Business Description By Clicking The Arrow Icon In The Top Right Corner...</p>
                            )}
                          </center>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("targetMarketModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Target Market: Any stakeholder group that is your business model’s primary customer. List your major types of customers. When determining who your customer is consider these questions: Do they buy the product/service directly from you indirectly through a channel? What group is your brand promise directed to? Do they have the characteristics of an ideal customer? (Max of 5 markets/5 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Target Market</h3>
                          <ul>
                            {targetMarkets.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen(
                                      "valuePropositionModal"
                                    )
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <h3 className={classes.boldBody}>Value Proposition</h3>

                          <Tooltip
                            id="tooltip-top"
                            title="Customer Value Proposition: The value/benefits the organization promises to consistently deliver its customers. Try to identify benefits that are measurable. (Max of 6 benefits/6 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <b>
                            Customer Value Proposition:
                            {/* {user.valueTitle1 ? user.valueTitle1 : ""} */}
                          </b>
                          <ul>
                            {customerValues.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                          {/* {console.log("Testing staff vavlues: ", staffValues.values)} */}
                          {staffValues.values.length > 0 ?
                            <>
                              <br />
                              <Tooltip
                                id="tooltip-top"
                                title="Purpose Beneficiary Value Proposition: The value/benefits the organization promises to consistently deliver its customers. Try to identify benefits that are measurable. (Max of 6 benefits/6 lines)"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <img src={InfoIcon} className={classes.infoIcon}></img>
                              </Tooltip>
                              <b>
                                Purpose Beneficiary Value Proposition:
                                {/* {user.valueTitle2 ? user.valueTitle2 : ""} */}
                              </b>
                              <ul>
                                {staffValues.values
                                  .filter((item) => item.text !== "")
                                  .map((item) => {
                                    return <li key={item.id}>{item.text}</li>;
                                  })}
                              </ul>
                            </>
                            : null}
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("keyResourcesModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Key Resources: Those resources (human, intangible or physical) that will be critical for business model success. The source of these resources may also be key stakeholders (Max of 9 resources/9 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Key Resources</h3>
                          <ul>
                            {keyResources.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen(
                                      "customerRelationshipsModal"
                                    )
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Customer Relationships: How does the organization relate to its customers? What people or technology interact with your customer in acquiring or supporting your customer? Consider your method of sale: how do you acquire your customer? Consider your method of retention: how do you retain your customer? (Max 9 relationships/9 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Customer Relationships</h3>
                          <ul>
                            {customerRelationships.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("keyCompetenciesModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Key Competencies: The four competencies the organization will need to have its business model perform at the level needed to fulfill its Purpose. These four competencies will be identified and prioritized through an extensive discovery process. They represent the organization’s “secret sauce” for success. (Must of 4 competencies/8 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Key Competencies</h3>
                          <ul>
                            {keyCompetencies.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("keyPartnershipsModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Key Partnerships: Key stakeholders that have a more formal and long term relationship with the organization (written or unwritten) (Max of 8 partnerships/8 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Key Partnerships</h3>
                          <ul>
                            {keyPartnerships.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("revenueStreamsModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Revenue Streams: How do you monetize your service offerings? What are the different type of sales/revenue opportunities (current or potential)? (Max 5 revenue streams/5 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Revenue Streams</h3>
                          <ul>
                            {revenueStreams.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("costStructureModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Cost Structure: List the major activities of your business model as they relate to your key customer relationships, channels, competencies, delivery of value proposition, resources, and partnerships. (Max of 8 costs/8 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Cost Structure</h3>
                          <ul>
                            {costStructures.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={4}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("keyActivitiesModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="Key Activities: List the major activities of your business model as they relate to your key customer relationships, channels, competencies, delivery of value proposition, resources, and partnerships. (Max of 10 activities/10 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Key Activities</h3>
                          <ul>
                            {keyActivities.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem sm={4} xs={12}>
                        <Card style={{ padding: "30px" }}>
                          <GridContainer
                            style={{ margin: "0px 0px -60px 0px" }}
                          >
                            <GridItem xs={9}></GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                              <div className={classes.dashboardModalBtn}>
                                <Button
                                  style={{ background: "#F4BF4F" }}
                                  onClick={() =>
                                    this.handleClickOpen("keyChannelsModal")
                                  }
                                >
                                  <b style={{ fontSize: "18px" }}><EditOutlined /></b>
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Tooltip
                            id="tooltip-top"
                            title="What technology or human or physical resource are you investing in to deliver your value proposition to your customer or Purpose beneficiary (channel that is outward directed)? What technology or human or physical resource are you investing in that supports your method of sale (channel that is inward directed)? Note that a key human resource serving as a channel will generally be a key stakeholder. (Max 10 channels/10 lines)"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <img src={InfoIcon} className={classes.infoIcon}></img>
                          </Tooltip>
                          <h3 className={classes.boldBody}>Key Channels (In / Out)</h3>
                          <ul>
                            {keyChannels.values
                              .filter((item) => item.text !== "")
                              .map((item) => {
                                return <li key={item.id}>{item.text}</li>;
                              })}
                          </ul>
                        </Card>
                      </GridItem>
                    </GridContainer>

                  </GridItem>
                </GridContainer>
                {/* LEARN PLAYBOOK MODAL */}
                <Dialog
                  style={{ height: "95%", marginTop: "40px", marginBottom: "40px" }}
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.learnPlaybookModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("learnPlaybookModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <h3 className={classes.subTitle}>Learn About The Playbook</h3>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <h4 className={classes.subTitle}>What is the Playbook?</h4>
                    <p>
                      1. Discovering and embracing your Purpose (Purpose
                      Embrace) 2. Designing a business model that will fulfill
                      on the Purpose (Strategy Aligned) 3. Identifying and
                      engaging your key stakeholders, the stakeholder
                      community accountable for and supporting the fulfillment
                      of that Purpose. (Stakeholders Engaged).
                      <br />
                      As you proceed on this path, you will use the Playbook
                      as a snapshot of what you are committed to and the
                      underpinnings and structure of the business you will use
                      to fulfill that commitment in the fourth area of
                      Purposeful Leadership: Actions Aligned.
                      <br />
                      The Playbook is designed as a one-page document you can
                      refer to daily. Your playbook will always be a work in
                      progress, constantly changing to meet the needs of an
                      ever changing stakeholder world.
                    </p>
                    <h4 className={classes.subTitle}>
                      Each of the 20 boxes or elements is listed below by
                      area:
                    </h4>
                    <p>
                      Purpose Embraced (5 elements): Purpose Origin, Purpose
                      Declaration Purpose Tagline, Purpose Envisioned, and
                      Purpose Metrics
                      <br />
                      Strategy Aligned (14 elements): Business Model Description, Values, Accountability
                      Structure, Health Metrics, Target market, Revenue
                      Streams, Customer Relationships, Value Proposition,
                      Channels (In/out), Key Competencies, Key Resources, Key
                      Partnerships, Key Activities, and Cost Structure
                      <br />
                      Stakeholders Engaged (1 element): Stakeholder Community
                    </p>
                    <h4 className={classes.subTitle}>How to Use Your Playbook</h4>
                    <p>
                      1. As you determine each element, enter the result into
                      your Playbook.
                      <br />
                      2. Notice that your business name is already on the Playbook based upon what you recorded in your Profile.
                      <br />
                      3. Also notice that each box or element has a small arrow in the upper right-hand corner.  To edit the element, just click on that arrow.  Once you have made your changes in edit mode, just click on “submit” to save the changes to your private data base.
                      <br />
                      4. If you hover over the element name, a descriptor will pop up to remind you what type of information should be entered in that particular element.
                      <br />
                      5. To keep it to one page, use simple phrases and keep your entries at a high level.
                      <br />
                      6. If there are a list of items, arrange them in order of priority, with the most important first.
                      <br />
                      7. During the process of mastering Purposeful Leadership, it is common that your entries will change.
                      <br />
                      8. Once you have completed all of the elements, it is very useful to view them together to see how all of the pieces fit together.  Since they are interrelated, you will find it much easier to notice inconsistencies this way.
                      <br />
                      9. You will want to refer to your Playbook daily or weekly.  It should be part of every planning or implementation document.  You may want to keep it open on your desktop or print it out for easy reference.
                      <br />
                      10.You will also want to use it as an orientation or onboarding tool.
                      <br />
                      11. It should be built into your decision-making process and constantly referred to; and its review should precede every planning session to insure its validity and relevance.
                      <br />
                      12. To get the most out of this tool, have your PL Consultant guide you through the process.
                    </p>

                  </DialogContent>
                </Dialog>

                {/* PURPOSE DECLARATION MODAL */}
                <Dialog
                  className={classes.modalContainer}
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.purposeDeclarationModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() =>
                        this.handleClose("purposeDeclarationModal")
                      }
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Purpose Declaration: A statement of an organization’s commitment or promise to its Stakeholder World of the difference it wants to make in that world. Generally begins: “We are committed to...” This declaration should be meaningful and important to the organization’s stakeholder world. It should provide the overriding context for everything the organization does: its Purpose. (Max of 18 words/3 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "45px" }}>Declare Your Purpose</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >

                    <PurposeDeclarationForm playbook={this.state.playbook} />
                  </DialogContent>
                </Dialog>

                {/* PURPOSE ORIGIN MODAL */}
                <Dialog
                  className={classes.modalContainer}
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.purposeOriginModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("purposeOriginModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Purpose Origin: A statement of an organization’s commitment or promise to its Stakeholder World of the difference it wants to make in that world. Generally begins: “We are committed to...” This declaration should be meaningful and important to the organization’s stakeholder world. It should provide the overriding context for everything the organization does: its Purpose. (Max of 30 words)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "45px" }}>Declare Your Purpose Origin</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <PurposeOriginForm
                      condition={this.props.user.purposeOriginCondition} problem={this.props.user.purposeOriginProblem} solution={this.props.user.purposeOriginSolution} playbook={this.state.playbook} />
                  </DialogContent>
                </Dialog>

                {/* TAGLINE MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.taglineModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("taglineModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Tagline: A statement of an organization’s commitment or promise to its Stakeholder World of the difference it wants to make in that world. Generally begins: “We are committed to...” This declaration should be meaningful and important to the organization’s stakeholder world. It should provide the overriding context for everything the organization does: its Purpose. (Max of 10 words)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "45px" }}>Declare Your Tagline</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <TaglineForm playbook={this.state.playbook} />
                  </DialogContent>
                </Dialog>

                {/* PURPOSE ENVISIONED MODAL */}
                <Dialog
                  fullScreen
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={purposeEnvisioned.dialogOpen}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() =>
                    closePlaybookListDialog(authUser.uid, "purposeEnvisioned")
                  }
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() =>
                        closePlaybookListDialog(
                          authUser.uid,
                          "purposeEnvisioned"
                        )
                      }
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Purpose Envisioned: A list of bullet points describing what its stakeholder world would look like when its Purpose is fulfilled. What results would show up in that world that are not there now? (Max of 8 bullet points/ 8 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle}>Envision Purpose</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <PurposeEnvisionedForm />
                  </DialogContent>
                </Dialog>

                {/* ACCOUNTABILITY STRUCTURE MODAL */}
                <Dialog
                  fullScreen
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.accountabilityStructureModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() =>
                        this.handleClose("accountabilityStructureModal")
                      }
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Accountability Structure: Consider major functions or roles in the organization and those people who are accountable for them. Please limit primary roles to five and sub-roles under each primary role to three. (Max of 14 roles/14 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "25px" }}>Decide On An Accountability Structure</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <AccountabilityStructureForm
                      accountabilityStructure={this.state.accountabilityStructure}
                      model={user.accountabilityStructureModel}
                    />
                  </DialogContent>
                </Dialog>
                {/* STAKEHOLDER COMMUNITY MODAL */}
                <Dialog
                  fullScreen
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.props.stakeholderCommunityForm}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => this.handleClose("stakeholderCommunityModal")}
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => {
                        this.handleClose("stakeholderCommunityModal")
                        this.props.setForm(false)
                      }
                      }
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Stakeholder Community: A list of the key stakeholders that are critical for the organization to achieve its Purpose; that is for the organization to be successful. A key stakeholder can be an individual, a group of individuals, another organization or a group of organizations. They can be individuals or organizations. A key stakeholder will appear in one or more of the other elements of the Playbook. (Max of 10 stakeholders/10 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} >Who Is Your Stakeholder Community</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <StakeholderCommunityForm />
                  </DialogContent>
                </Dialog>

                {/* CORE VALUES MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.coreValuesModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("coreValuesModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Core Values: Valued rules of behavior that are non-negotiable—that is, they must be adhered to or you cannot remain with the organization. Core values are already present in the organization, even if they have not been distinguished. (Max of 8 values/8 lines)... Goal Values: Valued rules of behavior that an organization aspires to but may not have been already present in the organization. Rules of behavior considered to be critical to fulfill on the organization’s Purpose. All Purposeful Organizations must have present either as a core or goal value, the following two values: (1) transparency/open and (2) Minimize harm/mindful (Max of 6 values/6 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "25px" }}>Core Values And Goals</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <CombinedValuesGoalsForm uid={this.props.authUser.uid} />
                  </DialogContent>
                </Dialog>
                {/* PURPOSE METRICS MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.purposeMetricsModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("purposeMetricsModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Purpose Metrics: A list of Measures that would reflect the envisioned results of a Purpose fulfilled as stated under Vision (Max of 4 measures/4 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "25px" }}>What are your purpose metrics?</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <PurposeMetricsForm />
                  </DialogContent>
                </Dialog>

                {/* HEALTH METRICS MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.healthMetricsModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("healthMetricsModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Health Metrics: Measures or indicators that reflect the fiscal health of the organization as it relates to business model performance and Purpose fulfillment. These measures should be leading indicators to provide ample time to make any course corrections. (Max of 4 metrics/4 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "25px" }}>What are your health metrics?</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <HealthMetricsForm />
                  </DialogContent>
                </Dialog>

                {/* BUSINESS DESCRIPTION MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.businessDescriptionModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() =>
                        this.handleClose("businessDescriptionModal")
                      }
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="A short description that describes the type of business model you are using to fulfill your Purpose. (Max of 10 words/1 line)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "45px" }}>Declare your Business Description</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <BusinessDescriptionForm playbook={this.state.playbook} />
                  </DialogContent>
                </Dialog>

                {/* TARGET MARKET MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.targetMarketModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("targetMarketModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Target Market: Any stakeholder group that is your business model’s primary customer. List your major types of customers. When determining who your customer is consider these questions: Do they buy the product/service directly from you indirectly through a channel? What group is your brand promise directed to? Do they have the characteristics of an ideal customer? (Max of 5 markets/5 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle}>Where is your target market?</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <TargetMarketForm />
                  </DialogContent>
                </Dialog>
                {/* VALUE PROPOSITION MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.valuePropositionModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("valuePropositionModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Customer Value Proposition: The value/benefits the organization promises to consistently deliver its customers. Try to identify benefits that are measurable. (Max of 6 benefits/6 lines)... Purpose Beneficiary Value Proposition: The value/benefits the organization promises to consistently deliver its customers. Try to identify benefits that are measurable. (Max of 6 benefits/6 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle}>
                        What is your Customer value proposition?
                      </h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <CombinedCustomerStaffValuePropositionForm />
                  </DialogContent>
                </Dialog>
                {/* KEY RESOURCES MODAL */}
                <Dialog
                  fullScreen
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.keyResourcesModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("keyResourcesModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Key Resources: Those resources (human, intangible or physical) that will be critical for business model success. The source of these resources may also be key stakeholders (Max of 9 resources/9 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle}>Recall Your Key Resources</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <KeyResourcesForm />
                  </DialogContent>
                </Dialog>
                {/* CUSTOMER RELATIONSHIPS MODAL */}
                <Dialog
                  fullScreen
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.customerRelationshipsModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() =>
                        this.handleClose("customerRelationshipsModal")
                      }
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Customer Relationships: How does the organization relate to its customers? What people or technology interact with your customer in acquiring or supporting your customer? Consider your method of sale: how do you acquire your customer? Consider your method of retention: how do you retain your customer? (Max 9 relationships/9 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle}>List Your Customer Relationships.</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <CustomerRelationshipsForm />
                  </DialogContent>
                </Dialog>
                {/* KEY COMPETENCIES MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.keyCompetenciesModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("keyCompetenciesModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Key Competencies: The four competencies the organization will need to have its business model perform at the level needed to fulfill its Purpose. These four competencies will be identified and prioritized through an extensive discovery process. They represent the organization’s “secret sauce” for success. (Must of 4 competencies/8 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle}>What are your key competencies?</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <KeyCompetenciesForm />
                  </DialogContent>
                </Dialog>
                {/* KEY PARTNERSHIPS MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.keyPartnershipsModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("keyPartnershipsModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Key Partnerships: Key stakeholders that have a more formal and long term relationship with the organization (written or unwritten) (Max of 8 partnerships/8 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle}>Who are your key partners?</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <KeyPartnershipsForm />
                    {/* <Button
                      color="danger"
                      style={{
                        marginLeft: "10px",
                        marginTop: "10px"
                      }}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("learnDashboardModal")}
                    >
                      SAVE
                  </Button> */}
                  </DialogContent>
                </Dialog>
                {/* REVENUE STREAMS MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.revenueStreamsModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("revenueStreamsModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Revenue Streams: How do you monetize your service offerings? What are the different type of sales/revenue opportunities (current or potential)? (Max 5 revenue streams/5 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle}>List your revenue streams.</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <RevenueStreamsForm />
                  </DialogContent>
                </Dialog>
                {/* COST STRUCTURE MODAL */}
                <Dialog
                  fullScreen
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.costStructureModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("costStructureModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Cost Structure: List the major activities of your business model as they relate to your key customer relationships, channels, competencies, delivery of value proposition, resources, and partnerships. (Max of 8 costs/8 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle}>What is your cost structure like?</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <CostStructureForm />
                  </DialogContent>
                </Dialog>
                {/* KEY ACTIVITIES MODAL */}
                <Dialog
                  fullScreen
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.keyActivitiesModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("keyActivitiesModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="Key Activities: List the major activities of your business model as they relate to your key customer relationships, channels, competencies, delivery of value proposition, resources, and partnerships. (Max of 10 activities/10 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "25px" }}>What are your key activities?</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <KeyActivitiesForm />
                  </DialogContent>
                </Dialog>
                {/* KEY CHANNELS MODAL */}
                <Dialog
                  fullScreen
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge,
                  }}
                  open={this.state.keyChannelsModal}
                  TransitionComponent={Transition}
                  keepMounted
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("keyChannelsModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <Tooltip
                      id="tooltip-top"
                      title="What technology or human or physical resource are you investing in to deliver your value proposition to your customer or Purpose beneficiary (channel that is outward directed)? What technology or human or physical resource are you investing in that supports your method of sale (channel that is inward directed)? Note that a key human resource serving as a channel will generally be a key stakeholder. (Max 10 channels/10 lines)"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <h3 className={classes.subTitle} style={{ marginLeft: "25px" }}>List Your Key Channels (In / Out)</h3>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <KeyChannelsForm />
                  </DialogContent>
                </Dialog>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.easeInOut}>
            <Hidden smDown implementation="css">
              <div
                className={classes.preloaderDesktop}
                style={{
                  background: "white",
                  position: "absolute",
                  width: "110%",
                  margin: "-25% 0% 0% -8%",
                  height: "180%",
                  zIndex: "99999",
                }}
              >
                <img
                  style={{
                    margin: "30% 0% 0% 33%",
                    position: "relative",
                    width: "25%",
                  }}
                  src={Logo}
                  alt="..."
                />
                <img
                  style={{
                    margin: "2% 0% 0% 38%",
                    position: "relative",
                    width: "20%",
                  }}
                  src={Loader}
                  alt="..."
                />
              </div>
            </Hidden>
            <Hidden mdUp implementation="css">
              <div
                className={classes.preloaderMobile}
                style={{
                  background: "white",
                  position: "absolute",
                  width: "110%",
                  margin: "-30% 0% 0% -10%",
                  height: "110%",
                  zIndex: "9999",
                }}
              >
                <img
                  style={{
                    margin: "30% 0% 0% 20%",
                    position: "relative",
                    width: "50%",
                  }}
                  src={Logo}
                  alt="..."
                />
                <img
                  style={{
                    margin: "15% 0% 0% 38%",
                    position: "relative",
                    width: "20%",
                  }}
                  src={Loader}
                  alt="..."
                />
              </div>
            </Hidden>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    practices: state.practices.practices,
    purposeEnvisioned: state.playbookList.purposeEnvisioned,
    firstLoadMembers: state.playbookList.firstLoadMembers,
    members: state.playbookList.members,
    purposeMetrics: state.playbookList.purposeMetrics,
    healthMetrics: state.playbookList.healthMetrics,
    targetMarkets: state.playbookList.targetMarkets,
    keyResources: state.playbookList.keyResources,
    customerRelationships: state.playbookList.customerRelationships,
    keyCompetencies: state.playbookList.keyCompetencies,
    revenueStreams: state.playbookList.revenueStreams,
    costStructures: state.playbookList.costStructures,
    keyActivities: state.playbookList.keyActivities,
    keyChannels: state.playbookList.keyChannels,
    keyPartnerships: state.playbookList.keyPartnerships,
    coreValues: state.playbookList.coreValues,
    coreGoals: state.playbookList.coreGoals,
    customerValues: state.playbookList.customerValues,
    staffValues: state.playbookList.staffValues,
    coreValuesGoals: state.playbookList.coreValuesGoals,
    customerStaffValues: state.playbookList.customerStaffValues,
    tagline: state.playbookList.tagline,
    businessDescription: state.playbookList.businessDescription,
    accountabilityStructure: state.playbookList.accountabilityStructure,
    stakeholderCommunityForm: state.playbookList.stakeholderCommunityForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSetPracticesList: dispatch(startSetPracticesList()), // JE says: So, so bad... must... not... start... refactoring...
    openPlaybookListDialog: (name) => dispatch(openDialog(name)),
    closePlaybookListDialog: (uid, name) =>
      dispatch(startLoadList(uid, name)).then(() =>
        dispatch(closeDialog(name))
      ),
    loadPlaybookListData: (uid) => dispatch(startLoad(uid)),
    setForm: (boolean) => dispatch(setCommunityStakeholderForm(boolean)),
    resetStateFromPlaybook: () => dispatch(resetStateFromPlaybook())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Playbook);
