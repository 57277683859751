import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import {
  setList,
  setListItem,
  startSaveList,
  submit,
} from "store/actions/playbookListActions.jsx";
import DraggableCardContainer from "components/Draggable/DraggableCardContainer.jsx";
import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

function CardContent({ card, setCard }) {
  return (
    <GridItem xs={11}>
      <CustomInput
        labelText="Beneficiary Value"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          value: card ? card.text : "",
          required: false,
          type: "text",
          onChange: (event) => setCard({ text: event.target.value }),
        }}
      />
    </GridItem>
  );
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  render() {
    const { classes, cards, setCards, setCard, submit, uid } = this.props;
console.log(cards)
    return (
      <div className={classes.formContainer}>
        <GridContainer justify="center">
        <DraggableCardContainer
            cards={cards}
            setCards={setCards}
            setCard={setCard}
            renderContent={CardContent}
          />
        </GridContainer>
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  console.log(state)
  return {
    user: state.profile.user,
    cards: state.playbookList.staffValues.values,
    uid: state.auth.user.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCards: (list) => dispatch(setList("staffValues", list)),
    setCard: (index, item) => dispatch(setListItem("staffValues", index, item)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Form);
