import React from "react";

  // Orders Data
  const glossary = {
    columns: [        
      {
        expander: true,
        Header: () => <strong>More</strong>,
        width: 65,
        Expander: ({ isExpanded, ...rest }) =>
          <div>
            {isExpanded
              ? <span>&#x25bc;</span>
              : <span>&#x25b6;</span>}
          </div>,
        style: {
          cursor: "pointer",
          fontSize: 20,
          padding: "0",
          textAlign: "center",
          userSelect: "none",
          color: "#4ec7bc"
        },
      },
      {
        Header: "TERM",
        accessor: "term"      
      },
      {
        Header: "PRACTICE",
        accessor: "practice"      
      },
      {
        Header: "CATEGORY",
        accessor: "category"      
      },
      {
        Header: "TOPIC",
        accessor: "topic"      
      },
      {
        Header: "LINK",
        accessor: "link"
      }
    ],  
    dataLoaded: false
  };
  
  export const initState = {
    glossary
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_GLOSSARY_LIST':
        console.log('SET_GLOSSARY_LIST')     
        return {
          ...state,
          glossary: {
            ...state.glossary,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
      default:
        return state
    }
  }
  