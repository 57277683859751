const metricsCarouselStyle = {
  carousel: {
    margin: "50px 0px"
  },
cardStyle: {
  margin: "0px 50px 50px 50px",
  width: "80% !important"
},
cardDiv: {
  height: "412px",
  boxShadow: "0 7px 13px 0 rgb(0 0 0 / 14%)"
},
cardTitle: {
  textAlign: "center",
  margin: '0px'
},
cardInputDiv: {
  display: "flex",
  flexDirection: "column",
  width: "93%",
  marginLeft: "20px",
  marginTop: "30px"
},
flexSpaceEvenly: {
  display: "flex",
  justifyContent: "space-evenly"
},
targetInputs: {
  width: "45%",
  paddingTop: "10px"
},


}


export default metricsCarouselStyle