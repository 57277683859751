/*eslint-disable*/
import React, { Component } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { withRouter } from 'react-router-dom';

// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/FFPO-LOGO-NEW.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import EmailForm from "../../components/Forms/EmailForm";

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import ReferralForm from "components/Forms/ReferralForm"
const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

let consultantArr = ["Not Assigned"]

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updatePhotoModal: false,
      open: false
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    console.log("modal modal", modal)
    x[modal] = true;
    this.setState({
      ...x,
    });
  }
  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {

    this.setState({
      dataLoaded: true
    })
  }

  render() {
    const { AuthBool, classes, user, assessments } = this.props;



    let profilePhoto = DefaultProfile
    if (AuthBool) { // fixes freeze on logout
      if (user.dataLoaded) {
        user.profileURL ? (
          profilePhoto = user.profileURL
        ) : (
          // add link to profile photo to firestore
          profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
          // add profile photo to firebase storage
          // update redux with link
        )
      }
    }

    //console.log(user)
    //console.log(assessments.dataLoaded)
    if (user.dataLoaded && assessments.dataLoaded
      && !this.state.dataLoaded) {
      this.loadData()
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          db.collection('consultants')
            .onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                consultantArr.push(doc.data().name)
              });
            });
        }
      })
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {this.state.dataLoaded ? (
            <Card style={{ paddingBottom: "20px" }}>
              <GridContainer>
                <GridItem xs={12}>
                  {/* this.state.dataLoaded ?
                    this.props.assessments.assessments.map(assessment => {
                      return <p>{assessment.question1}</p>
                    }) :
                    <p>Loading...</p>
                  */}

                </GridItem>
                {/*<ImageInput/>*/}
                <GridItem xs={6} sm={6} lg={4} className={classes.profilePicWrapper} style={{ zIndex: "99" }}
                >
                  <div onClick={() => this.handleClickOpen("updatePhotoModal")} >
                    <img
                      style={{ height: "25%", width: "150px" }}
                      className={classes.profilePic}
                      src={profilePhoto}
                      alt="User Photo"
                    />
                    <p className={classes.profileBody}><Edit /></p>
                  </div>
                </GridItem>

                <GridItem xs={6} sm={6} lg={4} style={{ marginTop: "15px" }}>
                  <h4 className={classes.subTitle}>PURPOSE DECLARATION:</h4>
                  {this.props.user.purposeDeclaration ? <p className={classes.body}>{this.props.user.purposeDeclaration}</p> : <p className={classes.body}>Click UPDATE to add purpose declaration</p>}
                  <h4 className={classes.subTitle}>BUSINESS DESCRIPTION:</h4>
                  {this.props.user.businessDescription ? <p className={classes.body}>{this.props.user.businessDescription}</p> : <p className={classes.body}>Click UPDATE to add your business description</p>}
                  <h4 className={classes.subTitle}>TAGLINE:</h4>
                  {this.props.user.tagline ? <p className={classes.body}>{this.props.user.tagline}</p> : <p className={classes.body}>Update in the Playbook under Tools to add your tagline</p>}
                  <h4 className={classes.subTitle}>NAME:</h4>
                  {this.props.user.firstName ? <p className={classes.body}>{this.props.user.firstName} {this.props.user.lastName ? this.props.user.lastName : null}</p> : <p className={classes.body}>Click UPDATE to add your name</p>}
                  <h4 className={classes.subTitle}>COMPANY NAME:</h4>
                  {this.props.user.companyName ? <p className={classes.body}>{this.props.user.companyName}</p> : <p className={classes.body}>Click UPDATE to add company name</p>}
                  <h4 className={classes.subTitle}>EMAIL:</h4>
                  <p className={classes.body}>{this.props.user.email}</p>
                </GridItem>
                <GridItem xs={12} sm={6} lg={4} style={{ marginTop: "15px" }}>

                  {/* <h4 className={classes.subTitle}>BUSINESS OR SERVICE TYPE:</h4>
                  {this.props.user.businessType ? <p className={classes.body}>{this.props.user.businessType}</p> : <p className={classes.body}>Click UPDATE to add business or service type</p>}
                 
                 <h4 className={classes.subTitle}>NUMBER OF EMPLOYEES:</h4>
                  {this.props.user.employeeCount ? <p className={classes.body}>{this.props.user.employeeCount}</p> : <p className={classes.body}>Click UPDATE to add your number of employees</p>} */}

                  {/* <h4 className={classes.subTitle}>BIO:</h4>
                  {this.props.user.biography ? <p className={classes.body}>{this.props.user.biography}</p> : <p className={classes.body}>Click UPDATE to add bio </p>} */}
                  <h4 className={classes.subTitle}>PHONE:</h4>
                  {this.props.user.phone ? <p className={classes.body}>{this.props.user.phone}</p> : <p className={classes.body}>Click UPDATE to add phone number</p>}


                  <h4 className={classes.subTitle}>SUBSCRIBER TYPE DESIGNATION:</h4>
                  {
                    this.props.user.typeValue ? (
                      <p className={classes.body}>{this.props.user.typeValue}</p>
                    ) : (
                      <p className={classes.body}>click UPDATE to add a TYPE for yourself</p>
                    )
                  }
                  {this.props.user.typeValue === "User" ?
                    <>
                      <h4 className={classes.subTitle}>CERTIFIED PL CONSULTANT:</h4>
                      <p className={classes.body}>{this.props.user.consultantValue}</p>
                    </>
                    : null}
                  <h4 className={classes.subTitle}>SIGNED DOCUMENTS:</h4>
                  {
                    this.props.user.termGroups.length > 0 && this.props.user.typeValue === "User" ?

                      this.props.user.termGroups.filter(group => group != "Pioneer Commitment" && group != "Professional Partner Terms" && group != "Consulting Partner Terms" &&
                        group != "Terms and Conditions for Certified PL Consultant" && group != "Certified PL Consultant Terms")
                        .map(filter =>
                          <p>{filter}</p>
                        )
                      :
                      this.props.user.termGroups.length > 0 && this.props.user.typeValue === "Certified PL Consultant" ?

                        this.props.user.termGroups.filter(group => group != "Pioneer Commitment" && group != "Professional Partner Terms" && group != "Consulting Partner Terms" &&
                          group != "Terms and Conditions for Certified PL Consultant" && group != "Certified PL Consultant Terms")
                          .map(filter =>
                            <p>{filter}</p>
                          )
                        :
                        <p className={classes.body}>click UPDATE to sign documents</p>
                  }

                  <div style={{ display: "flex" }}>
                    <Button
                      className={classes.button}
                      onClick={() => this.handleClickOpen("updateProfileModal")}
                      style={{ width: "150px", }}
                    >
                      UPDATE PROFILE
                    </Button>

                    <Button
                      className={classes.button}
                      style={{ width: "150px", marginLeft: "10px" }}
                      onClick={() => this.setState({
                        open: true
                      })}
                    >
                      Change Email ONLY
                    </Button>
                  </div>
                </GridItem>
                {/* <GridItem xs={12} style={{ justifyContent: "center" }}>
                  <center>
                    <Card style={{ justifyContent: "center", width: "50%", display: "flex" }}>
                      <GridItem xs={12}>
                        <ReferralForm />
                      </GridItem>
                    </Card>
                  </center>
                </GridItem> */}
                {/* Update Email Modal */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalMedium
                  }}
                  open={this.state.open}
                  TransitionComponent={Transition}
                  // keepMounted
                  onClose={() => this.setState({
                    open: false
                  })}
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description">
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.setState({
                        open: false
                      })}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <h3 className={classes.modalTitle} style={{ marginLeft: "20px" }}>Change Email</h3>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    {AuthBool ? (
                      <EmailForm
                        user={this.props.user}
                        uid={this.props.authUser.uid}
                        modalOpen={this.state.open}
                      />
                    ) : null}

                  </DialogContent>
                </Dialog>


                {/* UPDATE PROFILE MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge
                  }}
                  open={this.state.updateProfileModal}
                  TransitionComponent={Transition}
                  // keepMounted
                  onClose={() => this.handleClose("updateProfileModal")}
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("updateProfileModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <h2 className={classes.modalTitle}>EDIT PROFILE</h2>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >
                    {/* FORM */}
                    {AuthBool ? (
                      <ProfileForm
                        user={this.props.user}
                        uid={this.props.authUser.uid}
                        modalOpen={this.state.updateProfileModal}
                        consultantValue={this.props.user.consultantValue}
                        consultantArr={consultantArr}
                      />
                    ) : null}
                    {/* <Button
                    color="danger"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px"
                    }}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.handleClose("updateProfileModal")}
                  >
                    CLOSE
                  </Button> */}
                  </DialogContent>
                </Dialog>

                {/* UPDATE PROFILE PHOTO MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLarge
                  }}
                  open={this.state.updatePhotoModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => this.handleClose("updatePhotoModal")}
                  aria-labelledby="shipment-modal-slide-title"
                  aria-describedby="shipment-modal-slide-description"
                >
                  <DialogTitle
                    id="shipment-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      simple
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      onClick={() => this.handleClose("updatePhotoModal")}
                    >
                      {" "}
                      <Close className={classes.modalClose} />
                    </Button>
                    <h2 className={classes.modalTitle}>UPDATE PROFILE PHOTO</h2>
                  </DialogTitle>
                  <DialogContent
                    id="shipment-modal-slide-description"
                    className={classes.modalBody}
                  >

                    {/* FORM */}
                    <ProfilePicUpload
                      currentPic={profilePhoto}
                      username={this.props.user.username}
                      uid={this.props.authUser.uid}
                    />
                    <div
                      style={{
                        textAlign: "center"
                      }}
                    >
                      <Button
                        color="danger"
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("updatePhotoModal")}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>
              </GridContainer>
            </Card>
          ) : (
            <div className={classes.easeInOut}>
              <Hidden smDown implementation="css">
                <div className={classes.preloaderDesktop}
                  style={{
                    background: "white",
                    position: "absolute",
                    width: "110%",
                    margin: "-25% 0% 0% -28%",
                    height: "180%",
                    zIndex: "99999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 33%",
                      position: "relative",
                      width: "25%"
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "2% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
              <Hidden mdUp implementation="css">
                <div className={classes.preloaderMobile}
                  style={{
                    background: "white",
                    position: "absolute",
                    width: "110%",
                    margin: "-30% 0% 0% -10%",
                    height: "110%",
                    zIndex: "9999",
                  }}
                >
                  <img
                    style={{
                      margin: "30% 0% 0% 20%",
                      position: "relative",
                      width: "50%"
                    }}
                    src={Logo}
                    alt="..."
                  />
                  <img
                    style={{
                      margin: "15% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
            </div>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Orders);
