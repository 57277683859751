// /*eslint-disable*/

import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Select from "@material-ui/core/Select";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from "@material-ui/core/MenuItem";
import { setList, setListItem, startSaveList, submit } from "store/actions/playbookListActions.jsx";
import DraggableCardContainer from "components/Draggable/DraggableCardContainer.jsx";
import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";


class Form extends React.Component {

    constructor(props) {
        super(props);
        console.log('propz', props)
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden",
            coreDepartmentBehaviors: [],
            // updatedBehavior: "",
            // updatedValue: "",
            // updatedDepartment: ""
        };
    }

    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    updateBehavior = (event) => {
        event.preventDefault()
        this.setState({
            updatedBehavior: event.target.value
        })
    }

    submitCoreDepartmentBehavior = async (uid, name, list) => {
        let previousObj = {
            behavior: this.props.behavior,
            coreGoalValue: this.props.selectedGoal,
            department: this.props.selectedDepartment,
        }
        let updatedArray = this.props.coreDepartmentBehaviors.filter((el) => {
            return el.behavior != previousObj.behavior || el.coreGoalValue != previousObj.coreGoalValue || el.department != previousObj.department
        })
        // console.log('previousObj', previousObj)
        // console.log(this.props.coreDepartmentBehaviors)
        // console.log('updated array', updatedArray)
        updatedArray.push(list)
        // console.log('after', updatedArray)
        const coreDepartmentBehaviorRef = db.collection('TEST-users').doc(uid).collection('lists').doc(name)
        const res = await coreDepartmentBehaviorRef.set({
            values: updatedArray
        }, { merge: true }).then(() => this.setState({
            submitted: true
        }))
    }

    CardContent = ({ card, setCard }) => {
        const { selectedGoal, selectedDepartment } = this.props;
        let filteredCard = card.coreGoalValue === selectedGoal && card.department === selectedDepartment;
        console.log('selected goal', selectedGoal)
        console.log('current card', card)
        console.log('filtered card', filteredCard)
        return (
            <GridItem xs={11}>
                {filteredCard ?
                    <CustomInput
                        labelText="What behavior would you like to add to this Value and Department?"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: card ? card.behavior : '',
                            required: false,
                            type: "text",
                            onChange: (event) => setCard({ behavior: event.target.value }),
                        }}
                    />
                    :
                    null
                }
            </GridItem>
        );
    }

    render() {
        const { classes, cards, setCards, setCard, submit, submitted, uid, authUser, departments, coreGoalsValues, selectedGoal, selectedDepartment, behavior } = this.props;

        const resetRequest = () => {
            window.location.reload();
        }

        return (
            <div>
                {this.state.submitted ? (
                    <GridContainer justify="center">
                        <GridItem xs={11}>
                            {resetRequest()}
                            <h4 className={classes.textPrimary}>Updates Set!</h4>
                        </GridItem>
                    </GridContainer>
                ) : (
                    <div className={classes.formContainer}>
                        {console.log('behavior for editor', this.props)}
                        <GridContainer justify="center">
                            <GridContainer>
                                <GridItem xs={2}>
                                    <h6 style={{ color: "#50c6bc", fontWeight: "600" }}>CORE/GOAL VALUES</h6>
                                </GridItem>
                                <GridItem xs={3}>
                                    <FormControl
                                        // disabled
                                        className={classes.formControl}>
                                        <Select
                                            // disableUn
                                            MenuProps={{
                                                className: classes.selectMenu
                                            }}
                                            classes={{
                                                formControl: classes.addUserInput
                                            }}
                                            onChange={(event) => {
                                                this.setState({
                                                    updatedValue: event.target.value
                                                })
                                            }}
                                            value={this.state.updatedValue ? this.state.updatedValue : selectedGoal}
                                        >
                                            {coreGoalsValues.map((value, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelectedMultiple
                                                        }}
                                                        value={value}
                                                    >
                                                        {value}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={2}>
                                    <h6 style={{ color: "#50c6bc", fontWeight: "600" }}>DEPARTMENT</h6>
                                </GridItem>
                                <GridItem xs={3}>
                                    <FormControl
                                        // disabled
                                        className={classes.formControl}>
                                        <Select
                                            // disableUn
                                            MenuProps={{
                                                className: classes.selectMenu
                                            }}
                                            classes={{
                                                formControl: classes.addUserInput
                                            }}
                                            value={this.state.updatedDepartment ? this.state.updatedDepartment : selectedDepartment}
                                            onChange={(event) => {
                                                this.setState({
                                                    updatedDepartment: event.target.value
                                                })
                                            }}
                                        >
                                            {departments.map((department, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelectedMultiple
                                                        }}
                                                        value={department.text}
                                                    >
                                                        {department.text}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                {/* <GridItem xs={12}>
                                    <DraggableCardContainer
                                        cards={cards}
                                        setCards={setCards} setCard={setCard} renderContent={this.CardContent} />
                                </GridItem> */}
                                <GridItem xs={12} style={{ margin: "20px 0 0 0" }}>
                                    <CustomInput
                                        labelText="What behavior would you like to add to this Core/Goal Value and Department?"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            required: true,
                                            value: this.state.updatedBehavior ? this.state.updatedBehavior : behavior,
                                            type: "text",
                                            onChange: (event) => this.updateBehavior(event)
                                        }}

                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <Button
                                        onClick={() =>
                                            this.submitCoreDepartmentBehavior(authUser.uid, 'coreDepartmentBehaviors', {
                                                coreGoalValue: this.state.updatedValue ? this.state.updatedValue : selectedGoal,
                                                department: this.state.updatedDepartment ? this.state.updatedDepartment : selectedDepartment,
                                                behavior: this.state.updatedBehavior ? this.state.updatedBehavior : behavior
                                            })
                                        }
                                        color="primary"
                                        style={{ display: "block", margin: "0 auto 0 auto" }}
                                    >
                                        Submit
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridContainer>
                    </div>

                )}
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.profile.user,
        cards: state.playbookList.coreDepartmentBehaviors.values,
        submitted: state.playbookList.coreDepartmentBehaviors.submitted,
        uid: state.auth.user.uid
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCards: list => dispatch(setList('coreDepartmentBehaviors', list)),
        setCard: (index, item) => dispatch(setListItem('coreDepartmentBehaviors', index, item)),
        submit: (uid, cards) => dispatch(startSaveList(uid, 'coreDepartmentBehaviors', cards)).then(() => dispatch(submit('departments')))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Form);