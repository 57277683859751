/*eslint-disable*/
import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import firebase, { db, storage } from "firebase/fbConfig.js"

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.currentPic,
      uploadProgress: "",
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        imageChanged: true
      });
    };
    reader.readAsDataURL(file);
  }
  handleSubmit() {
    const pic = this.state.file
    console.log('pic', pic)
    // create storage ref
    var storageRef = storage.ref('profilePics/' + this.props.username + pic.name)
    console.log('storage', this.props.username, pic.name)
    // upload file
    var uploadTask = storageRef.put(pic)
    console.log('uploadTask', uploadTask)
    // update progress bar
    uploadTask.on('state_changed', (snapshot) => {
      console.log('snapshot', snapshot)
      // Observe state change events such as progress, pause, and
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var Progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('progress', Progress)
      this.setState({ uploadProgress: 'Progress is ' + Progress + '% done' });
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
        console.log('snapstate', snapshot.state)
          this.setState({ uploadProgress: ' Upload is paused' });
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
        console.log('snapstate', snapshot.state)
          this.setState({ uploadProgress: 'Upload is running' });
          break;
      }
    }, (error) => {
      // Handle unsuccessful uploads
      console.log('!!!!!!', error)
    }, () => {
      console.log('success')
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.setState({ uploadProgress: 'Upload finished. Updating Firestore' });

        db.collection("TEST-users").doc(this.props.uid).set({
          profileURL: downloadURL
        }, { merge:true }).then(() => {
          window.location.reload()
        })
      });
    })
  }


  render() {
    return (
      <div className="picture-container">
        {       console.log('progressss', this.state.uploadProgress, 'file:', this.state.file)}
        <div className="picture">
          <img
            src={this.state.imagePreviewUrl}
            className="picture-src"
            alt="..."
          />
          <input type="file" onChange={e => this.handleImageChange(e)} />
        </div>

        {this.state.uploadProgress ? (
             <span>{this.state.uploadProgress}</span>
          ) :
          (null)}

        {
          this.state.file ? (
            this.state.uploadProgress ? (
              <p>{this.state.uploadProgress}</p>
            ) : (

                <Button
                  color="primary"
                  onClick={() => this.handleSubmit()}
                >UPDATE</Button>
              )

            ):(

              <h6 className="description">Choose Picture</h6>
        )}
      </div>
    );
  }
}

export default PictureUpload;
