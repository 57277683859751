import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db } from "firebase/fbConfig.js";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Accordion from "components/Accordion/Accordion.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";
import { Grid } from "@material-ui/core";

import ThreadsMap from "./ThreadsMap";
// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx";

// Firebase Redux Integration
import { startSetPendingActionsList } from "store/actions/actionsActions";
import {
  tableToFirestoreObjects,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from "store/actions/firestoreActions";
import { startSetUsersList } from "store/actions/adminActions";
import CreateMessageForm from "../../../components/Forms/CreateMessageForm";

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class Threads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: "",
      username: "",
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  loadData = (id) => {
    let data = db
      .collection("TEST-users")
      .doc(id)
      .get()
      .then(
        (snapshot) => {
          var docData = snapshot.data();
          if (docData) {
            var docObj = {
              name: docData.firstName
                ? docData.firstName + " " + docData.lastName
                : "N/A",
              name: docData.firstName
                ? docData.firstName + " " + docData.lastName
                : "N/A",
            };
            return docObj;
          } else {
            return null;
          }
        },
        (error) => {
          console.log("error fetching data: ", error);
        }
      )
      .then((docObj) => {
        this.setState({
          data: docObj,
          dataLoaded: true,
        });
      });
  };

  refreshNotification = () => {
    // update firestore document with PENDING and comment
    db.collection("TEST-users")
      .doc(this.props.user.uid)
      .set(
        {
          notification: false,
          notificationUpdatedAt: new Date(),
        },
        { merge: true }
      )
      .then(() => {
        console.log(``);
        this.setState({
          notificationRefreshed: true,
        });
      })
      .catch((err) => {
        console.log(`${err}`);
      });
  };

  handleChange = (docID) => {
    console.log(docID);
  };

  render() {
    const { AuthBool, classes, user, id, users } = this.props;
    //console.log(users)
    const searchButton = classes.top + " " + classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded && !this.state.dataLoaded) {
        this.loadData(id);
      }
    }

    if (this.props.user) {
      if (!this.state.notificationRefreshed) {
        this.refreshNotification();
      }
    }

    console.log(user);

    return (
      <div>
        {this.state.dataLoaded ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <ThreadsMap uid={user.uid} id={id} />
              <div style={{ height: "110px" }}></div>
              <CreateMessageForm
                uid={user.uid}
                id={id}
                name={this.state.data.name}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <p className={classes.cardSubtitle}>Loading...</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
    user: state.auth.user,
    id: ownProps.match.params.id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startSetUsersList: dispatch(startSetUsersList()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Threads);
