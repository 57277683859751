import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js"
import { Chart } from 'react-google-charts'
import moment from 'moment'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/practicesStyle.jsx";
import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import { setFirstAssessment } from "store/actions/assessmentActions"
import { startSetUsersList } from "store/actions/adminActions"
// import { startSetUpcomingCoursesList } from "store/actions/coursesActions"
// import UpcomingCoursesChild from "./UpcomingCoursesChild";
import GroupCalls from "./GroupCalls";

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Principles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateProfileModal: false,
      updatePhotoModal: false,
      learnDashboardModal: false
    };
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
      //console.log(this.props)
    let coursesData = this.props.upcomingCourses.dataRows.map((prop, key) => {
      let colObj = { ...prop }
      const volUID = colObj.volUID
      const username = colObj.username
      // Load Data for Summary Cards and add buttons     
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        order: colObj.order,
        actions: (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => this.handleClickOpen("assignActionModal",
                volUID
              )}
          >ASSIGN ACTION</Button>)
      }
    })
 
    this.setState({
      coursesData,
      dataLoaded: true
    })
  }

  // Load data for Table
  // if there are no actions make a default one



  render() {
    const { AuthBool, classes, user, upcomingCourses, assessments } = this.props;
  //console.log(this.state.practicesData)
    let profilePhoto = DefaultProfile

    if (AuthBool) { // fixes freeze on logout
      if (user.dataLoaded) {
        user.profileURL ? (
          profilePhoto = user.profileURL
        ) : (
            // add link to profile photo to firestore
            profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
            // add profile photo to firebase storage        
            // update redux with link
          )
      }
    }
    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
        row[id] !== undefined ?
          String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        :
          true
      );
    }

    let lastAssessmentPurposePercent
    //console.log(user)
    //console.log(assessments.dataLoaded)
    if (upcomingCourses.dataLoaded
      && !this.state.dataLoaded) {
      //console.log("load data")
      this.loadData()
      // console.log(user)
      // console.log(user.levelUpdatedAt.toDate())
      // console.log(moment(user.levelUpdatedAt.toDate()).format('MM-DD-YYYY'))  
    }

    //console.log(assessments.assessments.length)

    return (
      <div>
        {(this.props.user && this.props.AuthBool) &&
        this.props.user.email &&
          <GroupCalls authUser={this.props.authUser} user={this.props.user} />
             
        }
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    upcomingCourses: state.courses.upcomingCourses
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  // console.log(ownProps)
  // console.log(this.state)
    // return {
    //   startSetUpcomingCoursesList: dispatch(startSetUpcomingCoursesList(this.props.user))
    // }
  }
  

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Principles);