import { firebase, googleAuthProvider, db } from '../../firebase/fbConfig'
import { push } from 'connected-react-router'

export const emailSignup = (authObject) => {
  return (dispatch) => {
    firebase.auth().createUserWithEmailAndPassword(authObject.email, authObject.password)
      .then(() => {
        dispatch(push('/actions'))
      })
      .catch((error) => {
        dispatch(signupError(error))
      });
  }
}

export const signupError = (error) => ({
  type: 'SIGNUP_ERROR',
  error
})

export const emailSignIn = (credentials) => {
  return (dispatch) => {
    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch(push('/actions'))
    }).catch((error) => {      
      dispatch(SignError(error))
    })
  }
}

export const SignError = (error) => ({
  type: 'SIGNIN_ERROR',
  error
})

export const loginError = (error) => ({
  type: 'LOGIN_ERROR',
  error
})

export const passwordReset = (emailAddress) => {
  var auth = firebase.auth();
  auth.sendPasswordResetEmail(emailAddress)
    .then(() => {
      console.log("Reset email sent")
    }).catch((error) => {
      console.log('Error: ' + error)
    });
}

export const startGoogleLogin = () => {
  return (dispatch) => {
    return firebase.auth().signInWithPopup(googleAuthProvider).then(() => {
      dispatch(push('/'))
    })
  }
}

export const login = (user = {}) => ({
  type: 'LOGIN',
  user
})

export const startLogout = () => {
  return (dispatch) => {
    return firebase.auth().signOut().then(() => {
      dispatch(push('/'))
    }).then(() => {
      dispatch(logout())
    }).then(() => {
      dispatch(push('/pages/login-page'))
    })
  }
}

export const logout = () => ({ type: 'LOGOUT' })