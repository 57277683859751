import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetGlossaryList = () => {
  return (dispatch) => {
    db.collection("glossary")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()

          var docObj = {
            id: doc.id,
            term: docData.term ? docData.term : "N/A",
            practice: docData.practice ? docData.practice : "N/A",
            definition: docData.definition ? docData.definition : "N/A",
            category: docData.category ? docData.category : "N/A",
            topic: docData.topic ? docData.topic : "N/A",
            link: docData.link ? (
              <Button
                style={{
                  width: "85%",
                  backgroundColor: "#FC662B",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "5px",
                  boxShadow: "4px 4px 0px 0px #e3470a",
                  "&:hover": {
                    backgroundColor: "#FC662B",
                    color: "white",
                    boxShadow: "2px 2px 0px 0px #e3470a"
                  },
                }}
                onClick={
                  () => window.open(docData.link)}
              >DOWNLOAD</Button>
            )
              : "N/A"
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {
        dispatch(setGlossaryList(data))
      })
  }
}

export const setGlossaryList = (dataRows) => ({
  type: 'SET_GLOSSARY_LIST',
  dataRows
})

