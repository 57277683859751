import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js"
import { Redirect } from 'react-router-dom'

// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

import { startSetCoursesCalendarList } from "store/actions/coursesActions"
import { startSetGroupCallsList } from "store/actions/groupCallsActions"

import { events } from "variables/general.jsx";

const localizer = BigCalendar.momentLocalizer(moment);

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: events,
      alert: null
    };
    this.hideAlert = this.hideAlert.bind(this);
  }
  selectedEvent(event) {
    window.location.replace(`/courses/${event.id}`)
    // console.log(event)
    // alert(event.title + " " + event.start);
  }
  addNewEventAlert(slotInfo) {
    this.setState({
      alert: (
        <SweetAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Input something"
          onConfirm={e => this.addNewEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      )
    });
  }
  addNewEvent(e, slotInfo) {
    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    });
    this.setState({
      alert: null,
      events: newEvents
    });
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }
  loadData = (uid) => {

    let data =  db.collection("TEST-users").doc(uid).collection("registeredCourses")
    .get().then((snapshot) => {
      const data = []
      // Parse data into array like in firebase.js
      snapshot.forEach((doc) => {
        var docData = doc.data()
        var docObj = {
          id: doc.id,
          ...docData
        }
        data.push(docObj);
      })
      console.log(data)
      return (data)
    }, (error) => {
            console.log('error fetching data: ', error)
        }).then(data => {
            this.setState({
                data: data,
                dataLoaded: true
            })
        })
    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
}

  render() {
    const { AuthBool, authUser, user, courses, groupCalls } = this.props;
    if (AuthBool) {
      if (!this.state.dataLoaded) {
          this.loadData(authUser.uid)
      }
  }

    var today = new Date();
    var y = today.getFullYear();
    var m = today.getMonth();
    var d = today.getDate();
    console.log(new Date(y, m, 1))

    // console.log(courses.dataRows)
    // console.log(groupCalls.dataRows)

    let allEvents = []

    if (courses.dataRows && groupCalls.dataRows) {
      allEvents = [...courses.dataRows, ...groupCalls.dataRows];
      console.log(allEvents)  
    }

    if (user.termGroups) {
      console.log(user.termGroups.length)
    
      if (user.termGroups.length < 2) {
        return <Redirect to='/profile' />
      }
    }

    console.log(this.state)
    return (
      <div>
        {this.state.alert}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card style={{background: "#fff"}}>
              <CardBody calendar>
                {this.state.data ? 
                <BigCalendar
                selectable
                localizer={localizer}
                events={allEvents}
                defaultView="month"
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={event => this.selectedEvent(event)}
                onSelectSlot={slotInfo => this.addNewEventAlert(slotInfo)}
                eventPropGetter={this.eventColors}
              /> :
              null
                
                }
                
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.data && this.state.data.map(course => {
              
              // <p style={{fontSize: "2rem", color: "black", position: "fixed", top: "0", right: "0", zIndex: "999"}}> hello {course.id}</p>
                //console.log(course.id)
                
        })}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    courses: state.courses.courses,
    groupCalls: state.groupCalls.groupCalls
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSetCoursesList: dispatch(startSetCoursesCalendarList()),
    startSetGroupCallsList: dispatch(startSetGroupCallsList())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(buttonStyle)
)(Calendar);
