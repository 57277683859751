import Actions from "views/Actions/Actions.jsx";
import JobApplication from "views/JobApplication/JobApplication.jsx";
import Brokers from "views/Brokering/Brokers.jsx";
import Orders from "views/Brokering/Orders.jsx";
import Profile from "views/Profile/Profile.jsx";
import PendingActions from "views/Admin/PendingActions.jsx";
import Users from "views/Admin/Users.jsx";
import pagesRoutes from "./pages.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Apps from "@material-ui/icons/Apps";
// import ContentPaste from "@material-ui/icons/ContentPaste";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import TakeAssessment from "../views/Assess/TakeAssessment.jsx";
import Path from "../views/Assess/Path.jsx";
import PurposePath from "../views/Assess/PurposePath.jsx";
import StakeholdersPath from "../views/Assess/StakeholdersPath.jsx";
import StrategyPath from "../views/Assess/StrategyPath.jsx";
import ActionsPath from "../views/Assess/ActionsPath.jsx";
import DashboardFFPO from "../views/DashboardFFPO/DashboardFFPO.jsx";
import CoreBeliefs from "../views/Learn/CoreBeliefs.jsx";
import Principles from "../views/Learn/Principles.jsx";
import Practices from "../views/Learn/Practices.jsx";
import CoachingPrograms from "../views/Grow/CoachingPrograms.jsx";
import ArticlesLibrary from "../views/Grow/ArticlesLibrary.jsx";
import GlossaryLibrary from "../views/Learn/GlossaryLibrary.jsx";
import StudiesLibrary from "../views/Grow/StudiesLibrary.jsx";
import BooksLibrary from "../views/Grow/BooksLibrary.jsx";
import ShareResourceForm from "../components/Forms/ShareResourceForm.jsx";
import ConsultantsDirectory from "../views/Grow/ConsultantsDirectory.jsx";
import ProfessionalsDirectory from "../views/Grow/ProfessionalsDirectory.jsx";
import CourseArchive from "../views/Grow/CourseArchive.jsx";
import UpcomingCourses from "../views/Grow/UpcomingCourses.jsx";
import ShareCourseForm from "../components/Forms/ShareCourseForm.jsx";
import NewsletterSignupForm from "../components/Forms/NewsletterSignupForm.jsx";
import ContactForm from "../components/Forms/ContactForm.jsx";
import Notifications from "../components/Forms/Notifications.jsx";
import Communities from "../views/Connect/Communities.jsx";
import GroupCalls from "../views/Connect/GroupCalls.jsx";
import BecomeInvolved from "../views/Connect/BecomeInvolved.jsx";
import BecomeAdvocate from "../views/Connect/BecomeAdvocate.jsx";
import BecomeChampion from "../views/Connect/BecomeChampion.jsx";
import BecomeMember from "../views/Connect/BecomeMember.jsx";
import BecomePartner from "../views/Connect/BecomePartner.jsx";
import BecomeSponsor from "../views/Connect/BecomeSponsor.jsx";
import WhoWeAre from "../views/About/WhoWeAre.jsx";
import ContactMessages from "../views/Admin/ContactMessages.jsx";
import Feedback from "../views/Admin/Feedback.jsx";
import NewsletterSubscribers from "../views/Admin/NewsletterSubscribers.jsx";
import SharedResources from "../views/Admin/SharedResources.jsx";
import SharedCourses from "../views/Admin/SharedCourses.jsx";
import SharedGlossary from "../views/Admin/SharedGlossary.jsx";
import SharedBlog from "../views/Admin/SharedBlog.jsx";
import Podcasts from "../views/Connect/Podcasts.jsx";
import Blog from "../views/Connect/Blog.jsx";
import Blog1 from "views/Connect/Blog1.jsx"
import Blog6 from "../views/Connect/Blog6.jsx";
import Blog2 from "../views/Connect/Blog2.jsx";
import ComingSoon from "../views/ComingSoon.jsx";
import Calendar from "../views/Calendar/Calendar.jsx";
import BlogID from "../views/Connect/BlogID.jsx";
import MemberDirectory from "../views/About/MemberDirectory.jsx";
import RegisterCourses from "../views/Grow/RegisterCourses.jsx";
import Newsletter from "../views/Connect/Newsletter.jsx";
import Threads from "../views/Chat/Threads/Threads.jsx";
import NewBlogForm from "../components/Forms/NewBlogForm.jsx";
import EventsDetails from "../views/Calendar/EventsDetails.jsx";
import ShareNewsletterForm from "../components/Forms/ShareNewsletterForm.jsx";
import Glossary from "../views/Learn/Glossary.jsx";
import Playbook from "../views/Learn/Playbook.jsx";
import StrategySheet from "../views/Learn/StrategySheet.jsx";
import GroupCallsParent from "../views/Connect/GroupCallsParent.jsx";
import SharedPractices from "../views/Admin/SharedPractices.jsx";
import EngagementWorksheets from "../views/Learn/EngagementWorksheets.jsx";
import Metrics from '../views/Metrics/Metrics.jsx';
import ValueBehaviors from '../views/ValueBehaviors/ValueBehaviors.jsx';
import Disabled from "../views/Disabled/Disabled.jsx";
import Initiative from "../views/ActionPlan/Initiative.jsx"
import InitiativeDetails from "../views/ActionPlan/InitiativeDetails.jsx";

import { Home, Person, School, TrendingUp, Build, ContactPhone, SupervisedUserCircle } from "@material-ui/icons";


export default [
  {
    collapse: true,
    admin: true,
    path: "/admin",
    name: "Admin",
    icon: SupervisedUserCircle,
    views: [
      { path: "/admin/Users", name: "Users", component: Users },
      { path: "/admin/messages", name: "Messages", component: ContactMessages },
      { path: "/admin/feedback", name: "Feedback", component: Feedback },
      { path: "/admin/newsletter", name: "Shared Newsletters", component: NewsletterSubscribers },
      { path: "/admin/shared-glossary", name: "Shared Glossary", component: SharedGlossary },
      { path: "/admin/shared-practices", name: "Shared Practices", component: SharedPractices },
      { path: "/admin/shared-blog", name: "Shared Blog", component: SharedBlog },
      { path: "/admin/shared-resources", name: "Shared Resources", component: SharedResources },
      { path: "/admin/shared-courses", name: "Shared Courses", component: SharedCourses },
    ]
  },
  { path: "/home", name: "Home", component: DashboardFFPO, icon: Home},
  { path: "/profile", name: "Profile", component: Profile, icon: Person },
  { path: "/actions", name: "Actions", admin: true, component: Actions, hide:true },
  // { path: "/notifications", name: "Notifications", component: Notifications,  admin: true,},
  {
    collapse: true,
    path: "/learn",
    name: "Learn",
    icon: School,
    state: "openLearn",
    views: [
      { path: "/learn/core-beliefs-principles", name: "Core Beliefs & Principles", component: CoreBeliefs },
      { path: "/learn/practices", name: "Practices", component: Practices },
      { path: "/learn/archive", name: "Webinar Archive", component: CourseArchive },
      { path: "/learn/glossary", name: "Glossary", component: Glossary },
      { path: "/learn/coaching-programs", name: "Coaching Programs", component: CoachingPrograms },
      // { path: "/learn/principles", name: "Principles", component: Principles },
    ]
  },
  {
    collapse: true,
    path: "/grow",
    name: "Grow",
    icon: TrendingUp,
    state: "openGrow",
    views: [
      { path: "/grow/articles", name: "Articles Library", component: ArticlesLibrary },
      { path: "/grow/studies", name: "Research Studies Library", component: StudiesLibrary },
      { path: "/grow/books", name: "Books Library", component: BooksLibrary },
      
    ]
  },
  {path: "/tools/details/:docID", component: InitiativeDetails, admin:true, hide: true,},
  { path: "/tools/strategy-sheet/:id", name: "Strategy Sheet", hide: true, component: StrategySheet },
  { path: "/assess/path", name: "Path to Purposeful Leadership", hide: true,component: Path },
  { path: "/grow/consultants", name: "Directory of Consultants", hide: true,component: ConsultantsDirectory },
  {
    collapse: true,
    path: "/tools",
    name: "Tools",
    icon: Build,
    state: "openTools",
    views: [
      { path: "/tools/playbook", name: "Playbook", component: Playbook },
      { path: "/learn/sesWorksheets", name: "SES Worksheets", component: EngagementWorksheets },
      { path: "/tools/dashboard", name: "Dashboard", component: Metrics },
      { path: "/tools/value-behaviors", name: "Value Behaviors", component: ValueBehaviors, },
      { path: "/tools/action-plan", name: "Initiatives", component: Initiative, },
    ]
  },

  { path: "/contact", name: "Contact Us", component: ContactForm, icon: ContactPhone },
  {
    disabled: true,
    path: "/disabled",
    component: Disabled,
    admin:true,
    hide:true
  },
];



