import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"
import moment from 'moment'
import { Link } from 'react-router-dom'
import AddToCalendar from 'react-add-to-calendar';
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import loader from "assets/img/loader.gif";
import miniLoading from "assets/img/simple_loading.gif";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Accordion from "components/Accordion/Accordion.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/threadsStyle";
import { Grid } from "@material-ui/core";

// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions'
import { tableToFirestoreObjects, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetUsersList } from "store/actions/adminActions"
import avatar from "assets/img/faces/user.png";
import DefaultProfile from "assets/img/default-avatar.png"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignLawyerModal: false,
      assignActionModal: false,
      volUID: '',
      username: '',
      refresh: false,
      messageSize: null,
      query: db.collection("TEST-users").doc(this.props.uid).collection("threads").doc(this.props.id).collection("messages")
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  //   loadData = (uid, id) => {
  //     let data = db.collection("TEST-users").doc(uid).collection("cases").doc(id)
  //     .get().then((snapshot) => {
  //         const data = []
  //         // Parse data into array like in firebase.js
  //         snapshot.forEach((doc) => {
  //           var docData = doc.data()
  //           console.log(docData)
  //           var docObj = {
  //             id: doc.id,
  //             message: docData.message ? docData.message : "N/A",
  //             uid: docData.uid ? docData.uid : "N/A",
  //             createdTime: docData.createdTime ? docData.createdTime : "N/A"
  //           }
  //           data.push(docObj);
  //         })
  //         return (data)
  //       }, (error) => {
  //         console.log('error fetching data: ', error)
  //       }).then(data => {
  //         this.setState({
  //           data: data,
  //           dataLoaded: true,
  //           refresh: false
  //         })
  //       })

  //console.log(this.state)
  //let relatedConceptsData = this.props.relatedConcepts.dataRows

  // this.setState({
  //   relatedConceptsData,
  //   dataLoaded: true
  // })
  //   }

  // loadData = (uid, id) => {
  //     let casesData = db.collection("TEST-users").doc(uid).collection("cases").doc(id).get().then(doc => {
  //         if (!doc.exists) {
  //           console.log('No such document!');
  //         } else {
  //           console.log('Document data:', doc.data());
  //           return(doc.data())
  //         }
  //       })
  //       .catch(err => {
  //         console.log('Error getting document', err);
  //       });



  //     console.log(casesData)

  //     this.setState({
  //         casesData,
  //         dataLoaded: true
  //     })
  // }
  //     loadData = (uid, id) => {
  //         db.collection("TEST-users").doc(uid).collection("cases").doc(id)
  //             .get().then((snapshot) => {
  //              let data = snapshot.data()
  //           }, (error) => {
  //             console.log('error fetching data: ', error)
  //           }).then(data => {
  //               console.log(data)
  //             this.setState({
  //               data: data,
  //               dataLoaded: true,
  //               refresh: false
  //             })
  //           })
  //    }
  loadData = (uid, id) => {
    console.log("loading data")
    let data = db.collection(window.location.pathname.includes("Course") ? "courses" : "groupCalls").doc(id)
      .get().then((snapshot) => {
        var docData = snapshot.data()
        if (docData) {
          var docObj = {
            id: snapshot.id,
           ...docData
          }
          console.log(docObj)
          return (docObj)
        } else {
          return (null)
        }
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(docObj => {
        this.setState({
          data: docObj,
          event: {
            title: docObj.title,
            description: docObj.description,
            location: docObj.zoom_link,
            startTime: (moment(docObj.start.toDate()).format('YYYY-MM-DD'))+"T"+(moment(docObj.start.toDate()).add(7, 'h').format('HH:mm')),
            endTime: (moment(docObj.end.toDate()).format('YYYY-MM-DD'))+"T"+(moment(docObj.end.toDate()).add(7, 'h').format('HH:mm'))
          },
          dataLoaded: true
        })
      })


    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
  }

  // lawyerLoadData = (uid, id) => {
  //   console.log("lawyer data")
  //   console.log(this.props.user.userType)
  //   if (this.props.user.userType == "Lawyer") {
  //     console.log("loading data")
  //     let data = db.collection("lawyer").doc(uid).collection("events").doc(id)
  //       .get().then((snapshot) => {
  //         var docData = snapshot.data()
  //         if (docData) {
  //           var docObj = {
  //             id: snapshot.id,
  //             ...docData
  //           }
  //           console.log(docObj)
  //           return (docObj)
  //         } else {
  //           return (null)
  //         }
  //       }, (error) => {
  //         console.log('error fetching data: ', error)
  //       }).then(docObj => {
  //           this.setState({
  //             data: docObj,
  //             event: {
  //               title: docObj.title,
  //               description: docObj.description,
  //               location: docObj.zoom,
  //               startTime: (moment(docObj.start.toDate()).format('YYYY-MM-DD'))+"T"+(moment(docObj.start.toDate()).format('HH:SS')),
  //               endTime: (moment(docObj.end.toDate()).format('YYYY-MM-DD'))+"T"+(moment(docObj.end.toDate()).format('HH:SS'))
  //             },
  //             dataLoaded: true
  //           })
  //         })
  //   } else {
  //     console.log("error")

  //   }


  //   //console.log(this.state)
  //   //let relatedConceptsData = this.props.relatedConcepts.dataRows

  //   // this.setState({
  //   //   relatedConceptsData,
  //   //   dataLoaded: true
  //   // })
  // }



  refresh = () => {
    this.setState({
      refresh: true
    })
  }

  changeMessageSize = (size) => {
    this.setState({
      messageSize: size
    })
  }

  handleChange = (docID) => {
    console.log(docID)
  }
  componentWillMount() {
  }

  render() {
    const { AuthBool, classes, currentCase, messages, currentThread, uid, id } = this.props;
    const searchButton =
      classes.top +
      " " +
      classes.searchButton;

    let items = [
      { outlook: 'Outlook'},
      { google: 'Google'},
      { apple: 'Apple Calendar' },
      { yahoo: 'Yahoo!' },
    ]

        if (AuthBool) {
          if (!this.state.dataLoaded) {
            this.loadData(uid, id)
          }
        }

       



    // let observer = this.state.query.onSnapshot(querySnapshot => {
    //   console.log(`Received query snapshot of size ${querySnapshot.size}`);
    //   if(this.state.messageSize === null) {
    //   this.changeMessageSize(querySnapshot.size)
    //   } else if (this.state.messageSize !== querySnapshot.size) {
    //     this.changeMessageSize(querySnapshot.size)
    //     this.loadData(uid, id)
    //   }
    //   // ...
    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });


    // let profile; 

    // if (this.props.users.dataRows !== undefined) {
    //   //let index = props.users.dataRows.indexOf(id);
    //   let obj = this.props.users.dataRows.find(o => o.volUID === id);
    //   console.log(this.props.users.dataRows)
    //   console.log(obj)
    //   profile =  obj.profileURL
    // }

    // console.log(profile)

    // let profilePhoto = DefaultProfile
    // if (AuthBool) {
    //   (user.dataLoaded && user.profileURL) ? (
    //     profilePhoto = user.profileURL
    //   ) : (
    //       // add link to profile photo to firestore
    //       profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
    //       // add profile photo to firebase storage        
    //       // update redux with link
    //     )
    // }

    console.log(this.state)
    console.log(this.props)
    console.log()
    return (
      <div>
        {
          this.state.dataLoaded ? (
            <div>
              <p>Title: {this.state.data.title}</p>
              <p>Description: {this.state.data.description}</p>
              <p>Presenters: {this.state.data.presenters}</p>
              <p>Length: {this.state.data.length}</p>
              <p>{moment(this.state.data.start.toDate()).format("MM/DD/YYYY HH:mm")}</p>
              <p>{moment(this.state.data.end.toDate()).format("MM/DD/YYYY HH:mm")}</p>
              <a href={`${this.state.data.zoom_link && this.state.data.zoom_link}`} target="_blank">
                    <Button
                        style={{ backgroundColor: "#0db2d8" }}
                        onClick={() => console.log("click")}
                      >Join Call</Button></a>  
              <AddToCalendar event={this.state.event}
               listItems={items}
                />
            </div>
          ) : (
              <div style={{backgroundColor: "#F7F4F0", position: "absolute", width: "100%", height: "100%"}}>
                <img
                  style={{margin: "65% 0% 0% 41%", position: "relative", width: "20%"}}
                  src={loader}
                  alt="..."
                />
              </div>            )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
    user: state.profile.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  //   console.log(ownProps)
  return {
    //startSetUsersList: dispatch(startSetUsersList()),
    //startGetCurrentCase: dispatch(startGetCurrentCase(ownProps.uid, ownProps.id)),
    //startSetMessagesList: dispatch(startSetMessagesList(ownProps.uid, ownProps.id))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Users);
