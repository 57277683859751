import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Redirect } from 'react-router-dom'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";
import { Grid } from "@material-ui/core";

// Forms
import ActionSubmitForm from "components/Forms/ActionSubmitForm.jsx"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore, setFirstAction } from "store/actions/actionsActions"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateActionModal: false,
      editActionID: "",
      editActionTitle: "",
    };
  }

  handleClickOpen = (modal, actionID, actionTitle) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      editActionID: actionID,
      editActionTitle: actionTitle
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
    let actionsStats = {
      points: 0,
      confirmed: 0,
      pending: 0,
      assigned: 0
    }

    // Load data for Table
    // if there are no actions make a default one

    if (this.props.actionsData.dataRows.length === 0) {
      setFirstAction(this.props.authUser)
    }

    let actionsData = this.props.actionsData.dataRows.map((prop, key) => {
      let colObj = { ...prop }

      const actionID = colObj.actionId
      const title = colObj.title

      // Load Data for Summary Cards and add buttons
      switch (colObj.status) {
        case "assigned":
          actionsStats["assigned"] += 1
          return {
            ...colObj,
            // status: (<span style={{ color: "red" }}>assigned</span>),
            id: key,
            actions: (
              <Button
                color="primary"
                fullWidth
                onClick={
                  () => this.handleClickOpen("updateActionModal",
                    actionID,
                    title,
                  )}
              >COMPLETE</Button>)
          };
        case "pending":
          actionsStats["pending"] += 1
          return {
            ...colObj,
            // status: (<span style={{ color: "yellow" }}>pending</span>),
            id: key,
            actions: (
              <Button
                color="info"
                fullWidth
                onClick={
                  () => this.handleClickOpen("updateActionModal",
                    actionID,
                    title,
                  )}
              >UPDATE</Button>)
          };
        case "confirmed":
          actionsStats["confirmed"] += 1
          actionsStats["points"] += parseInt(colObj.points)
          return {
            ...colObj,
            id: key,
            // status: (<span style={{ color: "#7ddb46" }}>confirmed</span>),
            actions: (
              <Button
                color="info"
                fullWidth
                onClick={
                  () => this.handleClickOpen("updateActionModal",
                    actionID,
                    title,
                  )}
              >UPDATE</Button>)
          };
      }
    })

    // LEADERBOARD
    let userRow = this.props.users.dataRows[this.props.users.userIndex]

    // Update points if wrong
    // if (actionsStats.points !== userRow[1]) {
    //   setUserScore(actionsStats.points, this.props.authUser.uid)
    // }

    // Make the users row blue
    userRow = userRow.map(datum => {
      return <p style={{ color: "#24aee4", marginBottom: '0' }}>{datum}</p>
    })
    this.props.users.dataRows[this.props.users.userIndex] = userRow

    this.setState({
      actionsStats,
      actionsData,
      users: this.props.users,
      UID: this.props.authUser.uid,
      dataLoaded: true
    })
  }

  handleChange = (docID) => {
    console.log(docID)
  }

  render() {
    const { AuthBool, classes } = this.props;

    // Format and upload data to firestore
    // updateFirestoreWithTableData('TEST-actions-list',(tableToFirestoreObjects(this.props.actionsData)))
    // updateFirestoreWithTableData('TEST-users',(tableToFirestoreObjects(this.props.users)))

    // // add uid to firestore documents
    // if(this.state.dataLoaded === true){
    //   this.props.actionsData.dataRows.map(row => addFieldToDocument(row[0], {volUID: "TestID"}))
    // }

    if (AuthBool) {
      if (this.props.actionsData.dataLoaded
        && this.props.users.dataLoaded
        && !this.state.dataLoaded) {
        this.loadData()
      }
    }

    if (this.state.dataLoaded) return <Redirect to='/home' />
    return (
      <div>
        {
          this.state.dataLoaded ? (
            <GridContainer>
              <GridItem xs={12} lg={9}>
                <h1 className={classes.title}>GO TO PROFILE</h1>
                <div>
                  {/* UPDATE ACTION MODAL */}
                  <Dialog
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge
                    }}
                    open={this.state.updateActionModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("updateActionModal")}
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("updateActionModal")}
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h2 className={classes.modalTitle}>EDIT ACTION: {this.state.editActionTitle}</h2>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <ActionSubmitForm
                        UID={this.state.UID}
                        actionID={this.state.editActionID}
                        title={this.state.editActionTitle}
                        modalClose={!this.state.updateActionModal}
                      />
                      <Button
                        className={classes.ltrButtonWhite}
                        style={{
                          marginLeft: "10px",
                          marginTop: "10px"
                        }}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("updateActionModal")}
                      >
                        CLOSE
              </Button>
                    </DialogContent>
                  </Dialog>
                </div>
              </GridItem>


            </GridContainer>
          ) : (
              <p className={classes.cardSubtitle}>Loading...</p>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    actionsData: state.actions.actions,
    users: state.actions.users,
    authUser: state.auth.user,
    user: state.profile.user
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Orders);
