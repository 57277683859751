import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import { routerMiddleware } from 'connected-react-router'
import { ConnectedRouter } from 'connected-react-router'
import logger from 'redux-logger';

import { firebase, db } from './firebase/fbConfig'
import { login, logout } from './store/actions/authActions'
import { startSetActionsList, startSetUsersList, startSetPendingActionsList } from './store/actions/actionsActions'
import { startSetAssessmentsList } from './store/actions/assessmentActions'
import rootReducer from './store/reducers/rootReducer'
import indexRoutes from "routes/index.jsx";
import fbConfig from './firebase/fbConfig'
import Blog from "./views/Connect/Blog.jsx";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";


const hist = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer(hist),
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument({ getFirebase, getFirestore }),
      routerMiddleware(hist),
      // logger
    ),
    reduxFirestore(fbConfig),
    reactReduxFirebase(fbConfig)
  )
);

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <ConnectedRouter history={hist}>
        <Router history={hist}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return <Route path={prop.path} component={prop.component} key={key} />;
            })}
          </Switch>
        </Router>
      </ConnectedRouter>
    </DndProvider>
  </Provider>,
  document.getElementById("root")
);

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    console.log('useruseruser', user)
    // Name, email address, and profile photo Url
    store.dispatch(login(user))
    store.dispatch(startSetActionsList(user.uid))
    // ADMIN PANEL ONLY SHOWS IF firebase > TEST-users > Loggedin user document > admin: true
    store.dispatch(startSetUsersList(user))
    store.dispatch(startSetAssessmentsList(user.uid))

    // const doc = await db.collection('TEST-users').doc(user.uid).get()
    // if(doc.exists && hist.location.pathname === '/' && doc.data().disabled === true){
    //   hist.push('/disabled')
    // }
    if (hist.location.pathname === '/') {
      hist.push('/home')
    }
  }
  else {
    hist.push('/pages/login-page')
  }
})
